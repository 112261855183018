import { useCookies } from "react-cookie";
import { AccountDTO, AccountRequestDTO } from '../../dtos/accounts/accountDTO';
import { useAxios } from "./useAxios";

type ApiResponse<T> = {
  data: T[];
}

export function useAccountsAPI() {
  const [cookies] = useCookies(["lynk.access-token", "lynk.partner-id"]);
  const authToken = {
    headers: { Authorization: `Bearer ${cookies["lynk.access-token"]}` },
  };

  const { getApi, deleteApi, postApi } = useAxios();

  async function getUserAccounts(
    productId: string,
    productUserId: string,
  ): Promise<ApiResponse<AccountDTO>> {
    return getApi(`/products/${productId}/accounts?user_id=${productUserId}`, authToken);
  }

  async function getPlaidLinkToken(): Promise<ApiResponse<AccountDTO>> {
    return getApi(`/request_plaid_link_token`, authToken);
  }

  async function getConnectedAccount(productId: string): Promise<ApiResponse<AccountDTO>> {
    return getApi(`/products/${productId}/linked_accounts?product_owned=true`, authToken);
  }

  async function deleteConnectedAccount(productId: string, accountId:string): Promise<ApiResponse<AccountDTO>> {
    return deleteApi(`/products/${productId}/linked_accounts/${accountId}`, authToken);
  }

  async function postConnectedAccount(
    productId: string,
    data: AccountRequestDTO,
  ): Promise<ApiResponse<AccountDTO>> {
    return postApi(`/products/${productId}/linked_accounts`, data, authToken);
  }

  return {
    getUserAccounts,
    getPlaidLinkToken,
    getConnectedAccount,
    deleteConnectedAccount,
    postConnectedAccount,
  };
}
