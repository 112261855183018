import React, { FC } from "react";
import Button from "@mui/material/Button";
import { ButtonProps } from "./types";
import { makeStyles } from '@mui/styles';
import { color } from "../../../consts/ColorConst";

const useStyles = makeStyles(() => ({
  containedStyle:{
      borderRadius: "12px",
      fontSize: "14px",
      fontWeight: 500,
      textTransform: "none",
      backgroundColor: color.BLUE_01,
      color: color.WHITE_01,
      "&:disabled":{
          backgroundColor: color.GRAY_05,
          color: color.WHITE_01,
      }
    },
  textStyle: {
    color: color.BLUE_01,
    padding: "0 4px",
    fontSize: 14,
    fontWeight: 500,
    textTransform: "none",
  }  
}));

export const LynkButtonText: FC<ButtonProps> = ({
  text,
  isSubmitButton = false,
  size,
  color,
  sx,
  onClick,
  disabled = false,
  variant = "contained",
  id,
}) => {
  const classes = useStyles();
  return (
    <Button
      data-testid={id}
      className={ variant === "text" ? classes.textStyle : classes.containedStyle }
      disableElevation
      variant={variant}
      onClick={onClick && (() => onClick())}
      size={size ? size : "large"}
      color={color ? color : "primary"}
      type={isSubmitButton ? "submit" : "button"}
      sx={sx}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};
