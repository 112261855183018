import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { Layout } from "../../oldComponents/layout";
import { isPasswordValid } from "../../utils/handleValuesHelper";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useApi } from "../../services/api/hooks";
import { PartnerUserRequestDTO } from "../../dtos/partnerUsers/partnerUserRequestDTO";
import { KarteraDialogue } from "../../components/kartera/dialogue";
import { KarteraButton } from "../../components/kartera/button";
import { KarteraTextField } from "../../components/kartera/textField";
import { color } from "../../consts/ColorConst";

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "100px 40px 40px",
    margin: "0 auto",
  },
  formContainer: {
    width: "420px",
    margin: "0 auto",
    backgroundColor: color.WHITE_01,
    padding: '20px 24px',
    borderRadius: 10,
    boxShadow: theme.shadow.elevation16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 14,
  },
  title: {
    fontSize: 20,
    marginBottom: 16,
  },
  buttonWrapper: {
    width: '100%',
    marginTop: 8,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  loadingWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export function Invite() {
  const { t } = useTranslation("translation", { keyPrefix: "invite" });
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [ params ] = useSearchParams();

  const { postPartnerUser, postStaffUser } = useApi();
  
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [hasError, setHasError] = useState(false);
  const [submitReturnMessage, setSubmitReturnMessage] = useState("");
  const [errorMessages, setErrorMessages] = useState({
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
  });

  const token = params.get('token');
  const email = params.get('email');

  document.title = "Kartera - Invite";


  useEffect(() => {
    setHasError(!!Object.values(errorMessages).find((msg) => msg !== ""));
  }, [errorMessages]);

  useEffect(() => {
    if (!token || !email) navigate("/invalid-address");
  }, []);

  function validatePassword() {
    const errorMessage = !isPasswordValid(password)
      ? t("errorMsgPassword")
      : "";
    setErrorMessages((current) => ({ ...current, password: errorMessage }));
  }

  function validateConfirmPassword() {
    const errorMessage =
      password !== confirmPassword ? t("errorMsgConfirmPassword") : "";
    setErrorMessages((current) => ({
      ...current,
      confirmPassword: errorMessage,
    }));
  }

  async function handleSubmit() {
    try {
      if (!token || !email) return;
      const data: PartnerUserRequestDTO = {
        token: token,
        email: email,
        legal_first_name: firstName,
        legal_last_name: lastName,
        password: password,
        confirm_password: confirmPassword,
      };
      if (pathname.includes("invite-staff-user")) {
        await postStaffUser(data);
      } else {
        await postPartnerUser(data, true);
      }
      setSubmitReturnMessage(t("successMessage"));
    } catch (error: any) {
      if (error.message) {
        setSubmitReturnMessage(error.message);
      } else {
        setSubmitReturnMessage(t('errorMessage', { email }));
      }
    }
  }

  const disabledButton =
    !!token &&
    !!email &&
    !!firstName &&
    !!lastName &&
    !!password &&
    !!confirmPassword &&
    !hasError;

  return (
    <Layout>
      <Box className={classes.container}>
          <Box className={classes.formContainer}>
            <h3 className={classes.title}>{t("title")}</h3>
            <KarteraTextField
                label={t("firstNameLabel")}
                fullWidth
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
            />
            <KarteraTextField
                width="400px"
                fullWidth
                label={t("lastNameLabel")}
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                required
            />
            <KarteraTextField
                width="400px"
                type="password"
                fullWidth
                label={t("password")}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                errorMessage={errorMessages["password"]}
                onBlur={validatePassword}
                required
            />
            <KarteraTextField
                width="400px"
                type="password"
                fullWidth
                label={t("confirmPassword")}
                onChange={(e) => setConfirmPassword(e.target.value)}
                errorMessage={errorMessages["confirmPassword"]}
                onBlur={validateConfirmPassword}
                value={confirmPassword}
            />
            <Box className={classes.buttonWrapper}>
              <KarteraButton
                text={t("confirmButton")}
                disabled={!disabledButton}
                onClick={handleSubmit}
              />
            </Box>
          </Box>
      </Box>
      { submitReturnMessage && (
         <KarteraDialogue 
          activeButtonType="primary"
          actionButtonText={t("doneButton")}
          onActionClick={() => navigate("/login")}
          size="small"
          autoHeight
          supressPanelTop
         >
          {submitReturnMessage}
        </KarteraDialogue>
      )}
    </Layout>
  );
}