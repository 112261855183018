import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { useApi } from "../../services/api/hooks";
import { color } from "../../consts/ColorConst";
import {
  checkMockData,
  generateUserMockData,
  getMockPaymentsData,
} from "../../utils/generateMockData";
import { UserDetailsPanel } from "./userDetailsPanel";
import { useTransactions } from "../../hooks/useTransactions";
import { dateConverter } from "../../utils/dateConverter";
import { ProductUserDTO } from "../../dtos/productUsers/productUserDTO";
import { KarteraTable } from "../../components/kartera/table";
import { LinkedAccountDTO } from "../../dtos/linkedAccounts/linkedAccountDTO";
import { PaymentStatus } from "../../components/paymentStatus";
import { PageStateType } from "../../components/kartera/table/types";
import { setSelectedProductUser } from "../../reducers/productUserReducers";

const useStyles = makeStyles(() => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '36px 40px',
    backgroundColor: color.GRAY_01,
  },
  linkWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  plaidLink: {
    textDecoration: 'none',
    fontSize: 14,
    fontWeight: 500,
    color: color.GREEN,
  },
  panelsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    margin: '0 0 25px',
    gap: 20,
  },
}));

export function UserDetails() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('translation', { keyPrefix: 'userDetail' });
  const { getUserDetails, searchPayments, getLinkedAccounts } = useApi();
  const { product_id, user_id } = useParams();

  const { renderTransactionDescription } = useTransactions();

  const [user, setUser] = useState<ProductUserDTO>();

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [accounts, setAccounts] = useState<LinkedAccountDTO[]>([]);
  const [refreshDate, setRefreshDate] = useState(new Date());
  const [paginationAndSortingMode, setPaginationAndSortingMode] = useState<'server' | 'client'>(
    'server',
  );

  document.title = user?.first_name
    ? `Kartera - ${user?.first_name} ${user?.last_name}`
    : 'Kartera';

  const filterFields = [
    { text: t('colDate'), value: 'created_at' },
    { text: t('colDescription'), value: 'description' },
    { text: t('colAccountId'), value: 'linked_account_id' },
    { text: t('colId'), value: 'id' },
    { text: t('colAmount'), value: 'amount' },
    { 
      text: t('colStatus'), 
      value: 'status', 
      items: [
        { value: 'INITIATED', text: t('queued') },
        { value: 'PROCESSED', text: t('completed') },
        { value: 'CANCELLED', text: t('cancelled') },
      ] 
    },
  ];

  function getParsedDate(params: GridValueGetterParams) {
    return dateConverter(params.row.created_at);
  }

  function getFormattedValue(params: GridValueGetterParams) {
    return  `${params.value} USD`;
  }

  function renderStatus(params: GridRenderCellParams) {
    return <PaymentStatus status={params.value} />;
  }

  const columns: GridColDef[] = [
    {
      field: 'created_at',
      headerName: t('colDate'),
      minWidth: 110,
      align: "right",
      valueGetter: getParsedDate,
    },
    {
      field: 'description',
      headerName: t('colDescription'),
      minWidth: 370,
      renderCell: renderTransactionDescription,
    },
    {
      field: 'linked_account_id',
      headerName: t('colAccountId'),
      minWidth: 240,
    },
    { field: 'id', headerName: t('colId'), minWidth: 230 },
    {
      field: 'amount',
      type: 'string',
      headerName: t('colAmount'),
      minWidth: 110,
      valueGetter: getFormattedValue, 
      align: 'right',
    },
    {
      field: 'status',
      headerName: t('colStatus'),
      minWidth: 120,
      renderCell: renderStatus,
    },
  ];

  async function handleAccount(account: string) {
    setSelectedAccount(account);
    setRefreshDate(new Date());
  }

  async function getUserPaymentsData(pageState: PageStateType, filters: any) {
    if (checkMockData()) {
      setPaginationAndSortingMode('client');
      setUser(generateUserMockData());
      return {
        data: getMockPaymentsData(),
        metadata: {
          total_elements: 500,
        },
      };
    }

    if (!product_id || !user_id || !selectedAccount) return;
    setPaginationAndSortingMode('server');
    return searchPayments(product_id, {
      type: "PAYMENT", 
      user_id,
      description: filters.description,
      id: filters.id,
      all: filters.all,
      linked_account_id: filters.linked_account_id,
      amount: filters.amount,
      status: filters.status,
      date_from: filters.date_from,
      date_to: filters.date_to,
      paginate: {
        sort: {
          field: pageState.sortField,
          direction: pageState.sortDirection,
        },
        page_number: pageState.page,
        size: pageState.pageSize
      },
    });
  }

  async function getUserData() {
    if (!product_id || !user_id) return;
    const userResponse = await getUserDetails(product_id, user_id);
    if (userResponse.email) {
      setUser(userResponse);
      dispatch(setSelectedProductUser(userResponse));
    }
  }

  async function getLinkedAccountsByUser() {
    if (!product_id || !user_id) return;
    const response = await getLinkedAccounts(product_id, user_id, false);
    setAccounts(response.data);
  }
 
  async function getAsyncData() {
    await getLinkedAccountsByUser();
    await getUserData();
  }

  useEffect(() => {
    if (checkMockData()) {
      setUser(generateUserMockData());
      dispatch(setSelectedProductUser(generateUserMockData()));
      return;
    }
    getAsyncData();

    return () => {
      dispatch(setSelectedProductUser(null));
    }
  }, []); 

  return (
    <div data-testid='user-detail-page-container'>
      <Box className={classes.contentWrapper}>
        <Box className={classes.panelsContainer}>
          {user && (
            <UserDetailsPanel
              user={user}
              setSelectedAccount={handleAccount}
              userAccounts={accounts}
            />
          )}
          {(process.env.REACT_APP_ENVIRONMENT !== 'PRODUCTION') && (
            <Box className={classes.linkWrapper}>
              <Link
                className={classes.plaidLink}
                to={`/product/${product_id}/users/${user_id}/plaid-link-test`}
              >
                {t('plaidLink')}
              </Link>
            </Box>
          )}
        </Box>
        <KarteraTable
          rows={[]}
          title={t('title')}
          paginationAndSortingMode={paginationAndSortingMode}
          fetchData={getUserPaymentsData}
          refreshDate={refreshDate}
          columns={columns}
          defaultPageSize={10}
          minHeight={500}
          searchPlaceholder={t('searchPlaceholder')}
          csvFileName={t('csvFileName')}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          filterFields={filterFields}
          filteringEnabled
        />
      </Box>
    </div>
  );
}
