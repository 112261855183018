import React, { FC, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Input, Theme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CancelIcon from "@mui/icons-material/Cancel";
import { color } from "../../../consts/ColorConst";
import { TextFieldProps } from "./types";
import { sentenceCaseGenerator } from "../../../utils/helperFunctions";

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    fontFamily: "Roboto",
    position: "relative",
    "& .Mui-focused": {
      backgroundColor: color.WHITE_01,
      border: `2px solid ${color.BLUE}`,
    },
    "& .Mui-disabled": {
      "&:hover": {
        "& .MuiInputBase-input": {
          height: "18px",
        },
        backgroundColor: color.GRAY_09,
        borderColor: color.GRAY_09,
      },
    },
  },
  inputStyle: {
    height: "28px",
    backgroundColor: color.GRAY_09,
    color: color.GRAY_DARK_05,
    border: `2px solid ${color.GRAY_09}`,
    borderRadius: 6,
    padding: "6px 10px",
    "& .MuiInputBase-input": {
      fontSize: "14px",
      fontWeight: 400,
    },
    "& .MuiInputBase-root": {
      width: "fit-content",
    },
    "&:hover": {
      backgroundColor: color.SECONDARY,
      borderColor: color.GRAY_DARK_08,
    },
  },
  inputReadOnly: {
    backgroundColor: "transparent",
    color: color.GRAY_DARK_04,
    border: 0,
    padding: "6px 0",
  },
  hasError: {
    backgroundColor: color.RED_04,
    borderColor: color.RED_03,
    color: color.RED_DARK_06,
  },
  required: {
    fontSize: "16px",
    fontWeight: 700,
    paddingLeft: "2px",
    color: color.RED_03,
  },
  errorMessage: {
    margin: "2px 0 0 0",
    fontFamily: "inherit",
    fontSize: "11px",
    color: color.RED,
  },
  subNote: {
    margin: "2px 0 0 0",
    fontFamily: "inherit",
    fontSize: "11px",
    color: color.GRAY_DARK_07,
  },
  icon: {
    width: "14px",
    height: "14px",
    color: color.SECONDARY_DARK_02,
    "&:hover": {
      color: color.GRAY,
    },
  },
}));

export const LynkTextField: FC<TextFieldProps> = ({
  id,
  type = "rest",
  variant = "text",
  placeholder,
  width = "100%",
  errorMessage,
  error,
  subNote,
  required,
  value,
  onChange,
  onBlur,
  onCancel,
  disabled,
  ref,
  sx,
}) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [onFocus, setOnFocus] = useState(false);
  const [onCancelButtomFocus, setOnCancelButtonFocus] = useState(false);

  return (
    <div className={classes.root} style={{ width: width }}>
      <Input
        ref={ref}
        fullWidth
        id={id}
        data-testid={id}
        onFocus={() => setOnFocus(true)}
        readOnly={type === "read-only"}
        type={
          variant === "password"
            ? showPassword
              ? "text"
              : "password"
            : variant
        }
        className={`
          ${classes.inputStyle}
          ${type === "read-only" && classes.inputReadOnly}
          ${(errorMessage || error) && classes.hasError}
        `}
        disableUnderline
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={(e: any) => {
          setOnFocus(false);
          onBlur && onBlur(e);
        }}
        required={required}
        disabled={disabled}
        sx={sx}
        endAdornment={
          <InputAdornment position="end">
            {variant === "password" ? (
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={() => setShowPassword(!showPassword)}
                tabIndex={-1}
                disabled={disabled}
              >
                {showPassword ? (
                  <VisibilityIcon className={classes.icon} />
                ) : (
                  <VisibilityOffIcon className={classes.icon} />
                )}
              </IconButton>
            ) : (
              (onFocus || onCancelButtomFocus) && (
                <IconButton
                  aria-label="close button"
                  edge="end"
                  onMouseOver={() => setOnCancelButtonFocus(true)}
                  onClick={onCancel}
                  onMouseLeave={() => setOnCancelButtonFocus(false)}
                  tabIndex={-1}
                  disabled={disabled}
                >
                  <CancelIcon className={classes.icon} />
                </IconButton>
              )
            )}
          </InputAdornment>
        }
      />
      {errorMessage && (
        <p className={classes.errorMessage}>
          {sentenceCaseGenerator(errorMessage)}
        </p>
      )}
      {subNote && (
        <p className={classes.subNote}>{sentenceCaseGenerator(subNote)}</p>
      )}
    </div>
  );
};
