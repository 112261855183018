import { useCookies } from "react-cookie";
import { PartnerDTO } from "../../dtos/partners/partnerDTO";
import { PartnerUserDTO } from "../../dtos/partnerUsers/partnerUserDTO";
import { PartnerUserInviteDTO } from "../../dtos/partnerUsers/partnerUserInviteDTO";
import { PartnerUserRequestDTO } from "../../dtos/partnerUsers/partnerUserRequestDTO";
import { ACHPaymentDTO } from "../../dtos/achPayments/achPaymentDTO";
import { ProductDTO } from "../../dtos/products/productDTO";
import { ProductTransactionDTO } from "../../dtos/transactions/productTransactionDTO";
import { ProductUserDTO } from "../../dtos/productUsers/productUserDTO";
import { StaffPartnerUserInviteRequestDTO } from "../../dtos/staffUsers/staffPartnerUserInviteRequestDTO";
import { StaffUserDTO } from "../../dtos/staffUsers/staffUserDTO";
import { useAxios } from "./useAxios";
import { CardDTO } from '../../dtos/cards/cardDTO';
import { ProductUpdateRequestDTO } from "../../dtos/products/productUpdateRequestDTO";

type ApiResponse<T> = {
  data: T[];
}

export function useStaffAdminAPI() {
  const [cookies] = useCookies(["lynk.access-token", "lynk.partner-id"]);
  const authToken = {
    headers: { Authorization: `Bearer ${cookies["lynk.access-token"]}` },
  };

  const { getApi, postApi, patchApi } = useAxios();
  
  async function getAdminPartners(
    pageSize = 10,
    currentPage = 0,
    sort = "createdAt,DESC",
    query = ""
  ): Promise<ApiResponse<PartnerDTO>> {
    return await getApi(
      `/staff/admin/partners?size=${pageSize}&page=${currentPage}&sort=${sort}${query}`,
      authToken,
      false
    );
  }

  async function getStaffUsers(
    pageSize = 10,
    currentPage = 0,
    sort = "createdAt,DESC",
    query = ""
  ): Promise<ApiResponse<StaffUserDTO>> {
    return await getApi(
      `/staff/users?size=${pageSize}&page=${currentPage}&sort=${sort}${query}`,
      authToken,
      false
    );
  }

  async function getAdminPartnerById(id: string): Promise<PartnerDTO> {
    return await getApi(`/staff/admin/partners/${id}`, authToken, false);
  }

  async function getAdminPartnerProductById(partnerId: string, productId: string): Promise<ProductDTO> {
    return await getApi(`/staff/admin/partners/${partnerId}/products/${productId}`, authToken, false);
  }

  async function updateAdminPartnerProductById(
    partnerId: string,
    productId: string,
    data: ProductUpdateRequestDTO,
  ): Promise<ProductDTO> {
    return await patchApi(
      `/staff/admin/partners/${partnerId}/products/${productId}`,
      data,
      authToken,
    );
  }

  async function getAdminPartnerProducts(
    partnerId: string,
    pageSize = 10,
    currentPage = 0,
    sort = "createdAt,DESC",
    query = ""
  ): Promise<ApiResponse<ProductDTO>> {
    return await getApi(
      `/staff/admin/partners/${partnerId}/products?size=${pageSize}&page=${currentPage}&sort=${sort}${query}`,
      authToken,
      false
    );
  }

  async function getAdminPartnerACHPayments(
    partnerId: string,
    productId: string,
    pageSize = 10,
    currentPage = 0,
    sort = "createdAt,DESC",
    query = ""
  ): Promise<ApiResponse<ACHPaymentDTO>> {
    return await getApi(
      `/staff/admin/partners/${partnerId}/products/${productId}/payments/ach`+
      `?size=${pageSize}&page=${currentPage}&sort=${sort}${query}`,
      authToken,
      false
    );
  }

  async function getAdminPartnerUsers(
    partnerId: string,
    pageSize = 10,
    currentPage = 0,
    sort = "createdAt,DESC",
    query = ""
  ): Promise<ApiResponse<PartnerUserDTO>> {
    return await getApi(
      `/staff/admin/partners/${partnerId}/users?size=${pageSize}&page=${currentPage}&sort=${sort}${query}`,
      authToken,
      false
    );
  }

  async function getAdminProductUsers(
    partnerId: string,
    productId: string,
    pageSize = 10,
    currentPage = 0,
    sort = "createdAt,DESC",
    query = ""
  ): Promise<ApiResponse<ProductUserDTO>> {
    return await getApi(
      `/staff/admin/partners/${partnerId}/products/${productId}/users?size=${pageSize}&page=${currentPage}&sort=${sort}${query}`,
      authToken,
      false
    );
  }

  async function getAdminProductTransactions(    
    partnerId: string,
    productId: string,
    pageSize = 10,
    currentPage = 0,
    sort = "createdAt,DESC",
    query = ""
  ): Promise<ApiResponse<ProductTransactionDTO>> {
    return await getApi(
      `/staff/admin/partners/${partnerId}/products/${productId}/transactions?size=${pageSize}&page=${currentPage}&sort=${sort}${query}`,
      authToken,
      false
    );
  }

  async function postStaffUserInvite(data: StaffPartnerUserInviteRequestDTO): Promise<PartnerUserInviteDTO> {
    return await postApi(`/staff/users/invite`, data, authToken);
  }

  async function postStaffUser(data: PartnerUserRequestDTO): Promise<StaffUserDTO> {
    return await postApi(`/staff/users`, data, authToken);
  }

  async function getAdminProductCards(
    pageSize = 10,
    currentPage = 0,
    sort = 'createdAt,DESC',
    query = '',
  ): Promise<ApiResponse<CardDTO>> {
    return await getApi(`/staff/admin/card_products`, authToken, false);
  }

  return {
    getAdminPartnerById,
    getAdminPartners,
    getStaffUsers,
    getAdminPartnerUsers,
    getAdminPartnerProductById,
    getAdminPartnerProducts,
    getAdminPartnerACHPayments,
    getAdminProductUsers,
    getAdminProductTransactions,
    postStaffUserInvite,
    postStaffUser,
    getAdminProductCards,
    updateAdminPartnerProductById
  };
}
