import React, { useCallback, useEffect, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Modal, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../services/api/hooks';
import { KarteraButton } from '../../../components/kartera/button';
import { KarteraProgress } from '../../../components/kartera/progress';
import { TwoFAEnableModalProps } from './types';
import { useOutSideClick } from '../../../hooks/useOUtsideClick';
import { KarteraPinCodeInput } from '../../../components/kartera/pinCodeInput';

const useStyles = makeStyles((theme: any) => ({
  stepOneContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 611,
    borderRadius: 20,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '28px',
    zIndex: 10,
    transform: 'translate(-50%, -50%)',
  },
  stepTwoContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 400,
    borderRadius: 20,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '28px',
    zIndex: 10,
    transform: 'translate(-50%, -50%)',
  },
  twoFAHeader: {
    padding: '0 0 15px 0',
    fontSize: 16,
    fontWeight: 500,
    width: '100%',
    textAlign: 'start',
  },
  pageDescription: {
    padding: '0 0 20px 0',
    fontSize: 14,
  },
  codeWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: 350,
  },
  buttonWrapper: {
    padding: '4px 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  errorMessage: {
    color: '#FF2559',
    fontSize: 12,
    padding: '0 0 10px 0',
  },
  message: {
    backgroundColor: '#d7faf0',
    marginTop: '-16px',
    fontSize: '13px',
    color: '#2e886f',
    padding: 12,
    borderRadius: '6px',
  },
  cancelButton: {
    margin: '0 12px 0 0',
  },
  codeInputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 0 20px 0',
  },
  finishButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export const TwoFAEnableModal: React.FC<TwoFAEnableModalProps> = ({ onModalOpen }) => {
  const classes = useStyles();
  const { postAuthTotpConnect, postAuthTotpActivate } = useApi();
  const { t } = useTranslation('translation', { keyPrefix: 'settings' });

  const modalRef = useRef(null);

  const [errorMessages, setErrorMessages] = useState({
    password: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [hasError, setHasError] = useState(false);
  const [qrCodeUri, setQrCodeUri] = useState('');
  const [secret, setSecret] = useState('');
  const [step, setStep] = useState(1);
  const [pinValue, setPinValue] = useState('');
  const [verifyDisabled, setVerifiedDisabled] = useState(true);
  const [requestedNewCode, setRequestedNewCode] = useState(false);
  const [totpDone, setTotpDone] = useState(false);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setHasError(!!Object.values(errorMessages).find((msg) => msg !== ''));
  }, [errorMessages]);

  const getdata = useCallback(async () => {
    const response = await postAuthTotpConnect();
    setQrCodeUri(response.qrCodeUri);
    setSecret(response.secret);
  }, []);

  const handleNext = useCallback(async () => {
    setStep(2);
  }, []);

  const handleFinish = useCallback(async () => {
    setLoadingOpen(true);
    setError(false);
    const response = await postAuthTotpActivate(pinValue, () => {
      setLoadingOpen(false);
      setError(true);
    });
      setTotpDone(true);
      setLoadingOpen(false);
      setError(false);
  }, [pinValue, secret]);

  const handleDone = useCallback(() => {
    onModalOpen(false);
  }, []);

  const handleCancel = useCallback(() => {
    onModalOpen(false);
  }, []);

  const handleLearnMore = useCallback(() => {
    window.location.assign('https://www.w3schools.com');
  }, []);

  useOutSideClick(modalRef, () => {
    handleCancel();
  });

  useEffect(() => {
    getdata();
  }, []);

  return (
    <Modal open={true} id={'changePasswordModal'}>
      {step === 1 ? (
        <Box className={classes.stepOneContainer} ref={modalRef}>
          <Typography className={classes.twoFAHeader}>{t('twoFATitle')}</Typography>
          <Typography className={classes.pageDescription}>{t('twoFADescription')}</Typography>
          <Box className={classes.codeWrapper}>
            { qrCodeUri ? <img src={qrCodeUri} sizes='200px' /> : <KarteraProgress /> }
          </Box>
          <Typography className={classes.pageDescription}>{t('qrCodeDescription')}</Typography>
          <Box className={classes.buttonWrapper}>
            <KarteraButton
              text={t('buttonLearnMore')}
              className={classes.cancelButton}
              onClick={handleLearnMore as any}
              variant={'tertiary'}
            />
            <Box>
              <KarteraButton
                text={t('buttonCancel')}
                className={classes.cancelButton}
                onClick={handleCancel as any}
                variant={'secondary'}
              />
              <KarteraButton text={t('buttonNext')} onClick={handleNext as any} />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className={classes.stepTwoContainer} ref={modalRef}>
          <Typography className={classes.twoFAHeader}>{t('twoFATitle')}</Typography>
          {totpDone === false && (
            <Typography className={classes.pageDescription}>{t('twoFACodeDescription')}</Typography>
          )}
          {totpDone ? (
            <>
              <Typography className={classes.pageDescription}>
                {t('twoFADoneDescription')}
              </Typography>
            </>
          ) : (
            <Box>
              {loadingOpen ? (
                <KarteraProgress />
              ) : (
                <Box className={classes.codeInputWrapper}>
                  <KarteraPinCodeInput
                    error={error}
                    length={6}
                    onComplete={(value: any) => {
                      setPinValue(value);
                      setVerifiedDisabled(false);
                    }}
                    disabled={error ? false : loadingOpen}
                    requestedNew={requestedNewCode}
                    setRequestedNew={setRequestedNewCode}
                    onChange={(value: string) => {
                      if (value.length < 6) {
                        setVerifiedDisabled(true);
                      }
                    }}
                  />
                  {error && (
                    <Typography className={classes.errorMessage} style={{ padding: '12px 0 0 0' }}>
                      {t('codeIncorrect')}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          )}
          <Box className={classes.buttonWrapper}>
            {totpDone ? (
              <Box className={classes.finishButtons}>
                <KarteraButton text={t('buttonDone')} onClick={handleDone as any} />
              </Box>
            ) : (
              <Box className={classes.finishButtons}>
                <KarteraButton
                  text={t('buttonCancel')}
                  className={classes.cancelButton}
                  onClick={handleCancel as any}
                  variant={'secondary'}
                />
                <KarteraButton
                  text={t('buttonFinish')}
                  onClick={handleFinish as any}
                  disabled={verifyDisabled}
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Modal>
  );
};
