import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsis,
  faFilter,
  faGear,
  faPlus,
  faSearch,
  faCopy, 
  faTrashAlt
} from "@fortawesome/pro-regular-svg-icons";
import { makeStyles } from "@mui/styles";
import { KarteraButton } from "../../button";
import { KarteraSearchField } from "../../searchField";
import { KarteraBadge } from "../../badge";
import { TableToolbarProps } from "./types";
import { color } from "../../../../consts/ColorConst";

const useStyles = makeStyles(() => ({
  root: {
    boxSizing: "border-box",
    width: "100%",
    height: "44px",
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 12px",
    backgroundColor: "white",
  },
  leftContentWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 16,
    fontWeight: 500,
  },
  rightButtonWrapper: {
    width: "auto",
    height: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: 8,
  },
  iconButton: {
    color: color.GREY_DARK_2,
  },
}));

export const KarteraTableToolbar: FC<TableToolbarProps> = ({
  counterTitle,
  searchPlaceholder,
  searchValue,
  setSearchValue,
  handleAddData,
  handleDeleteData,
  handleCopyData,
  handleSettings,
  handleFilterData,
  handleMoreOptions,
  handleExportData,
  totalElements,
  totalSelectedElements,
  quickFilter,
  filterCounter,
  filteringEnabled = true,
  component,
}) => {
  const classes = useStyles();

  const { t } = useTranslation("translation", { keyPrefix: "table" });
  const [searchOpen, setSearchOpen] = useState(false);

  function handleSearchCancel() {
    setSearchOpen(false);
    setSearchValue("");
  }

  function handleSearch(event: any) {
    setSearchValue(event.target.value)
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.leftContentWrapper}>
        {`${counterTitle} (${String(totalElements)})`}
      </Box>
      <Box className={classes.rightButtonWrapper}>
        {searchOpen ? (
          <Box>
            <KarteraSearchField
              placeholder={searchPlaceholder}
              value={searchValue}
              onChange={handleSearch}
              onCancel={handleSearchCancel}
            />
          </Box>
        ) : (
          <KarteraButton
            variant="tertiary"
            icon={<FontAwesomeIcon icon={faSearch} />}
            onClick={() => setSearchOpen(true)}
          />
        )}
        {quickFilter}
        {component}
        {handleAddData && (
          <KarteraButton
            variant="primary"
            icon={<FontAwesomeIcon icon={faPlus} />}
            text={t("buttonAdd")}
            onClick={handleAddData}
          />
        )}
        {handleCopyData && (
          <KarteraButton
            variant="tertiary"
            icon={<FontAwesomeIcon icon={faCopy} />}
            onClick={handleCopyData}
            disabled
          />
        )}
        {handleDeleteData && (
          <KarteraButton
            variant="tertiary"
            icon={<FontAwesomeIcon icon={faTrashAlt} />}
            text={`${
              totalSelectedElements && totalSelectedElements > 0
                ? "(" + totalSelectedElements + ")"
                : ""
            }`}
            onClick={handleDeleteData}
            disabled
          />
        )}
        {handleExportData && (
          <KarteraButton
            variant="tertiary"
            icon={<FontAwesomeIcon icon={faDownload} />}
            onClick={handleExportData}
          />
        )}
        {handleFilterData && filteringEnabled && (
          <KarteraBadge
            text={filterCounter ? String(filterCounter) : null}
          >
            <KarteraButton
              variant="tertiary"
              icon={<FontAwesomeIcon icon={faFilter} />}
              onClick={handleFilterData}
            />
          </KarteraBadge>
        )}
        {handleSettings && (
          <KarteraButton
            variant="tertiary"
            icon={<FontAwesomeIcon icon={faGear} />}
            onClick={handleSettings}
          />
        )}
        {handleMoreOptions && (
          <KarteraButton
            variant="tertiary"
            icon={<FontAwesomeIcon icon={faEllipsis} />}
            onClick={handleMoreOptions}
          />
        )}
      </Box>
    </Box>
  );
};
