import { useCookies } from "react-cookie";
import { useAxios } from "./useAxios";
import { ReportDTO, ReportRequestDTO, ReportSimpleRequestDTO } from "../../dtos/reports/reportDTO";

export function useReportsAPI() {
  const [cookies] = useCookies(["lynk.access-token", "lynk.partner-id"]);
  const authToken = {
    headers: { Authorization: `Bearer ${cookies["lynk.access-token"]}` },
  };

  const { postApi } = useAxios();

  async function getReportTotal(
    productId: string, 
    data: ReportRequestDTO,
  ): Promise<ReportDTO> {
    return postApi(
      `/products/${productId}/report/total`, 
      data, authToken
    );
  }

  async function getReportNewCustomers(
    productId: string, 
    data: ReportRequestDTO,
  ): Promise<ReportDTO> {
    return postApi(
      `/products/${productId}/report/new_customers`, 
      data, authToken
    );
  }

  async function getReportCashbackEarned(
    productId: string, 
    data: ReportSimpleRequestDTO,
  ): Promise<ReportDTO> {
    return postApi(
      `/products/${productId}/report/cashbacks_earned`, 
      data, authToken
    );
  }

  async function getReportPointsEarned(
    productId: string, 
    data: ReportSimpleRequestDTO,
  ): Promise<ReportDTO> {
    return postApi(
      `/products/${productId}/report/points_earned`, 
      data, authToken
    );
  }

  async function getReportPointsRedeemed(
    productId: string, 
    data: ReportSimpleRequestDTO,
  ): Promise<ReportDTO> {
    return postApi(
      `/products/${productId}/report/redeemed_points`, 
      data, authToken
    );
  }

  async function getReportCashbackRedeemed(
    productId: string, 
    data: ReportSimpleRequestDTO,
  ): Promise<ReportDTO> {
    return postApi(
      `/products/${productId}/report/redeemed_cashbacks`, 
      data, authToken
    );
  }

  async function getReportDisputes(
    productId: string, 
    data: ReportRequestDTO,
  ): Promise<ReportDTO> {
    return postApi(
      `/products/${productId}/report/disputes`, 
      data, authToken
    );
  }

  async function getReportAverageAmount(
    productId: string, 
    data: ReportRequestDTO,
  ): Promise<ReportDTO> {
    return postApi(
      `/products/${productId}/report/average_amount`, 
      data, authToken
    );
  }


  return {
    getReportTotal,
    getReportNewCustomers,
    getReportDisputes,
    getReportAverageAmount,
    getReportCashbackEarned,
    getReportPointsEarned,
    getReportPointsRedeemed,
    getReportCashbackRedeemed,
  };
}
