import { useCookies } from "react-cookie";
import { ProductApiKeyDTO } from "../../dtos/apiKey/productApiKeyDTO";
import { useAxios } from "./useAxios";

type ApiResponse<T> = {
  data: T[];
}

export function useProductApiKeyAPI() {
  const [cookies] = useCookies(["lynk.access-token", "lynk.partner-id"]);
  const authToken = {
    headers: { Authorization: `Bearer ${cookies["lynk.access-token"]}` },
  };

  const { getApi, postApi, deleteApi } = useAxios();


  async function getProductApiKeys(
    productId: string,
    pageSize = 100,
    currentPage = 0,
    sort = "createdAt,DESC"
  ): Promise<ApiResponse<ProductApiKeyDTO>> {
    return await getApi(
      `/products/${productId}/apikeys?size=${pageSize}&page=${currentPage}&sort=${sort}`, 
      authToken,
      false,
    );
  }

  async function createProductApiKey(productId: string): Promise<ProductApiKeyDTO> {
    return await postApi(`/products/${productId}/apikeys`, {}, authToken);
  }

  async function removeProductApiKey(productId: string, apiId: string) {
    return await deleteApi(
      `/products/${productId}/apikeys/${apiId}`,
      authToken
    );
  }

  return {
    getProductApiKeys,
    createProductApiKey,
    removeProductApiKey,
  };
}
