import { useCookies } from "react-cookie";
import { CardDTO } from "../../dtos/cards/cardDTO";
import { useAxios } from "./useAxios";

type ApiResponse<T> = {
  data: T[];
}

export function useCardsAPI() {
  const [cookies] = useCookies(["lynk.access-token", "lynk.partner-id"]);
  const authToken = {
    headers: { Authorization: `Bearer ${cookies["lynk.access-token"]}` },
  };

  const { getApi } = useAxios();

  async function getUserCards(
    productId: string, 
    productUserId: string, 
    accountId: string
  ): Promise<ApiResponse<CardDTO>> {
    return getApi(
      `/products/${productId}/cards?user_id=${productUserId}&account_id=${accountId}`, 
      authToken
    );
  }
  
  async function getUserCardToken(productId: string, productUserId: string): Promise<string> {
    return getApi(`/products/${productId}/cards/${productUserId}/token`, authToken, false);
  }


  return {
    getUserCards,
    getUserCardToken,
  };
}
