import React from 'react'

type KarteraLogoProps = {
    size?: number;
    color?: string;
};

export default function KarteraLogo({ size = 36, color = '#202124' }: KarteraLogoProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M36 36H23.9331L11.8375 24.1534L5.54614 18L11.8375 11.8323L18 18L36 36Z" fill={color}/>
        <path d="M36 0L18 18L11.8376 24.1534L0 36V23.4201L5.54618 18L11.8376 11.8323L23.9331 0H36Z" fill={color}/>
        <path d="M0 0V11.3434L11.5939 0H0Z" fill={color}/>
    </svg>
  )
}
