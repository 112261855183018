import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { color } from '../../../../consts/ColorConst';
import { truncateText } from '../../../../utils/helperFunctions';
import { KarteraRadioGroup } from '../../../../components/kartera/radioGroup';
import { ProductUserSearchDTO } from '../../../../dtos/productUsers/productUserSearchDTO';
import { dateConverter } from '../../../../utils/dateConverter';
import { KarteraCurrencyField } from '../../../../components/kartera/currencyField';
import { PaymentDTO } from '../../../../dtos/payments/paymentDTO';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 16,
    fontWeight: 500,
    paddingBottom: 4
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY_DARK_1,
    paddingTop: 6,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 6,
  },
  transactionData: {
    display: 'flex',
    gap: 10,
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY_DARK_4,
    '& strong': {
      fontWeight: 500,
    }
  },
  customerData: {
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY_LIGHT_1,
    '& strong': {
      fontWeight: 400,
      color: color.GREY_DARK_4,
    }
  },
  error: {
    fontSize: 11,
    fontWeight: 500,
    color: color.RED
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 0 22px 0',
  },
  refundOptionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    '& strong' : {
      color: color.GREY_LIGHT_1,
      fontWeight: 400,
    }
  },
}));


type Props = {
  customer: ProductUserSearchDTO;
  transaction: PaymentDTO;
  setPartialAmount: (value: string) => void;
  hasError: boolean;
  setHasError: (hasError: boolean) => void;
};

type PaymentType = 'FULL' | 'PARTIAL';

export function SelectAmount({
  customer,
  transaction,
  setPartialAmount,
  setHasError,
  hasError,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'refunds' });

  const [amountType, setAmountType] = useState<PaymentType>('FULL');
  const [amount, setAmount] = useState(transaction.amount);
  const [error, setError] = useState('');

  function handleAmountBlur() {
    if (Number(amount) > Number(transaction.amount)) {
      setError(t('errorBiggerThanAmount'));
      setHasError(true);
    } else if (Number(amount) === 0) {
      setError(t('errorNoAmount'));
      setHasError(true);
    } else if (Number(amount) < 0) {
      setError(t('errorNegativeAmount'));
      setHasError(true);
    } else {
      setError('');
      setPartialAmount(amount);
      setHasError(false);
    }
  }

  function handleAmountType(value: any) {
    if (value === 'FULL') {
      setAmount(transaction.amount);
      setError('');
    }
    setAmountType(value);
  }

  useEffect(() => {
    setPartialAmount(transaction.amount);
  }, []);

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>{t('createRefund')}</Typography>
      <Typography className={classes.label}>{t('customer')}:</Typography>
      <Typography className={classes.customerData}>
        <strong>{`${customer.first_name} ${customer.last_name}`}</strong>
        {` (${customer.email})`}
      </Typography>
      <Typography className={classes.label}>{t('transaction')}</Typography>
      <Typography className={classes.transactionData}>
        <strong>{dateConverter(transaction.created_at)}</strong>
        {`${truncateText(transaction.description, 30)}`}
      </Typography>
      <Divider sx={{ margin: '12px 0' }} />
      <Box className={classes.refundOptionsContainer}>
        <Typography className={classes.subtitle}>{t('refundTo')}</Typography>
        <KarteraRadioGroup
          items={[
            { text: t('cashBackCredit'), value: 'CASH_BACK', disabled: true },
            { text: <Trans>{t('bankAccountSelector')}</Trans>, value: 'BANK_ACCOUNT' },
          ]}
          itemsGap={1}
          value={'BANK_ACCOUNT'}
        />
      </Box>
      <Divider sx={{ margin: '12px 0' }} />
      <Box className={classes.refundOptionsContainer}>
        <Typography className={classes.subtitle}>{t('amountForRefund')}</Typography>
        <KarteraRadioGroup
          items={[
            { text: `Full (${transaction.amount} USD)`, value: 'FULL' },
            { text: 'Partial', value: 'PARTIAL' },
          ]}
          itemsGap={1}
          value={amountType}
          onChange={(e: any) => handleAmountType(e.target.value)}
          error={!!error}
        />
        <KarteraCurrencyField
          sx={{ marginLeft: 24 }}
          width={230}
          disabled={amountType !== 'PARTIAL'}
          placeholder={transaction.amount}
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          onBlur={handleAmountBlur}
          decimalScale={2}
          errorMessage={error}
        />
      </Box>
    </Box>
  );
}
