import { FocusEvent } from "react";

export function IsUnmaskFieldFilled(masked: string, maskChar: string) {
    const value = masked;
    const unmask = value.replaceAll(/[^a-zA-Z0-9]+/g, '').replaceAll(maskChar, '');
    return !!unmask;
}

export function numberFormatter(num: number, digits = 2) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "K" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
      { value: 1e12, symbol: "T" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup.slice().reverse().find(item => num >= item.value);
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + " " + item.symbol : "0";
}

export function currencyFormatter(value: number) {
    const USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return USDollar.format(value);
}
  

export function checkIsFilled(e: FocusEvent<HTMLInputElement>, lenght: number, maskChar: string) {
    const value = e.target.value;
    const unmask = value.replaceAll(/[^a-zA-Z0-9]+/g, '').replaceAll(maskChar, '');
    if (!unmask) return true;
    return unmask.length >= lenght;
}

export function handleOnlyNumber(value: any, setValue: (e: string) => void) {
    const regex = /^[0-9]*$/;
    if (regex.test(value) || value === "") {
        setValue(value)
    }
}

export function handleOnlyChar(value: any, setValue: (e: string) => void) {
    const regex = /[0-9]/;
    if (!regex.test(value) || value === "") {
        setValue(value)
    }
}


export function isDateValid(value: string) {
    try {
        const today = new Date();
        const date = new Date(value);
        return date < today;
    } catch (error) {
        return false;
    }
}

export function isBirthdayValid(value: string) {
    const currentYear = new Date().getFullYear();
    const birthYear = value.split('-')[0];
    const diff = currentYear - parseInt(birthYear);
    if (diff > 120 || diff < 16) {
        return false
    } else {
        return true
    }
}

export function isSINValid(sin: string) {
    const sinNumbers = sin.split(' ').join('');
    if (sinNumbers.length !== 9 || sinNumbers === '000000000') return false;
    const arr = sinNumbers.split('');
    const checkNumber = arr.pop();
    if (!checkNumber) return false;
    const sum = arr
        .map((val, index) => +val * ((index % 2) + 1))
        .join('').split('')
        .reduce((acc, val) => acc + parseInt(val), 0) * 9 % 10;

    return +checkNumber === sum;
}

export function isSSNValid(ssn: string) {
    const [part1, part2, part3] = ssn.split(' ');
    if (part1.length !== 3 ||
        part1 === '000' ||
        part1 === '666' ||
        +part1 >= 900)
        return false;
    if (part2.length !== 2 || part2 === '00') return false;
    if (part3.length !== 4 || part3 === '0000') return false;

    return true;
}

export function isEmailValid(email: string) {
    //eslint-disable-next-line
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);
}

export function containDangerousCharacters(value: string) {
    return /[<>&\\%$#*?(){}[\];:|]/g.test(value);
}

export function isEmailDomainAllowed(email: string) {
    const NOT_ALLOWED_EMAIL_DOMAINS = [
        "@gmail",
        "@hubspot",
        "@sendinblue",
        "@aweber",
        "@protonmail",
        "@outlook",
        "@yahoo",
        "@zoho",
        "@aol",
        "@mail.com",
        "@gmx",
        "@icloud",
        "@yandex",
    ];
    
    return !NOT_ALLOWED_EMAIL_DOMAINS.find((domain) => email.includes(domain));
}
  
export function emailIncludesPlusSign(email: string) {
    return email.split("@")[0].includes("+");
}

export function isPasswordValid(password: string) {
    return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-@$!%*#?&]).{8,}$/g.test(password);
}

export function isValidUrl(url: string) {
    //eslint-disable-next-line
    return /^http(s)?:\/\/[^\/\/](?!.*\.\.)(?!\/*\/\/)[\w-]+([\w\/.-:])+[\w-\/]+[\w\/]$$/i.test(url);
}

export function formatFloat(value: string) {
    const floatValue = parseFloat(value);
    return floatValue.toFixed(2).toString();
}

export function isWebsiteAddressValid(url: string) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i',
    ); // fragment locator
    return !!pattern.test(url);
}