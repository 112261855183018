import { useCookies } from "react-cookie";
import { ProductUserDTO } from "../../dtos/productUsers/productUserDTO";
import { useAxios } from "./useAxios";
import { ProductUserSearchDTO } from "../../dtos/productUsers/productUserSearchDTO";

type ApiResponse<T> = {
  data: T[];
}

export function useProductUsersAPI() {
  const [cookies] = useCookies(["lynk.access-token", "lynk.partner-id"]);
  const authToken = {
    headers: { Authorization: `Bearer ${cookies["lynk.access-token"]}` },
  };

  const { getApi } = useAxios();

  async function getUsersList(
    productId: string,
    query = '',
    pageSize = 20,
    currentPage = 0,
    sort = "createdAt,DESC"
  ): Promise<ApiResponse<ProductUserDTO>> {
    return await getApi(
      `/products/${productId}/users?${query && query + '&'}size=${pageSize}&page=${currentPage}&sort=${sort}`,
      authToken,
      false
    );
  }

  async function getUsersSearch(
    productId: string, 
    first_name = '',
    last_name = '',
    email = '',
    limit = '50',
  ): Promise<ProductUserSearchDTO[]> {
    return await getApi(
      `/products/${productId}/users/search?` +
      `${`first_name=${first_name}`}` +
      `${`&last_name=${last_name}`}` +
      `${`&email=${email}`}` +
      `${`&limit=${limit}`}`,
      authToken,
      false
    );
  }

  async function getUserDetails(
    productId: string, 
    productUserId: string
  ): Promise<ProductUserDTO> {
    return getApi(`/products/${productId}/users/${productUserId}`, authToken);
  }

  async function getUserPlaidLinkToken(
    productId: string, 
    productUserId: string
  ): Promise<ProductUserDTO> {
    return getApi(`/products/${productId}/users/${productUserId}/request_plaid_link_token`, authToken);
  }

  return {
    getUsersList,
    getUsersSearch,
    getUserDetails,
    getUserPlaidLinkToken,
  };
}
