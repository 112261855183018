import React, { FC } from "react";
import FormControl from "@mui/material/FormControl";
import { Theme, Typography } from "@mui/material";
import { SelectProps } from "./types";
import { makeStyles } from "@mui/styles";
import { color } from "../../../consts/ColorConst";

interface SelectStyle {
  width: string;
}

const useStyles = makeStyles<Theme, SelectStyle>(() => ({
  container: {
    width: ({ width }) => width,
  },
  selectContainer: {
    backgroundColor: color.GRAY_01,
    display: "flex",
    alignItems: "center",
    border: `2px solid ${color.GRAY_01}`,
    borderRadius: "12px",
    padding: "0 12px",
  },
  hasError: {
    borderColor: color.RED_02,
    color: color.RED_02,
  },
  labelStyle: {
    fontSize: "14px",
    fontWeight: 700,
    paddingBottom: "14px",
  },
  select: {
    outline: 0,
    width: "100%",
    background: "transparent",
    border: "none",
    transition: ".2s",
    color: color.BLACK_01,
    padding: "10px 0",
    fontSize: "12px",
    fontWeight: 500,
  },
  subNote: {
    fontSize: "10px",
    fontWeight: 400,
    color: color.GRAY_04,
    margin: 0,
    marginTop: "7px",
  },
  errorMessage: {
    fontSize: "10px",
    fontWeight: 400,
    color: color.RED_02,
    margin: 0,
    marginTop: "7px",
  }
}));

export const LynkSelect: FC<SelectProps> = ({
  items,
  label,
  placeholder,
  fullWidth = true,
  disabled = false,
  sx,
  subNote,
  errorMessage,
  width = "100%",
  value,
  required,
  onChange,
  onBlur,
  id,
}) => {
  const classes = useStyles({ width });

  return (
    <FormControl className={classes.container}  fullWidth={fullWidth} sx={sx}>
      {label && <Typography className={classes.labelStyle}>{required && <b>*</b>}{label}</Typography>}
      <div className={`${classes.selectContainer} ${errorMessage && classes.hasError}`}>
        <select
          data-testid={id}
          className={classes.select}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          required={required}
        >
          { placeholder && (
            <option 
              value="" 
              key={`select-placeholder-item-${placeholder}`} 
              hidden
            >
              {placeholder}
            </option>
          )}
          {items.map( item => {
            return (
              <>
                <option
                  key={`select-item-${item.value}`}
                  value={item.value} 
                  disabled={item.disabled}
                >
                  {item.text}
                </option>
                { item.subitems && item.subitems.map( subitem => (
                    <option 
                      key={`select-sub-item-${subitem.value}`}
                      value={subitem.value} 
                    >
                      &nbsp;&nbsp;{subitem.text}
                    </option>
                ))}
              </>
            );
          })}
        </select>
      </div>
      { errorMessage && (
        <p className={classes.errorMessage}>{errorMessage}</p>
      )}
      { subNote && (
        <p className={classes.subNote}>{subNote}</p>
      )}
    </FormControl>
  );
};
