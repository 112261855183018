import { FC, useCallback, useEffect, useState } from 'react';
import { OtpProps } from './types';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { color } from '../../../consts/ColorConst';
import KarteraLogo from '../../../assets/KarteraLogoBlack.svg';
import { KarteraButton } from '../button';
import { KarteraPinCodeInput } from '../pinCodeInput';
import { KarteraProgress } from '../progress';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 20,
    backgroundColor: color.WHITE_01,
    padding: '54px 32px 32px',
    border: `0.2px solid ${color.GRAY_05}`,
    boxSizing: 'border-box',
    borderRadius: 10,
  },
  description: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.2,
    letterSpacing: 0.2,
    color: color.GREY_DARK_4,
    width: '100%',
  },
  title: {
    fontSize: 26,
    fontWeight: 700,
  },
  pinCodeWrapper: {
    position: 'relative',
    padding: '12px 0 0',
  },
  verifyButtonWrapper: {
    padding: '0 0 16px 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  verifyButton: {
    disolay: 'flex',
    textAlign: 'center',
    padding: '4px 26px',
  },
  remainSeconds: {
    marginTop: 12,
    fontSize: 12,
    fontWeight: 500,
    textAlign: 'center',
    width: '100%',
  },
  requestButton: {
    cursor: 'pointer',
    color: color.GREEN_DARK_1,
    margin: '0 0 0 6px',
    fontSize: 14,
  },
  errorMessage: {
    textAlign: 'center',
    padding: '12px 0 0',
    fontSize: 12,
    fontWeight: 500,
    color: color.RED,
  },
  requestCodeWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '8px',
  },
  requestText: {
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY_DARK_1
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: '28px 0',
  }
}));

export const KarteraOtp: FC<OtpProps> = ({
  title,
  subtitle,
  type,
  description,
  descriptionTitle,
  totalSeconds,
  onPinCodeComplete,
  requestClick,
  progressValue,
  error = false,
  twoFAEnabled = false,
}) => {
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(totalSeconds);
  
  const classes = useStyles({ loadingOpen: loadingOpen, type });
  const minutes = Math.floor(secondsLeft / 60).toString().padStart(2, '0');
  const seconds = (secondsLeft % 60).toString().padStart(2, '0');
  
  const [requestedNewCode, setRequestedNewCode] = useState(false);
  const [resendCode, setResendCode] = useState(false);
  const [pinValue, setPinValue] = useState('');
  const [verifyDisabled, setVerifiedDisabled] = useState(true);

  const { t } = useTranslation('translation', { keyPrefix: 'otp' });

  function handleRequestAnother() {
    setSecondsLeft(totalSeconds);
    setLoadingOpen(false);
    setRequestedNewCode(true);
    requestClick && requestClick();
  }

  function handleResendCode() {
    if (resendCode) {
      window.open("mailto:support@kartera.com");
      return;
    }
    handleRequestAnother();
    setResendCode(true);
  }

  useEffect(() => {
    const timer = setInterval(() => {
      if (secondsLeft > 0) {
        setSecondsLeft(secondsLeft - 1);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [secondsLeft]);

  useEffect(() => {
    setSecondsLeft(totalSeconds);
  }, [totalSeconds]);

  useEffect(() => {
    if (error) {
      setLoadingOpen(false);
    }
  }, [error]);

  const handleVerify = useCallback(
    (value: any) => {
      setLoadingOpen(true);
      onPinCodeComplete(value);
    },
    [setLoadingOpen, onPinCodeComplete],
  );

  return (
    <Box sx={{ width: '400px', margin: '0 auto' }}>
      <Box className={classes.root}>
        <img src={KarteraLogo} alt='Kartera logo' width={70} />
        <Typography className={classes.title}>
          {title}
        </Typography>
        <Typography className={classes.description}>
          {description}
        </Typography>
        <Box className={classes.pinCodeWrapper}>
          <KarteraPinCodeInput
            error={error}
            length={6}
            onComplete={(value: any) => {
              setPinValue(value);
              setVerifiedDisabled(false);
            }}
            disabled={error ? false : loadingOpen}
            requestedNew={requestedNewCode}
            setRequestedNew={setRequestedNewCode}
            onChange={(value: string) => {
              if (value.length < 6) {
                setVerifiedDisabled(true);
              }
            }}
          />
          {!loadingOpen && (error || secondsLeft === 0) && twoFAEnabled === false && (
            <Typography className={classes.errorMessage}>
              {error ? t('incorrect') : t('codeExpired')}
            </Typography>
          )}
          {!loadingOpen && secondsLeft > 0 && twoFAEnabled === false && (
            <Typography className={classes.remainSeconds}>
              {secondsLeft >= 60
                ? `${minutes}:${seconds} ${t('remaining')}`
                : `${seconds} ${t('secondsRemaining')}`}
            </Typography>
          )}
        </Box>
        {!loadingOpen && (
          <Box className={classes.verifyButtonWrapper}>
            <KarteraButton
              spacious
              text={secondsLeft === 0 ? t('requestNewCode') : t('verifyButton')}
              className={classes.verifyButton}
              onClick={secondsLeft > 0 ? () => handleVerify(pinValue) : handleRequestAnother}
              disabled={verifyDisabled && secondsLeft > 0}
            />
          </Box>
        )}
        {!loadingOpen && secondsLeft > 0 && twoFAEnabled === false && (
          <Box className={classes.requestCodeWrapper}>
            <Typography className={classes.requestText}>
              {resendCode ? t('codeSentAgain') : t('notRecieved')}
            </Typography>
            <Typography 
              className={classes.requestButton} 
              onClick={handleResendCode}
            >
              {resendCode ? t('contactSupport') : t('requestAnother')}
            </Typography>
          </Box>
        )}
        {loadingOpen && error === false && (
          <Box className={classes.loaderWrapper}>
            <KarteraProgress size={50} value={progressValue} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
