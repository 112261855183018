import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { useApi } from "../../services/api/hooks";
import { checkMockData, getMockRefundsData } from "../../utils/generateMockData";
import commafy from "../../utils/commafy";
import { KarteraTable } from "../../components/kartera/table";
import { KarteraButton } from "../../components/kartera/button";
import { AddPaymentType } from "./addPaymentType/AddPaymentType";
import { color } from "../../consts/ColorConst";
import { PaymentStatus } from "../../components/paymentStatus";
import { EditPaymentType } from "./editPaymentType/EditPaymentType";
import { DeletePaymentType } from "./deletePaymentType/DeletePaymentType";
import { RecurringPaymentDTO } from "../../dtos/recurringPayments/recurringPaymentDTO";
import { PageStateType } from "../../components/kartera/table/types";

export function Payments() {
  const { product_id } = useParams();
  const { getRecurringPayments } = useApi();
  const { t } = useTranslation("translation", { keyPrefix: "payments" });

  const [selectedItems, setSelectedItems] = useState([]);
  const [currentRow, setCurrentRow] = useState<RecurringPaymentDTO>();
  const [showAddPaymentTypeDialog, setShowAddPaymentTypeDialog] = useState(false);
  const [showEditPaymentTypeDialog, setShowEditPaymentTypeDialog] = useState(false);
  const [showDeletePaymentTypeDialog, setShowDeletePaymentTypeDialog] = useState(false);
  const [paginationAndSortingMode, setPaginationAndSortingMode] =
    useState<"server" | "client">("server");
  const [refreshTime, setRefreshTime] = useState(new Date());
  const [selectedType, setSelectedType] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  async function getPayments(pageState: PageStateType) {
    if (checkMockData()) {
      setPaginationAndSortingMode("client");
      return {
        data: getMockRefundsData(),
        metadata: {
          total_elements: 1000,
        },
      };
    }

    if (!product_id || product_id === " ") return;
    setPaginationAndSortingMode("server");

    let query = selectedType && `&type=${selectedType}`;
    query = query + (selectedStatus && `&status=${selectedStatus}`);
    return await getRecurringPayments(
      product_id, 
      pageState.pageSize, 
      pageState.page,
      pageState.sortField, 
      query
    );
  }

  function renderStatus(params: GridRenderCellParams) {
    return <PaymentStatus status={params.row.disabled ? 'PAUSED' : 'ACTIVE'} />;
  }

  function getSingleAmount(params: GridRenderCellParams) {
    return params.row.interval === 'ONE_TIME' ? 
              `${commafy(params.row.amount)} USD` : '-';
  }

  function getMonthlyAmount(params: GridRenderCellParams) {
    return params.row.interval === 'MONTHLY' ? 
              `${commafy(params.row.amount)} USD` : '-';
  }

  function getYearlyAmount(params: GridRenderCellParams) {
    return params.row.interval === 'YEARLY' ? 
              `${commafy(params.row.amount)} USD` : '-';
  }

  function getTypeValue(params: GridRenderCellParams) {
    return params.row.interval === 'ONE_TIME' ? t('singlePayment') : t('subscription');
  }

  function editButton(params: GridRenderCellParams) {
    return (
      <FontAwesomeIcon 
        icon={faPen} 
        color={color.GREEN_DARK_1}
        width='16px' 
        onClick={() => {
          setCurrentRow(params.row);
          setShowEditPaymentTypeDialog(true)
        }}
      />
    )
  }

  function deleteButton(params: GridRenderCellParams) {
    return (
      <FontAwesomeIcon 
        icon={faTrash} 
        color={color.RED_DARK_1} 
        width='16px' 
        onClick={() => {
          setCurrentRow(params.row);
          setShowDeletePaymentTypeDialog(true);
        }}
      />
    )
  }

  const quickFilters = [
    {
      defaultValue: '', 
      items: [
        { value: '', text: 'All Types' },
        { value: 'SINGLE', text: 'Single' },
        { value: 'SUBSCRIPTION', text: 'Subscription' },
      ],
      setSelectedItem: setSelectedType,
    },
    {
      defaultValue: '',
      items: [
        { value: '', text: 'All Status' },
        { value: 'INITIATED', text: 'initiated' },
        { value: 'VALIDATED', text: 'Validated' },
        { value: 'AUTHORIZED', text: 'Authorized' },
        { value: 'ERROR', text: 'Error' },
        { value: 'PROCESSED', text: 'Processed' },
        { value: 'CANCELLED', text: 'Cancelled' },
      ],
      setSelectedItem: setSelectedStatus,
    },
  ] 

  useEffect(() => {
    setRefreshTime(new Date());
  }, [selectedStatus, selectedType]);

  const filterFields = [
    { value: "account_id", text: t("colAccountId") },
    { value: "counterparty_id", text: t("colCounterpartyId") },
    {
      value: "service",
      text: t("colService"),
      items: [
        { text: t("standard"), value: "STANDARD" },
        { text: t("sameday"), value: "SAMEDAY" },
      ],
    },
    {
      value: "status",
      text: t("colStatus"),
      items: [
        { text: t("pending"), value: "PENDING" },
        { text: t("canceled"), value: "CANCELED" },
        { text: t("processing"), value: "PROCESSING" },
        { text: t("error"), value: "ERROR" },
        { text: t("sent"), value: "SENT" },
        { text: t("returned"), value: "RETURNED" },
      ],
    },
    { value: "created_at", text: t("colDate") },
  ];

  const columns: GridColDef[] = [
    { 
      field: "description", 
      headerName: t("name"),
      minWidth: 200,
    },
    { field: "linked_account_id", headerName: t("account"), flex: 1 },
    { 
      field: "type", 
      headerName: t("type"), 
      minWidth: 120,
      valueGetter: getTypeValue, 
    },
    {
      field: "amount",
      type: "number",
      headerName: t("singleAmount"),
      minWidth: 140,
      valueGetter: getSingleAmount,
    },
    {
      field: "monthly",
      type: "number",
      headerName: t("monthlyRate"),
      minWidth: 140,
      valueGetter: getMonthlyAmount,
    },
    {
      field: "yearly",
      type: "number",
      headerName: t("yearlyRate"),
      minWidth: 140,
      valueGetter: getYearlyAmount,
    },
    {
      field: "status",
      headerName: t("status"),
      minWidth: 120,
      renderCell: renderStatus,
    },
    {
      field: 'edit',
      headerName: '',
      sortable: false,
      renderCell: editButton,
      width: 28,
    },
    {
      field: 'delete',
      headerName: '',
      sortable: false,
      renderCell: deleteButton,
      width: 28,
    },
  ];

  return (
    <>
      <KarteraTable
        rows={[]}
        noRowsMessage={t('noRefunds')}
        fetchData={getPayments}
        paginationAndSortingMode={paginationAndSortingMode}
        title={t("title")}
        columns={columns}
        defaultPageSize={10}
        searchPlaceholder={t("searchPlaceholder")}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        filterFields={filterFields}
        quickFilters={quickFilters}
        component={(
          <KarteraButton
            text='Add Payment Type'
            onClick={() => setShowAddPaymentTypeDialog(true)}
          />
        )}
        refreshDate={refreshTime}
      />
      {showAddPaymentTypeDialog && (
        <AddPaymentType 
          onClose={() => setShowAddPaymentTypeDialog(false)} 
          setRefreshTime={() => setRefreshTime(new Date())}
        />
      )}
      {showEditPaymentTypeDialog && currentRow && (
        <EditPaymentType 
          payment={currentRow}
          setShowDeletePaymentTypeDialog={() => setShowDeletePaymentTypeDialog(true)}
          onClose={() => setShowEditPaymentTypeDialog(false)} 
        />
      )}
      {showDeletePaymentTypeDialog && currentRow && (
        <DeletePaymentType 
          paymentId={currentRow.id}
          onClose={() => setShowDeletePaymentTypeDialog(false)}
          setRefeshTime={() => setRefreshTime(new Date())}
        />
      )}
    </>
  );
}
