import { IntervalType } from "../dtos/reports/reportDTO";

export function stepPercentageCalculator(fields: any[]) {
    let filledFields = 0;

    for (const field of fields) {
        if ((!!field) || 
            (typeof field === "string" && field !== "") ||
            (typeof field === "number" && field > 0) ||
            (typeof field === "object" && Object.keys(field).length > 0) ) {
                filledFields++
        }
    }

    return Math.ceil((filledFields / fields.length) * 100);
}

export function truncateText(text: string | undefined, maxChar: number, showDots = true) {
    if (!text) return "";
    return (showDots && text.length > maxChar 
            ? text.substring(0, maxChar - 2)+'...' 
            : text.substring(0, maxChar)
    );
}

export function sentenceCaseGenerator(text: string) {
    return text[0].toUpperCase() + text.slice(1).toLowerCase();
}

export function handleRangeLabel(range: string) {
    if (range === 'ALL') return 'All Time';
    if (range === 'YEAR') return 'This Year';
    if (range === 'MONTH') return 'This Month';
    if (range === 'WEEK') return 'This Week';
    if (range === 'DAY') return 'Today';
}

export function getInitialDateFromRange(range: IntervalType) {
    switch (range) {
        case 'ALL': 
            return new Date(new Date().setFullYear(2020, 0, 1)).toISOString().split('T')[0];
        case 'YEAR': 
            return new Date(new Date().setFullYear(new Date().getFullYear(), 0, 1)).toISOString().split('T')[0];
        case 'MONTH': 
            return new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split('T')[0];
        case 'WEEK': 
            return new Date(new Date().setDate(new Date().getDate() - new Date().getDay())).toISOString().split('T')[0];
        case 'DAY': 
            return new Date().toISOString().split('T')[0];
    }
}

export function underscoreToCammelCase(value: string) {
    const res = value.split('_')
                .map( word => word[0].toUpperCase() + word.slice(1))
                .join('');
    return res[0].toLowerCase() + res.slice(1);
}

export function formatDate(date: Date) {
    const d = new Date(date);
    const year = d.getFullYear();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export function handlePeriod(period: string) {
    const today = new Date();
    const year = today.getFullYear();

    let fromDate = today;
    let toDate = today;
    switch (period) {
        case 'yesterday': {
            const yesterday = new Date(today.setDate(today.getDate() - 1));
            fromDate = yesterday;
            toDate = yesterday;
            break;
        } case 'this week': {
            const firstDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 1));
            fromDate = firstDayOfWeek;
            toDate = today;
            break;
        } case 'this month': {
            const month = today.getMonth();
            const firstDayOfMonth = new Date(`${year}-${month + 1}-01`);
            fromDate = firstDayOfMonth;
            toDate = today;
            break;
        } case 'this year': {
            const firstDayOfYear = new Date(`${year}-01-02`);
            fromDate = firstDayOfYear;
            toDate = today;
            break; 
        }
    }
    return [fromDate, toDate];
}

export function hasErrorsInFields(errors: object, fields: string[]) {
    return Boolean(Object.entries(errors).find((item) => {
        if (fields.includes(item[0])) {
          return item[1] !== "";
        }
    }));
}