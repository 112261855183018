import { FC } from "react";
import { TooltipProps } from "./types";
import { Tooltip } from "@mui/material";
import { color } from "../../../consts/ColorConst";

export const KarteraTooltip: FC<TooltipProps> = ({
  placement,
  title,
  width = '100%',
  children,
}) => {
  return (
    <Tooltip
      title={title}
      arrow
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            width: width,
            fontSize: 11,
            fontWeight: 200,
            bgcolor: color.GREY,
            borderRadius: '4px',
            padding: '6px 8px',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            '& .MuiTooltip-arrow': {
              color: color.GREY,
            },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};
