import { faker } from "@faker-js/faker";
import _uniqueId from "lodash/uniqueId";
import mccCodes from "../consts/mccCodes.json";
import { dateConverter } from "./dateConverter";
import { CardDTO } from "../dtos/cards/cardDTO";
import { ProductTransactionDTO } from "../dtos/transactions/productTransactionDTO";
import { ProductUserDTO } from "../dtos/productUsers/productUserDTO";
import { TransferDTO } from "../dtos/transfers/transferDTO";
import { ReportDTO } from "../dtos/reports/reportDTO";
import { PaymentDTO } from "../dtos/payments/paymentDTO";

export type MockReportsType = {
  customers?: ReportDTO,
  averageSpends?: ReportDTO,
  disputes?: ReportDTO,
  totalRefunds?: ReportDTO,
  totalCashBacks?: ReportDTO,
}

const STORAGE = {
  USE_MOCK_DATA: "useMockData",
  USERS_DATA: "mockUsersData",
  TRANSACTIONS_DATA: "mockTransactionsData",
  PAYMENTS_DATA: "mockPaymentsData",
  TRANSFERS_DATA: "mockTransfersData",
  REFUNDS_DATA: "mockRefundsData",
  REPORTS_DATA: "mockReportsData",
};

export function checkMockData() {
  return Boolean(localStorage.getItem(STORAGE.USE_MOCK_DATA));
}

export function generateMockData() {
  localStorage.setItem(STORAGE.USE_MOCK_DATA, "true");

  //Users mock data
  const users = generateUsersMockData();
  localStorage.setItem(STORAGE.USERS_DATA, JSON.stringify(users));

  //Refunds mock data
  const refunds = generateRefundsMockData();
  localStorage.setItem(STORAGE.REFUNDS_DATA,JSON.stringify(refunds));

  //Payments (Transactions) mock data
  const payments = generatePaymentsMockData();
  localStorage.setItem(STORAGE.PAYMENTS_DATA,JSON.stringify(payments));

  //OpenSearch (Reports) mock data
  const reports = generateReportsMockData();
  localStorage.setItem(STORAGE.REPORTS_DATA, JSON.stringify(reports));

  //Transfers mock data
  const transfers = generateTransfersMockData(); 
  localStorage.setItem(STORAGE.TRANSFERS_DATA,JSON.stringify(transfers));
}

function generateUsersMockData() {
  const product_id = `product_${faker.database.mongodbObjectId()}`;
  const users = [];
  let counter = 100;
  while (counter > 0) {
    counter--;
    const user: ProductUserDTO = {
      product_id: product_id,
      id: `productuser_${faker.database.mongodbObjectId()}`,
      email: faker.internet.email().toLowerCase(),
      first_name: faker.name.firstName(),
      last_name: faker.name.lastName(),
      created_at: String(faker.date.past(1)),
      updated_at: String(faker.date.past(1)),
      verification_status: 'VERIFICATION_NEEDED',
    };
    users.push(user);
  }
  return users;
}

function generateTransfersMockData() {
  const transfers = [];
  let counter = 100;
  while (counter > 0) {
    counter--;
    const transfer: TransferDTO = {
      id: _uniqueId("transfer_"),
      amount: faker.finance.amount(100, 10000, 2),
      service: faker.helpers.arrayElement(["STANDARD", "SAMEDAY"]),
      status: faker.helpers.arrayElement([
        "QUEUED",
        "CANCELLED",
        "IN_PROGRESS",
        "COMPLETED",
        "RETURNED",
      ]),
    };
    transfers.push(transfer);
  }
  return transfers;
}

function generateUserCardsMockData() {
  let counter = Math.floor(Math.random() * 3) + 1;
  const cards = [];
  while (counter > 0) {
    counter--;
    const expiray = dateConverter(faker.date.future(1).toString());
    const card: CardDTO = {
      id: _uniqueId("transfer_"),
      type: "VIRTUAL",
      last_4: Math.floor(1000 + Math.random() * 9000).toString(),
      status: faker.helpers.arrayElement([
        "active",
        "unactivated",
        "suspended",
        "terminated",
      ]),
      user_id: _uniqueId("user_"),
      created_at: String(faker.date.past(1)),
      updated_at: String(faker.date.past(1)),
      expiration_date: expiray.substring(3, 5) + expiray.substring(8, 10),
      product_id: _uniqueId("product_"),
    };
    cards.push(card);
  }
  return cards;
}

function generateRefundsMockData() {
  let counter = 100;
  const refunds = [];
  while (counter > 0) {
    counter--;
    const refund: PaymentDTO = {
      id: `payment_${faker.database.mongodbObjectId()}`,
      user: {
        id: `productuser_${faker.database.mongodbObjectId()}`,
        email: faker.internet.email().toLowerCase(),
        first_name: faker.name.firstName(),
        last_name: faker.name.lastName(),
        created_at: String(faker.date.past(1)),
        updated_at: String(faker.date.past(1)),
        phone_country_code: "US",
        phone_number: faker.phone.number(),
      },
      linked_account_id: `linkedaccount_${faker.database.mongodbObjectId()}`,
      speed: faker.helpers.arrayElement(["STANDARD", "SAMEDAY"]),
      amount: faker.finance.amount(50, 500, 2),
      description: faker.finance.transactionDescription(),
      status: faker.helpers.arrayElement(["PROCESSED", "INITIATED","CANCELLED"]),
      created_at: String(faker.date.past(1)),
      updated_at: String(faker.date.past(1)),
      pretty_amount: faker.finance.amount(-100, 10000, 2)
    };
    refunds.push(refund);
  }
  return refunds;
}

function generateTransactionMockData() {
  const transactions = [];
  let counter = 200;
  while (counter > 0) {
    counter--;
    const transaction: ProductTransactionDTO = {
      id: _uniqueId("transaction_"),
      amount: faker.finance.amount(100, 10000, 2),
      product_id: _uniqueId("product_"),
      currency: "CAD",
      description: faker.finance.transactionDescription(),
      execution_timestamp: String(faker.date.recent(90)),
      merchant: {
        name: faker.name.fullName(),
        mid: _uniqueId("merchant_"),
        mcc: faker.helpers.arrayElement(Object.keys(mccCodes)),
        address: {
          city: faker.address.cityName(),
          state: faker.address.state(),
          postal_code: faker.address.zipCode(),
          country: faker.address.country(),
        },
      },
      type: "",
      status: "",
      card_id: ""
    };
    transactions.push(transaction);
  }
  return transactions;
}

function generatePaymentsMockData() {
  const payments = [];
  let counter = 200;
  while (counter > 0) {
    counter--;
    const payment: PaymentDTO = {
      id: `payment_${faker.database.mongodbObjectId()}`,
      user: {
        id: `productuser_${faker.database.mongodbObjectId()}`,
        email: faker.internet.email().toLowerCase(),
        first_name: faker.name.firstName(),
        last_name: faker.name.lastName(),
        created_at: String(faker.date.past(1)),
        updated_at: String(faker.date.past(1)),
        phone_country_code: "US",
        phone_number: faker.phone.number(),
      },
      linked_account_id: `linkedaccount_${faker.database.mongodbObjectId()}`,
      speed: faker.helpers.arrayElement(["STANDARD", "SAMEDAY"]),
      amount: faker.finance.amount(100, 10000, 2),
      description: faker.finance.transactionDescription(),
      status: faker.helpers.arrayElement(["PROCESSED", "INITIATED","CANCELLED"]),
      created_at: String(faker.date.past(1)),
      updated_at: String(faker.date.past(1)),
      pretty_amount: faker.finance.amount(-100, 10000, 2)
    };
    payments.push(payment);
  }
  return payments;
}

function generateReportsMockData() {
  const reports: MockReportsType = {};
  const customers: ReportDTO = [];
  const averageSpends: ReportDTO = [];
  const disputes: ReportDTO = [];
  const totalRefunds: ReportDTO = [];
  const totalCashBacks: ReportDTO = [];
  let counter = 7;
  while (counter > 0) {
    counter--;
    const customer = {
      amount: String(faker.datatype.number({ min: 10, max: 100})),
      time_span: String(faker.date.recent(90)),
    };
    const averageSpend = {
      amount: faker.finance.amount(1000, 50000, 2),
      time_span: String(faker.date.recent(90)),
    };
    const dispute = {
      amount: faker.finance.amount(1, 5, 2),
      time_span: String(faker.date.recent(90)),
    };
    const totalRefund = {
      amount: faker.finance.amount(100, 10000, 2),
      time_span: String(faker.date.recent(90)),
    };
    const totalCashBack = {
      amount: faker.finance.amount(10, 1000, 2),
      time_span: String(faker.date.recent(90)),
    };
    customers.push(customer);
    averageSpends.push(averageSpend);
    disputes.push(dispute);
    totalRefunds.push(totalRefund);
    totalCashBacks.push(totalCashBack);
  }
  reports.customers = customers;
  reports.averageSpends = averageSpends;
  reports.disputes = disputes;
  reports.totalRefunds = totalRefunds;
  reports.totalCashBacks = totalCashBacks;
  return reports;
}

export function clearMockData() {
  localStorage.removeItem(STORAGE.USE_MOCK_DATA);
  localStorage.removeItem(STORAGE.USERS_DATA);
  localStorage.removeItem(STORAGE.TRANSACTIONS_DATA);
  localStorage.removeItem(STORAGE.PAYMENTS_DATA);
  localStorage.removeItem(STORAGE.TRANSFERS_DATA);
  localStorage.removeItem(STORAGE.REFUNDS_DATA);
  localStorage.removeItem(STORAGE.REPORTS_DATA);
}

export function generateUserMockData() {
  const user: ProductUserDTO = {
    product_id: `product_${faker.database.mongodbObjectId()}`,
    id: `productuser_${faker.database.mongodbObjectId()}`,
    email: faker.internet.email().toLowerCase(),
    first_name: faker.name.firstName(),
    last_name: faker.name.lastName(),
    created_at: String(faker.date.past(1)),
    updated_at: String(faker.date.past(1)),
    verification_status: '',
  };
  return user;
}

export function getMockUsersData() {
  const users = localStorage.getItem(STORAGE.USERS_DATA);
  return users ? JSON.parse(users) : [];
}

export function getMockRefundsData() {
  const refunds = localStorage.getItem(STORAGE.REFUNDS_DATA);
  return refunds ? JSON.parse(refunds) : [];
}

export function getMockPaymentsData() {
  const payments = localStorage.getItem(STORAGE.PAYMENTS_DATA);
  return payments ? JSON.parse(payments) : [];
}

export function getMockReportsData() {
  const reports = localStorage.getItem(STORAGE.REPORTS_DATA);
  return reports ? JSON.parse(reports) : [];
}

export function getMockTransactionsData() {
  const transactions = localStorage.getItem(STORAGE.TRANSACTIONS_DATA);
  return transactions ? JSON.parse(transactions) : [];
}

export function getMockTransfersData() {
  const transfers = localStorage.getItem(STORAGE.TRANSFERS_DATA);
  return transfers ? JSON.parse(transfers) : [];
}
