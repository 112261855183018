import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";
import { KarteraDialogue } from "../../../components/kartera/dialogue";
import { KarteraTextField } from "../../../components/kartera/textField";
import { KarteraRadioGroup } from "../../../components/kartera/radioGroup";
import { KarteraCheckbox } from "../../../components/kartera/checkbox";
import { useApi } from "../../../services/api/hooks";
import { KarteraCurrencyField } from "../../../components/kartera/currencyField";
import { RecurringPaymentRequestDTO } from "../../../dtos/recurringPayments/recurringPaymentDTO";
import { useCookies } from "react-cookie";
import { KarteraSelectField } from "../../../components/kartera/selectField";

const useStyles = makeStyles(() => ({
  RadioGroupWrapper: {
    width: '100%',
    display: 'flex', 
    flexDirection: 'row', 
    marginBottom: 12,
  },
  footerWrapper: {
    marginTop: 12,
  },
  checkboxWrapper: {
    width: '100%',
    display: 'flex', 
    flexDirection: 'column',
    gap: 6,
  },
  fieldsWrapper: {
    display: 'flex', 
    flexDirection: 'column',
    padding: '0 0 16px 0',
    gap: 12,
  },
}));

type Props = {
    onClose: () => void;
    setRefreshTime?: () => void;
}

export function AddPaymentType({ setRefreshTime, onClose }: Props) {
    const { t } = useTranslation("translation", { keyPrefix: "payments" });
    const classes = useStyles();
    const { product_id } = useParams();
    const [cookies] = useCookies(["lynk.user-id"]);
    const {createRecurringPayment, getLinkedAccounts, getUsersList} = useApi();

    const [paymentType, setPaymentType] = useState('');
    const [paymentName, setPaymentName] = useState('');
    const [paymentRate, setPaymentRate] = useState<'MONTHLY' | 'YEARLY' | 'ONE_TIME' | ''>('');
    const [singlePaymentAmount, setSinglePaymentAmount] = useState();
    const [monthlyAmount, setMonthlyAmount] = useState();
    const [yearlyAmount, setYearlyAmount] = useState();
    const [accounts, setAccounts] = useState<any[]>([]);
    const [selectedAccount, setSelectedAccount] = useState('');
    const [productUser, setProductUser] = useState('');

    useEffect(() => {
      async function getData() {
        if (!product_id || !cookies["lynk.user-id"]) return;
        const response = await getLinkedAccounts(product_id, '', true);
        setAccounts(response.data);

        const userResponse = await getUsersList(product_id);
        setProductUser(userResponse.data[0].id)
      }

      getData();
    }, [])
  
    const disableButton = !paymentType || !paymentName;

    async function handleSubmit() {   
      if (!product_id) return onClose();
      const interval = paymentType === 'SINGLE' 
              ? 'ONE_TIME' : paymentRate;
      if (interval) {
        const amount = paymentRate === 'MONTHLY' 
              ? (monthlyAmount || '0')
              : paymentRate === 'YEARLY' 
                ? (yearlyAmount || '0')
                : singlePaymentAmount || '0'
        const data: RecurringPaymentRequestDTO = {
          product_user_id: productUser,
          linked_account_id: selectedAccount,
          speed: 'STANDARD',
          amount: amount,
          description: paymentName,
          interval: interval,
        }

        await createRecurringPayment(product_id, data);
        setRefreshTime && setRefreshTime();
      }

      onClose();
    }

    return (
      <KarteraDialogue 
        title={t('addPaymentType')}
        size='small'
        autoHeight
        actionButtonText={paymentType === 'SINGLE' ? t('add') : t('next')}
        activeButtonType="primary"
        activeButtonDisabled={disableButton}
        onActionClick={handleSubmit}
        cancelButtonText={t('cancel')}
        onCancelClick={onClose}
        showCloseButton={false}
        supressOutsideClick
      >
        <div className={classes.fieldsWrapper}>
          <KarteraSelectField
            label={t('account')}
            labelStyle="simple"
            value={selectedAccount}
            onChange={(e: any) => setSelectedAccount(e.target.value)}
            items={accounts?.map( account => ({ 
              text: account.account_name, value: account.id 
            }))}
            placeholder={t('selectAccount')}
            width={"312px"}
          />
          <KarteraTextField
            label={t('addPaymentName')}
            labelStyle="simple"
            placeholder={t('addPaymentNamePlaceholder')}
            value={paymentName}
            onChange={(e) => setPaymentName(e.target.value)}
            fullWidth
          />
        </div>
        <Divider  />
        <div className={classes.RadioGroupWrapper}>
          <KarteraRadioGroup
            itemsGap={1.5}
            items={[
                {
                    text: t('singlePayment'), 
                    value: 'SINGLE', 
                    subnote: t('singlePaymentSubnote')
                },{
                    text: t('subscription'), 
                    value: 'SUBSCRIPTION', 
                    subnote: t('subscriptionSubnote')
                }
            ]}
            onChange={(e) => setPaymentType(e.target.value)}
          />
        </div>
        <Divider />
        { paymentType === 'SUBSCRIPTION' ? (
          <div className={classes.footerWrapper}>
            <div className={classes.checkboxWrapper}>
                <KarteraCheckbox
                  label={t('monthlyRate')}
                  onChange={() => setPaymentRate('MONTHLY')}
                  checked={paymentRate === 'MONTHLY'}
                  size="small"
                />
                <KarteraCurrencyField
                  placeholder={t('enterAmount')}
                  type="monetary"
                  sx={{ margin: '0 0 12px 26px' }}
                  value={monthlyAmount}
                  onChange={(e: any) => setMonthlyAmount(e.target.value)}
                  disabled={paymentRate !== 'MONTHLY'}
                  decimalScale={2}
                />
            </div>
            <div className={classes.checkboxWrapper}>
                <KarteraCheckbox
                  label={t('yearlyRate')}
                  checked={paymentRate === 'YEARLY'}
                  onChange={() => setPaymentRate('YEARLY')}
                  size="small"
                />
                <KarteraCurrencyField
                  placeholder={t('enterAmount')}
                  type="monetary"
                  sx={{ marginLeft: 26 }}
                  value={yearlyAmount}
                  onChange={(e: any) => setYearlyAmount(e.target.value)}
                  disabled={paymentRate !== 'YEARLY'}
                  decimalScale={2}
                />
            </div>
          </div>
        ) : (
          <div className={classes.footerWrapper}>
              <KarteraCurrencyField
                label={t('totalForSinglePayment')}
                placeholder={t('enterAmount')}
                type="monetary"
                value={singlePaymentAmount}
                onChange={(e: any) => setSinglePaymentAmount(e.target.value)}
                decimalScale={2}
              />
          </div>
        )}
      </KarteraDialogue>
    );
}
