export function getCurrentEnvironment() {
    return process.env.REACT_APP_ENVIRONMENT;
}

export function getCurrentEnvironmentUrl() {
    return process.env.REACT_APP_API_URL;
}

export function isSandbox() {
    return process.env.REACT_APP_ENVIRONMENT === "SANDBOX";
}

export function isProduction() {
    return process.env.REACT_APP_ENVIRONMENT === "PRODUCTION";
}

export function isStaging() {
    return process.env.REACT_APP_ENVIRONMENT === "STAGING";
}

export function isDevelopment() {
    return process.env.REACT_APP_ENVIRONMENT === "DEVELOPMENT";
}