import { FC, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronDown, faLock, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { dateTimeConverter } from "../../../utils/dateConverter";
import { color } from "../../../consts/ColorConst";
import { UserStatus } from "../../../components/userStatus";
import { truncateText } from "../../../utils/helperFunctions";
import { LynkPanel } from "../../../components/common/panel";
import { ButtonMenu } from "../../../components/common/buttonMenu";
import { MenuItemType } from "../../../components/common/buttonMenu/types";
import { UserDetailsPanelProps } from "./types";
import { LinkedAccountDTO } from "../../../dtos/linkedAccounts/linkedAccountDTO";

const useStyles = makeStyles(() => ({
  selectButton: {
    backgroundColor: color.GREEN_LIGHT_1,
    fontSize: '14px',
    fontWeight: 500,
    color: color.WHITE_01,
    width: 180,
    height: 28,
    borderRadius: 6,
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: color.GREEN_DARK_1,
    },
  },
  userStatus: {
    flex: 1,
    marginLeft: 10,
  },
  panelUserContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 16px',
  },
  panelTextContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  accountTypeWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    padding: '2px 0 0 8px',
    color: color.GREEN_03,
  },
  accountTypeText: {
    fontSize: 14,
    fontWeight: 400,
  },
  noAccountText: {
    fontSize: 14,
    fontWeight: 500,
    color: color.GREY,
    letterSpacing: '0.2px',
  },
  oneAccountText: {
    fontSize: 14,
    fontWeight: 500,
    color: color.GREY_DARK_1,
    letterSpacing: '0.2px',
  },
  accountId: {
    marginTop: 4,
    fontSize: 13,
    fontWeight: 400,
    fontStyle: 'italic',
    color: color.GREY,
    letterSpacing: '0.2px',
  },
  panelTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '15px 16px 0px',
  },
  userNameLabel: {
    fontSize: 20,
    fontWeight: 500,
  },
  panelLabel: {
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY,
  },
  accountStatusWrapper: {
    display: 'flex',
  },
  panelValue: {
    fontSize: 14,
    fontWeight: 500,
    color: color.GREY_DARK_4,
    margin: '0 0 10px',
  },
}));

export const UserDetailsPanel: FC<UserDetailsPanelProps> = ({
  user,
  setSelectedAccount,
  userAccounts,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'userDetail' });
  const [selectedItem, setSelectedItem] = useState<MenuItemType>();

  const accountStatus = {
    active: {
      text: t('statusActive'),
      icon: <FontAwesomeIcon icon={faCheck} fontSize={14} />,
    },
    terminated: {
      text: t('statusTerminated'),
      icon: <FontAwesomeIcon icon={faXmarkCircle} fontSize={14} />,
    },
    locked: {
      text: t('statusLocked'),
      icon: <FontAwesomeIcon icon={faLock} fontSize={14} />,
    },
  };

  function handleAccountChange(account: LinkedAccountDTO) {
    setSelectedAccount(account.id);
  }

  useEffect(() => {
    if (userAccounts.length > 0) {
      const account = {
        icon: accountStatus['active'].icon,
        value: userAccounts[0].id,
        text: truncateText(userAccounts[0].name || userAccounts[0].bank_name, 20),
        secondaryText: accountStatus['active'].text,
      };

      setSelectedItem(account);
      handleAccountChange(userAccounts[0]);
    }
  }, [userAccounts]);

  return (
    <LynkPanel width={306} minWidth={306}>
      <Box className={classes.panelTitleContainer}>
        <Typography className={classes.userNameLabel}>{user.first_name} {user.last_name}</Typography>
        <Box className={classes.userStatus}>
          <UserStatus status='active' />
        </Box>
      </Box>
      <Box className={classes.panelUserContent}>
        <Box className={classes.panelTextContainer}>
          <Typography className={classes.panelLabel}>{t('emailAddress')}</Typography>
          <Typography className={classes.panelValue}>{truncateText(user.email, 32)}</Typography>
        </Box>
        <Box className={classes.panelTextContainer}>
          <Typography className={classes.panelLabel}>{t('registrationDate')}</Typography>
          <Typography className={classes.panelValue}>
            {user.created_at && dateTimeConverter(user.created_at)}
          </Typography>
        </Box>
        <Box className={classes.panelTextContainer}>
          <Typography className={classes.panelLabel}>{t('account')}</Typography>
          <Box className={classes.accountStatusWrapper}>
            {userAccounts.length === 0 ? (
              <Typography className={classes.noAccountText}>
                <i>{t('noAccountsAvailable')}</i>
              </Typography>
            ) : userAccounts.length === 1 ? (
              <Typography className={classes.oneAccountText}>
                {userAccounts[0].name || userAccounts[0].bank_name}
              </Typography>
            ) : (
              <ButtonMenu
                className={classes.selectButton}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                iconRight={<FontAwesomeIcon icon={faChevronDown} fontSize={11} />}
                loading={false}
                items={userAccounts.map((account) => ({
                  icon: accountStatus['active'].icon,
                  value: account.id,
                  text: account.name || account.bank_name,
                  secondaryText: account.id,
                  onClick: () => handleAccountChange(account),
                }))}
              />
            )}
            {selectedItem && userAccounts.length > 0 && (
              <Box className={classes.accountTypeWrapper}>
                {selectedItem.icon}
                <Typography className={classes.accountTypeText}>
                  {accountStatus['active'].text}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        {selectedItem && userAccounts.length > 0 && (
          <Typography className={classes.accountId}>
            {`(${truncateText(selectedItem.value, 40)})`}
          </Typography>
        )}
      </Box>
    </LynkPanel>
  );
};
