import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../context/Auth";

export const PublicRoutes = () => {
  const { isAuthenticated } = useAuth();

  const location = useLocation();
  
  return !isAuthenticated 
    ? <Outlet />
    : <Navigate to="/" state={{ from: location }} replace />
};
