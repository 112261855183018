import { FC } from "react";
import { makeStyles } from "@mui/styles";
import { Badge as MaterailBadge } from "@mui/material";

import { color } from "../../../consts/ColorConst";
import { BadgeProps } from "./types";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiBadge-badge": {
      height: "18px",
      minWidth: "18px",
      fontSize: "11px",
      fontFamily: "Roboto",
      margin: 5,
    },
  },
  default: {
    "& .MuiBadge-badge": {
      backgroundColor: color.RED_DARK_1,
    },
  },
  green: {
    "& .MuiBadge-badge": {
      backgroundColor: color.GREEN_LIGHT_2,
    },
  },
  grey: {
    "& .MuiBadge-badge": {
      backgroundColor: color.GREY_LIGHT_1,
    },
  },
  ghost: {
    "& .MuiBadge-badge": {
      color: color.GREY_DARK_2,
      backgroundColor: "transparent",
    },
  },
  dot: {
    "& .MuiBadge-badge": {
      height: "10px",
      minWidth: "10px",
      borderRadius: "50%",
      backgroundColor: color.GREEN_DARK_1,
    },
  },
}));

export const KarteraBadge: FC<BadgeProps> = ({
  icon,
  text,
  children,
  type = "default",
  max = 99,
}) => {
  const classes = useStyles();

  return (
    <MaterailBadge
      color="info"
      max={max}
      className={`${classes.root} ${classes[type]}`}
      badgeContent={icon ? icon : text}
      variant={type === "dot" ? "dot" : "standard"}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {children}
    </MaterailBadge>
  );
};
