export const REPORT_INTERVAL = [
    { value: 'DAY', text: 'Today' },
    { value: 'WEEK', text: 'This week' },
    { value: 'MONTH', text: 'This month' },
    { value: 'YEAR', text: 'This year' },
    { value: 'ALL', text: 'All time' },
];

export const REPORT_TOP_CUSTOMERS = [
    { name: 'Ralph Edwards', product: 'iPad Pro 2024 Model', value: 3443.92 },
    { name: 'Annette Black', product: 'Lego Star War edition', value: 882.90 },
    { name: 'Dianne Russell', product: 'Brand New Bike, Local buyer only', value: 773.00 },
    { name: 'Darrell Steward', product: 'Heimer Miller Bracelet', value: 343.12 },
    { name: 'Brooklyn Simmons', product: 'Dell Mouse (basic 90)', value: 23.90 },
    { name: 'Carmen Mendoza', product: 'Samsung Galaxy S22', value: 1243.92 }
];

export const TODAY = new Date().toISOString().split('T')[0];