import { CircularProgress, CircularProgressProps } from "@mui/material";
import { color } from "../../../consts/ColorConst";

export function KarteraProgress({
  size = 52,
  ...props
} : CircularProgressProps) {

  return (
      <CircularProgress
        size={size}
        sx={{ color: color.GREEN_LIGHT_2 }}
        {...props}
      />
  );
}
