import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { faCheck, faGears, faPause, faXmarkCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { color } from "../../consts/ColorConst";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: "0px 6px",
    borderRadius: "12px",
    fontSize: 12,
    fontWeight: 500,
    gap: 4,
  },
  ACTIVE: {
    color: color.GREEN_DARK_2,
    backgroundColor: color.GREEN_LIGHT_6,
    border: `2px solid ${color.GREEN_LIGHT_1}`,
  },
  PAUSED: {
    color: color.GREY,
    backgroundColor: color.GREY_LIGHT_6,
    border: `1.8px solid ${color.GREY}`,
  },
  COMPLETED: {
    color: color.GREEN_DARK_2,
    backgroundColor: color.GREEN_LIGHT_6,
    border: `2px solid ${color.GREEN_LIGHT_1}`,
  },
  PROCESSED: {
    color: color.GREEN_DARK_2,
    backgroundColor: color.GREEN_LIGHT_6,
    border: `2px solid ${color.GREEN_LIGHT_1}`,
  },
  QUEUED: {
    color: color.ORANGE_DARK_2,
    backgroundColor: color.ORANGE_LIGHT_6,
    border: `2px solid ${color.ORANGE_LIGHT_1}`,
  },
  INITIATED: {
    color: color.ORANGE_DARK_2,
    backgroundColor: color.ORANGE_LIGHT_6,
    border: `2px solid ${color.ORANGE_LIGHT_1}`,
  },
  IN_PROGRESS: {
    color: color.ORANGE_DARK_2,
    backgroundColor: color.ORANGE_LIGHT_6,
    border: `2px solid ${color.ORANGE_LIGHT_1}`,
  },
  CANCELLED: {
    color: color.RED_DARK_2,
    backgroundColor: color.RED_LIGHT_5,
    border: `2px solid ${color.RED_LIGHT_1}`,
  },
  RETURNED: {
    color: color.RED_DARK_2,
    backgroundColor: color.RED_LIGHT_5,
    border: `2px solid ${color.RED_LIGHT_1}`,
  },
}));

type Props = {
  status: "COMPLETED" | "PROCESSED" | "QUEUED" | "IN_PROGRESS" | 
          "RETURNED" | "CANCELLED" | "INITIATED" | "ACTIVE" | "PAUSED";
}

export function PaymentStatus({ status }: Props) {
  const classes = useStyles();
  const { t } = useTranslation("translation", { keyPrefix: "payments" });

  const STATUS_TEXT = {
    COMPLETED: t("completed"),
    PROCESSED: t("completed"),
    QUEUED: t("queued"),
    INITIATED: t("queued"),
    IN_PROGRESS: t("inProgress"),
    CANCELLED: t("cancelled"),
    RETURNED: t("returned"),
    ACTIVE: t("active"),
    PAUSED: t("paused"),
  };

  const ICONS = {
    COMPLETED: faCheck,
    PROCESSED: faCheck,
    QUEUED: faGears,
    INITIATED: faGears,
    IN_PROGRESS: faGears,
    CANCELLED: faXmarkCircle,
    RETURNED: faXmarkCircle,
    ACTIVE: faCheck,
    PAUSED: faPause,
  }
  
  return (
    <Box className={classes.container}>
      <Typography className={`${classes.statusWrapper} ${classes[status]}`}>
        {status && <FontAwesomeIcon icon={ICONS[status]} />}
        {STATUS_TEXT[status]}
      </Typography>
    </Box>
  );
}
