import { Theme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { KarteraDialogue } from "../kartera/dialogue";
import WelcomeDialogImage from "../../assets/welcomeDialogImage.png";

const useStyles = makeStyles<Theme>(() => ({
  message: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.2
  },
  dialogContainer: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.2,
  },
  messageContainer: {
    padding: '26px 0',
    display: 'flex', 
  },
  innerMessageContainer: {
    display: 'flex', 
    flexDirection: 'column',
    gap: 12,
  },
  messageTitle: {
    fontWeight: 700
  },
  image: {
    width: 165,
    height: 175,
    margin: '10px 36px 0 16px'
  }
}));

type Props = {
  startOnboarding: () => void;
  onClose: () => void;
}

export function OnboardingInformationDialog({ onClose, startOnboarding }: Props) {
  const classes = useStyles();

  const { t } = useTranslation("translation", { keyPrefix: "dashboard" });

  function handleOnboarding() {
    startOnboarding();
    onClose();
  }

  return (
    <KarteraDialogue 
      title={t('welcomeKartera')}
      size='xl'
      autoHeight
      showCloseButton={false}
      othersButtonText={t('verifyLater')}
      onOthersClick={onClose}
      actionButtonText={t('verifyNow')}
      activeButtonType="primary"
      onActionClick={handleOnboarding}
      onCancelClick={onClose}
    >
      <div className={classes.dialogContainer}>
        <p>{t('informationDialogP1')}</p>
        <div className={classes.messageContainer}>
          <img className={classes.image} src={WelcomeDialogImage} alt="Welcome image" />
          <div className={classes.innerMessageContainer}>
            <div>
              <p className={classes.messageTitle}>{t('informationDialogP2Title')}</p>
              <p>{t('informationDialogP2')}</p>
            </div>
            <div>
              <p className={classes.messageTitle}>{t('informationDialogP3Title')}</p>
              <p>{t('informationDialogP3')}</p>
            </div>
            <div>
              <p className={classes.messageTitle}>{t('informationDialogP4Title')}</p>
              <p>{t('informationDialogP4')}</p>
            </div>
          </div>
        </div>
        <p>{t('informationDialogP5')}</p>
      </div>
    </KarteraDialogue>
  );
}
