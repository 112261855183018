import React, { FC, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { Grow, MenuItem, Paper } from '@mui/material';
import { truncateText } from '../../../utils/helperFunctions';
import { color } from '../../../consts/ColorConst';
import { KarteraAvatar } from '../avatar';
import { AccountMenuProps } from './types';
import { useOutSideClick } from '../../../hooks/useOUtsideClick';

const useStyles = makeStyles((theme: any) => ({
  root: {
    position: 'absolute',
    padding: '0px 0 4px',
    boxShadow: theme.shadow.elevation8,
    zIndex: 999,
  },
  menuItemRoot: {
    fontSize: '14px',
    padding: '7px 10px',
    '&$menuItemSelected, &$menuItemSelected:focus, &$menuItemSelected:hover': {
      backgroundColor: color.GREEN_LIGHT_5,
    },
  },
  menuItemSelected: {},
  avatarPanelTop: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingBottom: '6px',
    borderBottom: `1px solid ${color.GREY_LIGHT_4}`,
    gap: 12,
  },
  avatarPanelUserDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: '11px 10px 10px 10px',
  },
  avatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '24px',
    minHeight: '42px',
    '& path': {
      fill: color.GREY_DARK_1,
    },
    padding: '4px 7px 0 10px',
  },
  userName: {
    fontSize: '14px',
    fontWeight: 400,
    color: color.GREY_DARK_4,
  },
  userEmail: {
    fontSize: '10px',
    color: color.GREY_DARK_2,
  },
  menuItemContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
}));

export const KarteraAccountMenu: FC<AccountMenuProps> = ({
  isOpen,
  onClose,
  top = 0,
  right = 0,
  width = '180px',
  avatarUrl,
  userName,
  userEmail,
  items,
}) => {
  const classes = useStyles();
  const panelRef = useRef(null);

  useOutSideClick(panelRef, () => onClose());

  function handleMenuClick(action?: any) {
    action && action();
    onClose();
  }

  return (
    <Grow in={isOpen}>
      <Paper className={classes.root} sx={{ width: width, top, right }} ref={panelRef}>
        <Box className={classes.avatarPanelTop}>
          <Box className={classes.avatarWrapper}>
            <KarteraAvatar
              variant='circular'
              size={{ height: 28, width: 28 }}
              alt='user avatar'
              src={avatarUrl}
              color='secondary'
            />
          </Box>
          <Box className={classes.avatarPanelUserDetails}>
            <label className={classes.userName}>{userName && truncateText(userName, 18)}</label>
            <label className={classes.userEmail}>{userEmail && truncateText(userEmail, 26)}</label>
          </Box>
        </Box>
        {items.map((item) => (
          <MenuItem
            key={item.text}
            dense
            onClick={() => handleMenuClick(item.onClick)}
            classes={{
              root: classes.menuItemRoot,
              selected: classes.menuItemSelected,
            }}
            divider={item.divider}
            sx={item.sx}
          >
            <Box className={classes.menuItemContent}>
              {item.icon}
              {item.text}
            </Box>
          </MenuItem>
        ))}
      </Paper>
    </Grow>
  );
};
