import { useState } from "react";
import { useDispatch } from "react-redux";
import { Theme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPercent, faSackDollar, faTreasureChest } from "@fortawesome/pro-regular-svg-icons";
import { KarteraDialogue } from "../../kartera/dialogue";
import { KarteraButton } from "../../kartera/button";
import { KarteraRadioTile } from "../../kartera/radioTile";
import { truncateText } from "../../../utils/helperFunctions";
import { RewardsType } from "../../../dtos/rewards/rewardsDTO";
import { setRewardsModalStep } from "../../../reducers/rewardsReducers";

const useStyles = makeStyles<Theme>(() => ({
  message: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.2
  },
  content: {
    display: 'flex', 
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 6,
    gap: 20,
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.2,
  },
  radioContainer: {
    width: '90%',
    display: 'flex', 
    flexDirection: 'column',
    textAlign: 'left',
    margin: 6,
    gap: 8, 
  },
  radioContent: {
    display: 'flex', 
    flexDirection: 'column',
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.2,
    gap: 8,
  },
  radioText: {
    lineHeight: 1.2,
  },
  messageContainer: {
    display: 'flex', 
    flexDirection: 'column',
    gap: 12, 
    padding: '14px 0 16px'
  },
  container: {
    display: 'flex', 
    flexDirection: 'column',
    gap: 12, 
  },
  moreInfo: {
    marginLeft: 2,
    height: 20,
  },
}));

type RewardsProps = {
  onCancel: () => void;
  rewardType: RewardsType;
  setRewardType: (type: RewardsType) => void;
}

function TypeOfRewardDialog({ onCancel, rewardType, setRewardType }: RewardsProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation("translation", { keyPrefix: "rewards" });

  function handleSubmit() {
    dispatch(setRewardsModalStep('SUBMIT'));
  }

  function handleText(title: string, p1: string, p2: string) {
    const [ showMoreInfo, setShowMoreInfo] = useState(false);

    return (
      <div className={classes.radioContainer}>
        <strong>{title}</strong>
        {!showMoreInfo ? (
          <div className={classes.radioContent}>
            <p className={classes.radioText}>
              {truncateText(p1, 120)}
              <KarteraButton
                className={classes.moreInfo}
                variant="link"
                text={t('moreInformation')}
                onClick={() => setShowMoreInfo(true)}
              />
            </p>
          </div>
        ) : (
          <div className={classes.radioContent}>
            <p className={classes.radioText}>{p1}</p>
            <p className={classes.radioText}>{p2}</p>
          </div>
        )}
      </div>
    )
  }

  return (
    <KarteraDialogue 
      id={'rewards-dialog'}
      title={t('TypeOfRewardDialogTitle')}
      size='medium'
      width='611px'
      autoHeight
      onCancelClick={onCancel}
      actionButtonText={t('next')}
      cancelButtonText={t('cancel')}
      activeButtonType="primary"
      onActionClick={handleSubmit}
      activeButtonDisabled={!rewardType}
    >
      <div className={classes.content}>
        <p>{t('TypeOfRewardDialogText')}</p>
        <div className={classes.container}>
          <KarteraRadioTile
            icon={<FontAwesomeIcon icon={faPercent} />}
            active={rewardType === 'INSTANT_DISCOUNT'}
            onClick={() => setRewardType('INSTANT_DISCOUNT')}
            text={handleText(t('instantDiscount'), t('instantDiscountP1'), t('instantDiscountP2'))}
          />
          <KarteraRadioTile
            icon={<FontAwesomeIcon icon={faSackDollar} />}
            active={rewardType === 'CASHBACK'}
            onClick={() => setRewardType('CASHBACK')}
            text={handleText(t('cashBackCredit'), t('cashBackCreditP1'), t('cashBackCreditP2'))}
          />
          <KarteraRadioTile
            icon={<FontAwesomeIcon icon={faTreasureChest} />}
            active={rewardType === 'POINT_SYSTEM'}
            onClick={() => setRewardType('POINT_SYSTEM')}
            text={handleText(t('pointsSystem'), t('pointsSystemP1'), t('pointsSystemP2'))}
          />
        </div>
      </div>
    </KarteraDialogue>
  );
}

export default TypeOfRewardDialog;
