import { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { useApi } from "../../services/api/hooks";
import { dateConverter } from "../../utils/dateConverter";
import { checkMockData, getMockPaymentsData } from "../../utils/generateMockData";
import { useTransactions } from "../../hooks/useTransactions";
import { KarteraTable } from "../../components/kartera/table";
import { KarteraButton } from "../../components/kartera/button";
import { AddPaymentType } from "../payments/addPaymentType/AddPaymentType";
import { currencyFormatter } from "../../utils/handleValuesHelper";
import { PaymentStatus } from "../../components/paymentStatus";
import { PageStateType } from "../../components/kartera/table/types";

const useStyles = makeStyles(() => ({
  noRowMessageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 10,
  },
  addPaymentTypeButton: {
    width: 160,
  },
}));

type Props = {
  hasPayments?: boolean;
  isDemo?: boolean;
  showBusinessVerificationDialog: () => void;
}

type PaginationType = 'server' | 'client'; 

function Transactions({ hasPayments, isDemo, showBusinessVerificationDialog }: Props) {
  const classes = useStyles();
  const { product_id } = useParams();
  const { searchPayments } = useApi();
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard' });
  const { renderTransactionDescription } = useTransactions();

  const [paginationMode, setPaginationMode] = useState<PaginationType>('server');
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [showPayment, setShowPayment] = useState(false);
  const [refreshDate, setRefreshDate] = useState(0);
  
  const filterFields = [
    { text: t('colDate'), value: 'created_at' },
    { text: t('colDescription'), value: 'description' },
    { text: t('colAccountId'), value: 'linked_account_id' },
    { text: t('colId'), value: 'id' },
    { text: t('colAmount'), value: 'pretty_amount' },
    { text: t('colStatus'), value: 'status' },
  ];

  function getParsedDate(params: GridValueGetterParams) {
    return dateConverter(params.row.created_at);
  }

  function getFormattedValue(params: GridValueGetterParams) {
    return `${currencyFormatter(params.value).substring(1)} USD`;
  }

  function renderStatus(params: GridRenderCellParams) {
    return <PaymentStatus status={params.value} />;
  }

  const columns: GridColDef[] = [
    {
      field: 'created_at',
      headerName: t('colDate'),
      minWidth: 130,
      align: "right",
      valueGetter: getParsedDate,
    },
    {
      field: 'description',
      headerName: t('colDescription'),
      minWidth: 380,
      renderCell: renderTransactionDescription,
    },
    {
      field: 'linked_account_id',
      headerName: t('colAccountId'),
      minWidth: 250,
    },
    { field: 'id', headerName: t('colId'), minWidth: 250 },
    {
      field: 'amount',
      type: 'number',
      headerName: t('colAmount'),
      minWidth: 160,
      valueGetter: getFormattedValue, 
    },
    {
      field: 'status',
      headerName: t('colStatus'),
      minWidth: 120,
      renderCell: renderStatus,
    },
  ];

  async function getPaymentsData(pageState: PageStateType, filters: any ) {
    if (checkMockData()) {
      setPaginationMode('client');
      return {
        data: getMockPaymentsData(),
        metadata: { total_elements: 200 },
      };
    }

    if (!product_id) return;
    setPaginationMode('server');
    return searchPayments(product_id, {
      type: "PAYMENT", 
      description: filters.description,
      id: filters.id,
      all: filters.all,
      linked_account_id: filters.linked_account_id,
      amount: filters.amount,
      status: filters.status,
      date_from: filters.date_from,
      date_to: filters.date_to,
      paginate: {
        sort: {
          field: pageState.sortField,
          direction: pageState.sortDirection,
        },
        page_number: pageState.page,
        size: pageState.pageSize
      },
    });
  }

  useEffect(() => {
    setRefreshDate(new Date().getTime());
  }, [isDemo]);

  return (
    <>
      <KarteraTable
        rows={[]}
        paginationAndSortingMode={paginationMode}
        title={t('tableTitle')}
        noRowsMessage={(
          <div className={classes.noRowMessageWrapper}>
            <div>{hasPayments ? t('noTransactionFound') : t('noRows')}</div>
            {!hasPayments && (
              <KarteraButton
                className={classes.addPaymentTypeButton}
                text={t('addPaymentTypes')}
                onClick={() => isDemo ? showBusinessVerificationDialog() : setShowPayment(true)}
              />
            )}
          </div>
        )}
        fetchData={getPaymentsData}
        refreshDate={refreshDate}
        columns={columns}
        defaultPageSize={10}
        minHeight={258}
        searchPlaceholder={t('searchPlaceholder')}
        csvFileName={t('csvFileName')}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        filterFields={filterFields}
        filteringEnabled={false}
      />
      {showPayment && (
        <AddPaymentType 
          onClose={() => setShowPayment(false)} 
        />
      )}
    </>
  );
}

export default Transactions;
