import { FC, useState } from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { color } from "../../../consts/ColorConst";
import { FilteRowProps, FilterRowType } from "./types";
import { KarteraDatePicker } from "../../kartera/datePicker";
import { KarteraTextField } from "../../kartera/textField";
import { KarteraSelectField } from "../../kartera/selectField";

const useStyles = makeStyles(() => ({
  filterRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 0 12px 0",
  },
  datePickers: {
    display: "flex",
    justifyContent: "space-between",
    width: 260,
    alignItems:"center"
  },
}));

const dateArrayFormat = [
  "date",
  "time",
  "created_at",
  "updated_at",
  "execution_timestamp",
];

const monetaryArrayFormat = [
  "amount",
];

export const FilterRow: FC<FilteRowProps> = ({
  filterRows,
  setFilterRows,
  setSelectors,
  rowItem,
  setError,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("translation", { keyPrefix: "dataGrid" });
  const [ toDateError, setToDateError ] = useState(false);
  const [ inputError, setInputError ] = useState(false);

  const isDateField = dateArrayFormat.includes(
    rowItem.fieldName.toLocaleLowerCase()
  );

  const isMonetaryField = monetaryArrayFormat.includes(
    rowItem.fieldName.toLocaleLowerCase()
  );

  function updateCurrentRow(
    currentItem: FilterRowType, 
    filterType: "fieldValue" | "toDate" | "fromDate",
    value: any,
  ) {
    const newFilters = [...filterRows];
    const index = newFilters.findIndex( item => 
      item.fieldName === currentItem.fieldName
    );
    newFilters[index][filterType] = value;
    let dateError = false;
    if (["toDate","fromDate"].includes(filterType)) {
      dateError = checkDate();
      setToDateError(dateError)
    }
    newFilters[index].error = (!value || dateError);
    setFilterRows([...newFilters]);
  }

  function checkError() {
    const rowsWithError = filterRows.filter( row => row.error === true );
    setError(rowsWithError.length > 0);
  }

  function checkDate() {
    if (rowItem.fromDate && rowItem.toDate) {
      return rowItem.fromDate.isAfter(rowItem.toDate.toDate());
    }
    return false;
  }

  function onDeleteClick() {
    const newFilterRows = filterRows.filter( item =>
      item.fieldName !== rowItem.fieldName
    );
    setFilterRows(newFilterRows);

    const selector = {
      text: rowItem.fieldLabel,
      value: rowItem.fieldName,
      items: rowItem.fieldValueSelect,
    }
    setSelectors((prev: any) => ([...prev, selector]));
  }

  function onFieldValueChange(e: any) {
    let value = e.target.value;
    if (isMonetaryField) {
      value = e.target.value.replace(/[^0-9.,]/g, '');
    }
    updateCurrentRow(rowItem, "fieldValue", value);
  }

  function onFromDateChange(value: any) {
    updateCurrentRow(rowItem, "fromDate", value);
    checkError();
  }

  function onToDateChange(value: any) {
    updateCurrentRow(rowItem, "toDate", value);
    checkError();
  }

  return (
    <Box className={classes.filterRow}>
      {rowItem.fieldName !== "" && (
        <>
          <KarteraTextField     
            width="160px"
            placeholder={t("selectField")}
            value={rowItem.fieldLabel}
            disabled={!!rowItem.fieldName}
          />
          {isDateField ? (
            <Box className={classes.datePickers}>
              <KarteraDatePicker
                id={`field-${rowItem.fieldName}`}
                value={rowItem.fromDate}
                onChange={onFromDateChange}
                width="122px"
                labelStyle="simple"
              />
              <Typography>-</Typography>
              <KarteraDatePicker
                value={rowItem.toDate}
                onChange={onToDateChange}
                width="122px"  
                error={toDateError}   
                labelStyle="simple"  
              />
            </Box>
          ) : rowItem.fieldValueSelect.length > 0 ? (
            <KarteraSelectField
              id={`field-${rowItem.fieldName}`}
              width="260px"
              items={rowItem.fieldValueSelect}
              placeholder={t("selectValue")}
              onChange={(e) => { 
                onFieldValueChange(e); 
                setInputError(!rowItem.fieldValue);
                checkError();
              }}
              onBlur={() => setInputError(!rowItem.fieldValue)}
              value={rowItem.fieldValue}
              error={inputError}
              labelStyle="simple"
            />
          ) : (
            <KarteraTextField
              id={`field-${rowItem.fieldName}`}
              width="260px"
              placeholder={t("enterField")}
              onChange={onFieldValueChange}
              onBlur={() => { 
                setInputError(!rowItem.fieldValue); 
                checkError();
              }}
              value={rowItem.fieldValue}
              error={inputError}
            /> 
          )}
          <FontAwesomeIcon
            icon={faTrash}
            style={{
              width: 11,
              height: 13,
              cursor: "pointer",
              color: color.GREEN,
            }}
            onClick={onDeleteClick}
          />
        </>
      )}
    </Box>
  );
};
