import React, { FC } from "react";
import { Box, Theme, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { color } from "../../../consts/ColorConst";
import { DatePickerProps } from "./types";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const useStyles = makeStyles<Theme>((theme: any) => ({
  inputStyle: {
    height: "28px",
    padding: "6px 10px 6px 8px",
    backgroundColor: color.GREY_LIGHT_5,
    color: color.GREY_DARK_4,
    border: `1.5px solid ${color.GREY_LIGHT_5}`,
    borderRadius: 6,
    "& .MuiInputBase-input": {
      fontSize: 14,
      padding: 0,
      fontWeight: 400,
      "&::placeholder": {
        color: color.GREY_DARK_4,
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&:hover": { 
      backgroundColor: color.GREY_LIGHT_5,
      borderColor: color.GREY_LIGHT_3,
      boxShadow: theme.shadow.elevation4Grey,
    },
    "&:active": { 
      backgroundColor: color.WHITE_01,
      border: `1.5px solid ${color.GREEN}`,
    },
    "& .Mui-focused": {
      backgroundColor: color.WHITE_01,
      border: `1.5px solid ${color.GREEN}`,
    },
  },
  paperStyle: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${color.RED_DARK_1} !important`,
      color: color.RED_DARK_3,
    },
    "& .MuiButtonBase-root": {
      fontSize: '14px !important',
    },
    "& .MuiTypography-root": {
      fontSize: '12px !important',
    },
    "& .MuiPaper-root": {
      borderRadius: 6,
      boxShadow: theme.shadow.elevation4Grey,
    },
    "& .MuiPickersDay-dayWithMargin": {
      "&:hover": {
        backgroundColor: color.GREY_LIGHT_4,
      },
      "&:active": { backgroundColor: `${color.GREEN} !important` },
    },
    "& .Mui-selected": {
      backgroundColor: `${color.GREEN} !important`,
      "&:hover": {
        backgroundColor: `${color.GREEN} !important`,
      },
    },
  },
  inputWhite: {
    backgroundColor: color.WHITE_01,
    color: color.GREY_DARK_2,
    border: `1px solid ${color.GREY_LIGHT_2}`,
    "&:hover": { 
      backgroundColor: color.WHITE_01,
    },
  },
  hasError: {
    "& .MuiInputBase-root": {
      backgroundColor: color.RED_LIGHT_5,
      color: color.RED_DARK_3,
      border: `1.5px solid ${color.RED}`,
    },
  },
  errorMessage: {
    margin: "2px 0 0 0",
    fontFamily: "inherit",
    fontSize: "11px",
    color: color.RED,
  },
  label: {
    display: 'block',
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.4,
    marginBottom: 4,
    color: color.GREY_DARK_1,
  },
  title: {
    marginBottom: 12,
    color: color.GREY_DARK_4,
  },
  labelDisabled: {
    color: color.GREY_LIGHT_2,
  }
}));

export const KarteraDatePicker: FC<DatePickerProps> = ({
  id,
  label,
  labelStyle = 'title',
  placeHolder,
  onChange,
  onBlur,
  value,
  width = "200px",
  error,
  errorMessage,
  disabled,
  disableFuture,
}) => {
  const classes = useStyles();

  return (
    <Box onBlur={onBlur}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <label 
          className={`
            ${classes.label} 
            ${labelStyle === 'title' && classes.title}
            ${disabled && classes.labelDisabled}
          `} 
        >
          {label}
        </label>
        <DesktopDatePicker
          disableFuture={disableFuture}
          inputFormat="MM/DD/YYYY"
          value={value}
          onChange={onChange}
          disabled={disabled}
          renderInput={(params) => (
            <TextField {...params} inputProps={{...params.inputProps, placeholder: placeHolder }}/>
          )}
          className={`${error || errorMessage && classes.hasError}`}
          InputProps={{
            id: id,
            className: classes.inputStyle,
            sx: { width: width }
          }}
          PopperProps={{
            className: classes.paperStyle, 
          }}
          InputAdornmentProps={{
            sx: {
              "& .MuiIconButton-root >svg": {
                width: 14,
                height: 14,
                color: color.GREY,
              },
            },
          }}
        />
      </LocalizationProvider>
      {errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}
    </Box>
  );
};
