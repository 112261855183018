export const color = {
    BLACK_01: '#000000',
    BLACK_02: '555555',
    BLUE: "#4C97FF",
    BLUE_01: '#0B72FF',
    BLUE_02: 'rgba(11, 114, 255, 0.05)',
    BLUE_LIGHT_01: '#76B0FF',
    BLUE_LIGHT_02: '#4D97FF',
    BLUE_LIGHT_03: '#DBEAFF',
    BLUE_LIGHT_04: '#B2D3FF',
    BLUE_LIGHT_05: '#9DC0F1',
    BLUE_DARK_04: '#005DDD',
    GRAY: "#8D95A1",
    GRAY_01: '#F5F5F5',
    GRAY_02: '#AEAEAE',
    GRAY_03: '#E5E5E5',
    GRAY_04: '#7D7D7D',
    GRAY_05: '#A7A7A7',
    GRAY_06: '#979797',
    GRAY_07: '#F9F9F9',
    GRAY_08: '#EFEFF4',
    GRAY_09: '#F2F3F5',
    GRAY_LIGHT_01: 'rgba(0, 0, 0, 0.5)',
    GRAY_LIGHT_02: '#F8F9FA',
    GRAY_LIGHT_04: "#DDE0E5",
    GRAY_DARK_01: 'rgba(0, 0, 0, 0.9)',
    GRAY_DARK_03: '#34393F',
    GRAY_DARK_04: '#1F2226',
    GRAY_DARK_05: '#495059',
    GRAY_DARK_06: '#737D8C',
    GRAY_DARK_07: '#5E6772',
    GRAY_DARK_08: '#C4C8CF',
    GRAY_DARK_09: 'rgba(125, 132, 143, 0.4)',

    GREEN_01: '#287425',
    GREEN_02: "rgba(41, 151, 37, 0.05)",
    GREEN_03: '#21AF67',
    GREEN_04: "rgba(26, 192, 107, 0.1)",
    GREEN_DARK_01: '#5E6772',
    GREEN_DARK_03: '#34393F',
    GREEN_DARK_04: '#0B3A22',
    GREEN_DARK_05: '#1A8850',
    RED_01: '#FFE0F0',
    RED_02: '#FF2559',
    RED_03: '#D1000D',
    RED_04: '#FFE5E5',
    RED_DARK_04: '#460008',
    RED_DARK_05: '#A2000d',
    RED_DARK_06: '#74000B',
    WHITE_01: '#FFFFFF',
    PRIMARY: '#0B72FF',
    PRIMARY_LIGHTEST_01: '#FBFBFF',
    PRIMARY_LIGHTEST_02: '#F5FAFF',
    PRIMARY_LIGHTEST_03: '#E7F1FF',
    PRIMARY_LIGHTEST_04: '#D8E8FF',
    PRIMARY_LIGHT_01: '#9DC7FF',
    PRIMARY_DARK_01: '#004BB3',
    PRIMARY_DARK_02: '#003680',
    SECONDARY: '#DDE0E5',
    SECONDARY_LIGHTEST_01: '#F8F8F9',
    SECONDARY_LIGHTEST_02: '#F1F2F4',
    SECONDARY_LIGHTEST_03: '#E8EAEC',
    SECONDARY_LIGHTEST_04: '#E3E5E8',
    SECONDARY_LIGHT_01: '#EEEFF2',
    SECONDARY_DARK_01: '#C0C4CB',
    SECONDARY_DARK_02: '#A6ADB6',
    NEGATIVE: '#FFA4A4',
    NEGATIVE_LIGHTEST_01: '#FFF5F5',
    NEGATIVE_LIGHTEST_02: '#FEE7E7',
    NEGATIVE_LIGHTEST_03: '#FDD7D8',
    NEGATIVE_LIGHTEST_04: '#FCCECF',
    NEGATIVE_LIGHT_01: '#FFDBDB',
    NEGATIVE_DARK_01: '#FF7B7B',
    NEGATIVE_DARK_02: '#FF5A5A',
    POSITIVE: '#90E8BB',
    POSITIVE_LIGHTEST_01: '#F6FDF9',
    POSITIVE_LIGHTEST_02: '#E9F9F1',
    POSITIVE_LIGHTEST_03: '#DAF5E7',
    POSITIVE_LIGHTEST_04: '#D1F2E1',
    POSITIVE_LIGHT_01: '#D3F6E4',
    POSITIVE_DARK_01: '#66DBA0',
    POSITIVE_DARK_02: '#3FCF84',
    NOTICE: '#FAC980',
    NOTICE_LIGHT_01: '#FDE9CC',
    NOTICE_DARK_01: '#FBB957',
    NOTICE_DARK_02: '#FEA928',
    NOTICE_LIGHTEST_01: '#FFFBF5',
    NOTICE_LIGHTEST_02: '#FFF5E6',
    NOTICE_LIGHTEST_03: '#FEEED6',
    NOTICE_LIGHTEST_04: '#FEEACC',

    ORANGE_02: '#F9DCB0',
    ORANGE_DARK_04: '#462A00',
    ORANGE_DARK_05: '#A26100',

    BLUE_LIGHT_5: '#F3F8FF',
    BLUE_LIGHT_4: '#DBEAFF',
    BLUE_LIGHT_3: '#B2D3FF',
    BLUE_LIGHT_2: '#76B0FF',
    BLUE_LIGHT_1: '#4D97FF',
    BLUE_NEW: '#0B72FF',
    BLUE_DARK_1: '#005DDD',
    BLUE_DARK_2: '#004BB3',
    BLUE_DARK_3: '#003680',
    BLUE_DARK_4: '#00204D',
    BLUE_DARK_5: '#00132E',
    RED_LIGHT_5: '#FFE5E5',
    RED_LIGHT_4: '#FFC6C6',
    RED_LIGHT_3: '#FFA4A4',
    RED_LIGHT_2: '#FF7B7B',
    RED_LIGHT_1: '#FF5A5A',
    RED: '#F1080D',
    RED_DARK_1: '#D1000D',
    RED_DARK_2: '#A2000D',
    RED_DARK_3: '#74000B',
    RED_DARK_4: '#460008',
    RED_DARK_5: '#170004',
    ORANGE_LIGHT_6: '#FFF5E6',
    ORANGE_LIGHT_5: '#FAECD7',
    ORANGE_LIGHT_4: '#F9DCB0',
    ORANGE_LIGHT_3: '#FAC980',
    ORANGE_LIGHT_2: '#FBB957',
    ORANGE_LIGHT_1: '#FEA928',
    ORANGE: '#F89500',
    ORANGE_DARK_1: '#D17D00',
    ORANGE_DARK_2: '#A26100',
    ORANGE_DARK_3: '#744600',
    ORANGE_DARK_4: '#462A00',
    ORANGE_DARK_5: '#170E00',
    GREY_LIGHT_6: '#F7F7F7',
    GREY_LIGHT_5: '#F2F3F5',
    GREY_LIGHT_4: '#DDE0E5',
    GREY_LIGHT_3: '#C0C4CB',
    GREY_LIGHT_2: '#A6ADB6',
    GREY_LIGHT_1: '#8D95A1',
    GREY: '#737D8C',
    GREY_DARK_1: '#5E6772',
    GREY_DARK_2: '#495059',
    GREY_DARK_3: '#34393F',
    GREY_DARK_4: '#202124',
    GREY_DARK_5: '#0A0B0D',
    GREEN_LIGHT_7: '#F6FDF9',
    GREEN_LIGHT_6: '#E9F9F1',
    GREEN_LIGHT_5: '#D2F7E4',
    GREEN_LIGHT_4: '#B2F0D0',
    GREEN_LIGHT_3: '#90E8BB',
    GREEN_LIGHT_2: '#66DBA0',
    GREEN_LIGHT_1: '#59ce8f',
    GREEN: '#1AC06B',
    GREEN_DARK_1: '#21AF67',
    GREEN_DARK_2: '#1A8850',
    GREEN_DARK_3: '#136139',
    GREEN_DARK_4: '#0B3A22',
    GREEN_DARK_5: '#04130B',
} 