import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import { useApi } from "../../../services/api/hooks";
import { KarteraChartCard } from "../../../components/kartera/chartCard";
import { IntervalType, ReportDTO, ReportRequestDTO, ReportSimpleRequestDTO, ReportType } from "../../../dtos/reports/reportDTO";
import { MockReportsType, getMockReportsData } from "../../../utils/generateMockData";
import { PartnerType } from "../../../context/types";
import { connect, ConnectedProps } from "react-redux";
import { AppRootState } from "../../../reducers";
import { getInitialDateFromRange } from "../../../utils/helperFunctions";

const useStyles = makeStyles(() => ({
  cardsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '10px 0 20px 0',
    gap: 32,
  },
}));

const reduxConnector = connect((state: AppRootState) => ({
  rewardType: state.rewardsState.reward_type,
  rewardPointsSpendRatio: state.rewardsState.point_system_spend_ratio,
}));

type GraphProps = {
  handleRewardsDialog: () => void;  
  interval: IntervalType;
  useMockData?: boolean;
  partner?: PartnerType | undefined;
}

type Props = GraphProps & ConnectedProps<typeof reduxConnector>;

function Graphs({ 
  handleRewardsDialog, 
  interval, 
  useMockData, 
  partner, 
  rewardType,
  rewardPointsSpendRatio,
}: Props) {
  const classes = useStyles();
  const { getReportAverageAmount, getReportDisputes,
          getReportNewCustomers, getReportTotal, 
          getReportCashbackEarned, getReportPointsEarned } = useApi();
  const { product_id } = useParams();
  const { t } = useTranslation('translation', { keyPrefix: 'graphs' });

  const [newCustomers, setNewCustomers] = useState<ReportDTO>([]);
  const [disputes, setDisputes] = useState<ReportDTO>([]);
  const [avgPayments, setAvgPayments] = useState<ReportDTO>([]);
  const [totalRefunds, setTotalRefunds] = useState<ReportDTO>([]);

  const simpleDataRequest: ReportSimpleRequestDTO = {
    interval: ['DAY', 'WEEK', 'MONTH'].includes(interval) ? 'DAY' : 'MONTH',
    range: {
        from: getInitialDateFromRange(interval),
        to: new Date().toISOString().split('T')[0]
    }
  };

  useEffect(() => {
    if (!useMockData) return;
    setTimeout(() => {
      const reports: MockReportsType = getMockReportsData();
      setNewCustomers(reports?.customers || []);
      setAvgPayments(reports?.averageSpends || []);
      setTotalRefunds(reports?.totalRefunds || []);
      setDisputes(reports?.disputes || []);
    }, 1);
  }, [useMockData]);

  async function handleReport(cb: any, type?: ReportType): Promise<ReportDTO | null> {
    if (!product_id) return null;
    if (partner && !partner.isVerified) return null;
    const dataRequest: ReportRequestDTO = {
      aggregation: simpleDataRequest,
      type: type
    };
    return cb(product_id, type ? dataRequest : simpleDataRequest);
  }

  async function getPointsEarnedInDollars(): Promise<ReportDTO | null> {
    if (!product_id) return null;
    const pointsEarned = await getReportPointsEarned(product_id, simpleDataRequest);
    return pointsEarned.map( point => ({
        amount: String(+point.amount / Number(rewardPointsSpendRatio)), 
        time_span: point.time_span 
    }));
  }

  return (
    <Box className={classes.cardsWrapper}>
      <KarteraChartCard
        title={t('newCustomers')}
        tooltip={t('hintNewCustomers')}
        interval={interval}
        totalFormat="NUMBER"
        type="bar"
        useMockData={useMockData}
        mockData={newCustomers}
        getData={() => handleReport(getReportNewCustomers)}
      />
      <KarteraChartCard
        title={t('averageSpend')}
        tooltip={t('hintAverageSpend')}
        interval={interval}
        type="bar"
        useMockData={useMockData}
        mockData={avgPayments}
        getData={() => handleReport(getReportAverageAmount, 'PAYMENT')}
      />
      <KarteraChartCard
        title="Average Withdraws"
        tooltip={t('hintAverageWithdraws')}
        interval={interval}
        type="bar"
        useMockData={useMockData}
        mockData={avgPayments}
        getData={() => handleReport(getReportAverageAmount, 'WITHDRAW')}
      />
      <KarteraChartCard
        title="Average Refunds"
        tooltip={t('hintAverageRefunds')}
        interval={interval}
        type="bar"
        useMockData={useMockData}
        mockData={avgPayments}
        getData={() => handleReport(getReportAverageAmount, 'REFUND')}
      />
      <KarteraChartCard
        title={t('dispute')}
        tooltip={t('hintDispute')}
        totalFormat="PERCENT"
        interval={interval}
        type="bar"
        useMockData={useMockData}
        mockData={disputes}
        getData={() => handleReport(getReportDisputes)}
      />
      <KarteraChartCard
        title="Total Payments"
        tooltip={t('hintTotalPayments')}
        interval={interval}
        type="bar"
        useMockData={useMockData}
        mockData={totalRefunds}
        getData={() => handleReport(getReportTotal, 'PAYMENT')}
      />
      <KarteraChartCard
        title="Total Withdraws"
        tooltip={t('hintTotalWithdraws')}
        interval={interval}
        type="bar"
        useMockData={useMockData}
        mockData={totalRefunds}
        getData={() => handleReport(getReportTotal, 'WITHDRAW')}
      />
      {(rewardType === 'POINT_SYSTEM' && rewardPointsSpendRatio ) ? (
        <KarteraChartCard
          title="Total Points Value"
          tooltip={t('hintTotalPoints')}
          interval={interval}
          type="bar"
          useMockData={useMockData}
          mockData={totalRefunds}
          getData={() => getPointsEarnedInDollars()}
        />
      ) : rewardType !== 'INSTANT_DISCOUNT' && (
        <KarteraChartCard
          title="Total Cash Back"
          isLoading={!rewardType}
          tooltip={t('hintTotalCashBack')}
          interval={interval}
          type="bar"
          useMockData={useMockData}
          mockData={totalRefunds}
          getData={() => handleReport(getReportCashbackEarned)}
          showButton={rewardType === 'NO_REWARD'}
          buttonClick={handleRewardsDialog}
        />
      )}
    </Box>
  );
}

export default reduxConnector(Graphs);