import { FC } from "react";
import { RadioButtonProps } from "./types";
import { FormControlLabel, Radio } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { color } from "../../../consts/ColorConst";

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  radio: {
    width: 16,
    height: 16,
    padding: 7,
    backgroundColor: `${color.GREY_LIGHT_5} !important`,
    '& .MuiSvgIcon-root': {
      color: color.GREY_DARK_2,
    },
    '&:active': {
      backgroundColor: `${color.GREY_LIGHT_3} !important`,
    },
    '&:hover': {
      backgroundColor: `${color.GREY_LIGHT_4} !important`,
    },
  },
  checked: {
    backgroundColor: `${color.WHITE_01} !important`,
    '& .MuiSvgIcon-root': {
      color: color.GREEN,
    },
    '&:active': {
      backgroundColor: `${color.WHITE_01} !important`,
      '& .MuiSvgIcon-root': {
        color: color.GREEN_DARK_3,
      },
    },
    '&:hover': {
      backgroundColor: `${color.WHITE_01} !important`,
      '& .MuiSvgIcon-root': {
        color: color.GREEN_DARK_2,
      },
    },
  },
  labelWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: -2,
  },
  labelStyle: {
    fontFamily: 'inherit',
    fontSize: 14,
    fontWeight: 400,
    marginLeft: 10,
    color: color.GREY_DARK_4,
    textTransform: 'capitalize',
  },
  subnote: {
    fontSize: 14,
    fontWeight: 400,
    marginLeft: 10,
    color: color.GREY,
    lineHeight: 1.1,
    marginTop: -2,
  },
  labelError: {
    color: color.RED_DARK_1,
  },
  labelDisabled: {
    color: color.GREY_LIGHT_2,
  },
  error: {
    backgroundColor: `${color.RED_LIGHT_5} !important`,
    '& .MuiSvgIcon-root': {
      color: color.RED_DARK_1,
    },
    '&:active': {
      backgroundColor: `${color.RED_LIGHT_5} !important`,
      '& .MuiSvgIcon-root': {
        color: color.RED_DARK_1,
      },
    },
    '&:hover': {
      backgroundColor: `${color.RED_LIGHT_5} !important`,
      '& .MuiSvgIcon-root': {
        color: color.RED_DARK_1,
      },
    },
  },
  disabled: {
    backgroundColor: `${color.GREY_LIGHT_5} !important`,
    '& .MuiSvgIcon-root': {
      color: color.GREY_LIGHT_2,
    },
    '& .Mui-checked': {
      backgroundColor: `${color.WHITE_01} !important`,
      '& .MuiSvgIcon-root': {
        color: color.GREEN,
      },
    },
  },
}));

export const KarteraRadioButton: FC<RadioButtonProps> = ({
  id,
  label,
  disabled,
  error,
  subnote,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <FormControlLabel 
      className={classes.container}
      control={
        <Radio
          size="small"
          className={`${error && classes.error}`}
          classes={{ 
            root: classes.radio, 
            checked: classes.checked, 
            disabled: classes.disabled
          }}
          disabled={disabled}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          {...rest}
        />
      }
      label={label && (
        <div className={classes.labelWrapper}>
          <label 
            className={`
              ${classes.labelStyle} 
              ${disabled && classes.labelDisabled}
              ${error && classes.labelError}
            `}
          >
            {label}
          </label>
          {subnote && (
            <label className={classes.subnote}>
              {subnote}
            </label>
          )}
        </div>
      )}
    />
  );
};
