import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  id: "",
  first_name: "",
  last_name: "",
  name: "",
  email: "",
  role: "",
  avatar_image: "",    
};

const partnerUserSlice = createSlice({
  name: 'partnerUser',
  initialState,
  reducers: {
    setPartnerUser: (state, action: PayloadAction<typeof initialState>) => {
      return action.payload;
    },
    setPartnerUserAvatar: (state, action: PayloadAction<string>) => {
      state.avatar_image = action.payload;
    },
  },
});

export const { setPartnerUser, setPartnerUserAvatar } = partnerUserSlice.actions;

export default partnerUserSlice.reducer;