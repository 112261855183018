import { FC, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import { color } from "../../../consts/ColorConst";
import { AppRootState } from "../../../reducers";
import { AvatarProps } from "./types";
import { useAuth } from "../../../context/Auth";
import { KarteraAvatar } from "../../kartera/avatar";
import { KarteraAccountMenu } from "../../kartera/accountMenu";
import { useApi } from '../../../services/api/hooks';
import { setPartnerUser } from "../../../reducers/partnerUserReducers";

const useStyles = makeStyles(() => ({
  container: {
    padding: 0,
    minWidth: 0,
    borderRadius: '50%',
  },
  avatarPanel: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    background: color.WHITE_01,
    top: 70,
    right: 0,
    width: 280,
    padding: '26px 20px',
    zIndex: 990,
  },
  avatarPanelTop: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: '20px',
  },
  avatarPanelUserDetails: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '20px',
  },
  userName: {
    fontSize: '18px',
    fontWeight: 700,
  },
  userEmail: {
    fontSize: '12px',
  },
}));

const reduxConnector = connect((state: AppRootState) => ({
  partnerUserName: state.partnerUserState.name,
  partnerUserEmail: state.partnerUserState.email,
  partnerUserAvatarImage: state.partnerUserState.avatar_image,
}));

type Props = AvatarProps & ConnectedProps<typeof reduxConnector>;

const KarteraUserNav: FC<Props> = ({
  id,
  alt,
  variant,
  showPanel,
  menuProps,
  partnerUserName,
  partnerUserEmail,
  partnerUserAvatarImage,
}) => {
  const classes = useStyles();
  const [cookies] = useCookies(['lynk.user-id', 'lynk.user-name', 'lynk.user-email']);
  const dispatch = useDispatch();
  const { partnerUser } = useAuth();
  const { getPartnerUsersList } = useApi();

  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const userName = partnerUserName || cookies['lynk.user-name'];
  const userEmail = partnerUserEmail || cookies['lynk.user-email'];

  async function getPartnerUserData() {
    if (partnerUser?.id) {
      dispatch(setPartnerUser({
          id: partnerUser.id,
          first_name: partnerUser.legal_first_name,
          last_name: partnerUser.legal_last_name,
          name: `${partnerUser.legal_first_name} ${partnerUser.legal_last_name}`,
          role: partnerUser.role,
          email: partnerUser.work_email,
          avatar_image: partnerUser.avatar_image,
      }));
    } 
  }

  useEffect(() => {
    getPartnerUserData();
  }, [partnerUser]);

  function togglePanel() {
    setIsPanelOpen((prev) => !prev);
  }

  return (
    <>
      <Button className={classes.container} onClick={togglePanel}>
        <KarteraAvatar
          data-testid={id}
          variant={variant}
          alt={alt}
          src={partnerUserAvatarImage}
          size={{ width: 32, height: 32 }}
        />
      </Button>
      {showPanel && menuProps && (
        <KarteraAccountMenu
          isOpen={isPanelOpen}
          onClose={() => setIsPanelOpen(false)}
          top={52}
          right={16}
          avatarUrl={partnerUserAvatarImage}
          userName={userName}
          userEmail={userEmail}
          items={menuProps}
        />
      )}
    </>
  );
};

export default reduxConnector(KarteraUserNav);
