import React, { FC, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { useApi } from "../../../services/api/hooks";
import { color } from "../../../consts/ColorConst";
import {  LynkIconButton } from "../../../components/common";
import { LynkTableGrid } from "../../../components/tableGrid";
import { InviteMemberModal } from "../inviteMemberModal";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { MemberType } from "../types";
import { LynkSelectField } from "../../../components/common/selectField";
import { KarteraButton } from "../../../components/kartera/button";
import { KarteraDialogue } from "../../../components/kartera/dialogue";
import { KarteraSnackBar } from "../../../components/kartera/snackBar";
import { useAuth } from "../../../context/Auth";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "440px",
    padding: "10px 4px 10px 0",
  },
  container: {
    width: "100%",
    height: "100%",
    padding: "10px 16px 18px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: 7,
    },
    "&::-webkit-scrollbar-track": {
      background: "#fff",
    },
    "&::-webkit-scrollbar-thumb": {
      background: color.GRAY_02,
      borderRadius: 4,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: color.GRAY, 
    }
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 16,
  },
  gridWrapper: {
    padding: 0,
    gap: 0,
  },
  buttonWrapper: {
    padding: 20,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: "0.2px",
    color: color.GRAY_DARK_01,
  },
  memberName: {
    fontWeight: 500,
  },
  inviteSent: {
    fontStyle: "italic",
    color: color.GRAY_LIGHT_01,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: "0.2px",
    color: color.GRAY_DARK_04,
  },
  message: {
    fontSize: 14,
    fontWeight: 400,
  },
  snackbarWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  }
}));

export function Company() {
  const classes = useStyles();
  const { pathname } = useLocation();

  const { getPartnerUsersList, updatePartnerUserById, 
          getStaffUsers, removePartnersInvite, deletePartnerUserById } = useApi();
  const { partner } = useAuth();

  const { t } = useTranslation("translation", { keyPrefix: "settings" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [refreshDate, setRefreshDate] = useState(new Date());
  const [memberToRemove, setMemberToRemove] = useState<MemberType | null>();
  const [snackbarText, setSnackbarText] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showFooter, setShowFooter] = useState(false); 

  const isStaffAdmin = pathname.includes('staff-admin');

  const ROLES = [
    { value: "ADMIN", text: t("roleAdmin") },
    { value: "READ_ONLY", text: t("roleReadOnly") },
  ];

  async function getPartnerUserData(page: number, pageSize: number, sort: string) {
    if (isStaffAdmin) return getStaffUsers();
    if (partner) return getPartnerUsersList();
  }

  useEffect(() => {
    if (partner) {
      getPartnerUsersList().then((response) => {
        if (response.data.length > 10) {
          setShowFooter(true);
        }
      });
    }
  }, [refreshDate]);

  function renderDeleteButton(params: GridRenderCellParams) {
    if (params.row.role === "OWNER") return null;
    return (
      <LynkIconButton
        sx={{ width: 26, height: 26, padding: 0 }}
        onClick={() => setMemberToRemove(params.row)}
      >
        <FontAwesomeIcon icon={faTrash} color={color.RED} width="13px" />
      </LynkIconButton>
    );
  }

  async function handleDeleteMember() {
    if (!memberToRemove?.id) return;

    if (memberToRemove.role === "INVITED") {
      await removePartnersInvite(memberToRemove.id);
    } else {
      await deletePartnerUserById(memberToRemove.id);
    }
    
    setSnackbarText(` 
      ${memberToRemove.legal_first_name.trim() !== '-' ? 
          memberToRemove.legal_first_name : 
          "Invited Member"
      } ${t("removed")}
    `);
    setSnackbarOpen(true);    
    setMemberToRemove(null);
    setRefreshDate(new Date());
    
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 3000);
  }

  function renderName(params: GridRenderCellParams) {
    if (params.row.legal_first_name) {
      return (
        <span className={classes.memberName}>
          {params.row.legal_first_name}
        </span>
      )
    }
    return (
      <span className={classes.inviteSent}> 
        {t("inviteSent")}
      </span>
    )
  }

  function renderRole(params: GridRenderCellParams) {
    if (params.row.role === "INVITED") return t("roleInvited");
    if (params.row.role === "OWNER") return t("roleOwner");

    return (
      <LynkSelectField
        variant="simple"
        items={ROLES}
        value={params.row.role}
        width="120px"
        onChange={async (event) => {
          await updatePartnerUserById(params.row.id, { 
            role: event.target.value
          });
          setRefreshDate(new Date());
        }}
      />
    )
  }

  const columns: GridColDef[] = [
    { 
      field: "legal_first_name", 
      headerName: t("colName"),
      minWidth: 186,
      renderCell: renderName, 
    },
    { 
      field: "work_email", 
      headerName: t("colEmail"), 
      minWidth: 220, 
    },
  ];

  if (!isStaffAdmin) {
    columns.push({
      field: "role",
      headerName: t("colRole"),
      minWidth: 80,
      renderCell: renderRole,
      editable: true,
    },
    {
      field: "delete",
      headerName: "",
      sortable: false,
      renderCell: renderDeleteButton,
      width: 20,
    });
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.titleWrapper}>
          <Typography className={classes.subtitle}>
            {isStaffAdmin ? t("staffSubtitle") : t("membersSubtitle")}
          </Typography>
        </Box>
        <Box className={classes.gridWrapper} id={"membersGrid"}>
          <LynkTableGrid
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            columns={columns}
            defaultPageSize={100}
            fetchData={getPartnerUserData}
            searchPlaceholder={t("searchPlaceholder")}
            title={isStaffAdmin ? t("staffTitle") : t("membersTitle")}
            paginationAndSortingMode="client"
            autoHeight 
            filteringEnabled={false}
            supressFooter
            refreshDate={refreshDate}
            customStyle={{
              ".roleStyle": {
                "& .MuiDataGrid-cell .MuiDataGrid-cellContent": {
                  color: color.GRAY_LIGHT_01,
                },
              },
            }}
            getRowClassName={(params) =>
              params.row.role === "INVITED" ? "roleStyle" : ""
            }
            component={
                <KarteraButton
                  icon={<FontAwesomeIcon icon={faUserPlus} />}
                  onClick={() => setIsModalOpen(true)}
                  variant="primary"
                  text={t("buttonInvite")}
                  disabled={!partner}
                />
            }
            getRowId={(row: any) => row.work_email}
          />
        </Box>
        { memberToRemove && (
          <KarteraDialogue
            size="small"
            activeButtonType="negative"
            titleIcon={<FontAwesomeIcon icon={faTrash} color={color.RED} fontSize={18} />}
            title={`${t("remove")} ${memberToRemove.legal_first_name.trim() !== '-' ? memberToRemove.legal_first_name : "Invited Member"}`}
            actionButtonText={t("remove")}
            onActionClick={handleDeleteMember}
            cancelButtonText={t("buttonCancel")}
            onCancelClick={() => setMemberToRemove(null)}
            showCloseButton={false}
          >
            <span className={classes.message}>
              {t("removeMemberDialogText")}
            </span>
          </KarteraDialogue>
        )}
        <Box className={classes.snackbarWrapper}>
          <KarteraSnackBar
            open={snackbarOpen}
            onCloseClick={() => setSnackbarOpen(false)}
            severity="simple"
            text={snackbarText}
            width="fit-content"
          />
        </Box>
        {isModalOpen && (
          <InviteMemberModal 
            setIsModalOpen={setIsModalOpen} 
            setRefreshDate={setRefreshDate} 
            setSnackbarText={setSnackbarText}
            setSnackbarVisible={setSnackbarOpen}
            isStaffAdmin={isStaffAdmin}
          /> 
        )}
      </Box>
    </Box>
  );
}
