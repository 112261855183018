import React, { FC } from "react";
import { IntercomProvider } from "react-use-intercom";
import { useCookies } from "react-cookie";
import { IntercomObjectType } from "./types";

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || "";

export const Intercom: FC = (props) => {
  const [cookies] = useCookies([
    "lynk.partner-id",
    "lynk.partner-name",
    "lynk.user-id",
    "lynk.user-name",
    "lynk.user-email",
  ]);

  let IntercomObject: IntercomObjectType | undefined;
  if (
    cookies["lynk.user-id"] &&
    cookies["lynk.user-name"] &&
    cookies["lynk.user-email"]
  ) {
    IntercomObject = {
      userId: cookies["lynk.user-id"],
      name: cookies["lynk.user-name"],
      email: cookies["lynk.user-email"],
      customAttributes: {
        lynk_environment: process.env.REACT_APP_ENVIRONMENT || ""
      }
    };

    if (cookies["lynk.partner-id"] && cookies["lynk.partner-name"]) {
      IntercomObject.company = {
        companyId: cookies["lynk.partner-id"],
        name: cookies["lynk.partner-name"],
      };
    }
  }
  return (
    <IntercomProvider
      appId={INTERCOM_APP_ID}
      autoBootProps={IntercomObject}
      autoBoot
    >
      {props.children}
    </IntercomProvider>
  );
};
