import { useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";
import { KarteraDialogue } from "../../../components/kartera/dialogue";
import { KarteraTextField } from "../../../components/kartera/textField";
import { KarteraCheckbox } from "../../../components/kartera/checkbox";
import { useApi } from "../../../services/api/hooks";
import { KarteraCurrencyField } from "../../../components/kartera/currencyField";
import { PaymentRequestDTO } from "../../../dtos/payments/paymentDTO";
import { RecurringPaymentDTO, RecurringPaymentRequestDTO } from "../../../dtos/recurringPayments/recurringPaymentDTO";
import { KarteraSwitch } from "../../../components/kartera/switch";
import { color } from "../../../consts/ColorConst";

const useStyles = makeStyles(() => ({
  RadioGroupWrapper: {
    width: '100%',
    display: 'flex', 
    flexDirection: 'row', 
    marginBottom: 12,
  },
  label: {
    color: color.GREY_DARK_1,
    fontSize: 14,
    fontWeight: 400,
    marginTop: 4,
  }, 
  footerWrapper: {
    marginTop: 12,
  },
  checkboxWrapper: {
    width: '100%',
    display: 'flex', 
    flexDirection: 'column',
    gap: 6,
  },
  fieldsWrapper: {
    display: 'flex', 
    flexDirection: 'column',
    padding: '0 0 16px 0',
    gap: 12,
  },
  fieldWrapper: {
    display: 'flex', 
    flexDirection: 'column',
    gap: 6,
  },
}));

type Props = {
  payment: RecurringPaymentDTO;
  onClose: () => void;
  setShowDeletePaymentTypeDialog: () => void;
}

export function EditPaymentType({ payment, onClose, setShowDeletePaymentTypeDialog }: Props) {
    const { t } = useTranslation("translation", { keyPrefix: "payments" });
    const classes = useStyles();
    const { product_id } = useParams();
    const {createPayment, createRecurringPayment} = useApi();

    const [isPaymentPaused, setIsPaymentPaused] = useState(payment.disabled);
    const [paymentName, setPaymentName] = useState(payment.description);
    const [singlePaymentAmount, setSinglePaymentAmount] = useState(payment.interval === 'ONE_TIME' ? payment.amount : '');
    const [monthlyAmount, setMonthlyAmount] = useState(payment.interval === 'MONTHLY' ? payment.amount : '');
    const [yearlyAmount, setYearlyAmount] = useState(payment.interval === 'YEARLY' ? payment.amount : '');

    async function handleSubmit() {   
      if (!product_id) return onClose();
      
      if (payment.interval === 'ONE_TIME') {
        const data: PaymentRequestDTO = {
          speed: 'STANDARD',
          amount: singlePaymentAmount,
          description: paymentName,
          linked_account_id: payment.linked_account_id
        }
        await createPayment(product_id, data);
        onClose();
        return;
      }

      const amount = payment.interval === 'MONTHLY' 
                        ? (monthlyAmount || '0') 
                        : (yearlyAmount || '0');
      const data: RecurringPaymentRequestDTO = {
        product_user_id: payment.product_user_id,
        linked_account_id: payment.linked_account_id,
        speed: 'STANDARD',
        amount: amount,
        description: paymentName,
        interval: payment.interval,
      }
      await createRecurringPayment(product_id, data);
      onClose();
    }

    function handleDeletePayment() {
      setShowDeletePaymentTypeDialog();
      onClose();
    }

    return (
      <KarteraDialogue 
        title={`Edit "${payment.description}"`}
        size='small'
        autoHeight
        actionButtonText={t('save')}
        activeButtonType="primary"
        onActionClick={handleSubmit}
        cancelButtonText={t('cancel')}
        onCancelClick={onClose}
        othersButtonText={t('delete')}
        onOthersClick={handleDeletePayment}
        showCloseButton={false}
        supressOutsideClick
      >
        <div className={classes.fieldsWrapper}>
          <div className={classes.fieldWrapper}>
            <label className={classes.label}>{t('status')}</label>
            <KarteraSwitch
              title="Status"
              checked={!isPaymentPaused}
              value={isPaymentPaused}
              onClick={() => setIsPaymentPaused(prev => !prev)}  
              label={isPaymentPaused ? t('paused') : t('active')}
            />
          </div>
        </div>
        <Divider  />
        <div className={classes.fieldsWrapper}>
          <div className={classes.fieldWrapper}>
            <label className={classes.label}>{t('account')}:</label>
            <label>{payment.linked_account_id}</label>
          </div>
          <KarteraTextField
            label={t('addPaymentName')}
            labelStyle="simple"
            placeholder={t('addPaymentNamePlaceholder')}
            value={paymentName}
            onChange={(e) => setPaymentName(e.target.value)}
            fullWidth
          />
          <div className={classes.fieldWrapper}>
            <label className={classes.label}>{t('paymentTypeLabel')}</label>
            <label>
              {payment.interval === 'ONE_TIME' ? t('singlePayment') : t('subscription')}
            </label>
          </div>
        </div>
        <Divider  />
        { payment.interval !== 'ONE_TIME' ? (
          <div className={classes.footerWrapper}>
            <div className={classes.checkboxWrapper}>
                <KarteraCheckbox
                  label={t('monthlyRate')}
                  onChange={() => null}
                  checked={payment.interval === 'MONTHLY'}
                  size="small"
                />
                <KarteraCurrencyField
                  placeholder={t('enterAmount')}
                  type="monetary"
                  sx={{ margin: '0 0 12px 26px' }}
                  value={monthlyAmount}
                  onChange={(e: any) => setMonthlyAmount(e.target.value)}
                  disabled={payment.interval !== 'MONTHLY'}
                  decimalScale={2}
                />
            </div>
            <div className={classes.checkboxWrapper}>
                <KarteraCheckbox
                  label={t('yearlyRate')}
                  checked={payment.interval === 'YEARLY'}
                  onChange={() => null}
                  size="small"
                />
                <KarteraCurrencyField
                  placeholder={t('enterAmount')}
                  type="monetary"
                  sx={{ marginLeft: 26 }}
                  value={yearlyAmount}
                  onChange={(e: any) => setYearlyAmount(e.target.value)}
                  disabled={payment.interval !== 'YEARLY'}
                  decimalScale={2}
                />
            </div>
          </div>
        ) : (
          <div className={classes.footerWrapper}>
              <KarteraCurrencyField
                label={t('totalForSinglePayment')}
                placeholder={t('enterAmount')}
                type="monetary"
                value={singlePaymentAmount}
                onChange={(e: any) => setSinglePaymentAmount(e.target.value)}
                decimalScale={2}
              />
          </div>
        )}
      </KarteraDialogue>
    );
}
