import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import reportWebVitals from "./reportWebVitals";
import { AuthContextProvider } from "./context/Auth";
import TagManager from "react-gtm-module";
import { isProduction } from "./utils/environmentUtils";
import { store } from "./store/store";
import App from "./App";
import theme from "./theme";
import themeDark from "./themeDark";
import "./i18n";

const tagManagerArgs = {
  gtmId: "GTM-NVQ9GRN",
};

if (isProduction()) {
  TagManager.initialize(tagManagerArgs);
}

const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={store}>
        <AuthContextProvider>
          <PersistGate loading={null} persistor={persistor}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <Router>
                  <App />
                </Router>
              </ThemeProvider>
            </StyledEngineProvider>
          </PersistGate>
        </AuthContextProvider>
      </Provider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();