import { FC, useEffect, useState } from "react";
import { MenuProps } from "./types";
import { LynkMenuItem } from "./menuItem/lynkMenuItem";

export const LynkMenu: FC<MenuProps> = ({
  id,
  items,
  variant = "primary",
  initialActiveItem = { text: "" },
  verticalStyle = false,
  sidebarOpen = true,
  disabled = false,
  sx,
}) => {
  const [selectedItem, setSelectedItem] = useState(initialActiveItem);

  useEffect(() => {
    if (initialActiveItem.text) {
      setSelectedItem(initialActiveItem);
    }
  }, [initialActiveItem]);

  return (
    <div data-testid={id} style={sx}>
      {items?.map((item) => (
        <LynkMenuItem
          key={item.text}
          activeItem={selectedItem}
          setActiveItem={setSelectedItem}
          item={item}
          verticalStyle={verticalStyle}
          sidebarOpen={sidebarOpen}
          disabled={disabled}
          variant={variant}
        />
      ))}
    </div>
  );
};
