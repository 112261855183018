import { FC, useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MenuItem, MenuItemProps, SubMenuItem } from "./types";
import { color } from "../../../../consts/ColorConst";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles<Theme>((theme) => ({
  primaryRoot: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "46px",
    cursor: "pointer",
    "& .MuiButtonBase-root": {
      justifyContent: "center",
      color: color.BLACK_01,
      borderLeft: "4px solid transparent",
    },
    "& path": {
      fill: color.BLACK_02,
      transition: "all 0.3s",
    },
    "&:hover": {
      "& .MuiButtonBase-root": {
        color: color.BLUE_01,
        fontWeight: 700,
      },
      "& path": {
        fill: color.BLUE_01,
      },
    },
  },
  secondaryRoot: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "32px",
    borderRadius: 6,
    marginBottom: 6,
    cursor: "pointer",
    "& .MuiTypography-root": {
      fontSize: "14px",
      fontWeight: 500,
      color: color.GRAY_DARK_07,
    },
    "& .MuiButtonBase-root": {
      height: "32px",
      justifyContent: "center",
      color: color.GRAY_DARK_07,
      borderRadius: 6,
    },
    "& path": {
      fill: color.GRAY_DARK_07,
      transition: "all 0.3s",
    },
    "&:hover": {
      "& .MuiButtonBase-root": {
        color: color.BLUE_01,
        fontWeight: 600,
      },
      "& path": {
        fill: color.BLUE_01,
      },
    },
  },
  menuItemDisabled: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "32px",
    borderRadius: 6,
    marginBottom: 6,
    cursor: "pointer",
    "& .MuiTypography-root": {
      fontSize: "14px",
      fontWeight: 500,
      color: color.GRAY_DARK_07,
    },
    "&:hover": {
      "& .MuiButtonBase-root": {
        cursor: "default",
        backgroundColor: color.WHITE_01,
      },
    },
  },
  primarySelected: {
    "& .MuiButtonBase-root": {
      borderColor: color.BLUE_01,
    },
    "& .MuiTypography-root": {
      color: color.BLUE_01,
      fontWeight: 700,
    },
    "& path": {
      fill: color.BLUE_01,
    },
  },
  secondarySelected: {
    "& .MuiButtonBase-root": {
      backgroundColor: color.BLUE_LIGHT_04,
    },
    "& .MuiTypography-root": {
      color: color.PRIMARY_DARK_01,
      fontWeight: 500,
    },
    "& path": {
      fill: color.PRIMARY_DARK_01,
    },
  },
  subMenuContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: color.BLUE_02,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    marginTop: -6,
    "& .MuiButtonBase-root": {
      color: color.BLACK_02,
      fontSize: "14px",
      fontWeight: 400,
      textDecoration: "none",
      transition: "all 0.3s",
    },
    "& path": {
      fill: color.BLACK_02,
      transition: "all 0.3s",
    },
  },
  subItemContainer: {
    listStyle: "none",
  },
  subMenuItem: {
    height: "32px",
    display: "flex",
    fontSize: "14px",
    fontWeight: 500,
    color: color.GRAY_DARK_07,
    border: 0,
    borderBottom: `3px solid transparent`,
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    "&:hover": {
      "& .MuiButtonBase-root": {
        color: color.BLUE_01,
      },
      "& path": {
        fill: color.BLUE_01,
      },
    },
  },
  subMenuSelected: {
    "& .MuiButtonBase-root": {
      color: color.BLUE_01,
      fontWeight: 900,
      borderColor: color.BLUE_01,
    },
    "& path": {
      fill: color.BLUE_01,
    },
    "& a": {
      color: color.BLUE_01,
      fontWeight: 700,
    },
  },
  arrowContainer: {
    minWidth: 0,
    transition: "all 0.3s",
  },
  menuArrowUp: {
    transform: "rotate(180deg)",
  },
  menuArrowDown: {
    transform: "rotate(0)",
  },
  loaderWrapper: {
    width: '100%',
    paddingLeft: '26px', 
    display: 'flex',
    justifyContent: 'flex-start',
  }
}));

export const LynkMenuItem: FC<MenuItemProps> = ({
  item,
  variant,
  activeItem,
  setActiveItem,
  verticalStyle,
  sidebarOpen,
  disabled,
}) => {
  const classes = useStyles();
  const [subMenuOpen, setsubMenuOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const showArrowIcon = item.subItems && !verticalStyle;

  useEffect(() => {
    activeItem.text === item.text
      ? setsubMenuOpen(true)
      : setsubMenuOpen(false);
    setIsActive(activeItem.text === item.text);
  }, [activeItem]);

  function handleItemClick(item: MenuItem) {
    if (disabled === false) {
      if (item.subItems) {
        setsubMenuOpen((prev) => !prev);
      } 
      setActiveItem({ text: item.text });
      item.onClick && item.onClick();
    }
  }

  function handleSubItemClick(item: MenuItem, subItem: SubMenuItem) {
    if (disabled === false) {
      setActiveItem({ text: item.text, subText: subItem.text });
      subItem.onClick && subItem.onClick();
    }
  }

  return (
    <Box>
      <ListItem
        disablePadding
        className={`
            ${disabled ? classes.menuItemDisabled : classes[variant + "Root"]} 
            ${(isActive && !disabled) && classes[variant + "Selected"]}
          `}
        onClick={() => handleItemClick(item)}
        sx={item.sx && item.sx}
        disabled={disabled}
      >
        <ListItemButton>
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: sidebarOpen ? 1 : "auto",
              justifyContent: "center",
            }}
          >
            {item.icon}
          </ListItemIcon>
          {item.loader ? (
            <Box className={classes.loaderWrapper}>
              <CircularProgress size={12} color="inherit"/>
            </Box>
          ) : (
            <ListItemText
              primary={item.text}
              sx={{ opacity: sidebarOpen ? 1 : 0 }}
            />
          )}

          {showArrowIcon && sidebarOpen && (
            <ListItemIcon
              className={`
                  ${classes.arrowContainer} 
                  ${subMenuOpen ? classes.menuArrowUp : classes.menuArrowDown}`}
            >
              <FontAwesomeIcon icon={faChevronDown} fontSize={12} />
            </ListItemIcon>
          )}
        </ListItemButton>
      </ListItem>
      {subMenuOpen && item.subItems && (
        <List className={classes.subMenuContainer}>
          {item.subItems.map((subItem) => {
            const isSubItemActive = subItem.text === activeItem.subText;
            return (
              <ListItem
                key={subItem.text}
                disablePadding
                className={classes.subItemContainer}
                sx={{ padding: sidebarOpen ? "4px 16px 0" : "4px 10px 0"}}
                onClick={() => handleSubItemClick(item, subItem)}
              >
                <div
                  className={`
                    ${classes.subMenuItem} 
                    ${isSubItemActive && classes.subMenuSelected}
                  `}
                >
                  <a href={subItem.url}>
                    {sidebarOpen ? subItem.text : subItem.text.substring(0, 3)}
                  </a>
                </div>
              </ListItem>
            );
          })}
        </List>
      )}
    </Box>
  );
};
