import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ProductUserSearchDTO } from '../../../../dtos/productUsers/productUserSearchDTO';
import { KarteraProgress } from '../../../../components/kartera/progress';
import { KarteraBackdrop } from '../../../../components/kartera/backdrop';

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: 'white',
    width: 360,
    padding: 22,
    borderRadius: 10,
    boxShadow: theme.shadow.elevation16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    paddingBottom: 14
  },
  loadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 110,
  }
}));

type Props = {
  customer: ProductUserSearchDTO;
}

export function SendingLoading({ customer }: Props) {
  const classes = useStyles();

  return (
    <KarteraBackdrop transparent>
      <Box className={classes.root}>
        <Typography className={classes.title}>
          {`Sending refund to ${customer.first_name} ${customer.last_name}...`} 
        </Typography>
        <Box className={classes.loadingWrapper}>
          <KarteraProgress />
        </Box>      
      </Box>
    </KarteraBackdrop>
  )
}
