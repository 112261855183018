import { FC, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { FilterMenuProps } from "./types";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { FilterRow } from "./filterRow";
import { FilterRowType } from "./filterRow/types";
import dayjs from "dayjs";
import { KarteraButton } from "../kartera/button";
import { KarteraSelectField } from "../kartera/selectField";

const useStyles = makeStyles(() => ({
  root: {
    width: 520,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 20,
    borderRadius: "6px",
    boxShadow:
      "0px 0px 12px rgba(94, 103, 114, 0.12), 0px 8px 12px rgba(94, 103, 114, 0.5), 0px 12px 60px rgba(94, 103, 114, 0.5)",
    zIndex: 999,
    backgroundColor: "white",
  },
  header: {
    fontSize: 16,
    fontWeight: 500,
    padding: "0 0 17px 0",
    display: "flex",
  },
  matches: {
    display: "flex",
    alignItems: "center",
    padding: "0 0 22px 0",
    fontSize: 14,
    fontWeight: 400,
  },
  fieldsHeader: {
    padding: "0 0 12px 0",
  },
  filterRows: {
    padding: "0 0 22px 0",
  },
  filterRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 0 12px 0",
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cancelButton: {
    padding: "0 12px 0 0",
  },
}));

const dateArrayFormat = [
  "date",
  "time",
  "created_at",
  "updated_at",
  "execution_timestamp",
];

export const FilterMenu: FC<FilterMenuProps> = ({
  filterMenuOpen,
  setFilterMenuOpen,
  fields,
  filters,
  setFilters,
  onApplyFilters,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("translation", { keyPrefix: "dataGrid" });

  const [currentFilters, setCurrentFilters] = useState<FilterRowType[]>(filters);
  const [currentFieldName, setCurrentFieldName] = useState("");
  const [selectors, setSelectors] = useState(fields);
  const [error, setError] = useState(false);

  const disableApplyButton = currentFilters.length <= 0 && filters.length <= currentFilters.length;

  const onSelectorChange = (e: any) => {
    const currentSelector = e.target.value;
    const fieldSelected = fields.find(field => field.value === currentSelector);
    if (!fieldSelected) return;
    
    const isDateFilter = dateArrayFormat.includes(
      currentSelector.toLocaleLowerCase()
    );

    const newFilterRow = {
      fieldLabel: fieldSelected?.text || "",
      fieldName: fieldSelected?.value || "",
      fieldValue: "",
      operatorValue: isDateFilter ? "between" : "equals",
      fieldValueSelect: fieldSelected?.items || [],
      fromDate: dayjs(new Date()),
      toDate: dayjs(new Date()),
      error: !isDateFilter,
    };
    setSelectors(prev => prev.filter(item => item.value !== currentSelector));
    setCurrentFilters(prev => ([...prev, newFilterRow]));
    setCurrentFieldName(fieldSelected?.value);
    setError(!isDateFilter);
  };

  useEffect(() => {
    setCurrentFilters(filters);
    handleFields();
  }, [filters, fields]);

  useEffect(() => {
    const inputValue = document.getElementById(`field-${currentFieldName}`);
    inputValue?.focus();
  }, [currentFieldName]);

  function handleFields() {
    const newFields = [...fields];
    filters.forEach( filter => {
      const fieldIndex = newFields.findIndex( field => field.value === filter.fieldName);
      newFields.splice(fieldIndex, 1);
    });
    setSelectors(newFields);
  }

  async function handleApply() {
    setFilters(currentFilters);
    onApplyFilters && await onApplyFilters(currentFilters);
  }

  function handleCancel() {
    setCurrentFilters(filters);
    handleFields();
    setFilterMenuOpen(false);
  }

  return (
    <>
      {filterMenuOpen && (
        <Box className={classes.root}>
          <Typography className={classes.header}>
            {`${t("filter")} ${currentFilters.length > 0 ? `(${currentFilters.length})` : ""}`}
          </Typography>
          <Box className={classes.matches}>{t("matchAll")}</Box>
          <Typography className={classes.fieldsHeader}>
            {t("fields")}
          </Typography>
          <Box className={classes.filterRows}>
            {currentFilters.map((item) => (
                <FilterRow
                  key={item.fieldName}
                  filterRows={currentFilters}
                  setFilterRows={setCurrentFilters}
                  setSelectors={setSelectors}
                  rowItem={item}
                  setError={setError}
                />
            ))}
            {selectors.length > 0 && (
              <Box className={classes.filterRow}>
                <KarteraSelectField
                  items={selectors}
                  width="160px"
                  placeholder={t("selectField")}
                  value=""
                  onChange={onSelectorChange}
                />
              </Box>
            )}
          </Box>
          <Box className={classes.buttonsWrapper}>
            <Box className={classes.cancelButton}>
              <KarteraButton
                text={t("cancel")}
                variant="secondary"
                onClick={handleCancel}
              />
            </Box>
            <KarteraButton
              text={`${t("apply")} ${
                currentFilters.length > 0 ? `(${currentFilters.length})` : ""
              }`}
              disabled={disableApplyButton || error}
              onClick={handleApply}
            />
          </Box>
        </Box>
      )}
    </>
  );
};
