import React, { FC } from 'react';
import { AppBannerProps } from './types';
import { Box, Collapse, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { color } from '../../../consts/ColorConst';
import { sentenceCaseGenerator } from '../../../utils/helperFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { LynkButton } from '../../common';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '2px 8px',
  },
  mainContent: {
    fontFamily: 'Roboto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '& > p': {
      fontSize: 13,
      fontWeight: 500,
      color: color.GRAY_DARK_04,
    },
    '& > button': {
      padding: 0,
      marginLeft: 6,
      color: color.GRAY_DARK_04,
      fontSize: 13,
      fontWeight: 700,
      textTransform: 'none',
      '&:hover': {
        color: color.GRAY_DARK_05,
      },
    },
  },
  error: {
    backgroundColor: color.NEGATIVE_DARK_02,
  },
  warning: {
    backgroundColor: color.NOTICE_DARK_02,
  },
  info: {
    backgroundColor: color.BLUE_LIGHT_02,
    color: 'white',
    '& .MuiTypography-root': {
      color: 'white',
    },
    '& >div > button': {
      color: 'white',
      '&:hover': {
        color: 'white',
      },
    },
  },
  success: {
    backgroundColor: color.GREEN_LIGHT_1,
  },
}));

export const KarteraAppBanner: FC<AppBannerProps> = ({ severity, text, buttonText, onClick }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={open}>
        <Box className={`${classes.root} ${classes[severity]}`}>
          <Box className={classes.mainContent}>
            <Typography>{sentenceCaseGenerator(text)}</Typography>
            <LynkButton
              type='tertiary'
              text={sentenceCaseGenerator(buttonText)}
              onClick={onClick}
            />
          </Box>
          <FontAwesomeIcon
            onClick={() => {
              setOpen(false);
            }}
            icon={faTimes}
            style={{
              height: 14,
              width: 16,
              cursor: 'pointer',
              color: severity === 'info' ? 'white' : 'unset',
            }}
          />
        </Box>
      </Collapse>
    </Box>
  );
};
