import React, { useCallback, useEffect, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Modal, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../services/api/hooks';
import { isPasswordValid } from '../../../utils/handleValuesHelper';
import { KarteraFormField } from '../../../components/kartera/formField';
import { KarteraButton } from '../../../components/kartera/button';
import { KarteraProgress } from '../../../components/kartera/progress';
import { ChangePassModalProps } from './types';
import { useOutSideClick } from '../../../hooks/useOUtsideClick';

const useStyles = makeStyles((theme: any) => ({
  root: {},
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 380,
    borderRadius: 20,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '38px',
    zIndex: 10,
    transform: 'translate(-50%, -50%)',
  },
  resetPasswordHeader: {
    padding: '0 0 15px 0',
    fontSize: 16,
    fontWeight: 500,
    width: '100%',
    textAlign: 'start',
  },
  pageDescription: {
    padding: '0 0 20px 0',
    fontSize: 14,
  },
  criteriaContainer: {
    padding: '0 0 20px 20px',
    fontSize: 14,
    width: '100%',
  },
  resetPasswordForm: {
    width: '100%',
    padding: '0 0 15px 0',
  },
  loadingWrapper: {
    padding: '0 0 20px 0',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  closeButtonWrapper: {
    padding: '10px 0 0 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  buttonWrapper: {
    padding: '4px 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  successText: {
    textAlign: 'center',
    fontSize: '14px',
  },
  errorText: {
    color: '#FF2559',
    fontSize: 14,
    textAlign: 'center',
  },
  errorMessage: {
    color: '#FF2559',
    fontSize: 12,
    padding: '0 0 10px 0',
  },
  message: {
    backgroundColor: '#d7faf0',
    marginTop: '-16px',
    fontSize: '13px',
    color: '#2e886f',
    padding: 12,
    borderRadius: '6px',
  },
  cancelButton: {
    margin: '0 12px 0 0',
  },
}));

function getEmailAndTokenFromParams(params: string) {
  try {
    const [param1, param2] = params.split('&');
    const email = param1.split('email=')[1];
    const token = param2.split('token=')[1];
    return { email, token };
  } catch {
    return { email: '', token: '' };
  }
}

export const ChangePasswordModal: React.FC<ChangePassModalProps> = ({
  onModalOpen,
  partnerUserId,
}) => {
  const classes = useStyles();
  const { postChangePartnerPassword } = useApi();
  const { t } = useTranslation('translation', { keyPrefix: 'settings' });

  const modalRef = useRef(null);

  const [errorMessages, setErrorMessages] = useState({
    password: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [hasError, setHasError] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [requestFailed, setRequestFailed] = useState(false);
  const [requestError, setRequestError] = useState('');
  const [requestSuccess, setRequestSuccess] = useState(false);

  useEffect(() => {
    setHasError(!!Object.values(errorMessages).find((msg) => msg !== ''));
  }, [errorMessages]);

  const criteria = [t('character'), t('upperCase'), t('symbol'), t('number')];

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      try {
        event.preventDefault();
        setRequestFailed(false);
        setLoadingOpen(true);
        setRequestError('');

        const data = {
          current_password: password,
          new_password: newPassword,
          confirm_new_password: confirmPassword,
        };
        await postChangePartnerPassword(partnerUserId, data, (error) => {
          setRequestFailed(true);
          setRequestError(error.message);
          setLoadingOpen(false);
          return;
        }).then((response) => {
          if (response === '') {
            setRequestSuccess(true);
            setLoadingOpen(false);
          }
        });
      } catch (error: any) {
        setRequestFailed(true);
        setRequestError(error);
        setLoadingOpen(false);
      }
    },
    [password, newPassword, confirmPassword, partnerUserId],
  );

  const handleCancel = useCallback(() => {
    onModalOpen(false);
  }, [password, confirmPassword]);

  const passwordValidation = () => {
    const errorMessage = !isPasswordValid(password) ? t('errorMsgPassword') : '';
    setErrorMessages((current) => ({ ...current, password: errorMessage }));
  };

  const newPasswordValidation = () => {
    const errorMessage = !isPasswordValid(newPassword)
      ? t('errorMsgPassword')
      : newPassword === password
      ? t('errorMsgNewPassword')
      : '';
    setErrorMessages((current) => ({ ...current, newPassword: errorMessage }));
  };

  const confirmPasswordValidation = () => {
    const errorMessage = newPassword !== confirmPassword ? t('errorMsgConfirmPassword') : '';
    setErrorMessages((current) => ({ ...current, confirmPassword: errorMessage }));
  };

  useOutSideClick(modalRef, () => {
    handleCancel();
  });

  const buttonDisabled =
    hasError || !password || !newPassword || !confirmPassword || partnerUserId === '';

  return (
    <Modal open={true} id={'changePasswordModal'} >
      <form className={classes.container} onSubmit={handleSubmit} ref={modalRef}>
        <Typography className={classes.resetPasswordHeader}>{t('pageTitle')}</Typography>
        <Typography className={classes.pageDescription}>{t('description', { email })}</Typography>
        <ul className={classes.criteriaContainer}>
          {criteria.map((item, index) => {
            return <li key={index}>{item}</li>;
          })}
        </ul>
        <Box className={classes.resetPasswordForm}>
          <KarteraFormField
            variant='password'
            placeholder={t('password')}
            value={password}
            errorMessage={errorMessages.password}
            onChange={(e) => {
              setPassword(e.target.value);
              if (requestFailed) {
                setRequestFailed(false);
              }
            }}
            onBlur={() => passwordValidation()}
            sx={{
              mb: errorMessages.password === '' ? '6px' : '56px',
              display: 'flex',
            }}
            label={t('currentPassword')}
            required
            fullWidth
          />
          <KarteraFormField
            variant='password'
            placeholder={t('newPassword')}
            value={newPassword}
            errorMessage={errorMessages.newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
              if (requestFailed) {
                setRequestFailed(false);
              }
            }}
            onBlur={() => newPasswordValidation()}
            sx={{
              mb: errorMessages.newPassword === '' ? '6px' : '16px',
              display: 'flex',
            }}
            label={t('newPassword')}
            required
            fullWidth
          />
          <KarteraFormField
            variant='password'
            placeholder={t('confirmPassword')}
            value={confirmPassword}
            errorMessage={errorMessages.confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              if (requestFailed) {
                setRequestFailed(false);
              }
            }}
            onBlur={() => confirmPasswordValidation()}
            sx={{
              mb: errorMessages.confirmPassword === '' ? '6px' : '16px',
              display: 'flex',
            }}
            label={t('repeatPassword')}
            required
            fullWidth
          />
        </Box>
        {loadingOpen ? (
          <Box className={classes.loadingWrapper}>
            <Typography className={classes.errorMessage}>{requestError}</Typography>
            <KarteraProgress />
          </Box>
        ) : requestSuccess ? (
          <Box>
            <Typography className={classes.successText}>{t('successText')}</Typography>
            <Box className={classes.closeButtonWrapper}>
              <KarteraButton text={t('buttonClose')} onClick={handleCancel as any} />
            </Box>
          </Box>
        ) : requestFailed ? (
          <Box>
            <Typography className={classes.errorText}>{requestError}</Typography>
          </Box>
        ) : (
          <Box className={classes.buttonWrapper}>
            <KarteraButton
              text={t('buttonCancel')}
              className={classes.cancelButton}
              onClick={handleCancel as any}
              variant={'secondary'}
            />
            <KarteraButton
              text={t('buttonChange')}
              onClick={handleSubmit as any}
              disabled={buttonDisabled}
            />
          </Box>
        )}
      </form>
    </Modal>
  );
};
