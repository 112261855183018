import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RewardsPointSystemIntroOfferType, RewardsType } from '../dtos/rewards/rewardsDTO';

export type RewardsStepsType = 'SETUP' | 'SELECT_TYPE' | 'SUBMIT';

const initialState = {
  isLoadingRewards: false,
  rewardModalOpen: false, 
  shouldCallGetRewardApi: false,
  rewardsModalStep: "SETUP" as RewardsStepsType,
  cashback_breakage: false,
  point_system_intro_offer_minimun_transaction: false,
  id: "",
  created_at: "",
  updated_at: "",
  product_id: "",
  active: false,
  reward_type: "",
  cashback_validity_days: 0,
  discount_percentage: 1.75,
  point_system_earn_ratio: "",
  point_system_spend_ratio: "",
  point_system_intro_offer: false,
  point_system_intro_offer_type: "MULTIPLY_FOR_FIRST_PURCHASE" as RewardsPointSystemIntroOfferType,
  point_system_intro_offer_param: "100",
  point_system_intro_offer_tnx_limit: "0",
};

const rewardsSlice = createSlice({
  name: 'rewards',
  initialState,
  reducers: {
    setId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setCreatedAt: (state, action: PayloadAction<string>) => {
      state.created_at = action.payload;
    },
    setUpdatedAt: (state, action: PayloadAction<string>) => {
      state.updated_at = action.payload;
    },
    setProductId: (state, action: PayloadAction<string>) => {
      state.product_id = action.payload;
    },
    setActive: (state, action: PayloadAction<boolean>) => {
      state.active = action.payload;
    },
    setRewardType: (state, action: PayloadAction<RewardsType>) => {
      state.reward_type = action.payload;
    },
    setCashbackValidityDays: (state, action: PayloadAction<number>) => {
      state.cashback_validity_days = action.payload;
    },
    setDiscountPercentage: (state, action: PayloadAction<number>) => {
      state.discount_percentage = action.payload;
    },
    setPointSystemEarnRatio: (state, action: PayloadAction<string>) => {
      state.point_system_earn_ratio = action.payload;
    },
    setPointSystemSpendRatio: (state, action: PayloadAction<string>) => {
      state.point_system_spend_ratio = action.payload;
    },
    setPointSystemIntroOffer: (state, action: PayloadAction<boolean>) => {
      state.point_system_intro_offer = action.payload;
    },
    setPointSystemIntroOfferType: (state, action: PayloadAction<RewardsPointSystemIntroOfferType>) => {
      state.point_system_intro_offer_type = action.payload;
    },
    setPointSystemIntroOfferParam: (state, action: PayloadAction<string>) => {
      state.point_system_intro_offer_param = action.payload;
    },
    setPointSystemIntroOfferTnxLimit: (state, action: PayloadAction<string>) => {
      state.point_system_intro_offer_tnx_limit = action.payload;
    }, 
    setRewardModalOpen: (state, action: PayloadAction<boolean>) => {
      state.rewardModalOpen = action.payload;
    },
    setRewardsModalStep: (state, action: PayloadAction<RewardsStepsType>) => {
      state.rewardsModalStep = action.payload;
    },
    setCashbackBreakage: (state, action: PayloadAction<boolean>) => {
      state.cashback_breakage = action.payload;
    },
    setPointsSystemIntroOfferMinimunTransaction: (state, action: PayloadAction<boolean>) => {
      state.point_system_intro_offer_minimun_transaction = action.payload;
    },
    setShouldCallGetRewardApi: (state, action: PayloadAction<boolean>) => {
      state.shouldCallGetRewardApi = action.payload;
    },
    setIsLoadingRewards: (state, action: PayloadAction<boolean>) => {
      state.isLoadingRewards = action.payload;
    },
    setClearRewards: () => initialState,
  },
});

export const {
  setId,
  setCreatedAt,
  setUpdatedAt,
  setProductId,
  setActive,
  setRewardType,
  setCashbackValidityDays,
  setCashbackBreakage,
  setDiscountPercentage,
  setPointSystemEarnRatio,
  setPointSystemSpendRatio,
  setPointSystemIntroOffer,
  setPointSystemIntroOfferType,
  setPointSystemIntroOfferParam,
  setPointSystemIntroOfferTnxLimit,
  setPointsSystemIntroOfferMinimunTransaction,
  setRewardModalOpen,
  setRewardsModalStep,
  setClearRewards,
  setShouldCallGetRewardApi,
  setIsLoadingRewards,
} = rewardsSlice.actions;

export default rewardsSlice.reducer;