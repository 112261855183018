import { combineReducers } from 'redux';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import onboardingReducers from './onboardingReducers';
import partnerUserReducers from './partnerUserReducers';
import loaderReducers from './loaderReducers';
import apiErrorReducers from './apiErrorReducers';
import accountConnectReducers from './accountCoonectReducer';
import businessVerificationReducers from './businessVerificationReducers';
import demoAccountReducers from './demoAccountReducers';
import apiMessageReducers from './apiMessageReducers';
import rewardsReducers from './rewardsReducers';
import productUserReducers from './productUserReducers';

// This will be the structure of the entire Store in redux

const rootReducer = combineReducers({
  onboardingState: onboardingReducers,
  partnerUserState: partnerUserReducers,
  loaderState: loaderReducers,
  apiErrorState: apiErrorReducers,
  accountConnectState: accountConnectReducers,
  businessVerification: businessVerificationReducers,
  demoAccountState: demoAccountReducers,
  apiMessageState: apiMessageReducers,
  rewardsState: rewardsReducers,
  productUserState: productUserReducers,
});

export type AppRootState = ReturnType<typeof rootReducer>;

/** A typed variant of the useSelector redux hook, that knows the type of this application root state.
 *
 * This is only an effort to prevent repetitively specifying selector types
 */
export const useTypedSelector: TypedUseSelectorHook<AppRootState> = useSelector;

export default rootReducer;
