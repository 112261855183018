import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { Box, Modal, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useApi } from '../../../services/api/hooks';
import { color } from '../../../consts/ColorConst';
import { isEmailDomainAllowed, isEmailValid } from '../../../utils/handleValuesHelper';
import { InviteModalProps } from './types';
import { KarteraButton } from '../../../components/kartera/button';
import { KarteraSelectField } from '../../../components/kartera/selectField';
import { KarteraTextAreaChip } from '../../../components/kartera/textAreaChip';
import { useAuth } from '../../../context/Auth';

const useStyles = makeStyles(() => ({
  inviteWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '360px',
    display: 'flex',
    flexDirection: 'column',
    padding: '21px 24px',
    transform: 'translate(-50%, -50%)',
    backgroundColor: color.WHITE_01,
  },
  inviteBoxTitle: {
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: 17,
  },
  inviteBoxBottom: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '22px',
    gap: 12,
  },
}));

export const InviteMemberModal: FC<InviteModalProps> = ({
  setIsModalOpen,
  setRefreshDate,
  setSnackbarText,
  setSnackbarVisible,
  isStaffAdmin,
}) => {
  const classes = useStyles();
  const [cookies] = useCookies(['lynk.partner-name']);

  const { postPartnersInvite, postStaffUserInvite, getPartnerUsersList, getStaffUsers } = useApi();
  const { t } = useTranslation('translation', { keyPrefix: 'settings' });
  const { partner } = useAuth();

  const [invites, setInvites] = useState<string[]>([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [hasInvalidItems, setHasInvalidItems] = useState(false);
  const [role, setRole] = useState('READ_ONLY');
  const [users, setUsers] = useState([]);

  async function getPartnerUserData() {
    if (isStaffAdmin) {
      return await getStaffUsers();
    }
    if (partner) {
      return await getPartnerUsersList();
    }
  }

  useEffect(() => {
    getPartnerUserData().then((response: any) => {
      setUsers(response.data);
    });
  }, []);

  const ROLES = [
    { value: 'READ_ONLY', text: t('roleReadOnly') },
    { value: 'ADMIN', text: t('roleAdmin') },
  ];

  async function sendPartnersInvitation() {
    const parsedInvites = invites.map((email) => email.trim());
    if (isStaffAdmin) {
      await postStaffUserInvite({
        emails: parsedInvites,
        ui_host_url: `${window.location.protocol}//${window.location.host}`,
      });
    } else {
      await postPartnersInvite({
        role,
        emails: parsedInvites,
        ui_host_url: `${window.location.protocol}//${window.location.host}`,
      });
    }

    const snackbarText =
      parsedInvites.length > 1
        ? t('invitesSentTo', { counter: parsedInvites.length })
        : t('inviteSentTo');
    setSnackbarText(snackbarText);
    setSnackbarVisible(true);
    setRefreshDate(new Date());
    setIsModalOpen(false);

    setTimeout(() => setSnackbarVisible(false), 5000);
  }

  function handleCloseModal() {
    setInvites([]);
    setIsModalOpen(false);
  }

  function handleInvites(e: string[]) {
    setInvites([...e]);
    setIsEmpty(false);
  }

  function handleEmailValidation(email: string) {
    const obj = users.find((o: any) => o.work_email === email);
    return isEmailValid(email) && isEmailDomainAllowed(email) && obj === undefined;
  }

  function handleBlur() {
    setIsEmpty(invites.length === 0);
  }

  return (
    <Modal open={true}>
      <Paper className={classes.inviteWrapper} id={'inviteModal'}>
        <h2 className={classes.inviteBoxTitle}>
          {isStaffAdmin ? t('inviteToStaff') : `${t('inviteTo')} "${cookies['lynk.partner-name']}"`}
        </h2>
        <KarteraTextAreaChip
          placeholder={t('textAreaPlaceholder')}
          value={invites}
          setValue={handleInvites}
          height='100px'
          validation={handleEmailValidation}
          setHasInvalidItems={setHasInvalidItems}
          onBlur={handleBlur}
          errorMessage={
            isEmpty ? t('textAreaError') : hasInvalidItems ? t('invalidEmailsError') : ''
          }
        />
        {!isStaffAdmin && (
          <KarteraSelectField
            items={ROLES}
            value={role}
            onChange={(e: any) => setRole(e.target.value)}
            label='Role:'
            width='146px'
            sx={{ margin: '8px 0 4px' }}
          />
        )}
        <Box className={classes.inviteBoxBottom}>
          <KarteraButton text={t('buttonCancel')} onClick={handleCloseModal} variant='secondary' />
          <KarteraButton
            text={`${t('modalSendButton')}${invites.length > 1 ? 's' : ''}`}
            onClick={sendPartnersInvitation}
            disabled={invites.length === 0 || hasInvalidItems}
          />
        </Box>
      </Paper>
    </Modal>
  );
};
