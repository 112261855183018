import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import { TbArrowsDownUp } from "react-icons/tb";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataGrid, GridSelectionModel, GridSortModel } from "@mui/x-data-grid";
import { LynkTableFooter } from "./tableFooter";
import { LynkTableToolbar } from './tableToolbar';
import { color } from '../../consts/ColorConst';
import { LynkChip } from '../common';
import { FilterMenu } from '../filterMenu';
import { FilterRowType } from '../filterMenu/filterRow/types';
import { truncateText } from '../../utils/helperFunctions';
import mccCodes from '../../consts/mccCodes.json';
import { MerchantType, PageStateType, QuickFilterItem, TableProps } from './types';
import { KarteraSelectField } from '../kartera/selectField';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  chipContainer: {
    display: 'flex',
    width: '100%',
    marginBottom: '10px',
    padding: '0 12px',
    gap: 6,
  },
  root: {
    position: 'relative',
    '& .MuiDataGrid-row': {
      maxHeight: '34px !important',
      minHeight: '34px !important',
      borderBottom: '1px solid transparent !important',
    },
    '& .Mui-selected': {
      backgroundColor: `${color.GREEN_LIGHT_4} !important`,
      borderBottom: `1px solid ${color.GREEN_LIGHT_4} !important`,
    },
    '& .MuiDataGrid-cell': {
      borderBottom: '1px solid transparent !important',
      color: color.GRAY_DARK_03,
      fontSize: 14,
      fontWeight: 400,
    },
    '& .MuiDataGrid-columnHeaders': {
      borderRadius: 0,
      backgroundColor: color.GRAY_09,
      border: 0,
      borderTop: `1px solid ${color.SECONDARY_DARK_01}`,
      borderBottom: `1px solid ${color.SECONDARY_DARK_01}`,
      height: '34px',
      minHeight: '34px !important',
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: color.GRAY_DARK_03,
      fontSize: 14,
      fontWeight: 500,
    },
  },
  checkbox: {
    "&:hover":{
      backgroundColor:color.GREEN_LIGHT_4
    },
    '&.MuiCheckbox-root.Mui-checked': {
      color: color.GREEN_DARK_1,
    },
  },
  noRows: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    marginTop: '40px',
    fontSize: '18px',
    color: '#555',
  },
  dateWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
}));

const CHIP_MAX_TEXT_LENGTH = 40;

export const LynkTableGrid: FC<TableProps> = ({
  title,
  columns,
  checkbox,
  selectedItems,
  setSelectedItems,
  refreshDate,
  fetchData,
  defaultPageSize = 10,
  minHeight = '300px',
  paginationAndSortingMode = 'server',
  searchPlaceholder,
  rowsPerPageOptions = [5, 10, 25, 100],
  onRowClick,
  csvFileName,
  quickFilters,
  supressToolbar,
  supressFooter,
  customStyle,
  getRowClassName,
  idModification = false,
  filterFields,
  filteringEnabled = true,
  component,
  autoHeight,
  getRowId,
  refresh,
}) => {
  const classes = useStyles();
  const chipContainerRef = useRef<HTMLDivElement>(null);

  const [currentField, setCurrentField] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredRows, setFilteredRows] = useState<any[]>([]);
  const [pageState, setPageState] = useState<PageStateType>({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: defaultPageSize,
    sort: 'createdAt,DESC',
  });
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [fields, setFields] = useState<QuickFilterItem[]>([]);

  const [filters, setFilters] = useState<FilterRowType[]>([]);
  const [visibleChips, setVisibleChips] = useState<ReactNode[]>([]);
  const [hiddenChipsCounter, setHiddenChipsCounter] = useState(0);

  function handleFilterQuery() {
    let query = '';
    filters.forEach((filter) => {
      const { fieldName, fieldValue, operatorValue, fromDate, toDate } = filter;
      if (fieldName !== '' && operatorValue !== '') {
        if (operatorValue === 'equals') {
          query = query + `&${fieldName}=${fieldValue}`;
        } else if (operatorValue === 'between') {
          query =
            query +
            `&date_from=${fromDate?.format('YYYY-MM-DD')}` +
            `&date_to=${toDate?.format('YYYY-MM-DD')}`;
        }
      }
    });
    return query;
  }

  async function getAsyncData() {
    if (!fetchData) return;
    try {
      setPageState((old) => ({ ...old, isLoading: true }));
      const query = handleFilterQuery();
      const response = await fetchData(pageState.page, pageState.pageSize, pageState.sort, query);
      if (!response.data) return;
      const data = response.data;
      const total = response.metadata?.total_elements || 0;
      setPageState((old) => ({ ...old, data, total, isLoading: false }));
    } catch (error) {
      setPageState((old) => ({ ...old, isLoading: false }));
    }
  }

  async function handleApplyFilters(currentFilters: FilterRowType[]) {
    handleChips(currentFilters);
    setFilterMenuOpen(false);
    await getAsyncData();
  }

  async function handleRemoveFilter(indexToRemove: number) {
    const newFilters = filters.filter((_, index) => index !== indexToRemove);
    setFilters(newFilters);
    handleChips(newFilters);
    await getAsyncData();
  }

  function handleChips(currentFilters: FilterRowType[]) {
    const validFilters = currentFilters.filter((item) => item.fieldName !== '');
    const chips = validFilters.map((item) => {
      if (
        ['date', 'time', 'created_at', 'updated_at', 'execution_timestamp'].includes(
          item.fieldName.toLocaleLowerCase(),
        )
      ) {
        return (
          <>
            {item.fromDate?.format('YYYY-MM-DD') === item.toDate?.format('YYYY-MM-DD') ? (
              <>
                {`${item.fieldLabel}: `}
                <strong>{item.fromDate?.format('YYYY-MM-DD')}</strong>
              </>
            ) : (
              <div className={classes.dateWrapper}>
                {`${item.fieldLabel}: `}
                <strong>{item.fromDate?.format('YYYY-MM-DD')}</strong>
                <span>to</span>
                <strong>{item.toDate?.format('YYYY-MM-DD')}</strong>
              </div>
            )}
          </>
        );
      }
      const truncatedText = truncateText(
        `
        ${item.fieldLabel}: ${item.fieldValue}
      `,
        CHIP_MAX_TEXT_LENGTH,
      ).split(': ');
      return (
        <>
          {`${truncatedText[0]}: `}
          <strong>{truncatedText[1]}</strong>
        </>
      );
    });

    if (chipContainerRef?.current) {
      const outerWidth = chipContainerRef.current.clientWidth;
      const allowedVisibleChips = Math.floor(outerWidth / 200);
      setVisibleChips(chips.slice(0, allowedVisibleChips));
      setHiddenChipsCounter(chips.length - allowedVisibleChips);
    }
  }

  useEffect(() => {
    getAsyncData();
  }, [pageState.page, pageState.pageSize, pageState.sort, refreshDate, filters, refresh]);

  useEffect(() => {
    if (!currentField || !searchValue) {
      setFilteredRows(pageState.data);
      return;
    }

    setFilteredRows(
      pageState.data?.filter((row) => {
        if (typeof row[currentField] !== 'string') return true;
        return row[currentField].toLowerCase().includes(searchValue.toLowerCase());
      }),
    );
  }, [pageState, searchValue]);

  function handleRowSelection(selectionModel: GridSelectionModel) {
    setSelectedItems(selectionModel);
  }

  function handleSort(model: GridSortModel) {
    if (!model || model.length === 0) return;
    setPageState((old) => ({
      ...old,
      sort: `${model[0].field},${model[0].sort?.toUpperCase()}`,
    }));
  }

  function handleCSVFileAndExport() {
    if (!filteredRows?.length) return;

    let fileContent = '\ufeff';
    const visibleColumns = columns.filter((col) => !col.hide);
    fileContent += visibleColumns.map((col) => col.headerName).join(';') + '\r\n';

    filteredRows.forEach((row) => {
      visibleColumns.forEach((col) => {
        const fieldName = col.field;
        if (fieldName.includes('merchant')) {
          const merchant: MerchantType = row['merchant'];
          switch (fieldName) {
            case 'merchant_mid':
              fileContent += merchant.mid;
              break;
            case 'merchant_name':
              fileContent += merchant.name;
              break;
            case 'merchant_mcc':
              //eslint-disable-next-line
              //@ts-ignore-next-line
              fileContent += mccCodes[merchant.mcc]?.edited_description || '';
              break;
            case 'merchant_city':
              fileContent += merchant.address.city;
              break;
            case 'merchant_state':
              fileContent += merchant.address.state;
              break;
            case 'merchant_postal_code':
              fileContent += merchant.address.postal_code;
              break;
            case 'merchant_country':
              fileContent += merchant.address.country;
              break;
          }
        } else {
          fileContent += row[fieldName] || '';
        }
        fileContent += ';';
      });
      fileContent += '\r\n';
    });

    const file = new Blob([fileContent], { type: 'text/csv;charset=UTF-8' });
    const fileName = `${csvFileName || 'link'}_${new Date().getTime()}.csv`;
    const fileLink = document.createElement('a');
    fileLink.href = URL.createObjectURL(file);
    fileLink.download = fileName;
    document.body.appendChild(fileLink);
    fileLink.click();
    document.body.removeChild(fileLink);
  }

  useEffect(() => {
    if (filterFields) {
      setFields(filterFields);
      return;
    }
    const fields: any = [];
    columns.map((item) => {
      if (item.headerName) {
        fields.push({ text: item.headerName, value: item.field });
      }
    });
    setFields(fields);
  }, [columns]);

  return (
    <Box className={classes.container} sx={{ height: minHeight }}>
      <FilterMenu
        filterMenuOpen={filterMenuOpen}
        setFilterMenuOpen={setFilterMenuOpen}
        fields={fields}
        filters={filters}
        setFilters={setFilters}
        onApplyFilters={handleApplyFilters}
      />
      {!supressToolbar && (
        <LynkTableToolbar
          totalSelectedElements={selectedItems.length}
          searchPlaceholder={searchPlaceholder}
          totalElements={filteredRows?.length || 0}
          currentField={currentField}
          counterTitle={title}
          handleExportData={csvFileName ? handleCSVFileAndExport : undefined}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          quickFilter={
            quickFilters &&
            quickFilters.map((filter, index) => (
              <KarteraSelectField
                key={`quickfilter-${index}`}
                defaultValue={filter.defaultValue}
                width={filter.width || '120px'}
                items={filter.items}
                placeholder={filter.defaultValue}
              />
            ))
          }
          handleFilterData={() => setFilterMenuOpen(true)}
          filterCounter={filters.length}
          filteringEnabled={filteringEnabled}
          component={component}
        />
      )}
      {filters && (
        <Box ref={chipContainerRef} className={classes.chipContainer}>
          {visibleChips.map((item, index) => (
            <LynkChip
              key={index}
              component={item}
              onClick={() => setFilterMenuOpen(true)}
              onDelete={() => handleRemoveFilter(index)}
              type='filter'
            />
          ))}
          {hiddenChipsCounter > 0 && (
            <LynkChip text={`+ ${hiddenChipsCounter} more filters`} type='field' />
          )}
        </Box>
      )}
      <DataGrid
        disableColumnSelector
        classes={{ root: classes.root, checkboxInput: classes.checkbox }}
        sx={{ border: 'none', ...customStyle }}
        density='compact'
        autoHeight={autoHeight}
        getRowClassName={getRowClassName}
        sortingMode={paginationAndSortingMode}
        paginationMode={paginationAndSortingMode}
        columns={columns}
        onRowClick={onRowClick && ((event) => onRowClick(event))}
        getRowId={getRowId}
        rows={filteredRows.map((item, index) => {
          if (idModification) {
            return { ...item, id: index };
          } else {
            return item;
          }
        })}
        rowCount={pageState.total}
        loading={pageState.isLoading}
        page={pageState.page}
        pageSize={pageState.pageSize}
        onPageChange={(page) => setPageState((old) => ({ ...old, page }))}
        onPageSizeChange={(pageSize) => setPageState((old) => ({ ...old, pageSize }))}
        disableColumnMenu
        showColumnRightBorder={false}
        onColumnHeaderClick={(e: any) => setCurrentField(e.field)}
        onSelectionModelChange={handleRowSelection}
        components={{
          ColumnUnsortedIcon: () => <TbArrowsDownUp color={color.SECONDARY_DARK_02} />,
          Footer: () => {
            if (supressFooter) return null;
            return (
              <LynkTableFooter
                setPageSize={(pageSize: number) => setPageState((old) => ({ ...old, pageSize }))}
                pageSizeOptions={rowsPerPageOptions}
              />
            );
          },
        }}
        onSortModelChange={handleSort}
        checkboxSelection={checkbox}
        disableSelectionOnClick
      />
    </Box>
  );
};
