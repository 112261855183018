import { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { color } from "../../consts/ColorConst";
import pluginsImg from "../../assets/welcomeDialogImage.png";
import { KarteraButton } from "../../components/kartera/button";
import { ManagePlugin } from "../../components/platforms/managePlugin";
import { KarteraPlatformCard } from "../../components/kartera/platformCard";

const useStyles = makeStyles((theme: any) => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    backgroundColor: color.GRAY_01,
    padding: '35px 40px',
  },
  contentContainer: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  platformContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 32,
    marginBottom: 32,
  },
  title: {
    fontSize: 26,
    fontWeight: 700,
    padding: '0 0 31px',
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  message: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.2
  },
  dialogContainer: {
    width: 760,
    padding: 16,
    background: color.WHITE_01,
    borderRadius: 6,
    boxShadow: theme.shadow.elevation4Grey,
    marginBottom: 40,
  },
  messageContainer: {
    display: 'flex', 
    padding: '8px 0 0'
  },
  innerMessageContainer: {
    display: 'flex', 
    flexDirection: 'column',
    padding: '8px 0 0 16px',
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.2,
    gap: 10,
  },
  image: {
    height: 160,
    margin: '20px',
  },
  button: {
    width: 'fit-content',
    padding: 0,
  }
}));

export function Platforms() {
  const classes = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'platforms' });

  const [isShopifyConnected, setIsShopifyConnected] = useState(false);
  const [isWooConnected, setIsWooConnected] = useState(false);

  document.title = 'Kartera - Platforms';

  return (
    <Box className={classes.root}>
      <Box className={classes.contentContainer}>
        <Box className={classes.titleContainer}>
          <Typography className={classes.title}>
            {t('platforms')}
          </Typography>
        </Box>
          { (!isShopifyConnected && !isWooConnected) && (
            <div className={classes.dialogContainer}>
              <Typography className={classes.subtitle}>
                {t('unconnectedPanelTitle')}
              </Typography>
              <div className={classes.messageContainer}>
                <img 
                  className={classes.image} 
                  src={pluginsImg} 
                  alt="Plugins image"
                />
                <div className={classes.innerMessageContainer}>
                  <p>{t('unconnectedPanelP1')}</p>
                  <p>{t('unconnectedPanelP2')}</p>
                  <p>{t('unconnectedPanelP3')}</p>
                  <p>{t('unconnectedPanelP4')}</p>
                  <KarteraButton
                    className={classes.button}
                    text={t('learnMoreButton')}
                    variant="tertiary"
                  />
                </div>
              </div>
            </div>
          )}
          <Box className={classes.platformContainer}>
            <KarteraPlatformCard 
              status={ isShopifyConnected ? 'ACTIVE' : 'DEFAULT' }
              platformName="Shopify"
              account="Les Business Commerce"
              url="https://lesbusines.myshopify.com"
              date="2024-07-23 11:54 AM"
              onConnect={() => setIsShopifyConnected(true)}
            />
            <KarteraPlatformCard 
              status={isWooConnected ? 'ACTIVE' : 'DEFAULT'}
              platformName="WooCommerce"
              account="LesBusiness Woo Shop"
              url="https://lesbus.woocommerce.com"
              date="2024-07-23 11:54 AM"
              onConnect={() => setIsWooConnected(true)}
            />
          </Box>
          { (isShopifyConnected || isWooConnected) && (
            <ManagePlugin 
              onClose={() => {
                setIsShopifyConnected(false);  
                setIsWooConnected(false);
              }}  
            /> 
          )}
      </Box>
    </Box>
  );
}
