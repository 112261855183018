import { FC, useEffect, useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MenuItem, MenuItemProps, SubMenuItem } from './types';
import { color } from '../../../../consts/ColorConst';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles<Theme>(() => ({
  primaryRoot: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '46px',
    cursor: 'pointer',
    '& .MuiButtonBase-root': {
      justifyContent: 'center',
      color: color.GREY_DARK_1,
      borderLeft: '4px solid transparent',
      padding: '6px 3px',
    },
    '& path': {
      fill: color.GREY_DARK_1,
      transition: 'all 0.3s',
    },
    '&:hover': {
      backgroundColor: color.GREEN_LIGHT_5,
      '& .MuiButtonBase-root': {
        color: color.GREY_DARK_3,
        fontWeight: 700,
      },
      '& path': {
        fill: color.GREY_DARK_3,
      },
    },
  },
  secondaryRoot: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '32px',
    borderRadius: 6,
    marginBottom: 6,
    cursor: 'pointer',
    transition: 'all 0.5s',
    zIndex: 2,
    '& .MuiTypography-root': {
      fontSize: '14px',
      fontWeight: 500,
      color: color.GREY_DARK_1,
    },
    '& .MuiButtonBase-root': {
      height: '32px',
      justifyContent: 'center',
      color: color.GREY_DARK_1,
      borderRadius: 6,
      padding: '6px 3px',
    },
    '& path': {
      fill: color.GREY_DARK_1,
    },
    '&:hover': {
      backgroundColor: color.GREEN_LIGHT_5,
      '& .MuiButtonBase-root': {
        color: color.GREY_DARK_3,
        fontWeight: 600,
      },
      '& path': {
        fill: color.GREY_DARK_3,
      },
    },
  },
  menuItemDisabled: {
    '& .MuiTypography-root': {
      color: color.GREY_DARK_1,
    },
    '&:hover': {
      backgroundColor: color.WHITE_01,
      '& .MuiButtonBase-root': {
        cursor: 'default',
      },
    },
  },
  primarySelected: {
    '& .MuiButtonBase-root': {
      borderColor: color.GREEN_LIGHT_3,
    },
    '& .MuiTypography-root': {
      color: color.GREEN_DARK_3,
      fontWeight: 700,
    },
    '& path': {
      fill: color.GREEN_DARK_3,
    },
    '&:hover': {
      backgroundColor: color.GREEN_LIGHT_2,
    },
  },
  secondarySelected: {
    '& .MuiButtonBase-root': {
      backgroundColor: color.GREEN_LIGHT_3,
    },
    '& .MuiTypography-root': {
      color: color.GREEN_DARK_3,
      fontWeight: 500,
    },
    '& path': {
      fill: color.GREEN_DARK_3,
    },
    '&:hover': {
      backgroundColor: color.GREEN_LIGHT_2,
    },
  },
  subMenuContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    background: color.GREEN_LIGHT_5,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    zIndex: 1,
    marginTop: -12,
    marginBottom: 6,
    '& .MuiButtonBase-root': {
      color: color.GREY,
      fontSize: '14px',
      fontWeight: 400,
      textDecoration: 'none',
      transition: 'all 0.3s',
    },
    '& path': {
      fill: color.GREY_DARK_1,
      transition: 'all 0.3s',
    },
    '&:hover': {
      color: color.GREEN_DARK_2,
      '& .MuiButtonBase-root': {
        color: color.GREEN_DARK_2,
        fontWeight: 600,
      },
    },
  },
  subItemContainer: {
    listStyle: 'none',
    padding: '4px 16px 0',
  },
  subItemContainerClosed: {
    listStyle: 'none',
    padding: '4px 6px 0',
    diplay: 'flex',
    justifyContent: 'center',
  },
  subMenuItem: {
    height: '32px',
    display: 'flex',
    fontSize: '14px',
    fontWeight: 500,
    overflow: 'hidden',
    color: color.GREY_DARK_1,
    border: 0,
    borderBottom: `3px solid transparent`,
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'space-between',
    '&:hover': {
      '& .MuiButtonBase-root': {
        color: color.GREY_DARK_3,
      },
      '& path': {
        fill: color.GREY_DARK_3,
      },
    },
  },
  subMenuSelected: {
    '& .MuiButtonBase-root': {
      color: color.GREEN_DARK_2,
      fontWeight: 900,
      borderColor: color.GREY_DARK_3,
    },
    '& path': {
      fill: color.GREEN_DARK_2,
    },
    '& a': {
      color: color.GREEN_DARK_2,
      fontWeight: 700,
    },
  },
  arrowContainer: {
    marginRight: 4,
    minWidth: 0,
    transition: 'all 0.3s',
  },
  menuArrowUp: {
    transform: 'rotate(180deg)',
  },
  menuArrowDown: {
    transform: 'rotate(0)',
  },
  loaderWrapper: {
    width: '100%',
    paddingLeft: '26px',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  iconWrapper: {
    minWidth: 0,
    justifyContent: 'center',
    padding: '0 6px',
  }
}));

export const KarteraMenuItem: FC<MenuItemProps> = ({
  item,
  variant,
  activeItem,
  setActiveItem,
  verticalStyle,
  sidebarOpen,
  disabled,
}) => {
  const classes = useStyles();
  const [subMenuOpen, setsubMenuOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [showLabel, setShowLabel] = useState(sidebarOpen)

  const showArrowIcon = item.subItems && !verticalStyle;

  useEffect(() => {
    activeItem.text === item.text ? setsubMenuOpen(true) : setsubMenuOpen(false);
    setIsActive(activeItem.text === item.text);
  }, [activeItem]);

  useEffect(() => {
    if (sidebarOpen) {
      setTimeout( () => setShowLabel(sidebarOpen), 100);
    } else  setShowLabel(sidebarOpen);
  }, [sidebarOpen]);

  function handleItemClick(item: MenuItem) {
    if (disabled === false) {
      if (item.subItems) {
        setsubMenuOpen((prev) => !prev);
      }
      setActiveItem({ text: item.text });
      item.onClick && item.onClick();
    } 
  }

  function handleSubItemClick(item: MenuItem, subItem: SubMenuItem) {
    if (disabled === false) {
      setActiveItem({ text: item.text, subText: subItem.text });
      subItem.onClick && subItem.onClick();
    }
  }

  return (
    <Box>
      <ListItem
        disablePadding
        className={`
            ${classes[variant + 'Root']} ${disabled && classes.menuItemDisabled} 
            ${isActive && !disabled && classes[variant + 'Selected']}
          `}
        onClick={() => handleItemClick(item)}
        sx={item.sx && item.sx}
        disabled={disabled}
      >
        <ListItemButton>
          <ListItemIcon className={classes.iconWrapper}>
            {item.icon}
          </ListItemIcon>
          {item.loader ? (
            <Box className={classes.loaderWrapper}>
              <CircularProgress size={12} color='inherit' />
            </Box>
          ) : (
            <ListItemText primary={item.text} sx={{ opacity: showLabel ? 1 : 0 }} />
          )}

          {showArrowIcon && sidebarOpen && (
            <ListItemIcon
              className={`
                  ${classes.arrowContainer} 
                  ${subMenuOpen ? classes.menuArrowUp : classes.menuArrowDown}`}
            >
              <FontAwesomeIcon icon={faChevronDown} fontSize={10} />
            </ListItemIcon>
          )}
        </ListItemButton>
      </ListItem>
      {subMenuOpen && item.subItems && (
        <List className={classes.subMenuContainer}>
          {item.subItems.map((subItem) => {
            const isSubItemActive = subItem.text === activeItem.subText;
            return (
              <ListItem
                key={subItem.text}
                disablePadding
                className={`
                  ${sidebarOpen ? classes.subItemContainer : classes.subItemContainerClosed}
                `}
                onClick={() => handleSubItemClick(item, subItem)}
              >
                <div
                  className={`
                    ${classes.subMenuItem} 
                    ${isSubItemActive && classes.subMenuSelected}
                  `}
                >
                  <a href={subItem.url} >
                    {showLabel ? subItem.text : subItem.text.substring(0, 3)}
                  </a>
                </div>
              </ListItem>
            );
          })}
        </List>
      )}
    </Box>
  );
};
