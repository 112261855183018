import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { ActionConfirmationProps } from "./types";
import { KarteraTextField } from '../kartera/textField';
import { KarteraDialogue } from "../kartera/dialogue";

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0, 0.4)',
    zIndex: 999,
    position: 'absolute',
    top: '0px',
    left: 0,
    display: 'flex',
    whiteSpace: 'pre-line',
  },
  title: {
    fontSize: '20px',
    fontWeight: 700,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
  },
}));

export function ActionConfirmationModal({
  modalOpen,
  setModalOpen,
  title,
  text,
  fieldLabel,
  confirmationText,
  cancelButtonText,
  cancelButtonAction,
  confirmationButtonText,
  confirmationButtonAction,
  id,
}: ActionConfirmationProps) {
  const { t } = useTranslation("translation", { keyPrefix: "actionConfirmationModal" });
  const classes = useStyles();

  const [ confirmText, setConfirmText ] = useState("");
  const [ enableConfirmButton, setEnableConfirmButton ] = useState(false);

  useEffect(() => {
    setConfirmText("");
  }, [modalOpen]);

  useEffect(() => {
    if (confirmText === confirmationText) {
      setEnableConfirmButton(true);
    } else {
      setEnableConfirmButton(false);
    }
  }, [confirmText]);

  function handleConfirmationButtonClick() {
    if (confirmText === confirmationText) {
      confirmationButtonAction();
      setModalOpen(false);
    }
  }

  function handleCancelButtonClick() {
    cancelButtonAction && cancelButtonAction();
    setModalOpen(false);
  }


  if (!modalOpen) return null;

  return (
    <KarteraDialogue
      id={id}
      datatestid={id}
      title={title}
      activeButtonType='negative'
      size='small'
      cancelButtonText={t('cancelButton')}
      onCancelClick={handleCancelButtonClick}
      actionButtonText={confirmationButtonText} 
      onActionClick={handleConfirmationButtonClick}
      activeButtonDisabled={!enableConfirmButton}
      autoHeight
      showCloseButton={false} 
    >
      <div className={classes.modalContent}>
        {text}
        <KarteraTextField
          value={confirmText}
          placeholder={t('typeDelete')}
          onChange={(e) => setConfirmText(e.target.value)}
          type='text'
          label={fieldLabel}
          width={'100%'}
        />
      </div>
    </KarteraDialogue>
  );
}
