import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Theme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { KarteraDialogue } from '../../kartera/dialogue';
import SetupRewardImage from '../../../assets/setupRewardImage.png';
import { setRewardsModalStep } from '../../../reducers/rewardsReducers';

const useStyles = makeStyles<Theme>(() => ({
  message: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.2,
  },
  dialogContainer: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.2,
  },
  imageContainer: {
    padding: '20px 0',
    display: 'flex',
    justifyContent: 'center',
    gap: 28,
    height: 240,
  },
  paragraphContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
}));

type Props = {
  onDiscard: () => void;
};

export function SetupRewardsDialog({ onDiscard }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('translation', { keyPrefix: 'rewards' });
  const [loaded, setLoaded] = useState(false);

  function handleAction() {
    dispatch(setRewardsModalStep('SELECT_TYPE'));
  }

  return (
    <KarteraDialogue
      id={'rewards-dialog'}
      title={t('setupDialogTitle')}
      size='xl'
      width='611px'
      autoHeight
      othersButtonText={t('learnMore')}
      onOthersClick={() => null}
      onCancelClick={onDiscard}
      cancelButtonText={t('later')}
      actionButtonText={t('setupNow')}
      activeButtonType='primary'
      onActionClick={handleAction}
      sx={{
        '&.MuiPaper-root': { display: loaded ? 'block' : 'none' },
      }}
    >
      <div className={classes.dialogContainer}>
        <div className={classes.imageContainer}>
          <img src={SetupRewardImage} alt='Computer image' onLoad={() => setLoaded(true)} />
        </div>

        <div className={classes.paragraphContainer}>
          <p>{t('setupDialogP1')}</p>
          <p>{t('setupDialogP2')}</p>
          <p>{t('setupDialogP3')}</p>
        </div>
      </div>
    </KarteraDialogue>
  );
}
