import { FC } from "react";
import { makeStyles } from "@mui/styles";
import { color } from "../../../consts/ColorConst";
import { ButtonProps } from "./types";
import { Theme } from "@mui/material";

type StyleProps = {
  mode: 'LIGHT' | 'DARK';
};

const useStyles = makeStyles<Theme, StyleProps>((theme: any) => {
  const primary = {
    backgroundColor: color.GREEN_LIGHT_1,
    color: color.WHITE_01,
    "& path": { fill: color.WHITE_01 },
    "&:hover": { 
      backgroundColor: color.GREEN_DARK_1, 
      boxShadow: theme.shadow.elevation4Green,  
    },
    "&:active": { 
      backgroundColor: color.GREEN_DARK_2,
      boxShadow: theme.shadow.elevation4Green,  
    },
    "&:disabled": { 
      backgroundColor: color.GREEN_LIGHT_4,
      color: color.WHITE_01, 
    },
  };

  const secondary = {
    backgroundColor: color.GREY_LIGHT_4,
    "&:hover": { 
      backgroundColor: color.GREY_LIGHT_3,
      boxShadow: theme.shadow.elevation4Grey,  
    },
    "&:active": { 
      backgroundColor: color.GREY_LIGHT_2,
      boxShadow: theme.shadow.elevation4Grey, 
    },
    "&:disabled": { backgroundColor: color.GREY_LIGHT_5 },
  };

  return {
    button: {
      height: 28,
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 0.2,
      fontFamily: "Roboto",
      borderRadius: 6,
      width: 'max-content',
      textTransform: "capitalize",
      padding: "6px 10px",
      border: "none",
      cursor: "pointer",
      transition: "all ease .3s",
      color: color.GREY_DARK_4,
      "& path": { fill: color.GREY_DARK_4 },
      "&:disabled": {
        cursor: "not-allowed",
        color: color.GRAY_LIGHT_01,
        "& path": { fill: color.GREY_LIGHT_1 },
      },
      [theme.breakpoints.down('md')]: {
        height: 34,
      },
    },
    content: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: 6,
    },
    primary,
    kartera: {
      ...primary,
      color: ({ mode }) => mode === 'LIGHT' ? color.WHITE_01 : color.GRAY_DARK_03,
    },
    fire: {
      backgroundColor: ({ mode }) => mode === 'LIGHT' ? color.RED_LIGHT_2 : color.RED_LIGHT_3,
      "&:hover": { 
        backgroundColor: ({ mode }) => mode === 'LIGHT' ? color.RED_LIGHT_1 : color.RED_LIGHT_2,
        boxShadow: theme.shadow.elevation4Red, 
      },
      "&:active": { 
        backgroundColor: ({ mode }) => mode === 'LIGHT' ? color.RED : color.RED_LIGHT_1,
        boxShadow: theme.shadow.elevation4Red,
      },
      "&:disabled": { backgroundColor: color.RED_LIGHT_5 },
    },
    minimalist: {
      backgroundColor: "transparent",
      border: `1px solid ${color.GRAY_DARK_05}`,
      borderColor: ({ mode }) => mode === 'LIGHT' ? color.GRAY_DARK_05 : color.GREY_LIGHT_5,
      color: ({ mode }) => mode === 'LIGHT' ? color.GRAY_DARK_04 : color.WHITE_01,
      "&:hover": {
        backgroundColor: ({ mode }) => mode === 'LIGHT' ? color.GRAY_LIGHT_04 : color.GRAY_DARK_07,
      },
      "&:active": {
        backgroundColor: ({ mode }) => mode === 'LIGHT' ? color.GREY_LIGHT_3 : color.GRAY_DARK_06,
      },
    },
    neutral: {
      ...secondary,
    },
    ocean: {
      backgroundColor: ({ mode }) => mode === 'LIGHT' ? color.BLUE_LIGHT_02 : color.BLUE_LIGHT_04,
      color: ({ mode }) => mode === 'LIGHT' ? color.WHITE_01 : color.GRAY_DARK_03,
      "& path": { fill: color.WHITE_01 },
      "&:hover": { 
        backgroundColor: ({ mode }) => mode === 'LIGHT' ? color.BLUE_DARK_04 : color.BLUE_LIGHT_01,
        boxShadow: theme.shadow.elevation4Green,  
      },
      "&:active": { 
        backgroundColor: ({ mode }) => mode === 'LIGHT' ? color.BLUE_DARK_2 : color.BLUE_LIGHT_02,
        boxShadow: theme.shadow.elevation4Green,  
      },
      "&:disabled": { 
        backgroundColor: color.BLUE_LIGHT_5,
        color: color.WHITE_01, 
      },
    },
    sunset: {
      backgroundColor: ({ mode }) => mode === 'LIGHT' ? color.ORANGE_LIGHT_2 : color.ORANGE_LIGHT_3,
      color: color.GRAY_DARK_03,
      "& path": { fill: color.WHITE_01 },
      "&:hover": { 
        backgroundColor: ({ mode }) => mode === 'LIGHT' ? color.ORANGE_LIGHT_1 : color.ORANGE_LIGHT_2,
        boxShadow: theme.shadow.elevation4Green,  
      },
      "&:active": { 
        backgroundColor: ({ mode }) => mode === 'LIGHT' ? color.ORANGE : color.ORANGE_LIGHT_1,
        boxShadow: theme.shadow.elevation4Green,  
      },
      "&:disabled": { 
        backgroundColor: color.ORANGE_LIGHT_4,
        color: color.WHITE_01, 
      },
    },
    secondary,
    tertiary: {
      backgroundColor: "transparent",
      color: color.GREEN_DARK_1,
      "& path": { fill: color.GREEN_DARK_1 },
      "&:hover": {
        color: color.GREEN_DARK_2,
        "& path": { fill: color.GREEN_DARK_2 },
      },
      "&:active": {
        color: color.GREEN_DARK_3,
        "& path": { fill: color.GREEN_DARK_3 },
      },
      "&:disabled": {
        color: color.GREEN_LIGHT_4,
        "& path": { fill: color.GREEN_LIGHT_4 },
      },
    },
    link: {
      backgroundColor: "transparent",
      padding: 0,
      color: color.GREEN_DARK_1,
      "&:hover": {
        color: color.GREEN_DARK_2,
      },
      "&:active": {
        color: color.GREEN_DARK_3,
      },
      "&:disabled": {
        color: color.GREEN_LIGHT_4
      },
    },
    negative: {
      backgroundColor: color.RED_LIGHT_3,
      "&:hover": { 
        backgroundColor: color.RED_LIGHT_2,
        boxShadow: theme.shadow.elevation4Red, 
      },
      "&:active": { 
        backgroundColor: color.RED_LIGHT_1,
        boxShadow: theme.shadow.elevation4Red,
      },
      "&:disabled": { backgroundColor: color.RED_LIGHT_5 },
    },
    positive: {
      backgroundColor: color.GREEN_LIGHT_3,
      "&:hover": { 
        backgroundColor: color.GREEN_LIGHT_2,
        boxShadow: theme.shadow.elevation4Green,
      },
      "&:active": { 
        backgroundColor: color.GREEN_LIGHT_1,
        boxShadow: theme.shadow.elevation4Green, 
      },
      "&:disabled": { backgroundColor: color.GREEN_LIGHT_5 },
    },
    notice: {
      backgroundColor: color.ORANGE_LIGHT_4,
      "&:hover": { 
        backgroundColor: color.ORANGE_LIGHT_3,
        boxShadow: theme.shadow.elevation4Orange, 
      },
      "&:active": { 
        backgroundColor: color.ORANGE_LIGHT_2,
        boxShadow: theme.shadow.elevation4Orange,
      },
      "&:disabled": { backgroundColor: color.ORANGE_LIGHT_5 },
    },
    spacious: {
      height: "34px",
      borderRadius: 7,
      padding: "7px 12px",
    },
  }
});

export const KarteraButton: FC<ButtonProps> = ({
  id,
  text,
  icon,
  type = 'button',
  spacious,
  disabled = false,
  className,
  variant = "primary",
  mode = "LIGHT",
  onClick,
  onMouseOver,
  onMouseLeave,
}) => {
  const classes = useStyles({ mode });

/*   const classWithTheme = (variant: string) => {
    return mode === "DARK" ? `${variant}Dark` : variant;
  } */

  return (
    <button
      id={id}
      data-testid={id}
      className={`
        ${classes.button} 
        ${classes[variant]} 
        ${classes[variant]} 
        ${spacious && classes.spacious}
        ${className}
      `}
      onClick={onClick}
      type={type}
      disabled={disabled}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      <div className={classes.content}>
        {icon && icon}
        {text}
      </div>
    </button>
  );
};
