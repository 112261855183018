import React, { FC } from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { color } from "../../../consts/ColorConst";
import { TextAreaProps } from "./types";
import { sentenceCaseGenerator } from "../../../utils/helperFunctions";

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    fontFamily: "Roboto",
  }, 
  textAreaStyle: {
    backgroundColor: color.GRAY_09,
    border: `2px solid ${color.GRAY_09}`,
    fontSize: "14px",
    fontWeight: 400,
    padding: "6px 10px",
    borderRadius: "6px",
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    "&:hover": {
      backgroundColor: color.SECONDARY,
      borderColor: color.SECONDARY_DARK_01,
      boxShadow: `0 0 4px #7d848f66`,
      "&::-webkit-scrollbar-thumb": {
        borderRight: `6px solid ${color.SECONDARY}`,
      },
    },
    "&:focus": {
      backgroundColor: color.GRAY_09,
      outline: "none !important",
      borderColor: color.BLUE_01,
    },
    "&::-webkit-scrollbar": {
      width: "12px",
    },
    "&::-webkit-scrollbar-track": {
      margin: "6px 0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: color.SECONDARY_DARK_02,
      borderRight: `6px solid ${color.GRAY_09}`,
    },
  },
  textAreaDisabled: {
    backgroundColor: color.GRAY_LIGHT_02,
    border: `2px solid ${color.GRAY_LIGHT_02}`,
    color: color.SECONDARY,
    "&:hover": {
      backgroundColor: color.GRAY_LIGHT_02,
      borderColor: color.GRAY_LIGHT_02,
      boxShadow: "none",
      "&::-webkit-scrollbar-thumb": {
        borderRight: `6px solid ${color.GRAY_LIGHT_02}`,
      },
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: color.SECONDARY,
      borderRight: `6px solid ${color.GRAY_LIGHT_02}`,
    },
  },
  hasError: {
    backgroundColor: color.RED_04,
    borderColor: color.RED_02,
    color: color.RED_DARK_05,
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: color.RED_DARK_05,
    },
  },
  textAreaFixed: {
    resize: "none",
  },
  subNote: {
    fontSize: "11px",
    fontWeight: 400,
    color: color.GRAY_04,
    margin: 0,
    marginTop: "7px",
  },
  errorMessage: {
    fontSize: "11px",
    fontWeight: 400,
    color: color.RED_02,
    margin: 0,
  },
  inputReadOnly: {
    backgroundColor: "transparent",
    color: color.GRAY_DARK_04,
    border: 0,
    padding: "6px 0",
  },
}));

export const LynkTextArea: FC<TextAreaProps> = ({
  id,
  type = "rest",
  placeholder,
  width = "100%",
  height = "80px",
  errorMessage,
  subNote,
  required,
  fixed = true,
  value,
  onChange,
  onBlur,
  disabled,
  ref,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ width, height }}>
      <textarea  
        id={"textArea"}
        ref={ref}
        data-testid={id}
        readOnly={type === "read-only"}
        className={`
          ${classes.textAreaStyle}
          ${type === "read-only" && classes.textAreaReadOnly}
          ${fixed && classes.textAreaFixed}
          ${disabled && classes.textAreaDisabled}
          ${errorMessage && classes.hasError}
        `}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
        disabled={disabled}
      />
      {errorMessage && (
        <p className={classes.errorMessage}>
          {sentenceCaseGenerator(errorMessage)}
        </p>
      )}
      {subNote && (
        <p className={classes.subNote}>
          {sentenceCaseGenerator(subNote)}
        </p>
      )}
    </div>
  );
};
