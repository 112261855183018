import React, { FC, createRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Theme } from '@mui/material';
import { color } from '../../../consts/ColorConst';
import { TextAreaProps, SelectedItem } from './types';
import { sentenceCaseGenerator } from '../../../utils/helperFunctions';
import { LynkChip } from '../../common';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    position: 'relative',
    backgroundColor: color.GRAY_09,
    border: `2px solid ${color.GRAY_09}`,
    fontSize: '14px',
    fontWeight: 400,
    padding: '6px 0 10px 10px',
    borderRadius: '6px',
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '&:hover': {
      backgroundColor: color.SECONDARY,
      borderColor: color.SECONDARY_DARK_01,
      boxShadow: `0 0 4px #7d848f66`,
      '&::-webkit-scrollbar-thumb': {
        borderRight: `6px solid ${color.SECONDARY}`,
      },
    },
    '&:active': {
      backgroundColor: color.GRAY_09,
      outline: 'none !important',
      borderColor: color.GREEN,
    },
    '&::-webkit-scrollbar': {
      width: '12px',
    },
    '&::-webkit-scrollbar-track': {
      margin: '6px 0',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: color.SECONDARY_DARK_02,
      borderRight: `6px solid ${color.GRAY_09}`,
    },
  },
  input: {
    border: 0,
    width: 20,
    fontSize: '14px',
    fontWeight: 400,
    backgroundColor: 'transparent',
    outline: 'none !important',
  },
  chipWrapper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 4,
  },
  active: {
    backgroundColor: color.GRAY_09,
    outline: 'none !important',
    borderColor: color.GREEN,
  },
  textAreaDisabled: {
    backgroundColor: color.GRAY_LIGHT_02,
    border: `2px solid ${color.GRAY_LIGHT_02}`,
    color: color.SECONDARY,
    '&:hover': {
      backgroundColor: color.GRAY_LIGHT_02,
      borderColor: color.GRAY_LIGHT_02,
      boxShadow: 'none',
      '&::-webkit-scrollbar-thumb': {
        borderRight: `6px solid ${color.GRAY_LIGHT_02}`,
      },
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: color.SECONDARY,
      borderRight: `6px solid ${color.GRAY_LIGHT_02}`,
    },
  },
  hasError: {
    backgroundColor: color.RED_04,
    borderColor: color.RED_02,
    color: color.RED_DARK_05,
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: color.RED_DARK_05,
    },
  },
  textAreaFixed: {
    resize: 'none',
  },
  subNote: {
    fontSize: '11px',
    fontWeight: 400,
    color: color.GRAY_04,
    margin: 0,
    marginTop: '7px',
  },
  placeholder: {
    position: 'absolute',
    top: 7,
    left: 10,
    fontSize: '14px',
    fontWeight: 400,
    color: color.GRAY_DARK_06,
  },
  errorMessage: {
    fontSize: '11px',
    fontWeight: 400,
    color: color.RED_02,
    marginTop: 3,
  },
  inputReadOnly: {
    backgroundColor: 'transparent',
    color: color.GRAY_DARK_04,
    border: 0,
    padding: '6px 0',
    marginTop: 3,
  },
}));

export const KarteraTextAreaChip: FC<TextAreaProps> = ({
  id,
  placeholder,
  width = '100%',
  height = '80px',
  errorMessage,
  subNote,
  value = [],
  setValue,
  validation,
  onBlur,
  disabled,
  setHasInvalidItems,
}) => {
  const classes = useStyles();
  const inputRef = createRef<HTMLInputElement>();
  const [inputValue, setInputValue] = React.useState('');
  const [isActive, setIsActive] = useState(false);
  const [selectedItems, setSelectedItems] = useState<SelectedItem[]>();

  function handleSetItems(itemValue: string[]) {
    setValue(itemValue);
    const validatedItems = itemValue.map((item) => ({
      value: item,
      error: validation ? !validation(item) : false,
    }));
    setSelectedItems(validatedItems);
    const hasError = validatedItems.find((item) => item.error === true);
    setHasInvalidItems && setHasInvalidItems(!!hasError);
  }

  function handleKeyDown(event: any) {
    if (['Enter', 'Tab', ',', ';', ' '].includes(event.key)) {
      if (inputValue.trim()) {
        const uniqueValues = new Set([...value, inputValue]);
        const newValue = Array.from(uniqueValues);
        handleSetItems(newValue);
        setInputValue('');
      }
    }
  }

  function handleDeleteChip(chipValue: string) {
    const filteredItems = value?.filter((item) => item !== chipValue);
    handleSetItems(filteredItems);
  }

  function handleInputChange(event: any) {
    if (![',', ';', ' '].includes(event.target.value.trim())) {
      setInputValue(event.target.value);
    }
  }

  function handleFocus() {
    setIsActive(true);
    inputRef?.current?.focus();
  }

  function handleBlur() {
    setIsActive(false);
    onBlur && onBlur();
  }

  function updateInputSize() {
    if (inputRef?.current) {
      const len = inputValue.length > 5 ? inputValue.length : 5;
      const width = len < 10 ? len * 11 : len * 9;
      inputRef.current.style.width = `${width}px`;
    }
  }

  function handlePaste(e: any) {
    setTimeout(() => {
      const pastedValue = e.target.value.trim();
      let splitedValues;
      if (pastedValue.includes(',')) splitedValues = pastedValue.split(',');
      if (pastedValue.includes(';')) splitedValues = pastedValue.split(';');
      if (pastedValue.includes(' ')) splitedValues = pastedValue.split(' ');

      const uniqueValues = new Set([...value, ...splitedValues]);
      const newValue = Array.from(uniqueValues);
      handleSetItems(newValue);
      setInputValue('');
    }, 0);
  }

  return (
    <div>
      <div
        data-testid={id}
        className={`
          ${classes.root}
          ${isActive && classes.active}
          ${disabled && classes.textAreaDisabled}
          ${errorMessage && classes.hasError}
        `}
        style={{ width, height }}
        onFocus={handleFocus}
        onClick={handleFocus}
        onBlur={handleBlur}
      >
        {placeholder && value.length === 0 && inputValue === '' && (
          <span className={classes.placeholder}>{placeholder}</span>
        )}
        <Box className={classes.chipWrapper}>
          {selectedItems?.map((item) => (
            <LynkChip
              type='field'
              key={item.value}
              text={item.value}
              onDelete={() => handleDeleteChip(item.value)}
              error={item.error}
              className={classes.chip}
            />
          ))}
          <input
            ref={inputRef}
            type='text'
            className={classes.input}
            onInput={updateInputSize}
            value={inputValue}
            onChange={handleInputChange}
            onPaste={(e) => handlePaste(e)}
            onKeyDown={handleKeyDown}
          />
        </Box>
      </div>
      {errorMessage && (
        <p className={classes.errorMessage}>{sentenceCaseGenerator(errorMessage)}</p>
      )}
      {subNote && <p className={classes.subNote}>{sentenceCaseGenerator(subNote)}</p>}
    </div>
  );
};
