import { useRef, useState } from "react";
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { Box, IconButton, Modal, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faUsers, faBuildingColumns, faKey, faGift, faGlobe } from '@fortawesome/pro-regular-svg-icons';
import { faUser} from "@fortawesome/free-solid-svg-icons";
import { useOutSideClick } from "./../../hooks/useOUtsideClick";
import { AppRootState } from '../../reducers';
import { useAuth } from '../../context/Auth';
import { KarteraMenu } from '../../components/kartera/menu';
import { KarteraButton } from '../../components/kartera/button';
import { KarteraAlert } from '../../components/kartera/alert';
import BankAccount from './bankAccount';
import { ApiKeys } from './apiKeys';
import { KarteraTooltip } from '../../components/kartera/tooltip';
import { SettingsProps } from "./types";
import { color } from "../../consts/ColorConst";
import Account from "./account";
import { Company } from "./company";
import RewardsProgram from "./rewards";
import { Webhooks } from "./webhooks";
import { IpWhitelist } from "./ipWhitelist";
import { setApiHasError } from "../../reducers/apiErrorReducers";

const useStyles = makeStyles(() => ({
  modal: {
    position: 'absolute',
    display: 'flex',
    top: '50%',
    left: '50%',
    width: 800,
    height: 557,
    flexDirection: 'column',
    borderRadius: 10,
    padding: 0,
    transform: 'translate(-50%, -50%)',
    backgroundColor: color.WHITE_01,
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: '0.2px',
    color: color.GRAY_DARK_01,
  },
  modalTop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '57px',
    borderBottom: `1px solid ${color.SECONDARY}`,
    padding: '0 24px',
  },
  modalContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  contentWrapper: {
    width: 620,
  },
  menuWrapper: {
    width: '214px',
    height: '440px',
    padding: '12px',
    borderRight: `1px solid ${color.SECONDARY}`,
  },
  modalBottom: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 60,
    padding: '0 24px',
    borderTop: `1px solid ${color.SECONDARY}`,
    backgroundColor: color.WHITE_01,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  closeButton: {
    padding: '3px 5px',
  },
  tooltip:{
    fontSize:"12px",
    color:color.RED
  }
}));

const reduxConnector = connect((state: AppRootState) => ({
  hasError: state.apiErrorState.hasError,
  errorMessage: state.apiErrorState.errorMessage,
}));

type Props = SettingsProps & ConnectedProps<typeof reduxConnector>;

type PageType = 'account' | 'company' | 'bankAccount' | 'apiKeys' | 
      'rewards' | 'webhooks' | 'whitelist';

function Settings({ isOpen, handleClose, hasError, errorMessage }: Props) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { partnerUser } = useAuth();

  const { t } = useTranslation('translation', { keyPrefix: 'settings' });
  const modalRef = useRef(null);

  const dispatch = useDispatch();

  const [cookies] = useCookies(['lynk.partner-id']);
  const hasPartner = Boolean(cookies['lynk.partner-id']);
  const isStaffAdmin = pathname.includes('staff-admin');

  const [activePage, setActivePage] = useState<PageType>('account');
  const [tooltip, setTooltip] = useState(false);

  useOutSideClick(modalRef, () => {
    if (!document.getElementById('inviteModal') && 
        !document.getElementById('membersGrid') && 
        !document.getElementById('rewards-dialog') && 
        !document.getElementById('deleteRewardModal') && 
        !document.getElementById('apiKeyDialog') && 
        !document.getElementById('webhookDialog') && 
        !document.getElementById('changePasswordModal')) {
      handleClose();
    }
  });

  const menuItems = [
    {
      text: t('menuAccount'),
      icon: <FontAwesomeIcon icon={faUser} width={20} />,
      onClick: () => setActivePage('account'),
    },
  ];

  if (partnerUser?.role !== 'READ_ONLY') {
    menuItems.push({
      text: isStaffAdmin ? t('menuStaff') : t('menuCompany'),
      icon: <FontAwesomeIcon icon={faUsers} width={20} />,
      onClick: () => setActivePage('company'),
    });
    menuItems.push({
      text: isStaffAdmin ? t('menuStaff') : t('menuBankAccount'),
      icon: <FontAwesomeIcon icon={faBuildingColumns} width={20} />,
      onClick: () => setActivePage('bankAccount'),
    });
    menuItems.push({
      text: isStaffAdmin ? t('menuStaff') : t('webhooks'),
      icon: <FontAwesomeIcon icon={faGlobe} width={20} />,
      onClick: () => setActivePage('webhooks'),
    });
    menuItems.push({
      text: isStaffAdmin ? t('menuStaff') : t('menuApiKeys'),
      icon: <FontAwesomeIcon icon={faKey} width={20} />,
      onClick: () => setActivePage('apiKeys'),
    });
    menuItems.push({
      text: isStaffAdmin ? t('menuStaff') : t('menuRewards'),
      icon: <FontAwesomeIcon icon={faGift} width={20} />,
      onClick: () => setActivePage('rewards'),
    });
  }

  return (
    <Modal open={isOpen}>
      <Paper className={classes.modal} ref={modalRef}>
        <Box className={classes.modalTop}>
          <Typography className={classes.title}>{t('title')}</Typography>
          <IconButton className={classes.closeButton} onClick={() => handleClose()}>
            <FontAwesomeIcon icon={faClose} fontSize={18} color={color.GRAY} />
          </IconButton>
        </Box>

        {hasError && (
          <KarteraAlert
            text={
              errorMessage.includes('BANK_ACCOUNT_PENDING')
                ? "Product's account has to be in Ready status. Now it's in status: Pending"
                : errorMessage.includes('SQL')
                ? 'There is a problem with your profile please contact support'
                : errorMessage
            }
            severity={'error'}
            onClose={() => dispatch(setApiHasError(false))}
          />
        )}

        <Box className={classes.modalContentWrapper}>
          <Box
            className={classes.menuWrapper}
            onMouseEnter={() => !hasPartner && setTooltip(true)}
            onMouseLeave={() => !hasPartner && setTooltip(false)}
          >
            <KarteraMenu
              variant='secondary'
              initialActiveItem={
                activePage === 'account'
                  ? { text: t('menuAccount') }
                  : activePage === 'company'
                  ? { text: t('menuCompany') }
                  : activePage === 'bankAccount'
                  ? { text: t('menuBankAccount') }
                  : activePage === 'apiKeys'
                  ? { text: t('menuApiKeys') }
                  : activePage === 'rewards'
                  ? { text: t('menuRewards') }
                  : { text: t('menuWhitelist') }
              }
              items={menuItems}
              disabled={!hasPartner}
            />
            {tooltip && (
              <KarteraTooltip title={''} placement={'right'}>
                <Box className={classes.tooltip}>{t('completeOnboard')}</Box>
              </KarteraTooltip>
            )}
          </Box>
          <Box className={classes.contentWrapper}>
            {activePage === 'account' && <Account />}
            {activePage === 'company' && <Company />}
            {activePage === 'bankAccount' && <BankAccount />}
            {activePage === 'apiKeys' && <ApiKeys />}
            {activePage === 'rewards' && <RewardsProgram />}
            {activePage === 'webhooks' && <Webhooks />}
            {activePage === 'whitelist' && <IpWhitelist />}
          </Box>
        </Box>
        <Box className={classes.modalBottom}>
          <KarteraButton onClick={() => handleClose()} text={t('buttonClose')} />
        </Box>
      </Paper>
    </Modal>
  );
}

export default reduxConnector(Settings);
