import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
};

const businessVerificationSlice = createSlice({
  name: 'businessVerification',
  initialState,
  reducers: {
    setBusinessVerification: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
  },
});

export const { setBusinessVerification } = businessVerificationSlice.actions;

export default businessVerificationSlice.reducer;