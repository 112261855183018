import { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { LynkIconButton } from '../../../components/common';
import { useApi } from '../../../services/api/hooks';
import { color } from '../../../consts/ColorConst';
import { LynkTableGrid } from '../../../components/tableGrid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KarteraButton } from '../../../components/kartera/button';
import { faTrash, faGlobe, faPencil } from '@fortawesome/pro-regular-svg-icons';
import { dateConverter } from '../../../utils/dateConverter';
import { KarteraTextField } from '../../../components/kartera/textField';
import { KarteraSelectField } from '../../../components/kartera/selectField';
import { KarteraDialogue } from '../../../components/kartera/dialogue';
import { ActionConfirmationModal } from '../../../components/actionConfirmationModal';

const useStyles = makeStyles(() => ({
  root: { 
    width: '100%', 
    height: "440px",
    padding: "10px 4px 0 0",
  },
  container: {
    width: "100%",
    height: "100%",
    padding: "10px 16px 18px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: 7,
    },
    "&::-webkit-scrollbar-track": {
      background: "#fff",
    },
    "&::-webkit-scrollbar-thumb": {
      background: color.GRAY_02,
      borderRadius: 4,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: color.GRAY, 
    }
  },
  gridWrapper: {
    padding: 0,
    gap: 0,
  },
  text: {
    fontSize: '14px',
    fontWeight: 400,
    color: color.GREY_DARK_2,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  innerContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  headerAbsoluteContainer: {
    position: 'absolute',
    right: '0',
    top: '72px',
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  panelWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    marginBottom: 12,
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: 500,
    color: color.GREY_DARK_3,
  },
}));

export function Webhooks() {
  const classes = useStyles();
  const { product_id } = useParams();
  const { t } = useTranslation('translation', { keyPrefix: 'webhooks' });
  const { getProductWebhooks, createProductWebhook, updateProductWebhook, removeProductWebhook } = useApi();

  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [url, setUrl] = useState('');
  const [webhookId, setWebhookId] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [refreshDate, setRefreshDate] = useState(new Date());
  const [showTable, setShowTable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  async function handleGetWebhooks(page: number, pageSize: number, sort: string) {
    if (!product_id || product_id === ' ') return;
    return await getProductWebhooks(product_id, pageSize, page, sort);
  }

  useEffect(() => {
    async function fetchData() {
      if (!product_id || product_id === ' ') return;
      setIsLoading(true);
      const response = await getProductWebhooks(product_id);
      if (response.data.length > 0) {
        setShowTable(true);
      }
      setIsLoading(false);
    }

    fetchData();
  }, [refreshDate]);

  async function handleOpenWebhookForm() {
    if (!product_id) return;
    setModalOpen(true);
  }

  function refreshAndCloseModal(setCloseModal: (val: boolean) => void) {
    setRefreshDate(new Date());
    setCloseModal(false);
    setUrl('');
    setError('')
  }

  async function handleRemoveWebhook() {
    if (!product_id) return;
    await removeProductWebhook(product_id, webhookId);
    refreshAndCloseModal(setDeleteModalOpen);
  }

  async function handleCreateOrUpdateWebhook() {
    if (!product_id || !url) return;
    if (isEditing) {
      setIsEditing(false);
      const updateResponse = await updateProductWebhook(
        product_id,
        webhookId,
        {
          url: url,
          status: 'enabled',
        },
        (error) => setError(error.message),
      );
      if (updateResponse) {
        refreshAndCloseModal(setModalOpen);
      }
    } else {
        const createResponse = await createProductWebhook(
          product_id,
          {
            url: url,
            event: 'account.update',
          },
          (error) => setError(error.message),
        );
        if (createResponse) {
          refreshAndCloseModal(setModalOpen);
        }
      }
  }

  async function showDeleteModal(apiId: string) {
    setDeleteModalOpen(true);
    setWebhookId(apiId);
  }

  const editButton = (params: GridRenderCellParams) => (
    <LynkIconButton
      sx={{ width: 26, height: 26, padding: 0 }}
      onClick={() => {
        setIsEditing(true);
        setUrl(params.row.url);
        setWebhookId(params.row.id);
        setModalOpen(params.row.id);
      }}
    >
      <FontAwesomeIcon icon={faPencil} color={color.GREEN_DARK_1} width='14px' />
    </LynkIconButton>
  );

  const deleteButton = (params: GridRenderCellParams) => (
    <LynkIconButton
      sx={{ width: 26, height: 26, padding: 0 }}
      onClick={() => showDeleteModal(params.row.id)}
    >
      <FontAwesomeIcon icon={faTrash} color={color.RED_DARK_1} width='14px' />
    </LynkIconButton>
  );

  function getParsedDate(params: any) {
    return dateConverter(params.row.created_at);
  }

  const columns: GridColDef[] = [
    {
      field: 'event',
      sortable: false,
      headerName: t('colEvent'),
      width: 140,
    },
    {
      field: 'url',
      sortable: false,
      headerName: t('colUrl'),
      width: 200,
    },
    {
      field: 'created_at',
      sortable: false,
      headerName: t('colCreatedOn'),
      width: 100,
      valueGetter: getParsedDate,
    },
    {
      field: 'edit',
      headerName: '',
      sortable: false,
      renderCell: editButton,
      width: 28,
    },
    {
      field: 'delete',
      headerName: '',
      sortable: false,
      renderCell: deleteButton,
      width: 28,
    },
  ];

  if (isLoading) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center'>
        <CircularProgress sx={{ color: color.GREEN }} size={38} />
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.container} data-testid='webhooks-page-container'>
        <Box className={classes.innerContent}>
          <Typography className={classes.text}>{t('messageP1')}</Typography>
          {!showTable && <Typography className={classes.text}>{t('messageP2')}</Typography>}
          <Box className={showTable ? classes.headerAbsoluteContainer : classes.headerContainer}>
            <KarteraButton
              text={t('addWebhook')}
              onClick={handleOpenWebhookForm}
              icon={<FontAwesomeIcon icon={faGlobe} width={20} />}
            />
          </Box>
          {showTable && (
            <Box className={classes.gridWrapper}>
              <LynkTableGrid
                refreshDate={refreshDate}
                fetchData={handleGetWebhooks}
                title={t('title')}
                columns={columns}
                autoHeight
                supressFooter
                searchPlaceholder={t('searchPlaceholder')}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                filteringEnabled={false}
              />
            </Box>
          )}
        </Box>
      </Box>
      {deleteModalOpen && (
        <ActionConfirmationModal
          id='webhookDialog'
          modalOpen={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
          title={t('deleteModalTitle')}
          text={
            <Box className={classes.panelWrapper}>
              <Typography className={classes.text}>{t('deleteModalP1')}</Typography>
              <Typography className={classes.text}>{t('deleteModalP2')}</Typography>
              <Typography className={classes.text}>
                <Trans>{t('deleteModalP3')}</Trans>
              </Typography>
            </Box>
          }
          confirmationText={t('deleteModalConfirmationWord')}
          confirmationButtonText={t('delete')}
          confirmationButtonAction={handleRemoveWebhook}
        />
      )}
      {modalOpen && (
        <KarteraDialogue
          id='webhookDialog'
          size='small'
          autoHeight
          supressPanelTop
          activeButtonType='primary'
          cancelButtonText={t('cancel')}
          onCancelClick={() => setModalOpen(false)}
          actionButtonText={isEditing ? t('save') : t('add')}
          onActionClick={handleCreateOrUpdateWebhook}
        >
          <Box className={classes.modalContainer}>
            <Typography className={classes.modalTitle}>
              {isEditing ? t('editWebhook') : t('newWebhook')}
            </Typography>
            <KarteraSelectField
              label={`${t('colEvent')}:`}
              labelStyle='simple'
              placeholder={t('eventPlaceholder')}
              fullWidth
              value='account.update'
              items={[{ value: 'account.update', text: 'account.update' }]}
              disabled
            />
            <KarteraTextField
              label={`${t('colUrl')}:`}
              labelStyle='simple'
              placeholder={t('urlPlaceholder')}
              fullWidth
              value={url}
              onChange={(e: any) => setUrl(e.target.value)}
              errorMessage={error}
              error={error !== ''}
            />
          </Box>
        </KarteraDialogue>
      )}
    </Box>
  );
}
