import { FC, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import KarteraCompleteLogo from "../../assets/karteraCompleteLogoBlack.svg";
import { color } from "../../consts/ColorConst";
import { SidebarProps } from "./types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { KarteraMenu } from "../kartera/menu";

const useStyles = makeStyles((theme: any) => ({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "230px",
    minWidth: "230px",
    height: "100vh",
    padding: "14px 20px",
    transition: "all 0.1s ease-out",
    backgroundColor: theme.palette.background.paper,
    borderRight: `1px solid ${color.GREY_LIGHT_4}`,
    zIndex: "991",
  },
  toggleSidebarButton: {
    position: "absolute",
    top: 21,
    right: -13,
    height: 24,
    width: 24,
    backgroundColor: color.GREEN_LIGHT_4,
    transition: "transform 0.3s",
    "& >svg":{
      color:color.GREEN
    },
    "&:hover": {
      backgroundColor: color.GREEN_LIGHT_5,
    },
  },
  sidebarClosed: {
    minWidth: "82px",
    width: "82px",
  },
  topContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    transition: "transform 0.3s",
    padding: "0 6px",
    marginBottom: "52px",
    overflow: 'hidden',
    gap: 14,
  },
  topContainerClosed: {
    transition: "transform 0.3s",
    width: "40px",
  },
  typeContainer: {
    overflow: "hidden",
  },
  typeInnerContainer: {
    display: "flex",
    alignItems: "center",
    padding: "16px 14px 28px",
    backgroundColor: color.WHITE_01,
    borderTop: `1px solid ${color.GREY_LIGHT_1}`,
    gap: 10,
  },
  typeText: {
    fontSize: 14,
    color: color.GREY,
    transition: "all 0.3s",
  },
  typeInnerOpen: {
    padding: "16px 30px 28px",
  },
}));

export const Sidebar: FC<SidebarProps> = ({
  disabledMenu = false,
  hideHeader,
  menuArgs,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <Box className={`${classes.container} ${!open && classes.sidebarClosed}`}>
      <IconButton
        onClick={handleDrawerToggle}
        className={classes.toggleSidebarButton}
        sx={{ transform: open ? "rotate(0)" : "rotate(180deg)" }}
      >
        <FontAwesomeIcon
          icon={faChevronLeft}
          fontSize={11}
          color={color.BLUE_01}
        />
      </IconButton>
      <Box>
        {!hideHeader && (
          <Box className={`${classes.topContainer} ${!open && classes.topContainerClosed}`}>
            <img 
              src={KarteraCompleteLogo} 
              alt="Kartera logo" 
              height={36}
            />
          </Box>
        )}
        <KarteraMenu
          id='sidebar-menu'
          variant="secondary"
          items={menuArgs.items}
          sidebarOpen={open}
          disabled={disabledMenu}
          initialActiveItem={menuArgs.initialActiveItem}
        />
      </Box>
    </Box>
  );
};
