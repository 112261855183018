import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { color } from '../../../../consts/ColorConst';
import { truncateText } from '../../../../utils/helperFunctions';
import { ProductUserSearchDTO } from '../../../../dtos/productUsers/productUserSearchDTO';
import { dateConverter } from '../../../../utils/dateConverter';
import { PaymentDTO } from '../../../../dtos/payments/paymentDTO';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 16,
    fontWeight: 500,
    paddingBottom: 6
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY_DARK_1,
    paddingTop: 10,
  },
  transactionData: {
    display: 'flex',
    gap: 10,
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY_DARK_4,
    '& strong': {
      fontWeight: 500,
    }
  },
  dataLabel: {
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY_LIGHT_1,
    '& strong': {
      fontWeight: 500,
      color: color.GREY_DARK_4,
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 0 22px 0',
  },
}));


type Props = {
  customer: ProductUserSearchDTO;
  transaction: PaymentDTO
  amount: string;
}

export function SendRefund({ customer, transaction, amount }: Props) {
  const classes = useStyles();
  const { t } = useTranslation("translation", { keyPrefix: "refunds" });

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>
        {`Send Refund to ${customer.first_name} ${customer.last_name}?`} 
      </Typography>
      <Typography className={classes.label}>
        {t('customer')}:
      </Typography>
      <Typography className={classes.dataLabel}>
        <strong>{`${customer.first_name} ${customer.last_name}`}</strong>
        {` (${customer.email})`}  
      </Typography>
      <Typography className={classes.label}>
        {t('transaction')}
      </Typography>
      <Typography className={classes.transactionData}>
        <strong>{dateConverter(transaction.created_at)}</strong>
        {`${truncateText(transaction.description, 30)}`}  
      </Typography>
      <Typography className={classes.label}>
        {t('refundTo')}:
      </Typography>
      <Typography className={classes.dataLabel}>
        <Trans>{t('bankAccount')}</Trans>
      </Typography>
      <Typography className={classes.label}>
        {t('amount')}:
      </Typography>
      <Typography className={classes.dataLabel}>
        <strong>{`${amount} USD`}</strong>
        {` (${t('fullRefund')})`}
      </Typography>
      <Typography className={classes.label}>
        {t('fee')}:
      </Typography>
      <Typography className={classes.dataLabel}>
        <strong>1.25 USD</strong>
        {` (${t('paidByMerchant')})`}  
      </Typography>
      <Typography className={classes.label}>
        {t('processingTime')}
      </Typography>
      <Typography className={classes.dataLabel}>
        <strong>12-48 hrs</strong>  
        {` (${t('excludingWeekends')})`}  
      </Typography>
    </Box>
  )
}
