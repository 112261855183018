export const dateConverter = (utcDate: string) => {
    const date = new Date(utcDate)
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    return `${year}-${month}-${day}`;
}

export const dateTimeConverter = (utcDate: string) => {
    const date = new Date(utcDate)
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    let hour = date.getHours();
    const minute = (`0${date.getMinutes()}`).slice(-2);
    const ampm = hour < 12 ? "AM" : "PM";
    hour = hour > 12 ? hour - 12 : hour;
    return `${day}/${month}/${year} ${hour}:${minute} ${ampm}`;
}

export function datefromTimestamp(timestamp: string) {
    const date = new Date(Number(timestamp) * 1000)
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    
    return `${year}-${month}-${day}`;
}