import { configureStore } from '@reduxjs/toolkit';
import { persistReducer } from "redux-persist";
//import logger from 'redux-logger';
import storage from "redux-persist/lib/storage";
import reducers from "../reducers";

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['onboardingState', 'accountConnectState'], // only onboardingState and accountConnectState will be persisted
};

//to persist the store data into the local storage
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
    /*   process.env.NODE_ENV === 'development'
        ? getDefaultMiddleware({ serializableCheck: false }).concat(logger)
        : getDefaultMiddleware({ serializableCheck: false }), */
});
