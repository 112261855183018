import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { color } from '../../../consts/ColorConst';
import { Props } from './types';
import { KarteraChip } from '../chip';
import { faArrowUpRightFromSquare, faCheck, faChevronDown, faPause, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonMenu } from '../../common/buttonMenu';
import { useState } from 'react';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { KarteraButton } from '../button';
import { KarteraDialogue } from '../dialogue';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '300px',
    height: '252px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: color.WHITE_01,
    boxShadow: theme.shadow.elevation4Grey,
    borderRadius: 6,
    padding: '12px 16px 20px',
    position: 'relative',
  },
  topContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  topLeftContent: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    color: color.GREY_DARK_4,
  },
  content: {
    padding: '18px 0 0',
  },
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    marginBottom: 12,
    fontSize: 14,
    fontWeight: 500,
    color: color.GREY_DARK_4,
    '& label': {
      fontWeight: 400,
      color: color.GREY_LIGHT_1,
    },
    '& a': {
      color: color.GREY_DARK_4,
      textDecoration: 'none',
      "&:hover": {
        color: color.GREEN,
        cursor: 'pointer',
      }
    },
  },
  buttonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  disabled: {
    '& span': {
      color: `${color.GREY_LIGHT_2} !important`,
    },
    '& label': {
      color: `${color.GREY_LIGHT_3} !important`,
    },
    '& a': {
      color: `${color.GREY_LIGHT_2} !important`,
      "&:hover": {
        color: `${color.GREY_LIGHT_2} !important`,
        cursor: 'default',
      }
    },
  },
  disconnected: {
    filter: "blur(6px)",
    "-webkit-filter": "blur(6px)",
  },
  selectButton: {
    height: 28,
    borderRadius: 6,
    backgroundColor: color.GREY_LIGHT_4,
    color: color.GREY_DARK_4,
    gap: 8,
    "&:hover": {
      backgroundColor: color.GREY_LIGHT_3,
    }
  },
  connectButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'max-content',
    zIndex: 1,
  },
  disconnectDialogWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '4px 0',
    gap: 12,
    '& p': {
      margin: 0,
    }
  }
}));

export function KarteraPlatformCard({ 
  status,
  platformName,
  account,
  date,
  url,
  onConnect,
}: Props ) {
  const classes = useStyles();
  const { t } = useTranslation("translation", { keyPrefix: "platform" });

  const [selectedItem, setSelectedItem] = useState();
  const [showDisconnectDialog, setShowDisconnectDialog] = useState(false);

  const isDefault = status === 'DEFAULT';
  const isActive = status === 'ACTIVE';

  const chipTypes = {
    'ACTIVE': { icon: faCheck, variant: 'positive' },
    'INACTIVE': { icon: faPause, variant: 'notice' },
    'FAILED': { icon: faExclamationCircle, variant: 'negative' },
  }

  const buttonMenuItems = [
    {
      text: `${t('open')} ${platformName}`,
      value: "OPEN",
      icon: <FontAwesomeIcon icon={faArrowUpRightFromSquare} fontSize={12} />,
      redirectUrl: url,
    },
    {
      text: t('disconnect'),
      value: "production",
      icon: <FontAwesomeIcon icon={faTrash} fontSize={12} />,
      onClick: () => setShowDisconnectDialog(true),
    },
  ];

  function handleStatusText(status: string) {
    return status[0] + status.substring(1).toLowerCase();
  }

  return (
    <Box className={`${classes.root}`}>
      <Box className={classes.topContent}>
        <Box className={classes.topLeftContent}>
          <Typography className={classes.title}>
            {platformName}
          </Typography>
          {!isDefault && (
            <KarteraChip
              text={handleStatusText(status)}
              size='small'
              icon={(
                <FontAwesomeIcon 
                  icon={chipTypes[status].icon} 
                  size='1x'
                />
              )}
              variant={chipTypes[status].variant as any} 
            />
          )}
        </Box>
      </Box>
      <Box 
        className={`
          ${classes.content} 
          ${!isActive && classes.disabled}
          ${isDefault && classes.disconnected}
        `}
      >
        <Box className={classes.fieldWrapper}>
          <label>{t('account')}</label>
          <span>{account}</span>
        </Box>
        <Box className={classes.fieldWrapper}>
          <label>{t('url')}</label>
          <a href={isActive ? url : undefined} rel="noreferrer" target="_blank">{url}</a>
        </Box>
        <Box className={classes.fieldWrapper}>
          <label>{t('connectionDate')}</label>
          <span>{date}</span>
        </Box>
        <Box className={classes.buttonWrapper}>
          <ButtonMenu
            text='Manage'
            className={classes.selectButton}    
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            iconRight={
              <FontAwesomeIcon icon={faChevronDown} fontSize={12} />
            }
            items={buttonMenuItems}
            disabled={!isActive}
          />
        </Box>   
      </Box>
      {isDefault && (
        <KarteraButton
          className={classes.connectButton} 
          text={`${t('connect')} ${platformName}`}
          onClick={onConnect}
        />
      )}
      {showDisconnectDialog && (
        <KarteraDialogue 
          title={`${t('disconnect')} ${platformName}`}
          titleIcon={(
            <FontAwesomeIcon 
              icon={faTrash} 
              color={color.RED_DARK_1}
              width='22px' 
              size="xl"
            />
          )}
          size='small'
          autoHeight
          actionButtonText={t('disconnect')}
          activeButtonType="negative"
          cancelButtonText={t('cancel')}
          onCancelClick={() => setShowDisconnectDialog(false)}
          showCloseButton={false}
          supressOutsideClick
        >
          <div className={classes.disconnectDialogWrapper}>
            <p>{t('disconnectP1', {platform: platformName})}</p>
            <p>{t('disconnectP2', {platform: platformName})}</p>
          </div>
        </KarteraDialogue>
      )}
    </Box>
  );
}
