import { createSlice } from "@reduxjs/toolkit";
import { ProductUserDTO } from "../dtos/productUsers/productUserDTO";

type ProductUserStateType = {
    selectedProductUser: ProductUserDTO | null;
};

const initialState: ProductUserStateType = {
    selectedProductUser: null,
};

const productUserSlice = createSlice({
    name: 'productUser',
    initialState,
    reducers: {
        setSelectedProductUser: (state, action) => {
            state.selectedProductUser = action.payload;
        },
    },
});


export const { setSelectedProductUser } = productUserSlice.actions;

export default productUserSlice.reducer;