import { useCallback, useEffect, useState } from 'react';
import { Box, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Trans, useTranslation } from 'react-i18next';
import { color } from '../../../consts/ColorConst';
import { useApi } from '../../../services/api/hooks';
import { connect, ConnectedProps } from 'react-redux';
import { AppRootState } from '../../../reducers';
import { KarteraButton } from '../../../components/kartera/button';
import { KarteraChip } from '../../../components/kartera/chip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { KarteraDialogue } from '../../../components/kartera/dialogue';
import { usePlaidLink } from 'react-plaid-link';
import { useAuth } from '../../../context/Auth';
import { useCookies } from 'react-cookie';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '440px',
    padding: '20px 20px',
    whiteSpace: 'pre-line',
  },
  descripMoreWrapper: {
    padding: '12px 0 0 0',
  },
  heading: {
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY_DARK_1,
    letterSpacing: '0.2px',
  },
  description: {
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY_DARK_3,
    letterSpacing: '0.2px',
    lineHeight: '20px',
    padding: '0 0 10px 0',
  },
  learnMore: {
    textDecoration: 'none',
    color: color.GREEN,
    fontSize: 14,
    fontWeight: 400,
  },
  learnMorePlaid: {
    textDecoration: 'none',
    margin: '15px 0 0 0',
    color: color.GREEN,
    fontSize: 14,
    fontWeight: 400,
    display: 'flex',
  },
  buttonWrapper: {
    padding: '25px 0 0 0',
  },
}));

const reduxConnector = connect((state: AppRootState) => ({
  accountConnectClosed: state.accountConnectState.closed,
}));

type Props = ConnectedProps<typeof reduxConnector>;

function BankAccount() {
  const classes = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'settings' });
  const [connected, setConnected] = useState(false);
  const [disconnectModal, setDisconnectModal] = useState(false);
  const [account, setAccount] = useState({ account_type: '', account_number: '', id: '' });

  const { currentProduct, partner } = useAuth();
  const productId = currentProduct.id;

  const [cookies] = useCookies(['lynk.partner-id']);
  const hasPartner = Boolean(cookies['lynk.partner-id']);

  const onDisconnectClick = () => {
    setDisconnectModal(true);
  };

  const [token, setToken] = useState('');

  const onSuccess = useCallback(
    async (public_token) => {
      const postedAccount = await postConnectedAccount(productId, {
        plaid_public_token: public_token,
      }).then(async () => {
        const accountResponse: any = await getConnectedAccount(productId);
        if (accountResponse.data.length > 0) {
          setConnected(true);
          setAccount(accountResponse?.data[0]);
        }
      });
    },
    [token],
  );

  const [config, setConfig] = useState({
    token: '',
    onSuccess,
  });

  const { getPlaidLinkToken, getConnectedAccount, deleteConnectedAccount, postConnectedAccount } =
    useApi();

  async function getAsyncData() {
    const accountResponse: any = await getConnectedAccount(productId);
    if (accountResponse && accountResponse.data.length > 0) {
      setConnected(true);
      setAccount(accountResponse.data[0]);
    }
  }

  useEffect(() => {
    if (partner) {
      getAsyncData();
    }
  }, []);

  const { open, exit } = usePlaidLink(config);

  const onConnectClick = async () => {
    const tokenResponse: any = await getPlaidLinkToken();
    setConfig({ ...config, token: tokenResponse?.plaid_link_token });
  };

  useEffect(() => {
    if (config.token !== '') {
      open();
    }
    exit();
  }, [config, open, exit]);

  const onConfirmDelete = useCallback(async () => {
    const deleteResponse = await deleteConnectedAccount(productId, account.id);
    setConnected(false);
    setDisconnectModal(false);
  }, [token, account]);

  return (
    <Box className={classes.container}>
      {connected ? (
        <Box>
          <Typography className={classes.heading}>{t('status')}:</Typography>
          <KarteraChip
            text={t('connected')}
            size={'small'}
            icon={<FontAwesomeIcon icon={faLink} width={'12px'} />}
          />
          <Typography className={classes.heading} style={{ paddingTop: 10 }}>
            {t('connectedAccount')}:
          </Typography>
          <Typography className={classes.description} style={{ fontWeight: 500 }}>
            Bank of America
          </Typography>
          <Typography className={classes.description}>{`${
            account?.account_type
          } (${account?.account_number.slice(0, 3)}******${account?.account_number.slice(
            account?.account_number.length - 3,
            account?.account_number.length,
          )})`}</Typography>
          <Box className={classes.descripMoreWrapper}>
            <Typography className={classes.description}>
              <Trans i18nkey='settings'>{t('connectedDes1')}</Trans>
            </Typography>
            <Typography className={classes.description}>
              <Trans i18nkey='settings'>{t('connectedDes2')}</Trans>
            </Typography>
            <Typography className={classes.description}>
              <Trans i18nkey='settings'>{t('connectedDes3')}</Trans>
              <Link className={classes.learnMore} href={'https://plaid.com/docs/'}>
                {t('learnMore')}
              </Link>
            </Typography>
          </Box>
          <Box className={classes.buttonWrapper}>
            <KarteraButton
              text={t('disconnectButton')}
              variant={'secondary'}
              onClick={onDisconnectClick}
            />
          </Box>
          {disconnectModal && (
            <Box>
              <KarteraDialogue
                size={'small'}
                activeButtonType={'negative'}
                actionButtonText={t('disconnectButton')}
                onActionClick={onConfirmDelete}
                cancelButtonText={t('cancel')}
                onCancelClick={() => setDisconnectModal(false)}
                showCloseButton={false}
                autoHeight
              >
                {' '}
                <Typography
                  className={classes.description}
                  style={{ fontWeight: 500, fontSize: 16, paddingBottom: 12 }}
                >
                  {t('disconnectHeader')}
                </Typography>
                <Typography className={classes.description}>
                  {t('disconnectDescription1')}
                </Typography>
                <Typography className={classes.description}>
                  {t('disconnectDescription2')}
                </Typography>
                <Typography className={classes.description}>
                  {t('disconnectDescription3')}
                </Typography>
              </KarteraDialogue>
            </Box>
          )}
        </Box>
      ) : (
        <Box>
          <Typography className={classes.description}>
            <Trans i18nkey='settings'>{t('notConnectedDes1')}</Trans>
          </Typography>
          <Typography className={classes.description}>
            <Trans i18nkey='settings'>{t('notConnectedDes2')}</Trans>
          </Typography>{' '}
          <Typography className={classes.description}>
            <Trans i18nkey='settings'>{t('notConnectedDes3')}</Trans>
          </Typography>
          <Link className={classes.learnMorePlaid} href={'https://plaid.com/docs/'}>
            {t('learnMorePlaid')}
          </Link>
          <Box className={classes.buttonWrapper}>
            <KarteraButton
              text={t('connectButton')}
              variant={'secondary'}
              onClick={onConnectClick}
              disabled={!hasPartner}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default reduxConnector(BankAccount);
