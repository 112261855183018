import { useTranslation } from 'react-i18next';
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { faChainBroken } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useApi } from '../../../services/api/hooks';
import { color } from '../../../consts/ColorConst';
import { KarteraButton } from '../../../components/kartera/button';

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: color.GREY_DARK_4,
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: 400,
    borderRadius: 10,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadow.elevation16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '48px 32px 32px',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  title: {
    fontSize: 26,
    fontWeight: 700,
  },
  pageDescription: {
    padding: '18px 0',
    fontSize: 14,
    lineHeight: 1.2,
  },
  submitButton: {
    padding: '4px 16px',
  },
}));

type props = {
  email: string;
}

export function LinkExpiredDialog({ email }: props) {
  const classes = useStyles();
  const { postResetPassword } = useApi();
  const { t } = useTranslation('translation', { keyPrefix: 'changePassword' });

  document.title = 'Kartera - Link Expired';

  async function handleRequestNewLink() {
    await postResetPassword({
      email,
      ui_host_url: `${window.location.protocol}//${window.location.host}`,
    });
  }

  return (
    <Box data-testid='link-expired-or-invalid' className={classes.container}>
      <div className={classes.titleWrapper}>
        <FontAwesomeIcon icon={faChainBroken} size='xl'/>
        <Typography className={classes.title}>{t('linkExpired')}</Typography>
      </div>
      <Typography className={classes.pageDescription}>{t('linkExpiredDescription')}</Typography>
      <KarteraButton
        text={t('requestNewLink')}
        className={classes.submitButton}
        onClick={handleRequestNewLink}
        spacious
      />
    </Box>
  );
}
