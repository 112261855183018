import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { color } from "../../../consts/ColorConst";
import { PaymentStatus } from "../../../components/paymentStatus";
import { KarteraDialogue } from "../../../components/kartera/dialogue";
import { Divider } from "@mui/material";
import { KarteraButton } from "../../../components/kartera/button";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles(() => ({
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "40px",
    padding: "0 40px",
    backgroundColor: color.GRAY_07,
  },
  titleWrapper: {
    display: "flex",
    width: '100%',
    alignItems: "center",
    gap: 8,
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.2,
  },
  boxTop: {
    width: '100%',
    display: 'flex', 
    flexDirection: 'row', 
    padding: '0 0 16px',
  },
  box: {
    width: '100%',
    display: 'flex', 
    flexDirection: 'row', 
    padding: '16px 0 8px',
  },
  innerBox: {
    width: '50%', 
    display: 'flex',
    flexDirection: 'column', 
    gap: 12,
  },
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'column', 
    gap: 4,
  },
  fieldName: {
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY_DARK_1
  },
  value: {
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY_DARK_4
  },
}));

type Props = {
    onClose: () => void;
    data: {
        status: "COMPLETED" | "QUEUED" | "IN_PROGRESS" | "RETURNED" | "CANCELLED";
        customer: string;
        email: string;
        fee: string;
        accountId: string;
        refundSentDate: string;
        refundProcessedDate: string;
        refundCancelledDate: string;
        transactionDate: string;
        transactionDescription: string;
        refundAmount: string;
        transactionAmount: string;
    }
}

export function RefundsDialog({ data, onClose }: Props) {
    const { t } = useTranslation("translation", { keyPrefix: "refunds" });
    const classes = useStyles();
  
    return (
        <KarteraDialogue 
            titleComponent={(
                <div className={classes.titleWrapper}>
                    <p className={classes.title}>
                        {`Details: Refund to ${data.customer}`}
                    </p>
                    <PaymentStatus status={data.status}/>
                </div>
            )}
            size='large'
            autoHeight
            actionButtonText={'Close'}
            activeButtonType="primary"
            onActionClick={onClose}
            onCancelClick={onClose}
            leftButtonComponent={(
                <KarteraButton
                    variant="tertiary"
                    text="Download Statement"
                    icon={<FontAwesomeIcon icon={faDownload}/>}
                />
            )}
        >
            <div className={classes.boxTop}>
                <div className={classes.innerBox}>
                    <div className={classes.fieldWrapper}>
                        <p className={classes.fieldName}>{t('customer')}:</p>
                        <p className={classes.value}>{data.customer}</p>
                    </div>
                    <div className={classes.fieldWrapper}>
                        <p className={classes.fieldName}>{t('email')}:</p>
                        <p className={classes.value}>{data.email}</p>
                    </div>
                    <div className={classes.fieldWrapper}>
                        <p className={classes.fieldName}>{t('accountId')}:</p>
                        <p className={classes.value}>{data.accountId}</p>
                    </div>
                </div>
                <div className={classes.innerBox}>
                    <div className={classes.fieldWrapper}>
                        <p className={classes.fieldName}>{t('refundAmount')}:</p>
                        <p className={classes.value}>{data.refundAmount}</p>
                    </div>
                    <div className={classes.fieldWrapper}>
                        <p className={classes.fieldName}>{t('fee')}</p>
                        <p className={classes.value}>{data.fee}</p>
                    </div>
                    <div className={classes.fieldWrapper}>
                        <p className={classes.fieldName}>{t('refundSentDate')}:</p>
                        <p className={classes.value}>{data.refundSentDate}</p>
                    </div>
                    { data.status === 'CANCELLED' && (
                        <div className={classes.fieldWrapper}>
                            <p className={classes.fieldName}>{t('refundCancelledDate')}:</p>
                            <p className={classes.value}>{data.refundCancelledDate}</p>
                        </div>
                    )}
                    { data.status === 'COMPLETED' && (
                        <div className={classes.fieldWrapper}>
                            <p className={classes.fieldName}>{t('refundProcessedDate')}:</p>
                            <p className={classes.value}>{data.refundProcessedDate}</p>
                        </div>
                    )}
                </div>
            </div>
            <Divider  />
            <div className={classes.box}>
                <div className={classes.innerBox}>
                    <div className={classes.fieldWrapper}>
                        <p className={classes.fieldName}>{t('transactionDate')}:</p>
                        <p className={classes.value}>{data.transactionDate}</p>
                    </div>
                    <div className={classes.fieldWrapper}>
                        <p className={classes.fieldName}>{t('transactionDescription')}:</p>
                        <p className={classes.value}>{data.transactionDescription}</p>
                    </div>
                </div>
                <div className={classes.fieldWrapper}>
                    <p className={classes.fieldName}>{t('transactionAmount')}:</p>
                    <p className={classes.value}>{data.transactionAmount}</p>
                </div>
            </div>
        </KarteraDialogue>
    );
}
