import { Box, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@mui/styles';
import { color } from '../../../consts/ColorConst';
import { KarteraBackdrop } from '../../kartera/backdrop';
import { KarteraButton } from '../../kartera/button';
import { KarteraUploadButton } from '../../kartera/uploadButton';
import { ErrorDialogueProps } from './types';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 360,
    fontSize: 14,
    backgroundColor: color.WHITE_01,
    padding: '20px 24px 16px',
    borderRadius: 10,
    boxShadow: theme.shadow.elevation16,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 8
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    color: color.GREY_DARK_4,
  },
  text: {
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY_DARK_3,
  },
  contentcontainer: {
    padding: '12px 0 20px',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 12,
  },
}));

export function UploadImageErrorDialogue({
  title,
  text,
  onCancel,
  onChooseImage,
}: ErrorDialogueProps) {
  const classes = useStyles();

  return (
    <KarteraBackdrop transparent>
      <Box className={classes.root}>
        <Box className={classes.titleContainer}>
            <FontAwesomeIcon
              icon={faXmarkCircle}
              color={color.RED_DARK_1}
              fontSize={21}
            />
            <Typography className={classes.title}>
              {title}
            </Typography>
        </Box>
        <Box className={classes.contentcontainer}>
            <Typography className={classes.text}>
              {text}
            </Typography>
        </Box>
        <Box className={classes.buttonsWrapper}>
          <KarteraButton
            text='Cancel'
            variant='secondary'
            onClick={onCancel}
          />
          <KarteraUploadButton
              onChange={onChooseImage}
              fileType="image/jpeg, image/png"
              type='primary'
              text='Choose Image'
          />
        </Box>
      </Box>
    </KarteraBackdrop>
  );
}
