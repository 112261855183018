import { useCookies } from "react-cookie";
import { useAxios } from "./useAxios";

export function useDocAPI() {
  const { getApi } = useAxios();
  const [cookies] = useCookies(["lynk.access-token", "lynk.partner-id"]);
  const authToken = {
    headers: { Authorization: `Bearer ${cookies["lynk.access-token"]}` },
  };

  async function getApiDoc(): Promise<string> {
    return getApi("/api_doc");
  }

  return {
    getApiDoc
  };
}
