import { FC, useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { LynkIconButton } from '../../../components/common';
import { useApi } from '../../../services/api/hooks';
import { ActionConfirmationModal } from '../../../components/actionConfirmationModal';
import { color } from '../../../consts/ColorConst';
import { LynkTableGrid } from '../../../components/tableGrid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KarteraButton } from '../../../components/kartera/button';
import { faTrash, faKey } from '@fortawesome/pro-regular-svg-icons';
import { dateTimeConverter } from '../../../utils/dateConverter';
import { KarteraCodeSnippet } from '../../../components/kartera/codeSnippet';
import { useAuth } from '../../../context/Auth';
import { KarteraDialogue } from '../../../components/kartera/dialogue';
import { ProductApiKeyDTO } from '../../../dtos/apiKey/productApiKeyDTO';
import { ConditionalComponent } from '../../../components/contitionalComponent';

const useStyles = makeStyles(() => ({
  root: { 
    width: '100%', 
    height: "440px",
    padding: "10px 4px 0px 0",
  },
  container: {
    width: "100%",
    height: "100%",
    padding: "10px 16px 18px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: 7,
    },
    "&::-webkit-scrollbar-track": {
      background: "#fff",
    },
    "&::-webkit-scrollbar-thumb": {
      background: color.GRAY_02,
      borderRadius: 4,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: color.GRAY, 
    }
  },
  gridWrapper: {
    padding: 0,
    gap: 0,
  },
  apiDesc: {
    fontSize: '14px',
    fontWeight: 400,
    color: color.GREY_DARK_2,
    padding: '0 0 20px 0',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  innerContent: {
    position: 'relative'
  },
  headerAbsoluteContainer: {
    position: 'absolute',
    width: 130,
    left: '434px',
    top: '72px',
  },
  modalBodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    whiteSpace: 'pre-line',
  },
  modalDeleteContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    marginBottom: 12,
  },
  modalText: {
    fontSize: 14,
    lineHeight: 1.2,
    color: color.GREY_DARK_3,
    "& strong": {
      fontWeight: 500,
    }
  },
}));

export const ApiKeys: FC = () => {
  const classes = useStyles();
  const { product_id } = useParams();
  const { currentProduct } = useAuth();
  const { t } = useTranslation('translation', { keyPrefix: 'apiKeys' });
  const { getProductApiKeys, createProductApiKey, removeProductApiKey, getProductById } = useApi();

  const [showNewApiKeyModal, setShowNewApiKeyModal] = useState(false);
  const [showApiKeyDeleteModal, setShowApiKeyDeleteModal] = useState(false);
  const [currentApiKey, setCurrentApiKey] = useState('');
  const [apiKeyToDelete, setApiKeyToDelete] = useState<ProductApiKeyDTO>();
  const [selectedItems, setSelectedItems] = useState([]);
  const [refreshDate, setRefreshDate] = useState(new Date());
  const [showTable, setShowTable] = useState(false);
  const [connectedAccount, setConnectedAccount] = useState(false);
  const [maxReached, setMaxReached] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const productId = currentProduct.id;
  const deleteDialogAPIKeyTitle = apiKeyToDelete && `${apiKeyToDelete.api_key.substring(0, 17)}...`;

  document.title = 'Kartera - API Keys';

  async function getAPIKeysData() {
    if (!product_id || product_id === ' ') return;
    return await getProductApiKeys(product_id);
  }

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const response = await getAPIKeysData();
      if (response?.data?.length && response?.data?.length  > 0) {
        setShowTable(true);
      }
      if (response?.data?.length  && response?.data?.length  > 2) {
        setMaxReached(true);
      } else {
        setMaxReached(false);
      }
      setIsLoading(false);
    }
    
    fetchData();
  }, [refreshDate]);

  async function getProductStatus() {
    const productResponse = await getProductById(productId);
    if (productResponse.status === 'BANK_ACCOUNT_READY') {
      setConnectedAccount(true);
    } else {
      setConnectedAccount(false);
    }
  }

  useEffect(() => {
    if (!product_id || product_id === ' ') return;
    getProductStatus();
  }, []);

  async function handleCreateApiKey() {
    if (!product_id) return;
    const response = await createProductApiKey(product_id);
    setShowNewApiKeyModal(true);
    setCurrentApiKey(response.api_key);
    setRefreshDate(new Date());
  }

  async function handleRemoveApiKey() {
    if (!product_id || !apiKeyToDelete) return;
    await removeProductApiKey(product_id, apiKeyToDelete.id);
    setRefreshDate(new Date());
  }

  async function handleDeleteButton(apiKey: ProductApiKeyDTO) {
    setShowApiKeyDeleteModal(true);
    setApiKeyToDelete(apiKey);
  }

  const deleteButton = (params: GridRenderCellParams) => (
    <LynkIconButton
      sx={{ width: 26, height: 26, padding: 0 }}
      onClick={() => handleDeleteButton(params.row)}
    >
      <FontAwesomeIcon icon={faTrash} color={color.RED_DARK_1} width='14px' />
    </LynkIconButton>
  );

  function getParsedDate(params: any) {
    return dateTimeConverter(params.row.created_at);
  }

  const columns: GridColDef[] = [
    {
      field: 'api_key',
      sortable: false,
      headerName: t('colApiKey'),
      flex: 1,
    },
    {
      field: 'created_at',
      sortable: false,
      headerName: t('colCreatedOn'),
      width: 180,
      valueGetter: getParsedDate,
    },
    {
      field: 'delete',
      headerName: '',
      sortable: false,
      renderCell: deleteButton,
      width: 48,
    },
  ];

  if (isLoading) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center'>
        <CircularProgress sx={{ color: color.GREEN }} size={38} />
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.container} data-testid='api-keys-page-container'>
        <Box className={classes.innerContent}>
          <Typography className={classes.apiDesc}>
            {showTable ? t('APIListDesc') : t('APIDesc')}
          </Typography>
          <Box className={showTable ? classes.headerAbsoluteContainer : classes.headerContainer}>
            <KarteraButton
              text={t('addApiKeyButton')}
              onClick={handleCreateApiKey}
              icon={<FontAwesomeIcon icon={faKey} width={20} />}
              disabled={!connectedAccount || maxReached}
            />
          </Box>
          {showTable && (
            <Box className={classes.gridWrapper}>
              <LynkTableGrid
                refreshDate={refreshDate}
                fetchData={getAPIKeysData}
                title={t('title')}
                columns={columns}
                autoHeight
                supressFooter
                searchPlaceholder={t('searchPlaceholder')}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                filteringEnabled={false}
              />
            </Box>
          )}
        </Box>
      </Box>
      <ConditionalComponent condition={showApiKeyDeleteModal}>
        <ActionConfirmationModal
          id="apiKeyDialog"
          modalOpen={showApiKeyDeleteModal}
          setModalOpen={setShowApiKeyDeleteModal}
          title={`${t('deleteModalTile')} "${deleteDialogAPIKeyTitle}"?`}
          text={(
            <Box className={classes.modalDeleteContainer}>
              <Typography className={classes.modalText}>{t('deleteModalP1')}</Typography>
              <Typography className={classes.modalText}>{t('deleteModalP2')}</Typography>
              <Typography className={classes.modalText}>
                <Trans>{t('deleteModalP3')}</Trans>
              </Typography>
            </Box>
          )}
          confirmationText={t('deleteModalConfirmationWord')}
          confirmationButtonText={t('deleteModalConfirmButton')}
          confirmationButtonAction={handleRemoveApiKey}
        />
      </ConditionalComponent>
      <ConditionalComponent condition={showNewApiKeyModal}>
        <KarteraDialogue
          id='apiKeyDialog'
          title= {t('newAPIKey')}
          activeButtonType='primary'
          size='small'
          actionButtonText={t('modalButton')} 
          onActionClick={() => setShowNewApiKeyModal(false)} 
          autoHeight
          showCloseButton={false} 
        >
          <Box className={classes.modalBodyContainer}>
            <Typography className={classes.modalText}>
              <Trans>{t('modalText')}</Trans>
            </Typography>
            <KarteraCodeSnippet
              fixCopyButton
              useParagraphTag
              code={currentApiKey}
            />
          </Box>
        </KarteraDialogue>
      </ConditionalComponent>
    </Box>
  );
};
