import { FC, useState } from "react";
import { ButtonProps } from "./types";
import { makeStyles } from '@mui/styles';
import { color } from "../../../consts/ColorConst";

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '28px',
    [theme.breakpoints.down('md')]: {
      height: '28px',
    },
    width: 'fit-content',
    borderRadius: 6,
    overflow: 'hidden',
  },
  button: {
    height: '28px',
    [theme.breakpoints.down('md')]: {
      height: '28px',
    },
    padding: '6px 10px',
    fontSize: '14px',
    fontWeight: 500,
    textTransform: 'none',
    border: 'none',
    cursor: 'pointer',
    borderRight: '1px solid #fff',
    transition: 'all ease .3s',
    backgroundColor: color.GREY_LIGHT_4,
    color: color.GREY_DARK_4,
    '&:hover:enabled': { backgroundColor: color.GREY_LIGHT_3 },
    '&:active:enabled': { backgroundColor: color.GREY_LIGHT_2 },
    '&:disabled': {
      backgroundColor: color.GREY_LIGHT_5,
      color: color.GREY_LIGHT_1,
    },
  },
  selectedItem: {
    backgroundColor: color.GREEN_LIGHT_1,
    color: color.WHITE_01,
    '&:hover:enabled': { backgroundColor: color.GREEN_DARK_1 },
    '&:active:enabled': { backgroundColor: color.GREEN_DARK_2 },
    '&:disabled': {
      backgroundColor: color.GREEN_LIGHT_4,
      color: color.WHITE_01,
    },
  },
}));

export const KarteraSegmentedButton: FC<ButtonProps> = ({
  id,
  items,
  disabled = false,
  className,
}) => {
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState("0");

  function handleClick(index: number, onClick: any) {
    setSelectedItem(String(index));
    onClick && onClick();
  }

  return (
    <div 
      id={id}
      className={classes.root}
    >
      { items.map( (item, index) => (
        <button
          key={`${item.text}-${index}`}
          type="button"
          disabled={disabled}
          onClick={() => handleClick(index, item.onClick)}
          className={`
            ${classes.button} 
            ${selectedItem === String(index) && classes.selectedItem}
            ${className}
          `}
        >
          {item.text}
        </button>
      ))}
    </div>
  );
};
