import { useAxios } from "./useAxios";

type VersionResponseType = {
  version: string;
}

export function useVersionAPI() {
  const { getApi } = useAxios();

  async function getApiVersion(): Promise<VersionResponseType> {
    return getApi("/version", {}, false, false, false);
  }

  return {
    getApiVersion
  };
}
