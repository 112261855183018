import { FC } from "react";
import { Box, BoxProps } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { color } from "../../../consts/ColorConst";

const useStyles = makeStyles(() => ({
  panel: {
    position: "relative",
    boxSizing: "border-box",
    height: "100%",
    borderRadius: 6,
    backgroundColor: color.WHITE_01,
    boxShadow: `0px 0px 4px ${color.GRAY_DARK_09}`,
    overflow: "hidden",
  },
}));

export const LynkPanel: FC<BoxProps> = ({ children, ...rest }) => {
  const classes = useStyles();

  return (
    <Box className={classes.panel} {...rest}>
        { children }
    </Box>
  );
};
