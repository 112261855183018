import React, { FC } from "react";
import { makeStyles } from "@mui/styles";
import { MenuItem, Select, Theme } from "@mui/material";
import { color } from "../../../consts/ColorConst";
import { SelectFieldProps } from "./types";
import { KeyboardArrowDown } from "@mui/icons-material";
import { sentenceCaseGenerator } from "../../../utils/helperFunctions";

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    position: "relative",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .Mui-disabled": {
      "&:hover": {
        backgroundColor: color.GRAY_09,
        borderColor: color.GRAY_09,
      },
    },
  },
  rootFocus: {
    "& .Mui-focused": {
      backgroundColor: color.WHITE_01,
      border: `2px solid ${color.BLUE}`,
    },
  },
  menuItemRoot: {
    padding: "0 12px",
    "&$menuItemSelected, &$menuItemSelected:focus, &$menuItemSelected:hover": {
      backgroundColor: color.BLUE_LIGHT_03
    }
  },
  menuItemSelected: {},
  selectStyle: {
    height: "28px",
    backgroundColor: color.GRAY_09,
    border: `2px solid ${color.GRAY_09}`,
    borderRadius: 6,
    "& .MuiInputBase-input": {
      padding: "0 0 0 10px",
    },
    "& .MuiSelect-select": {
      fontSize: "14px",
      fontWeight: 400,
      color: color.GRAY_DARK_05,
    },
    "& > svg": {
      color: color.GRAY,
    },
    "&:hover": {
      backgroundColor: color.SECONDARY,
      borderColor: color.GRAY_DARK_08,
    },
    "&:focus": {
      backgroundColor: color.SECONDARY,
      borderColor: color.GRAY_DARK_08,
    },
  },
  simpleSelectStyle: {
    backgroundColor: "transparent",
    borderRadius: 6,
    "& .MuiInputBase-input": {
      padding: "0",
    },
    "& .MuiSelect-select": {
      fontSize: "14px",
      fontWeight: 400,
      color: color.GRAY_DARK_05,
    },
    "& > svg": {
      color: color.GRAY,
    },
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
    },
    "&:focus": {
      backgroundColor: "transparent",
      borderColor: "transparent",
    },
  },
  placeholder: {
    color: color.GRAY_DARK_06,
  },
  hasError: {
    backgroundColor: color.RED_04,
    borderColor: color.RED_03,
    color: color.RED_DARK_06,
    "& .MuiSelect-select": {
      color: color.RED_DARK_06,
    },
    "& > svg": {
      color: color.RED_03,
    }
  },
  errorMessage: {
    margin: "2px 0 0 0", 
    fontFamily: "inherit",
    fontSize: "11px",
    color: color.RED,
  },
  subNote: {
    margin: "2px 0 0 0",
    fontFamily: "inherit",
    fontSize: "11px",
    color: color.GRAY_DARK_07,
  },
}));

export const LynkSelectField: FC<SelectFieldProps> = ({
  id,
  variant = "normal",
  placeholder,
  width = "100%",
  errorMessage,
  error,
  defaultValue,
  subNote,
  required,
  items,
  value,
  onChange,
  onBlur,
  disabled,
  ref,
  sx,
}) => {
  const classes = useStyles();

  return (
    <div 
      className={`
        ${classes.root} 
        ${variant === "normal" && classes.rootFocus}
      `} 
      style={{ width: width }}
    >
      <Select
        id={id}
        data-testid={id}
        ref={ref}
        fullWidth
        displayEmpty
        IconComponent={KeyboardArrowDown}
        className={`
          ${variant === "normal" ? classes.selectStyle : classes.simpleSelectStyle} 
          ${(!!errorMessage || error) && classes.hasError}
        `}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        onBlur={(e: any) => onBlur && onBlur(e)}
        required={required}
        disabled={disabled}
        error={!!errorMessage}
        MenuProps={{ PaperProps: {
          style: {
            marginTop: 4,
            maxHeight: 300,
            width: width,
          },
        }}}
        renderValue={value => {
          if (!value && placeholder) {
            return <span className={classes.placeholder}>{placeholder}</span>
          }
          const selectedItem = items.find(item => item.value === value ); 
          return selectedItem?.text
        }}
        sx={sx}
      >
        {items.map( item => (
          <MenuItem
            dense
            key={`select-item-${item.text}`}
            classes={{ 
              root: classes.menuItemRoot,
              selected: classes.menuItemSelected,
            }}
            value={item.value} 
            disabled={item.disabled}
          >
            {item.text}
          </MenuItem>
        ))}
      </Select>
      {errorMessage && (
        <p className={classes.errorMessage}>
          {sentenceCaseGenerator(errorMessage)}
        </p>
      )}
      {subNote && (
        <p className={classes.subNote}>
          {sentenceCaseGenerator(subNote)}
        </p>
      )}
    </div>
  );
};
