import { useCookies } from "react-cookie";
import { useAxios } from "./useAxios";
import { PaymentDTO, PaymentRequestDTO } from "../../dtos/payments/paymentDTO";
import { RecurringPaymentDTO, RecurringPaymentRequestDTO } from "../../dtos/recurringPayments/recurringPaymentDTO";
import { PaymentSearchDTO, PaymentSearchRequestDTO } from "../../dtos/payments/paymentSearchDTO";

type ApiResponse<T> = {
  data: T[];
}

export function usePaymentsAPI() {
  const [cookies] = useCookies(["lynk.access-token", "lynk.partner-id"]);
  const authToken = {
    headers: { Authorization: `Bearer ${cookies["lynk.access-token"]}` },
  };

  const { getApi, postApi, deleteApi } = useAxios();

  async function createPayment(productId: string, data: PaymentRequestDTO): Promise<PaymentDTO> {
    return await postApi(`/products/${productId}/payments?type=PAYMENT`, data, authToken);
  }

  async function createRefund(
    productId: string, 
    data: PaymentRequestDTO, 
  ): Promise<PaymentDTO> {
    return await postApi(
      `/products/${productId}/payments?type=REFUND`, 
      data, authToken, undefined, false, true
    );
  }

  async function createRecurringPayment(
    productId: string, 
    data: RecurringPaymentRequestDTO
  ): Promise<RecurringPaymentDTO> {
    return await postApi(`/products/${productId}/recurring_payments`, data, authToken);
  }

  async function getPayments(
    productId: string,
    pageSize = 20,
    currentPage = 0,
    sort = 'createdAt,DESC',
    query = '',
  ): Promise<ApiResponse<PaymentDTO>> {
    return getApi(
      `/products/${productId}/payments?size=${pageSize}&page=${currentPage}&sort=${sort}${query}`,
      authToken,
      false,
    );
  }

  async function getRecurringPayments(
    productId: string,
    pageSize = 20,
    currentPage = 0,
    sort = 'createdAt,DESC',
    query = '',
  ): Promise<ApiResponse<RecurringPaymentDTO>> {
    return getApi(
      `/products/${productId}/recurring_payments?size=${pageSize}&page=${currentPage}&sort=${sort}${query}`,
      authToken,
      false,
    );
  }

  async function deleteRecurringPayments(productId: string, paymentId: string): Promise<any> {
    return deleteApi(`/products/${productId}/recurring_payments/${paymentId}`, authToken, false);
  }

  async function getPaymentsByUser(
    productId: string,
    productUserId: string,
    pageSize = 20,
    currentPage = 0,
    sort = 'createdAt,DESC',
    query = '',
  ): Promise<ApiResponse<PaymentDTO>> {
    return getApi(
      `/products/${productId}/payments?user_id=${productUserId}&size=${pageSize}&page=${currentPage}&sort=${sort}${query}`,
      authToken,
      false,
    );
  }

  async function getRefunds(
    productId: string,
    pageSize: number,
    currentPage: number,
    sort = 'createdAt,DESC',
    query = '',
  ): Promise<ApiResponse<PaymentDTO>> {
    return await getApi(
      `/products/${productId}/payments?type=REFUND&size=${pageSize}&page=${currentPage}&sort=${sort}${query}`,
      authToken,
      false,
    );
  }

  async function searchPayments(
    productId: string,
    data?: PaymentSearchRequestDTO,
  ): Promise<ApiResponse<PaymentDTO>> {
    return postApi(
      `/products/${productId}/payments/search`,
      data,
      authToken,
      undefined,
      false,
    );
  }

  return {
    getPayments,
    searchPayments,
    getRecurringPayments,
    deleteRecurringPayments,
    getPaymentsByUser,
    getRefunds,
    createPayment,
    createRefund,
    createRecurringPayment,
  };
}
