import React, { FC } from "react";
import { CheckboxProps } from "./types";
import { Checkbox } from "@mui/material";
import { CheckBoxRounded } from "@mui/icons-material";
import { makeStyles } from '@mui/styles';
import { color } from "../../../consts/ColorConst";

const useStyles = makeStyles(() => ({
  container:{
      display: "flex",
      alignItems: "center",
      fontSize: "10px",
      fontWeight: 400,
      color: color.GRAY_04,
    }
}));

export const LynkCheckbox: FC<CheckboxProps> = ({
  label,
  disabled,
  defaultChecked,
  sx,
  checkedColor = color.BLUE_01,
  onChange,
  checked,
  id,
}) => {
  const classes = useStyles();

  return (
    <div 
      className={classes.container}
      style={sx}
    >
      <Checkbox
        data-testid={id}
        sx={{
          fontSize: "30px",
          "& svg": {
            fontSize: 18,
            color: color.GRAY_06,
          },
        }}
        disabled={disabled}
        checkedIcon={
          <CheckBoxRounded sx={{ fill: checkedColor }}/>
        }
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={onChange}
      />
      {label && <label>{label}</label>}
    </div>
  );
};
