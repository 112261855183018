import { useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { color } from '../../consts/ColorConst';
import { KarteraHeadlineMenu } from '../../components/kartera/headlineMenu';
import { KarteraReportCard } from '../../components/kartera/reportCard';
import { useApi } from '../../services/api/hooks';
import { ReportSimpleRequestDTO } from '../../dtos/reports/reportDTO';
import { getInitialDateFromRange } from '../../utils/helperFunctions';
import { REPORT_INTERVAL, REPORT_TOP_CUSTOMERS, TODAY } from '../../consts/UIConstants';
import { AppRootState } from '../../reducers';
import { checkMockData } from '../../utils/generateMockData';
import { PaymentDTO } from '../../dtos/payments/paymentDTO';

const useStyles = makeStyles((theme: any) => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    backgroundColor: color.GRAY_01,
    padding: '35px 40px',
  },
  contentContainer: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 0 20px 0',
  },
  title: {
    fontSize: 26,
    fontWeight: 700,
    padding: '0 20px 0 0',
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  message: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.2,
  },
  dialogContainer: {
    width: 800,
    padding: 16,
    background: color.WHITE_01,
    borderRadius: 6,
    boxShadow: theme.shadow.elevation4Grey,
  },
  messageContainer: {
    display: 'flex',
    padding: '8px 0 0',
  },
  innerMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.2,
    gap: 10,
  },
  messageTitle: {
    fontWeight: 700,
  },
  image: {
    margin: '0 20px',
  },
  button: {
    width: 'fit-content',
    padding: 0,
  },
  cardsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 32,
    padding: '10px 0 20px 0',
  },
}));

type IntervalType = 'DAY' | 'WEEK' | 'MONTH' | 'YEAR' | 'ALL';

const reduxConnector = connect((state: AppRootState) => ({
  rewardType: state.rewardsState.reward_type,
  rewardPointsSpendRatio: state.rewardsState.point_system_spend_ratio,
}));

type Props = ConnectedProps<typeof reduxConnector>;

function Reports({ rewardType, rewardPointsSpendRatio }: Props) {
  const classes = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const { product_id } = useParams();
  const {
    getReportCashbackEarned,
    getReportCashbackRedeemed,
    searchPayments,
    getReportPointsEarned,
    getReportPointsRedeemed,
  } = useApi();

  document.title = 'Kartera - Reports & Analytics';

  const [reportInterval, setReportInterval] = useState<IntervalType>('MONTH');
  const [initialDate, setInitialDate] = useState(getInitialDateFromRange(reportInterval));
  const [isLoading, setIsLoading] = useState(false);

  const rewardEarned = useRef(0);
  const rewardRedeemed = useRef(0);
  const rewardPointsEarned = useRef(0);
  const rewardPointsRedeemed = useRef(0);
  const topCustomers = useRef(REPORT_TOP_CUSTOMERS);

  const simpleDataRequest: ReportSimpleRequestDTO = {
    interval: 'YEAR',
    range: {
      from: initialDate,
      to: TODAY,
    },
  };

  async function getReports(productId: string) {
    let totalEarned = 0;
    let totalRedeemed = 0;

    if (rewardType === 'POINT_SYSTEM') {
      const [pointsEarned, pointsRedeemed] = await Promise.all([
        getReportPointsEarned(productId, simpleDataRequest),
        getReportPointsRedeemed(productId, simpleDataRequest),
      ]);
      rewardPointsEarned.current = +pointsEarned[0]?.amount || 0;
      rewardPointsRedeemed.current = +pointsRedeemed[0]?.amount || 0;
      totalEarned = +pointsEarned[0]?.amount / Number(rewardPointsSpendRatio || 0);
      totalRedeemed = +pointsRedeemed[0]?.amount / Number(rewardPointsSpendRatio || 0);
    } else {
      const [cashbackEarned, cashbackRedeemed] = await Promise.all([
        getReportCashbackEarned(productId, simpleDataRequest),
        getReportCashbackRedeemed(productId, simpleDataRequest),
      ]);
      totalEarned = +cashbackEarned[0]?.amount || 0;
      totalRedeemed = +cashbackRedeemed[0]?.amount || 0;
    }
    rewardEarned.current = totalEarned || 0;
    rewardRedeemed.current = totalRedeemed || 0;
  }

  async function getPayments(productId: string) {
    const payments = await searchPayments(productId, {
      type: 'PAYMENT',
      date_from: initialDate,
      date_to: TODAY,
      paginate: {
        sort: {
          field: 'amount',
          direction: 'DESC',
        },
        page_number: 0,
        size: 10,
      },
    });
    topCustomers.current = payments.data.map((payment: PaymentDTO) => ({
      name: payment.user.first_name + ' ' + payment.user.last_name,
      product: payment.description,
      value: +payment.amount,
    }));
  }

  async function handleReports() {
    if (checkMockData()) return;
    if (!product_id) return;
    try {
      setIsLoading(true);
      if (rewardType && rewardType !== 'NO_REWARD' && rewardPointsSpendRatio) {
        await getReports(product_id);
      }
      await getPayments(product_id);
    } catch (error) {
      console.error('Error getting reports', error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    handleReports();
  }, [reportInterval, rewardType, rewardPointsSpendRatio]);

  function handleDropdownChange(event: any) {
    setReportInterval(event.target.value);
    setInitialDate(getInitialDateFromRange(event.target.value));
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.contentContainer}>
        <Box className={classes.titleContainer}>
          <Typography className={classes.title}>{t('yourPerformance')}</Typography>
          <KarteraHeadlineMenu
            items={REPORT_INTERVAL}
            width={'120px'}
            defaultValue={'MONTH'}
            onChange={handleDropdownChange}
          />
        </Box>
        <Box className={classes.cardsWrapper}>
          <KarteraReportCard
            variant='BALANCE'
            range={reportInterval}
            firstValue={String(rewardEarned.current)}
            secondValue={String(rewardEarned.current)}
          />
          <KarteraReportCard
            variant='REVENUE'
            range={reportInterval}
            firstValue={String(rewardEarned.current)}
            secondValue={String(rewardEarned.current)}
          />
          <KarteraReportCard
            variant='REWARDS'
            isPointSystem={rewardType === 'POINT_SYSTEM'}
            range={reportInterval}
            firstValue={String(rewardEarned.current)}
            earnedPoints={String(rewardPointsEarned.current)}
            redeemedPoints={String(rewardPointsRedeemed.current)}
            secondValue={String(rewardRedeemed.current)}
          />
          <KarteraReportCard
            variant='TOP_CUSTOMERS'
            range={reportInterval}
            customers={topCustomers?.current}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default reduxConnector(Reports);
