import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useApi } from "../../services/api/hooks";
import { checkMockData, getMockRefundsData } from "../../utils/generateMockData";
import { KarteraTable } from "../../components/kartera/table";
import { KarteraButton } from "../../components/kartera/button";
import { PaymentStatus } from "../../components/paymentStatus";
import { CreateRefund } from "./createRefund/createRefund";
import { RefundsDialog } from "./refundsDialog/RefundsDialog";
import { dateConverter } from "../../utils/dateConverter";
import { currencyFormatter } from "../../utils/handleValuesHelper";
import { PageStateType, QuickFilter } from "../../components/kartera/table/types";
import { PaymentDTO } from "../../dtos/payments/paymentDTO";

type Props = {
  isDemo: boolean;
  showBusinessVerificationDialog: () => void;
}

type RefundGridType = {
  row: PaymentDTO,
}

type RefundStatusType =  "CANCELLED" | "COMPLETED" | "IN_PROGRESS" | "QUEUED" | "RETURNED";

type RefundType = {
  status: RefundStatusType;
  customer: string;
  email: string;
  fee: string;
  accountId: string;
  refundSentDate: string;
  refundProcessedDate: string;
  transactionDate: string;
  transactionDescription: string;
  refundAmount: string;
  transactionAmount: string;
  refundCancelledDate: string;
}

export function Refunds({ isDemo, showBusinessVerificationDialog }: Props) {
  const { product_id } = useParams();
  const { searchPayments } = useApi();
  const { t } = useTranslation("translation", { keyPrefix: "refunds" });

  const [selectedItems, setSelectedItems] = useState([]);
  const [refreshDate, setRefreshDate] = useState(0);
  const [refreshTable, setRefreshTable] = useState(0);

  const [selectedRefund, setSelectedRefund] = useState<RefundType | null>(null);
  const [isCreateRefundModalOpen, setIsCreateRefundModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [paginationAndSortingMode, setPaginationAndSortingMode] = useState<'server' | 'client'>(
    'server',
  );

  async function getRefundsData(pageState: PageStateType, filters: any) {
    if (isDemo || checkMockData()) {
      setPaginationAndSortingMode('client');
      return {
        data: getMockRefundsData(),
        metadata: {
          total_elements: 100,
        },
      };
    }

    if (!product_id || product_id === ' ') return;
    setPaginationAndSortingMode('server');
    return searchPayments(product_id, {
      type: 'REFUND',
      description: filters.description,
      id: filters.id,
      all: filters.all,
      linked_account_id: filters.linked_account_id,
      amount: filters.amount,
      status: filters.status,
      date_from: filters.date_from,
      date_to: filters.date_to,
      paginate: {
        sort: {
          field: pageState.sortField,
          direction: pageState.sortDirection,
        },
        page_number: pageState.page,
        size: pageState.pageSize,
      },
    });
  }

  useEffect(() => {
    setRefreshDate(new Date().getTime());
  }, [isDemo]);

  function renderStatus(params: GridRenderCellParams) {
    return <PaymentStatus status={params.value} />;
  }

  function getFormattedValue(params: GridValueGetterParams) {
    return `${currencyFormatter(params.value).substring(1)} USD`;
  }

  function getCustomerName(params: GridValueGetterParams) {
    return `${params.row.user.first_name} ${params.row.user.last_name}`;
  }

  function getCustomerEmail(params: GridValueGetterParams) {
    return params.row.user.email;
  }

  function getParsedDate(params: GridValueGetterParams) {
    return dateConverter(params.row.created_at);
  }

  const quickFilters: QuickFilter[] = [
    {
      defaultValue: 'All time', 
      field: 'created_at',
      type: 'PERIOD',
      items: [
        { value: 'Today', text: 'Today' },
        { value: 'Yesterday', text: 'Yesterday' },
        { value: 'This week', text: 'This week' },
        { value: 'This month', text: 'This month' },
        { value: 'This year', text: 'This year' },
        { value: 'All time', text: 'All time' },
      ],
    },
    {
      defaultValue: 'All Status', 
      field: 'status',
      type: 'STATUS',
      items: [
        { text: 'All Status', value: 'All Status' }, 
        { value: 'INITIATED', text: t('queued') },
        { value: 'PROCESSED', text: t('completed') },
        { value: 'CANCELLED', text: t('cancelled') },
      ]
    },
  ] 

  const filterFields = [
    { text: t('date'), value: 'created_at' },
    /* { text: t('email'), value: 'email' },
    { text: t('customer'), value: 'first_name' }, */
    { text: t('linkedAccountId'), value: 'linked_account_id' },
    { text: t('amount'), value: 'amount' },
    { 
      text: t('status'), 
      value: 'status', 
      items: [
        { value: 'INITIATED', text: t('queued') },
        { value: 'PROCESSED', text: t('completed') },
        { value: 'CANCELLED', text: t('cancelled') },
      ] 
    },
  ];

  const columns: GridColDef[] = [
    { 
      field: "created_at", 
      headerName: t("date"),
      align: "right",
      minWidth: 130,
      valueGetter: getParsedDate, 
    },
    { 
      field: "customer", 
      headerName: t("customer"), 
      minWidth: 210,
      valueGetter: getCustomerName,
    },
    { 
      field: "email", 
      headerName: t("email"), 
      minWidth: 270,
      valueGetter: getCustomerEmail,
    },
    { field: "linked_account_id", headerName: t("linkedAccountId"), minWidth: 280 },
    {
      field: "amount",
      type: "number",
      headerName: t("amount"),
      minWidth: 150,
      valueGetter: getFormattedValue,
    },
    {
      field: "status",
      headerName: t("status"),
      minWidth: 120,
      renderCell: renderStatus,
    },
  ];

  function handleRefundGridClick(el: RefundGridType) {
    const refund: RefundType = {
      status: el.row.status as RefundStatusType,
      customer: `${el.row.user.first_name} ${el.row.user.last_name}`,
      email: el.row.user.email,
      fee: "$5.00",
      accountId: el.row.linked_account_id,
      refundSentDate: dateConverter(el.row.created_at),
      refundProcessedDate: dateConverter(el.row.updated_at),
      transactionDate: dateConverter(el.row.updated_at),
      transactionDescription: el.row.description,
      refundAmount: el.row.amount,
      transactionAmount: el.row.amount,
      refundCancelledDate: dateConverter(el.row.updated_at),
    }
    setIsDetailsModalOpen(true);
    setSelectedRefund(refund);
  }

  function handleRefundDialogClose() {
    setIsCreateRefundModalOpen(false);
    setRefreshTable(refreshTable + 1);
  }

  return (
    <>
      <KarteraTable
        rows={[]}
        noRowsMessage={t('noRefunds')}
        onRowClick={(el: RefundGridType) => handleRefundGridClick(el)}
        fetchData={getRefundsData}
        paginationAndSortingMode={paginationAndSortingMode}
        title={t('title')}
        columns={columns}
        defaultPageSize={10}
        minHeight={258}
        searchPlaceholder={t('searchPlaceholder')}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        filterFields={filterFields}
        quickFilters={quickFilters}
        refreshDate={refreshDate}
        refreshTable={refreshTable}
        component={
          <KarteraButton
            text='Create Refund'
            onClick={() =>
              isDemo ? showBusinessVerificationDialog() : setIsCreateRefundModalOpen(true)
            }
          />
        }
      />
      {isCreateRefundModalOpen && (
        <CreateRefund onClose={handleRefundDialogClose}/>
      )}
      {isDetailsModalOpen && selectedRefund && (
        <RefundsDialog onClose={() => setIsDetailsModalOpen(false)} data={selectedRefund} />
      )}
    </>
  );
}
