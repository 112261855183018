import React, { FC } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Theme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { color } from "../../../consts/ColorConst";
import { TextFieldProps } from "./types";
import { LynkTextField } from "../textField";

const useStyles = makeStyles<Theme>((theme) => ({
  verticalDirection: {
    diplay: "flex",
    flexDirection: "column",
  },
  horizontalDirection: {
    diplay: "flex",
    flexDirection: "row",
  },
  labelStyle: {
    height: "28px",
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 400,
    color: color.GRAY_DARK_07,
    textTransform: "capitalize",
  },
  horizontalLabel: {
    justifyContent: "flex-end",
    marginRight: "8px",
  },
  verticalLabel: {
    justifyContent: "flex-start",
    marginRight: "0",
  },
  labelDisabled: {
    color: color.GRAY_05,
  },
  required: {
    fontSize: "16px",
    fontWeight: 700,
    paddingLeft: "2px",
    color: color.RED_03,
  },
}));

export const LynkFormField: FC<TextFieldProps> = ({
  id,
  label,
  value,
  type = "rest",
  variant = "text",
  direction = "vertical",
  subNote,
  placeholder,
  errorMessage,
  width = "100%",
  labelWidth = "fit-content",
  required,
  onChange,
  onBlur,
  onCancel,
  disabled,
  ref,
  sx,
}) => {
  const classes = useStyles();

  return (
    <FormControl
      className={classes[direction + "Direction"]}
      variant="outlined"
      sx={{ ...sx, width: width }}
    >
      {label && (
        <Typography
          className={`
            ${classes.labelStyle} 
            ${classes[direction + "Label"]}
            ${disabled && classes.labelDisabled}
          `}
          sx={{ width: labelWidth }}
        >
          {label}
          {required && <span className={classes.required}>*</span>}
        </Typography>
      )}
      <LynkTextField
        ref={ref}
        type={type}
        value={value}
        variant={variant}
        placeholder={placeholder}
        errorMessage={errorMessage}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        onCancel={onCancel}
      />
    </FormControl>
  );
};
