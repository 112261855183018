import { useCookies } from "react-cookie";
import { ACHPaymentDTO } from "../../dtos/achPayments/achPaymentDTO";
import { useAxios } from "./useAxios";

type ApiResponse<T> = {
  data: T[];
}

export function useACHPaymentsAPI() {
  const [cookies] = useCookies(["lynk.access-token", "lynk.partner-id"]);
  const authToken = {
    headers: { Authorization: `Bearer ${cookies["lynk.access-token"]}` },
  };

  const { getApi } = useAxios();

  async function getACHPayments(
    productId: string,
    pageSize: number,
    currentPage: number,
    sort = "createdAt,DESC",
    query = ""
  ): Promise<ApiResponse<ACHPaymentDTO>> {
    return await getApi(
      `/products/${productId}/payments/ach?size=${pageSize}&page=${currentPage}&sort=${sort}${query}`,
      authToken,
      false
    );
  }

  return {
    getACHPayments,
  };
}
