import { useCookies } from "react-cookie";
import { PartnerUserDTO } from "../../dtos/partnerUsers/partnerUserDTO";
import { PartnerUserInviteDTO } from "../../dtos/partnerUsers/partnerUserInviteDTO";
import { PartnerUserInviteRequestDTO } from "../../dtos/partnerUsers/partnerUserInviteRequestDTO";
import { PartnerUserRequestDTO } from "../../dtos/partnerUsers/partnerUserRequestDTO";
import { PartnerUserUpdateRequestDTO } from "../../dtos/partnerUsers/partnerUserUpdateRequestDTO";
import { useAxios } from "./useAxios";
import { ChangePartnerPasswordRequestDTO } from '../../dtos/auth/changePartnerPasswordRequestDTO';

type ApiResponse<T> = {
  data: T[];
};

export function usePartnerUsersAPI() {
  const [cookies] = useCookies(['lynk.access-token', 'lynk.partner-id']);
  const authToken = {
    headers: { Authorization: `Bearer ${cookies['lynk.access-token']}` },
  };

  const { getApi, postApi, patchApi, deleteApi } = useAxios();

  async function getPartnerUsersList(
    pageSize = 100,
    currentPage = 0,
    sort = 'createdAt,DESC',
    query = '',
  ): Promise<ApiResponse<PartnerUserDTO>> {
    return getApi(
      `/partner_users?size=${pageSize}&page=${currentPage}&sort=${sort}${query}`,
      authToken,
      false,
    );
  }

  async function getPartnerUserById(partnerUserId: string): Promise<PartnerUserDTO> {
    return getApi(`/partner_users/${partnerUserId}`, authToken, false, true);
  }

  async function updatePartnerUserById(
    partnerUserId: string,
    data: PartnerUserUpdateRequestDTO,
  ): Promise<PartnerUserDTO> {
    return patchApi(`/partner_users/${partnerUserId}`, data, authToken, undefined, false);
  }

  async function deletePartnerUserById(partnerUserId: string) {
    return deleteApi(`/partner_users/${partnerUserId}`, authToken);
  }

  async function postPartnersInvite(
    data: PartnerUserInviteRequestDTO,
  ): Promise<PartnerUserInviteDTO> {
    return postApi(`/partner_users/invite`, data, authToken);
  }

  async function removePartnersInvite(
    id: string,
  ): Promise<PartnerUserInviteDTO> {
    return deleteApi(`/partner_users/invite/${id}`, authToken);
  }

  async function postPartnerUser(
    data: PartnerUserRequestDTO,
    throwError = false,
  ): Promise<PartnerUserDTO> {
    return postApi(`/partner_users`, data, {}, undefined, false, throwError);
  }

  async function postPartnerUserAvatar(id: string, data: any): Promise<PartnerUserDTO> {
    const params = {
      headers: {
        Authorization: `Bearer ${cookies['lynk.access-token']}`,
        'Content-Type': 'multipart/form-data',
      },
    };
    return postApi(`/partner_users/${id}/avatar`, data, params);
  }

  async function removePartnerUserAvatar(id: string): Promise<PartnerUserDTO> {
    return deleteApi(`/partner_users/${id}/avatar`, authToken);
  }

  async function postChangePartnerPassword(
    partnerId: string,
    data: ChangePartnerPasswordRequestDTO,
    callBackError?: (error: any) => void,
  ) {
    return patchApi(
      `/auth/partner_users/${partnerId}/change_password`,
      data,
      authToken,
      callBackError,
      false,
    );
  }

  return {
    postPartnerUser,
    postPartnerUserAvatar,
    removePartnerUserAvatar,
    updatePartnerUserById,
    getPartnerUsersList,
    getPartnerUserById,
    deletePartnerUserById,
    postPartnersInvite,
    removePartnersInvite,
    postChangePartnerPassword,
  };
}
