import React, { FC, useEffect, useState } from "react";
import { Box, TablePagination } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { color } from "../../../consts/ColorConst";
import {
  GridFooterContainer,
  gridPageCountSelector,
  gridPageSelector,
  gridPageSizeSelector,
  gridRowCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { TableFooterProps } from "./types";

const useStyles = makeStyles(() => ({
  root: {
    boxSizing: 'border-box',
    width: '100%',
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 12px',
    backgroundColor: color.WHITE_01,
    '& .MuiTablePagination-select': {
      color: `${color.GREEN_DARK_1} !important`,
      '& path': {
        fill: `${color.GREEN_LIGHT_4} !important`,
      },
    },
    '& .MuiIconButton-root': {
      color: `${color.GREEN_DARK_1} !important`,
    },
  },
  actions: {
    color: `${color.GREEN_DARK_1} !important`,
    padding: 0,
    '& .Mui-disabled': {
      color: `${color.GREEN_LIGHT_4} !important`,
    },
  },
  select: {
    color: `${color.GREEN_DARK_1} !important`,
    padding: 0,
    '& .Mui-disabled': {
      color: `${color.GREEN_LIGHT_4} !important`,
    },
  },
  item: {
    '&.Mui-selected': {
      backgroundColor: `${color.GREEN_LIGHT_4} !important`,
    },
  },
  leftContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 400,
    color: color.GRAY,
  },
  rightButtonWrapper: {
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  iconButton: {
    color: '#333',
  },
}));

export const LynkTableFooter: FC<TableFooterProps> = ({
  pageSizeOptions,
  setPageSize,
}) => {
  const classes = useStyles();
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

  const { t } = useTranslation("translation", { keyPrefix: "table" });

  const [timerStatus, setTimerStatus] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimerStatus(prev => prev + 1);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <GridFooterContainer>
      <div className={classes.root}>
        <Box className={classes.leftContentWrapper}>
          {t('lastUpdated')}
          {`${
            timerStatus < 15
              ? t('aFewSecondsAgo')
              : timerStatus >= 15 && timerStatus < 30
              ? `15 ${t('secondsAgo')}`
              : timerStatus >= 30 && timerStatus < 45
              ? `30 ${t('secondsAgo')}`
              : timerStatus >= 45 && timerStatus < 60
              ? `45 ${t('secondsAgo')}`
              : timerStatus >= 60 && timerStatus < 3600
              ? `${Math.floor(timerStatus / 60)} ${t('minutesAgo')}`
              : timerStatus > 3600 && timerStatus < 24 * 3600
              ? `${Math.floor(timerStatus / 3600)} ${t('hoursAgo')}`
              : `${Math.floor(timerStatus / (24 * 3600))} ${t('daysAgo')}`
          }`}
        </Box>
        <Box className={classes.rightButtonWrapper}>
          <TablePagination
            classes={{
              actions: classes.actions,
              selectRoot: classes.select,
              menuItem: classes.item,
            }}
            component='div'
            align='right'
            showFirstButton
            showLastButton
            page={page}
            count={rowCount}
            rowsPerPage={pageSize}
            hidden={pageCount === 0}
            rowsPerPageOptions={pageSizeOptions}
            onPageChange={(event, value) => apiRef.current.setPage(value)}
            onRowsPerPageChange={(event) => setPageSize(Number(event.target.value))}
            variant='footer'
            color={color.GREEN_DARK_1}
          />
        </Box>
      </div>
    </GridFooterContainer>
  );
};
