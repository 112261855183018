import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faDownload, faXmarkHexagon } from '@fortawesome/pro-regular-svg-icons';
import { color } from '../../../consts/ColorConst';
import { KarteraButton } from '../../../components/kartera/button';
import { KarteraBackdrop } from '../../../components/kartera/backdrop';
import { SelectCustomer } from './selectCustomer';
import { SelectTransaction } from './selectTransaction';
import { SelectAmount } from './selectAmount';
import { SendRefund } from './sendRefund';
import { ProductUserSearchDTO } from '../../../dtos/productUsers/productUserSearchDTO';
import { useApi } from '../../../services/api/hooks';
import { SendingLoading } from './sendingLoading';
import { KarteraDialogue } from '../../../components/kartera/dialogue';
import { PaymentDTO } from '../../../dtos/payments/paymentDTO';

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: 'white',
    width: 360,
    padding: 22,
    borderRadius: 10,
    boxShadow: theme.shadow.elevation16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelButtonWrapper: {
    padding: '0 12px 0 0',
  },
  panelWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '6px 0',
    gap: 8,
    '& p': {
      fontSize: 14,
      fontWeight: 400,
      color: color.GREY_DARK_4,
    }
  },
  errorPanelText: {
    '& strong': {
      color: color.GREEN,
      fontWeight: 400,
    }
  }
}));

type Props = {
  onClose: () => void;
};

export function CreateRefund({ onClose }: Props) {
  const classes = useStyles();
  const modalRef = useRef(null);
  const { createRefund } = useApi();
  const { product_id } = useParams();
  const { t } = useTranslation('translation', { keyPrefix: 'refunds' });

  const [step, setStep] = useState(1);
  const [customer, setCustomer] = useState<ProductUserSearchDTO>();
  const [transaction, setTransaction] = useState<PaymentDTO>();
  const [amount, setAmount] = useState<string>();
  const [noAmountError, setNoAmountError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [hasError, setHasError] = useState(false);

  function handleNextStep() {
    setStep((prev) => prev + 1);
  }

  function handleTransaction(transaction: PaymentDTO) {
    setTransaction(transaction);
    setAmount(undefined);
    setNoAmountError(false);
  }

  async function handleSubmitRefund() {
    try {
      setIsLoading(true);
      if (transaction && amount && product_id) {
        await createRefund(product_id, {
          speed: 'STANDARD',
          amount: amount,
          description: transaction?.description,
          linked_account_id: transaction.linked_account_id,
          product_user_id: transaction.user.id,
          in_payment_id: transaction.id,
        });
      }
      setShowSuccessDialog(true);
    } catch (e: any) {
      setShowErrorDialog(true);
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading && customer) {
    return <SendingLoading customer={customer} />;
  }

  if (showSuccessDialog) {
    return (
      <KarteraDialogue
        title={t('successPanelTitle')}
        titleIcon={<FontAwesomeIcon icon={faCheckCircle} color={color.GREEN_DARK_2} size={'xl'} />}
        size='small'
        activeButtonType='primary'
        onCancelClick={onClose}
        actionButtonText={t('close')}
        onActionClick={onClose}
        cancelButtonText={t('downloadStatement')}
        cancelButtonIcon={<FontAwesomeIcon icon={faDownload} />}
        autoHeight
      >
        <div className={classes.panelWrapper}>
          <p>
            <Trans>
              {t('successPanelP1', {
                amount: amount,
                customer: `${customer?.first_name} ${customer?.last_name}`,
              })}
            </Trans>
          </p>
          <p>
            <Trans>{t('successPanelP2Bank')}</Trans>
          </p>
        </div>
      </KarteraDialogue>
    );
  }

  if (showErrorDialog) {
    return (
      <KarteraDialogue
        title={t('errorPanelTitle')}
        titleIcon={<FontAwesomeIcon icon={faXmarkHexagon} color={color.RED_DARK_1} size={'xl'} />}
        size='small'
        activeButtonType='primary'
        onCancelClick={onClose}
        actionButtonText={t('close')}
        onActionClick={onClose}
        onSecondaryButtonClick={handleSubmitRefund}
        cancelButtonText={t('tryAgain')}
        autoHeight
      >
        <div className={classes.panelWrapper}>
          <p>
            <Trans>{t('errorPanelP1')}</Trans>
          </p>
          <p className={classes.errorPanelText}>
            <Trans>{t('errorPanelP2')}</Trans>
          </p>
        </div>
      </KarteraDialogue>
    );
  }

  return (
    <KarteraBackdrop transparent>
      <Box className={classes.root} ref={modalRef}>
        {step === 1 ? (
          <SelectCustomer
            setCustomer={(user: any) => setCustomer(user)}
            nextStep={handleNextStep}
          />
        ) : step === 2 && customer ? (
          <SelectTransaction
            customer={customer}
            setTransaction={handleTransaction}
            nextStep={handleNextStep}
          />
        ) : step === 3 && customer && transaction ? (
          <SelectAmount
            customer={customer}
            transaction={transaction}
            setPartialAmount={setAmount}
            setHasError={setHasError}
            hasError={hasError}
          />
        ) : step === 4 && customer && transaction && amount ? (
          <SendRefund customer={customer} transaction={transaction} amount={amount} />
        ) : null}
        <Box className={classes.buttonsWrapper}>
          <Box className={classes.cancelButtonWrapper}>
            {step > 1 && (
              <KarteraButton
                text={t('back')}
                variant='secondary'
                onClick={() => setStep((prev) => prev - 1)}
              />
            )}
          </Box>
          {step === 4 ? (
            <KarteraButton text={t('send')} onClick={handleSubmitRefund} variant='primary' />
          ) : (
            <KarteraButton
              text={step > 2 ? t('next') : t('close')}
              onClick={() => {
                if (step > 2) {
                  if (!amount) return setNoAmountError(true);
                  return handleNextStep();
                }
                onClose();
              }}
              variant='primary'
              disabled={hasError}
            />
          )}
        </Box>
      </Box>
    </KarteraBackdrop>
  );
}
