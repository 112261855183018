import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
    isDemoAccount: false,
    shouldShowMockData: false,
    shouldShowOnboardingDialog: false,
};

const demoAccountSlice = createSlice({
    name: 'demoAccount',
    initialState,
    reducers: {
        setIsDemoAccount: (state, action: PayloadAction<boolean>) => {
            state.isDemoAccount = action.payload;
        },
        setShouldShowMockData: (state, action: PayloadAction<boolean>) => {
            state.shouldShowMockData = action.payload;
        },
        setShouldShowOnboardingDialog: (state, action: PayloadAction<boolean>) => {
            state.shouldShowOnboardingDialog = action.payload;
        }
    },
});

export const { 
    setIsDemoAccount,
    setShouldShowMockData,
    setShouldShowOnboardingDialog,
} = demoAccountSlice.actions;

export default demoAccountSlice.reducer;