import { useCookies } from "react-cookie";
import { useAxios } from "./useAxios";
import { LinkedAccountDTO } from "../../dtos/linkedAccounts/linkedAccountDTO";

type ApiResponse<T> = {
  data: T[];
}

export function useLinkedAccountAPI() {
  const [cookies] = useCookies(["lynk.access-token", "lynk.partner-id"]);
  const authToken = {
    headers: { Authorization: `Bearer ${cookies["lynk.access-token"]}` },
  };

  const { getApi } = useAxios();

  async function getLinkedAccounts(
    productId: string,
    userId = '',
    productOwned = false,
    pageSize = 20,
    currentPage = 0,
    sort = "createdAt,DESC",
  ): Promise<ApiResponse<LinkedAccountDTO>> {
    return getApi(
      `/products/${productId}/linked_accounts?user_id=${userId}` +
      `&product_owned=${productOwned}&size=${pageSize}&page=${currentPage}&sort=${sort}`,
      authToken,
      false
    );
  }


  return {
    getLinkedAccounts,
  };
}
