import { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, MenuItem, Select } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { sentenceCaseGenerator } from '../../../utils/helperFunctions';
import { color } from '../../../consts/ColorConst';
import { HeadlineProps } from './types';

const useStyles = makeStyles((theme: any) => ({
  root: {
    fontFamily: 'Roboto',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    '& .Mui-focused': {
      backgroundColor: color.GREY_LIGHT_2,
      border: `1.5px solid ${color.GREY_LIGHT_2}`,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .Mui-disabled': {
      backgroundColor: color.GREY_LIGHT_6,
      color: color.GREY_DARK_4,
      '&:hover': {
        backgroundColor: color.GREY_LIGHT_6,
        borderColor: color.GREY_LIGHT_6,
        boxShadow: 'none',
      },
      '& > svg': {
        color: color.GREY_DARK_4,
      },
    },
  },
  menuItemRoot: {
    padding: '0px 14px',
    fontSize: 14,
    '&$menuItemSelected, &$menuItemSelected:focus, &$menuItemSelected:hover': {
      backgroundColor: color.GREY_LIGHT_4,
    },
  },
  menuItemSelected: {},
  selectStyle: {
    height: '28px',
    width: '100%',
    backgroundColor: color.GREY_LIGHT_4,
    color: color.GREY_DARK_4,
    border: `1.5px solid ${color.GREY_LIGHT_4}`,
    borderRadius: 6,
    '& .MuiInputBase-input': {
      padding: '0 0 0 10px',
      '&::placeholder': {
        color: color.GREY_DARK_4,
      },
    },
    '& .MuiSelect-select': {
      fontSize: '14px',
      fontWeight: 500,
      color: color.GREY_DARK_4,
    },
    '& > svg': {
      color: color.GREY_DARK_4,
    },
    '&:hover': {
      backgroundColor: color.GREY_LIGHT_3,
      borderColor: color.GREY_LIGHT_3,
      boxShadow: theme.shadow.elevation4Grey,
    },
  },
  placeholder: {
    color: color.GREY_DARK_4,
  },
  hasError: {
    backgroundColor: color.RED_LIGHT_5,
    borderColor: color.RED,
    '& .MuiInputBase-input': {
      color: color.RED_DARK_3,
      '&::placeholder': {
        color: color.GREY_DARK_4,
      },
    },
    '& > svg': {
      color: color.RED_DARK_2,
    },
  },
  errorMessage: {
    margin: '2px 0 0 0',
    fontFamily: 'inherit',
    fontSize: '11px',
    color: color.RED,
  },
  subNote: {
    margin: '2px 0 0 0',
    fontSize: '10px',
    letterSpacing: 0.3,
    color: color.GREY_DARK_1,
  },
  label: {
    display: 'block',
    fontSize: 26,
    fontWeight: 700,
    letterSpacing: 0.4,
    marginBottom: 4,
    color: color.GREY_DARK_4,
  },
  title: {
    marginBottom: 12,
    color: color.GREY_DARK_4,
  },
  disabled: {
    color: color.GREY_DARK_4,
  },
}));

export const KarteraHeadlineMenu: FC<HeadlineProps> = ({
  placeholder,
  width = '100%',
  errorMessage,
  error,
  subNote,
  items,
  label,
  labelStyle = 'title',
  sx,
  disabled,
  onChange,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} sx={{ ...sx, width: width }}>
      {label && (
        <label
          className={`
            ${classes.label} 
            ${labelStyle === 'title' && classes.title}
            ${disabled && classes.disabled}
          `}
        >
          {label}
        </label>
      )}
      <Select
        displayEmpty
        IconComponent={KeyboardArrowDown}
        className={`
          ${classes.selectStyle} 
          ${(!!errorMessage || error) && classes.hasError}
        `}
        error={!!errorMessage}
        MenuProps={{
          PaperProps: {
            style: {
              marginTop: 4,
              maxHeight: 300,
              width: width,
            },
          },
        }}
        renderValue={(value) => {
          if (!value && placeholder) {
            return <span className={classes.placeholder}>{placeholder}</span>;
          }
          const selectedItem = items.find((item) => item.value === value);
          return selectedItem?.text;
        }}
        disabled={disabled}
        {...rest}
        onChange={onChange}
      >
        {items.map((item) => (
          <MenuItem
            dense
            key={`select-item-${item.text}`}
            classes={{
              root: classes.menuItemRoot,
              selected: classes.menuItemSelected,
            }}
            value={item.value}
            disabled={item.disabled}
          >
            {item.text}
          </MenuItem>
        ))}
      </Select>
      {errorMessage && (
        <p className={classes.errorMessage}>{sentenceCaseGenerator(errorMessage)}</p>
      )}
      {subNote && <p className={classes.subNote}>{sentenceCaseGenerator(subNote)}</p>}
    </Box>
  );
};
