import React, { FC } from 'react';
import { Avatar as MaterialAvatar, Box, Theme } from '@mui/material';
import { AvatarProps, AvatarStyle } from './types';
import { makeStyles } from '@mui/styles';
import { color as themeColors } from '../../../consts/ColorConst';

const colors = {
  primary: {
    main: themeColors.GREEN_LIGHT_1,
    hover: themeColors.GREEN_DARK_1,
    active: themeColors.GREEN_DARK_2,
    color: 'white',
  },
  secondary: {
    main: themeColors.SECONDARY,
    hover: themeColors.SECONDARY_DARK_01,
    active: themeColors.SECONDARY_DARK_01,
    color: themeColors.GREEN_DARK_03,
  },
  tertiary: {
    main: 'white',
    hover: 'white',
    active: 'white',
    color: themeColors.GRAY_DARK_01,
    hoverColor: themeColors.GRAY_DARK_05,
    activeColor: themeColors.GREEN_DARK_03,
  },
};

const useStyles = makeStyles<Theme, AvatarStyle>(() => ({
  avatarContaine: {
    '& .MuiAvatar-root': {
      color: ({ color, pressed }) =>
        color === 'tertiary' && pressed ? colors[color].activeColor : colors[color].color,
      backgroundColor: ({ color, pressed }) =>
        pressed ? colors[color].active : colors[color].main,
      opacity: ({ disabled }) => disabled && 0.5,
      '&:hover': {
        backgroundColor: ({ color }) => colors[color].hover,
        color: ({ color }) => color === 'tertiary' && colors[color].hoverColor,
      },
    },
  },
}));

export const KarteraAvatar: FC<AvatarProps> = ({
  alt,
  src,
  size,
  icon,
  variant,
  id,
  color = 'primary',
  disabled = false,
  pressed = false,
  srcType = 'base64',
}) => {
  const classes = useStyles({
    color: color,
    disabled: disabled,
    pressed: pressed,
  });

  function getAvatarImage() {
    if (!src) return '';

    return srcType === 'base64' && src ? `data:image/png;base64, ${src}` : src;
  }

  return (
    <Box className={classes.avatarContaine}>
      {icon ? (
        <MaterialAvatar data-testid={id} variant={variant} alt={alt} sx={size}>
          {icon}
        </MaterialAvatar>
      ) : (
        <MaterialAvatar
          data-testid={id}
          variant={variant}
          src={getAvatarImage()}
          alt={alt}
          sx={size}
        ></MaterialAvatar>
      )}
    </Box>
  );
};
