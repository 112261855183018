import { Suspense, useEffect, useState } from 'react';
import Loader from './components/loader';
import { Intercom } from './components/intercom';
import { Router } from './Router';
import { KarteraAppBanner } from './components/kartera/appBanner';
import './App.css';

const App = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);

  useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    fetch('/version.json')
      .then((response) => response.json())
      .then((data) => {
        const localVersion = localStorage.getItem('appVersion');
        if (localVersion !== data.version && !!localVersion) {
          setIsUpdateAvailable(true);
          localStorage.setItem('appVersion', data.version);
        }
        if (!localVersion) {
          localStorage.setItem('appVersion', data.version);
        }
      });

    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  }, []);

  function refreshPage() {
    window.location.reload();
  }

  return (
    <Intercom>
      <Loader />
      {isUpdateAvailable && (
        <KarteraAppBanner 
          severity='warning'
          text="A new version of the app is available."  
          buttonText='Refresh'
          onClick={refreshPage}
        />
      )}
      <Suspense fallback={null}>
        <Router isOnline={isOnline} />
      </Suspense>
    </Intercom>
  );
};

export default App;
