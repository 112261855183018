import { useCookies } from "react-cookie";
import { ProductWebhookDTO } from "../../dtos/webhooks/productWebhookDTO";
import { ProductWebhookRequestDTO, ProductWebhookUpdateRequestDTO } from "../../dtos/webhooks/productWebhookRequestDTO";
import { useAxios } from "./useAxios";

type ApiResponse<T> = {
  data: T[];
}

export function useWebhooksAPI() {
  const [cookies] = useCookies(["lynk.access-token", "lynk.partner-id"]);
  const authToken = {
    headers: { Authorization: `Bearer ${cookies["lynk.access-token"]}` },
  };

  const { getApi, postApi, patchApi, deleteApi } = useAxios();

  async function getProductWebhooks(
    productId: string,
    pageSize = 20,
    currentPage = 0,
    sort = "createdAt,DESC"
  ): Promise<ApiResponse<ProductWebhookDTO>> {
    return await getApi(
      `/products/${productId}/webhooks?size=${pageSize}&page=${currentPage}&sort=${sort}`,
      authToken, 
      false
    );
  }

  async function createProductWebhook(
    productId: string,
    data: ProductWebhookRequestDTO,
    callBackError?: (error: { message: string }) => void,
  ): Promise<ProductWebhookDTO> {
    return await postApi(`/products/${productId}/webhooks`, data, authToken, callBackError);
  }

  async function updateProductWebhook(
    productId: string,
    id: string,
    data: ProductWebhookUpdateRequestDTO,
    callBackError?: (error: { message: string }) => void,
  ): Promise<ProductWebhookDTO> {
    return await patchApi(`/products/${productId}/webhooks/${id}`, data, authToken, callBackError);
  }

  async function removeProductWebhook(productId: string, id: string) {
    return await deleteApi(
      `/products/${productId}/webhooks/${id}`,
      authToken
    );
  }
 
  return {
    getProductWebhooks,
    createProductWebhook,
    updateProductWebhook,
    removeProductWebhook,
  };
}
