import React, { FC } from "react";
import { Navbar } from "../navbar/navbar";
import { LayoutProps } from "./types";

export const Layout: FC<LayoutProps> = ({
  headerTitle,
  publicRoute,
  children,
}) => {
  return (
    <>
      <Navbar headerTitle={headerTitle} publicRoute={publicRoute} />
      {children}
    </>
  );
};
