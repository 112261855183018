import { FC, useState } from "react";
import { NumericFormat } from "react-number-format";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/pro-regular-svg-icons";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "@mui/material";
import { color } from "../../../consts/ColorConst";
import { KarteraTextField } from "../textField";
import { TextFieldProps } from "./types";
import { KarteraButton } from "../button";

const useStyles = makeStyles(() => ({
  root: {
    "& .Mui-focused": {
      backgroundColor: color.WHITE_01,
      border: `1.5px solid ${color.GREEN}`,
    },
  },
  icon: {
    height: "11px",
    color: color.GREY_LIGHT_2,
    "&:hover": {
      color: color.GREY,
    },
  },
  button: {
    padding: 0,
    fontSize: 12,
    textTransform: "none",
  },
  label: {
    display: 'block',
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.4,
    marginBottom: 4,
    color: color.GREY_DARK_1,
  },
  title: {
    marginBottom: 12,
    color: color.GREY_DARK_4,
  },
  labelDisabled: {
    color: color.GREY_LIGHT_2,
  },
  errorMessage: {
    margin: "2px 0 0 2px",
    fontFamily: "inherit",
    fontSize: 11,
    color: color.RED,
  },
  subNote: {
    margin: "2px 0 0 2px",
    fontSize: 10,
    letterSpacing: 0.3,
    color: color.GREY_DARK_1,
  },
}));

export const KarteraCurrencyField: FC<TextFieldProps> = ({
  value,
  decimalScale,
  disabled,
  onRestoreDefaultValue,
  onBlur,
  onCancel,
  onChange,
  placeholder,
  label,
  width,
  errorMessage,
  sx, 
  subNote,
}) => {
  const classes = useStyles();
  const [onFocus, setOnFocus] = useState(false);
  const [onHover, setOnHover] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [onCancelButtomFocus, setOnCancelButtonFocus] = useState(false);

  function handleBlur(e: any) {
    setOnFocus(false);
    if (onBlur) onBlur(e);
  }

  function handleChange(e: any) {
    setIsDirty(true);
    if (onChange) onChange(e);
  }

  function handleRestore() {
    setIsDirty(false);
    if (onRestoreDefaultValue) onRestoreDefaultValue();
  }

  function handleCancel(e: any) {
    setIsDirty(true);
    if (onCancel) onCancel(e);
  }

  return (
    <div className={classes.root} style={{ ...sx, width }}>
      {label && (
        <label 
          className={`
            ${classes.label} 
            ${disabled && classes.labelDisabled}
          `} 
        >
          {label}
        </label>
      )}
      <NumericFormat 
        allowLeadingZeros 
        thousandSeparator="," 
        decimalSeparator="."
        fixedDecimalScale
        decimalScale={decimalScale}
        width={width}
        error={!!errorMessage}
        placeholder={placeholder}
        customInput={KarteraTextField}
        onMouseOver={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
        disabled={disabled}
        startAdornment={(
          <FontAwesomeIcon 
            icon={faDollarSign} 
            fontSize={14}
            style={{ padding: '0 6px 2px 0' }}
            color={errorMessage ? color.RED_DARK_2 : color.GREY_LIGHT_1}
          />
        )}
        endAdornment={(onFocus || onCancelButtomFocus) ? (
          <IconButton
            aria-label="clear button"
            edge="end"
            onMouseOver={() => setOnCancelButtonFocus(true)}
            onClick={handleCancel}
            onMouseLeave={() => setOnCancelButtonFocus(false)}
            tabIndex={-1}
            disabled={disabled}
            disableRipple
          >
            <FontAwesomeIcon icon={faXmarkCircle} className={classes.icon} />
          </IconButton>
        ) : (isDirty && onHover && onRestoreDefaultValue) && (
            <KarteraButton
              aria-label="restore button"
              className={classes.button}
              onClick={handleRestore}
              disabled={disabled}
              variant="tertiary"
              text="restore"
            />
        )}
        value={value}
        onChange={handleChange}
        onFocus={() => setOnFocus(true)}
        onBlur={handleBlur}
        variant="default"
      />
      {errorMessage && (
        <p className={classes.errorMessage}>
          {errorMessage}
        </p>
      )}
      {subNote && <p className={classes.subNote}>{subNote}</p>}
    </div>
  );
};
