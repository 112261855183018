import { FormEvent, useState, useRef, useEffect } from "react";
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { useCookies } from "react-cookie";
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { useApi } from '../../../services/api/hooks';
import { useAuth } from '../../../context/Auth';
import { AppRootState } from '../../../reducers';
import { handleOnlyChar } from '../../../utils/handleValuesHelper';
import { PartnerRequestDTO } from '../../../dtos/partners/partnerRequestDTO';
import { KarteraButton } from '../../../components/kartera/button';
import { KarteraTextField } from '../../../components/kartera/textField';
import { KarteraBackdrop } from "../../../components/kartera/backdrop";
import { color } from "../../../consts/ColorConst";
import { useOutSideClick } from "../../../hooks/useOUtsideClick";
import { PartnerStatus } from "../../../consts/PartnerStatus";
import { setBusinessName, setIdempotencyId } from "../../../reducers/onboardingReducers";

const useStyles = makeStyles({
  formContainer: {
    width: 400,
    backgroundColor: color.WHITE_01,
    padding: '20px 24px',
    borderRadius: 10,
    '& > input': {
      fontSize: 14,
    },
  },
  headerContainer: {
    marginBottom: 22,
    '& > h3': {
      fontSize: 20,
      fontWeight: 500,
      letterSpacing: '0.2px',
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 34,
  },
});

const reduxConnector = connect(
  (state: AppRootState) => ({
    businessName: state.onboardingState.businessName,
    idempotencyId:state.onboardingState.idempotencyId
  }));

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;

type Props = PropsFromRedux & {
  closeOnboarding: () => void;
};

function SandboxOnboarding({ businessName,idempotencyId, closeOnboarding }: Props) {
  const classes = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'sandboxOnboarding' });
  const { registerPartner } = useAuth();
  const { postPartner } = useApi();
  const dispatch = useDispatch();
  const [cookies] = useCookies(['lynk.user-name']);
  const modalRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  document.title = 'Kartera - Onboarding';

  async function onHandleSubmit(e: FormEvent) {
    e.preventDefault();
    const [firstName, lastName] = cookies['lynk.user-name'].split(' ');
    const data: PartnerRequestDTO = {
      legal_business_name: businessName,
      business_id_type: 'EIN',
      business_id: '12-3456789',
      description: 'Sandbox Demo Account',
      incorporation_date: '2020-01-01',
      incorporation_country_iso_code: 'US',
      incorporation_state_province_code: 'NY',
      legal_structure: 'C_CORPORATION',
      business_category: 'other',
      phone_country_code: 'US',
      phone_number: '989-878-6754',
      url: 'https://www.example.com',
      idempotency_id: idempotencyId,
      physical_address: {
        street: 'Demo',
        city: 'Demo',
        state: 'CA',
        unit_number: '',
        postal_code: '89878',
        country: 'USA',
      },
      owner: {
        legal_first_name: firstName,
        legal_last_name: lastName,
        passport_number: '123',
        passport_country_code: 'US',
        national_id_type: 'SOCIAL_SECURITY_NUMBER',
        national_id: '574-12-1233',
        citizenship_country_iso_code: 'US',
        date_of_birth: '1990-01-01',
        phone_country_code: 'US',
        phone_number: '989-878-6754',
        gender: 'M',
        relationship_to_company: 'CEO',
        physical_address: {
          street: 'Demo',
          city: 'Demo',
          state: 'CA',
          postal_code: '89878',
          country: 'USA',
          unit_number: '',
        },
      },
    };
    try {
      setIsLoading(true);
      const response = await postPartner(data);
      const { id, verification_status, legal_business_name } = response;
      if (id) {
        registerPartner(id, legal_business_name, verification_status === PartnerStatus.VERIFIED);
      }
      closeOnboarding();
    } catch (error) {
      closeOnboarding();
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(()=>{
    idempotencyId==='' && dispatch(setIdempotencyId(uuidv4()))
  },[])

  useOutSideClick(modalRef, () => closeOnboarding());

  const buttonEnabled = businessName !== '';

  return (
    <KarteraBackdrop>
      <form className={classes.formContainer} onSubmit={onHandleSubmit} ref={modalRef}>
        <div className={classes.headerContainer}>
          <h3>{t('incorporationTitle')}</h3>
        </div>
        <KarteraTextField
          type='text'
          fullWidth
          required
          label={t('businessNameLabel')}
          placeholder={t('businessNamePlaceholder')}
          value={businessName}
          onChange={(e) =>
            handleOnlyChar(e.target.value, (value: string) =>
              dispatch(setBusinessName(value)),
            )
          }
          subNote={t('businessNameNote')}
        />
        <div className={classes.buttonWrapper}>
          <KarteraButton
            text={t('submitButton')}
            disabled={!buttonEnabled || isLoading}
            onClick={onHandleSubmit}
          />
        </div>
      </form>
    </KarteraBackdrop>
  );
}

export default reduxConnector(SandboxOnboarding);
