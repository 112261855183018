import React, { useCallback, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../services/api/hooks';
import {
  emailIncludesPlusSign,
  isEmailDomainAllowed,
  isEmailValid,
} from '../../utils/handleValuesHelper';
import { color } from '../../consts/ColorConst';
import { KarteraTextField } from '../../components/kartera/textField';
import { KarteraButton } from '../../components/kartera/button';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: color.GREY_DARK_4,
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: 400,
    borderRadius: 10,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadow.elevation16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '54px 36px',
  },
  sentContainer: {
    padding: '54px 32px 28px',
  },
  sentWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  resendWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    gap: 6,
  },
  resendText: {
    fontSize: 14,
    color: color.GREY_DARK_1,
    fontWeight: 400,
  },
  email: {
    color: color.GREEN,
    fontSize: 14,
    fontWeight: 400,
    textDecoration: 'none',
  },
  resendButton: {
    fontSize: 14,
    fontWeight: 500,
    color: color.GREEN_DARK_1,
    cursor: 'pointer',
  },
  resetPasswordHeader: {
    fontSize: 26,
    fontWeight: 700,
  },
  pageDescription: {
    padding: '16px 0 28px',
    fontSize: 14,
    lineHeight: 1.2,
  },
  resetPasswordForm: {
    width: '100%',
    padding: '0 0 22px 0',
    display: 'flex',
    justifyContent: 'center',
  },
  loadingWrapper: {
    padding: '0 0 20px 0',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonWrapper: {
    width: '200px',
    display: 'flex',
    justifyContent: 'space-between',
    gap: 4,
    '& button': {
      padding: '8px 14px',
    }
  },
  errorMessage: {
    color: '#FF2559',
    fontSize: 12,
    padding: '0 0 10px 0',
  },
  message: {
    backgroundColor: '#d7faf0',
    marginTop: '-16px',
    fontSize: '13px',
    color: '#2e886f',
    padding: 12,
    borderRadius: '6px',
  },
}));

export const ResetPassword: React.FC = () => {
  const classes = useStyles();
  const { postResetPassword } = useApi();
  const { t } = useTranslation('translation', { keyPrefix: 'resetPassword' });

  const [email, setEmail] = useState('');
  const [errorMessages, setErrorMessages] = useState({ email: '' });
  const [hasError, setHasError] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailResent, setEmailResent] = useState(false);
  const navigate = useNavigate();

  document.title = 'Kartera - Reset Password';

  useEffect(() => {
    setHasError(!!Object.values(errorMessages).find((msg) => msg !== ''));
  }, [errorMessages]);

  useEffect(() => {
    email && setEmailSent(false);
  }, [email]);

  const onEmailChange = useCallback((e) => setEmail(e.target.value), [setEmail]);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      try {
        event.preventDefault();
        const response = await postResetPassword({
          email,
          ui_host_url: `${window.location.protocol}//${window.location.host}`,
        });
        if (response.status === 400) {
          setEmailSent(false);
          return;
        }
        setEmailSent(true);
      } catch (error) {
        setErrorMessages((prev) => ({ ...prev, email: t('errorMsgInvalidEmail') }));
        setEmailSent(false);
      }
    },
    [email],
  );

  const emailValidation = () => {
    if (!email) return;
    let errorMessage = '';
    if (!isEmailValid(email)) errorMessage = t('errorMsgInvalidEmail');
    if (!isEmailDomainAllowed(email)) errorMessage = t('errorMsgWorkEmail');
    if (emailIncludesPlusSign(email)) errorMessage = t('errorMsgEmailPlusSign');
    setErrorMessages((old) => ({ ...old, email: errorMessage }));
  };

  function clearEmail() {
    setEmail('');
    setErrorMessages({ email: ''});
  }

  function resendEmail(e: any) {
    setEmailResent(true);
    handleSubmit(e) as any;
  }

  const buttonDisabled = hasError || !email;
  return (
    <Box data-testid='reset-password-page-container' className={classes.root}>
      {emailSent ? (
        <Box className={`${classes.container} ${classes.sentContainer}`}>
          <Box className={classes.sentWrapper}>
            <FontAwesomeIcon
              icon={faPaperPlane}
              fontSize={22}
            />
            <Typography className={classes.resetPasswordHeader}>
              { emailResent ? t('resendTitle') : t('checkTitle')}
            </Typography>
          </Box>
          <Typography className={classes.pageDescription}>
            { emailResent ? t('resendMessage', { email }) : t('checkDescription', { email })}
          </Typography>
          <Box className={classes.resendWrapper}>
            <Typography className={classes.resendText}>
              { emailResent ? t('resendMessage2') : t('resendText')}
            </Typography>
              { emailResent ? (
                <a className={classes.email} href="mailto:support@kartera.com" >
                  support@kartera.com
                </a>
              ) : (
                <Typography className={classes.resendButton} onClick={ resendEmail }>
                  {t('resendButton')}
                </Typography>
              )}
          </Box>
        </Box>
      ) : (
        <form className={classes.container}>
          <Typography className={classes.resetPasswordHeader}>{t('pageTitle')}</Typography>
          <Typography className={classes.pageDescription}>{t('description')}</Typography>
          <Box className={classes.resetPasswordForm}>
            <KarteraTextField
              type='email'
              placeholder={t('emailPlaceholder')}
              value={email}
              onCancel={clearEmail}
              onChange={onEmailChange}
              onBlur={() => emailValidation()}
              errorMessage={errorMessages.email}
              spacious
            />
          </Box>
          <Box className={classes.buttonWrapper}>
            <KarteraButton
              text={t('backButtonText')}
              variant={'secondary'}
              onClick={() => navigate('/login')}
              spacious
            />
            <KarteraButton
              text={t('continueButtonText')}
              disabled={buttonDisabled}
              onClick={handleSubmit}
              spacious
            />
          </Box>
        </form>
      )}
    </Box>
  );
};
