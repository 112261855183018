// eslint-disable-next-line
// @ts-nocheck
import { FC, useCallback, useRef, useState } from 'react';
import { Box, Modal, Paper, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@mui/styles';
import { color } from '../../../consts/ColorConst';
import { DialogueProps } from './types';
import { useOutSideClick } from '../../../hooks/useOUtsideClick';
import { KarteraButton } from '../button';
import { faX } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: color.WHITE_01,
    padding: '20px 24px 16px',
    borderRadius: 10,
    boxShadow: theme.shadow.elevation16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontFamily: 'Roboto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  backdropOn: {
    backgroundColor: 'rgba( 0, 0, 0, 0.45)',
  },
  backdropOff: {
    backgroundColor: 'none',
  },
  small: {
    width: 360,
    height: 197,
    fontSize: 14,
  },
  medium: {
    width: 440,
    height: 319,
  },
  large: {
    width: 660,
    height: 555,
  },
  xl: {
    width: 760,
    height: 555,
  },
  titleCloseContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      fontSize: 18,
    },
  },
  titleIconContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    display: 'flex',
  },
  stepsTitle: {
    padding: '0 0 0 4px',
  },
  titleIconWrapper: {
    margin: '0 8px 0 0',
  },
  closeIcon: {
    cursor: 'pointer',
    color: color.GRAY,
  },
  contentcontainer: {
    padding: '12px 0 20px',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  rightButtonsWrapper: {
    display: 'flex',
  },
  cancelButtonWrapper: {
    padding: '0 12px 0 0',
  },
}));

export const KarteraDialogue: FC<DialogueProps> = ({
  id,
  datatestid,
  title,
  titleIcon,
  titleComponent,
  size,
  width,
  actionButtonText,
  activeButtonIcon,
  onActionClick,
  activeButtonType,
  activeButtonDisabled,
  cancelButtonText,
  cancelButtonIcon,
  cancelButtonDisabled,
  onCancelClick,
  onSecondaryButtonClick,
  othersButtonText,
  othersButtonDisabled,
  onOthersClick,
  leftButtonComponent,
  steps,
  children,
  supressPanelTop,
  autoHeight,
  showCloseButton = true,
  supressOutsideClick,
  transparent,
  sx,
}) => {
  const classes = useStyles();
  const [currentStep, setcurrentStep] = useState(1);
  const modalRef = useRef(null);

  const onActionButtonClick = useCallback(() => {
    if (steps) {
      currentStep < steps.length && setcurrentStep(currentStep + 1);
      steps[currentStep - 1].onActionClick && steps[currentStep - 1].onActionClick();
    } else {
      onActionClick && onActionClick();
    }
  }, [steps, currentStep, onActionClick]);

  const onCancelButtonClick = useCallback(() => {
    if (steps) {
      currentStep > 1 && setcurrentStep(currentStep - 1);
      steps[currentStep - 1].onCancelClick && steps[currentStep - 1].onCancelClick();
    } else {
      onCancelClick && onCancelClick();
    }
  }, [steps, currentStep, onCancelClick]);

  useOutSideClick(modalRef, () => !supressOutsideClick && onCancelClick());

  const style = sx || {}
  if (autoHeight) style.height = 'auto !important';
  if (width) style.width = `${width} !important`;

  return (
    <Modal 
      open={true} 
      classes={{backdrop: transparent ? classes.backdropOff: classes.backdropOn }} 
    >
      <Paper
        id={id}
        data-testid={datatestid}
        className={`${classes.root} ${classes[size]}`}
        sx={style}
        ref={modalRef}
      >
        {!supressPanelTop && (
          <Box className={classes.titleCloseContainer}>
            {titleComponent}
            <Box className={classes.titleIconContainer}>
              {titleIcon && <Box className={classes.titleIconWrapper}>{titleIcon}</Box>}
              <Typography className={classes.title}>
                {title}
                {steps && (
                  <Typography
                    className={classes.stepsTitle}
                  >{`(${currentStep}/${steps.length})`}</Typography>
                )}
              </Typography>
            </Box>
            {showCloseButton === true && (
              <FontAwesomeIcon
                onClick={onCancelClick}
                icon={faX}
                style={{
                  color: color.GREY_DARK_1,
                  height: 12,
                  width: 12,
                  cursor: 'pointer',
                }}
              />
            )}
          </Box>
        )}
        <Box className={classes.contentcontainer}>
          {steps ? steps[currentStep - 1].content : children}
        </Box>
        <Box className={classes.buttonsWrapper}>
          <Box className={classes.leftButtonsWrapper}>
            {leftButtonComponent}
            {othersButtonText && (
              <KarteraButton
                id="kartera_other_button"
                text={othersButtonText}
                variant='link'
                onClick={onOthersClick}
                disabled={othersButtonDisabled}
              />
            )}
          </Box>
          <Box className={classes.rightButtonsWrapper}>
            <Box className={classes.cancelButtonWrapper}>
              {(cancelButtonText || (steps && steps[currentStep - 1].cancelButtonText)) && (
                <KarteraButton
                  id="kartera_cancel_button"
                  text={
                    cancelButtonText
                      ? cancelButtonText
                      : steps && steps[currentStep - 1].cancelButtonText
                  }
                  icon={cancelButtonIcon}
                  variant='secondary'
                  onClick={onSecondaryButtonClick ? onSecondaryButtonClick : onCancelButtonClick}
                  disabled={cancelButtonDisabled}
                />
              )}
            </Box>
            {(actionButtonText || (steps && steps[currentStep - 1].actionButtonText)) && (
              <KarteraButton
                id="kartera_action_button"
                text={
                  (steps && steps[currentStep - 1].actionButtonText) ?
                    steps[currentStep - 1].actionButtonText :
                    actionButtonText
                }
                onClick={onActionButtonClick}
                variant={activeButtonType}
                disabled={activeButtonDisabled}
              />
            )}
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};
