import { FC, useState, useEffect } from "react";
import { Breadcrumbs, Box, Link, Typography, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useIntercom } from "react-use-intercom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentQuestion, faGear } from "@fortawesome/pro-regular-svg-icons";
import KarteraLogoBlack from "../../../assets/KarteraLogoBlack.svg";
import KarteraLogoWhite from "../../../assets/KarteraLogoWhite.svg";
import { color } from "../../../consts/ColorConst";
import { useAuth } from "../../../context/Auth";
import UserNav from "../../common/userNav";
import Settings from "../../../pages/settings";
import { AppBarProps } from "./types";
import { KarteraButton } from "../button";

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.main,
    width: "100%",
    padding: "16px 20px",
    position: "sticky",
    top: 0,
    zIndex: 900,
    borderBottom: `1px solid ${color.GREY_LIGHT_4}`,
    [theme.breakpoints.down('md')]: {
      gap: 16,
    },
  },
  adminContainer: {
    backgroundColor: color.GREEN_LIGHT_1,
    color: color.WHITE_01,
    borderBottom: 'none',
  },
  image: {
    heigth: '36px',
    marginRight: 16,
  },
  scrolledContainer: {
    boxShadow: theme.shadow.elevation8,
  },
  innerContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    margin: "0 20px 0 20px",
    position: "relative",
  },
  titleLogoWrapper: {
    display: "flex",
    alignItems: "center",
  },
  rightContent: {
    width: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 24,
  },
}));

export const KarteraAppBar: FC<AppBarProps> = ({ 
  breadcrumbs, 
  isAdmin, 
  isScrolled,
  component,
  isSimpleBar, 
  isPublicRoute,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("translation", { keyPrefix: "navbar" });
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();
  const intercom = useIntercom();
  const [cookies] = useCookies(["lynk.staff-user-id"]);
  const staffUserId = cookies["lynk.staff-user-id"];

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreeen] = useState(false); 

  function handleResize() {
    if (window.innerWidth < 900) {
        setIsSmallScreeen(true);
    } else {
        setIsSmallScreeen(false);
    }
  }
  
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return window.removeEventListener("resize", handleResize);
  }, []);

  const menuProps: any = [
    {
      text: t("menuSettings"),
      icon: <FontAwesomeIcon icon={faGear} />,
      onClick: () => setIsSettingsOpen(true),
      sx: { paddingTop: "10px !important" },
    },
    {
      text: t("menuHelp"),
      icon: <FontAwesomeIcon icon={faCommentQuestion} />,
      onClick: () => intercom.show(),
      divider: true,
      sx: { paddingBottom: "10px !important" },
    },
  ];

  if (!isAdmin && staffUserId) {
    menuProps.push({
      text: t("menuStaffAdmin"),
      onClick: () => navigate("/staff-admin"),
      divider: true,
      sx: { paddingBottom: "10px !important" },
    });
  }

  menuProps.push({
    text: t("menuLogout"),
    onClick: () => logout(),
    sx: { paddingTop: "10px !important" },
  });

  return (
    <Box 
      className={
        `${classes.container} 
         ${isAdmin && classes.adminContainer}
         ${isScrolled && classes.scrolledContainer}
      `}
    >
      <Box className={classes.titleLogoWrapper}>
        {isSimpleBar && (
          <Link href="/">
            <img 
              className={classes.image}
              src={isAdmin ? KarteraLogoWhite : KarteraLogoBlack} 
              alt="Kartera logo"
            />
          </Link>
        )}
        {breadcrumbs && (
          <Breadcrumbs 
            color={isAdmin ? color.GREEN_LIGHT_5 : color.GREEN_LIGHT_2}
            maxItems={isSmallScreen ? 2 : 5 }
            itemsBeforeCollapse={1}
          >
            {breadcrumbs.map( (item, idx) => (
              item.href ? (
                <Link 
                  key={`breadcrumbs-${idx}`} 
                  sx={{ cursor: "pointer" }}
                  underline='none' 
                  color={isAdmin ? color.GREEN_LIGHT_5 : color.GREEN_LIGHT_2}
                  onClick={() => item.href && navigate(item.href)}
                >
                  {item.text}
                </Link>
              )
              : (
                <Typography 
                  key={`breadcrumbs-${idx}`} 
                  color={isAdmin ? color.WHITE_01 : color.GREEN_DARK_1}
                  style={{ fontWeight: 500 }}
                >
                  {item.text}
                </Typography>
              )
            ))}
          </Breadcrumbs>
        )}
      </Box>

      <Box className={classes.rightContent}>
        {component}
        {component && (
          <Divider 
            orientation="vertical" 
            variant="fullWidth" 
            flexItem 
            sx={{ marginRight: 1 }}
          />
        )}
        {isAuthenticated ? (
          <UserNav
            alt="User Avatar"
            variant="circular"
            menuProps={isSimpleBar && !isAdmin ? undefined : menuProps}
            showPanel
          />
          ) : (
            !isPublicRoute && (
              <KarteraButton text={t("menuLogout")} onClick={() => logout()} />
            )
          )}
      </Box>
      {isSettingsOpen && (
        <Settings
          isOpen={isSettingsOpen}
          handleClose={() => setIsSettingsOpen(false)}
        />
      )}
    </Box>
  );
};
