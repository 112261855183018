import { useCookies } from "react-cookie";
import { ProductTransactionDTO } from "../../dtos/transactions/productTransactionDTO";
import { useAxios } from "./useAxios";

type ApiResponse<T> = {
  data: T[];
}

export function useTransactionsAPI() {
  const [cookies] = useCookies(["lynk.access-token", "lynk.partner-id"]);
  const authToken = {
    headers: { Authorization: `Bearer ${cookies["lynk.access-token"]}` },
  };

  const { getApi } = useAxios();

  async function getUserTransactionsByAccount(
    productId: string,
    productUserId: string,
    accountId: string,
    pageSize = 20,
    currentPage = 0,
    sort = "createdAt,DESC",
    query = ""
  ): Promise<ApiResponse<ProductTransactionDTO>> {
    return getApi(
      `/products/${productId}/transactions?user_id=${productUserId}` +
      `&account_id=${accountId}&size=${pageSize}&page=${currentPage}&sort=${sort}${query}`,
      authToken,
      false
    );
  }

  async function getTransactions(
    productId: string,
    pageSize = 20,
    currentPage = 0,
    sort = "createdAt,DESC",
    query = ""
  ): Promise<ApiResponse<ProductTransactionDTO>> {
    return getApi(
      `/products/${productId}/transactions?size=${pageSize}&page=${currentPage}&sort=${sort}${query}`, 
      authToken,
      false
    );
  }

  async function getTransactionsByAccount(
    productId: string,
    accountId: string,
    pageSize = 20,
    currentPage = 0,
    sort = "createdAt,DESC",
    query = ""
  ): Promise<ApiResponse<ProductTransactionDTO>> {
    return getApi(
      `/products/${productId}/transactions?account_id=${accountId}&size=${pageSize}&page=${currentPage}&sort=${sort}${query}`, 
      authToken,
      false
    );
  }

  return {
    getUserTransactionsByAccount,
    getTransactions,
    getTransactionsByAccount,
  };
}
