import { FC } from "react";
import { CheckboxProps } from "./types";
import { Checkbox, FormControlLabel } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { color } from "../../../consts/ColorConst";

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
  },
  root: {
    alignItems: 'flex-start',
    margin: 0,
    padding: 0,
  },
  checkbox: {
    width: 16,
    height: 16,
    fontSize: 22,
    '& .MuiButtonBase-root': {
      backgroundColor: color.GREY_LIGHT_5,
    },
    '& .MuiSvgIcon-root': {
      color: color.GREY_DARK_2,
      width: 22,
      height: 22,
      [theme.breakpoints.down('md')]: {
        width: 30,
        height: 30,
      },
    },
    '&:hover': {
      backgroundColor: color.GREY_LIGHT_4,
      boxShadow: theme.shadow.elevation4Grey,
      '& .MuiSvgIcon-root': {
        color: color.GREY_DARK_3,
      },
    },
    '&:active': {
      backgroundColor: color.GREY_LIGHT_3,
      boxShadow: theme.shadow.elevation4Grey,
      '& .MuiSvgIcon-root': {
        color: color.GREY_DARK_2,
      },
    },
  },
  checked: {
    '& .MuiButtonBase-root': {
      backgroundColor: `${color.WHITE_01} !import`,
    },
    '& .MuiSvgIcon-root': {
      color: color.GREEN,
    },
    '&:hover': {
      backgroundColor: `${color.WHITE_01} !import`,
      boxShadow: theme.shadow.elevation4Green,
      '& .MuiSvgIcon-root': {
        color: color.GREEN_DARK_2,
      },
    },
    '&:active': {
      backgroundColor: `${color.WHITE_01} !import`,
      boxShadow: theme.shadow.elevation4Green,
      '& .MuiSvgIcon-root': {
        color: color.GREEN_DARK_3,
      },
    },
  },
  labelContainer: {
    display: 'flex',
  },
  labelStyle: {
    fontSize: 14,
    fontWeight: 400,
    marginLeft: 8,
    lineHeight: 1.3,
    color: color.GREY_DARK_4,
  },
  labelError: {
    color: color.RED_03,
  },
  labelDisabled: {
    color: color.GREY_LIGHT_2,
  },
  error: {
    '& .MuiButtonBase-root': {
      backgroundColor: color.RED_LIGHT_5,
    },
    '& .MuiSvgIcon-root': {
      color: color.RED,
    },
    '& .Mui-checked': {
      backgroundColor: color.WHITE_01,
      '& .MuiSvgIcon-root': {
        color: color.RED_DARK_1,
      },
    },
    '&:hover': {
      '& .MuiSvgIcon-root': {
        color: color.RED,
      },
    },
  },
  disabled: {
    '& .MuiButtonBase-root': {
      backgroundColor: color.GREY_LIGHT_2,
    },
    '& .MuiSvgIcon-root': {
      color: color.GREY_LIGHT_2,
    },
    '& .Mui-checked': {
      backgroundColor: color.GREEN_LIGHT_4,
      '& .MuiButtonBase-root': {
        backgroundColor: color.GREEN_LIGHT_4,
      },
      '& .MuiSvgIcon-root': {
        color: color.GREEN_LIGHT_4,
      },
    },
  },
}));

export const KarteraCheckbox: FC<CheckboxProps> = ({
  label,
  disabled,
  error,
  sx,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div 
      className={`
        ${classes.container}
        ${error && classes.error}
      `}
      style={sx}
    >
      <FormControlLabel
        classes={{ root: classes.root }}
        control={
          <Checkbox
            classes={{ 
              root: classes.checkbox, 
              checked: classes.checked,
              disabled: classes.disabled,
            }}
            disabled={disabled}
            disableRipple
            {...rest}
          />
        }
        label={
          <div className={classes.labelContainer}>
            <label 
              className={`
                ${classes.labelStyle} 
                ${disabled && classes.labelDisabled}
                ${error && classes.labelError}
              `}
            >
              {label}
            </label>
          </div>
        }
      />
    </div>
  );
};
