import React, { useCallback, useEffect, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Modal, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { KarteraButton } from '../../../components/kartera/button';
import { TwoFAModalProps } from './types';
import { useOutSideClick } from '../../../hooks/useOUtsideClick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { color } from '../../../consts/ColorConst';

const useStyles = makeStyles((theme: any) => ({
  root: {},
  stepOneContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 420,
    borderRadius: 10,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '20px 26px',
    zIndex: 10,
    transform: 'translate(-50%, -50%)',
  },
  iconTitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    alignItems: 'center',
    padding: '0 0 16px 0',
  },
  twoFAHeader: {
    fontSize: 16,
    fontWeight: 500,
    width: '100%',
    textAlign: 'start',
    padding: '0 0 0 6px',
  },
  pageDescription: {
    padding: '0 0 10px 0',
    fontSize: 14,
    lineHeight: 1.18,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 10,
    width: '100%',
    gap: 12,
  },
}));

export const TwoFADisableModal: React.FC<TwoFAModalProps> = ({ onModalOpen }) => {
  const classes = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'settings' });

  const modalRef = useRef(null);

  const handleNext = useCallback(async () => {
    return '';
  }, []);

  const handleCancel = useCallback(() => {
    onModalOpen(false);
  }, []);

  useOutSideClick(modalRef, () => {
    handleCancel();
  });

  return (
    <Modal open={true} id={'changePasswordModal'}>
      <Box className={classes.stepOneContainer} ref={modalRef}>
        <Box className={classes.iconTitle}>
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            fontSize={21}
            color={color.ORANGE_DARK_1}
          />
          <Typography className={classes.twoFAHeader}>{t('disableTitle')}</Typography>
        </Box>
        <Typography className={classes.pageDescription}>{t('disableDescription')}</Typography>
        <Typography className={classes.pageDescription}>{t('disableCommand')}</Typography>
        <Box className={classes.buttonWrapper}>
          <KarteraButton
            text={t('buttonCancel')}
            onClick={handleCancel as any}
            variant={'secondary'}
          />
          <KarteraButton
            text={t('buttonContact')}
            onClick={handleNext as any}
            variant={'notice'}
          />
        </Box>
      </Box>
    </Modal>
  );
};
