import { useCookies } from "react-cookie";
import { PartnerDTO } from "../../dtos/partners/partnerDTO";
import { PartnerRequestDTO } from "../../dtos/partners/partnerRequestDTO";
import { useAxios } from "./useAxios";

type ApiResponse<T> = {
  data: T[];
}

export function usePartnersAPI() {
  const [cookies] = useCookies(["lynk.access-token", "lynk.partner-id"]);
  const authToken = {
    headers: { Authorization: `Bearer ${cookies["lynk.access-token"]}` },
  };

  const { getApi, postApi } = useAxios();


  async function getPartners(showLoading: boolean): Promise<ApiResponse<PartnerDTO>> {
    return getApi(`/partners`, authToken, showLoading);
  }

  async function getPartnerById(id: string, showLoading: boolean, throwError: boolean): Promise<PartnerDTO> {
    return getApi(`/partners/${id}`, authToken, showLoading, throwError);
  }

  async function postPartner(data: PartnerRequestDTO): Promise<PartnerDTO> {
    return postApi("/partners", data, authToken);
  }


  return {
    postPartner,
    getPartners,
    getPartnerById,
  };
}
