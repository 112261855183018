import { useCookies } from "react-cookie";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../context/Auth";
import Loader from "../components/loader";

export const PrivateRoutes = () => {
  const { isContextUpdated, currentProduct, isAuthenticated } = useAuth();
  const [cookies] = useCookies([ "lynk.partner-id", "lynk.staff-user-id" ]);
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!isContextUpdated) return <Loader />;

  if (!cookies["lynk.partner-id"]) {
    if (location.pathname === "/verification-pending") {
      return <Outlet />
    }

    if (location.pathname !== "/") {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
  } 

  if (currentProduct.id) {
    
    if (location.pathname === "/" || (
        !currentProduct.ready &&
        !location.pathname.includes("/api-docs") &&
        !location.pathname.includes("/verification-pending") &&
        !location.pathname.includes(`/product/${currentProduct.id}`)
    )) {
      return (
        <Navigate
          to={`/product/${currentProduct.id}`}
          state={{ from: location }}
          replace
        />
      );
    }
  }

  if (!cookies["lynk.staff-user-id"] && location.pathname.includes("staff-admin")) {
    return (
      <Navigate
        to={`/product/${currentProduct.id}`}
        state={{ from: location }}
        replace
      />
    );
  }

  return <Outlet />;
};
