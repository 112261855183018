import { useCookies } from 'react-cookie';
import { OpenSearchRequestDTO } from '../../dtos/openSearch/OpenSearchDTO';
import { useAxios } from './useAxios';

export function useOpenSearchAPI() {
    const [cookies] = useCookies(["lynk.access-token", "lynk.partner-id"]);
    const authToken = {
        headers: { Authorization: `Bearer ${cookies["lynk.access-token"]}` },
    };
    const { postApi } = useAxios();

    async function syncOpenSearchIndex(data: OpenSearchRequestDTO): Promise<string> {
        return postApi(`/opensearch/sync`, data, authToken, undefined, false, true);
    }

    return {
        syncOpenSearchIndex,
    };
}