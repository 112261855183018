
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { color } from "../../consts/ColorConst";
import { UserStatusProps } from "./types";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        alignItems: 'center',
        width: 'fit-content',
        padding: "0 5px",
        borderRadius: 12,
        gap: 4,
        "& p": {
            fontSize: 12,
            fontWeight: 500,
        }
    },
    active: {
        color: color.GREEN_DARK_2,
        backgroundColor: color.GREEN_LIGHT_6,
        border: `2px solid ${color.GREEN_LIGHT_2}`,
    },
    inProgress: {
        color: "#FF9900",
        border: "2px solid #FF9900",
    },
    refunded: {
        color: "#32D583",
        border: "2px solid #32D583",
    },
    rejected: {
        color: "#F97066",
        border: "2px solid #F97066",
    },
}));


export function UserStatus({ status }: UserStatusProps) {
  const classes = useStyles();
  const { t } = useTranslation("translation", { keyPrefix: "userList" });

  const STATUS_TEXT = {
      active: t("statusActive"),
      inProgress: t("statusInProgress"),
      refunded: t("statusRefunded"),
      rejected: t("statusRejected"),
  }

  return (
        <Box className={`${classes.root} ${classes[status]}`}>
            <FontAwesomeIcon icon={faCheck} fontSize={12} />
            <Typography>
                {STATUS_TEXT[status]}
            </Typography>
        </Box>
  );
}
