import React, { FC } from "react";
import { ButtonProps } from "./types";
import { makeStyles } from "@mui/styles";
import { color } from "../../../consts/ColorConst";
import { Chip } from "@mui/material";

const useStyles = makeStyles(() => ({
  root: {
    height: '28px !important',
    padding: '0 0 0 2px',
    '&& .MuiChip-label': {
      fontWeight: 500,
      padding: '6px',
    },
    fontFamily: 'Roboto',
  },
  iconWrapper: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  primary: {
    fontSize: '14px',
    backgroundColor: `${color.PRIMARY_LIGHTEST_02} !important`,
    color: `${color.BLUE_DARK_04} !important`,
    border: `1px solid ${color.BLUE_DARK_04} !important`,
    '&& svg': {
      color: color.BLUE,
      '&:hover': { color: color.BLUE_DARK_04 },
      width: '17px !important',
      height: 19,
    },
    '&&:hover': { backgroundColor: `${color.PRIMARY_LIGHTEST_03} !important` },
    '&&:active': { backgroundColor: `${color.PRIMARY_LIGHTEST_04} !important` },
    '&&:disabled': {
      backgroundColor: `${color.PRIMARY_LIGHTEST_01} !important`,
      color: color.PRIMARY_LIGHT_01,
      '& path': { fill: color.WHITE_01 },
    },
  },
  iconWrapper_primary: {
    '&& path': { fill: color.BLUE_DARK_04 },
  },
  secondary: {
    fontSize: '14px',
    backgroundColor: `${color.SECONDARY_LIGHTEST_02} !important`,
    color: `${color.GRAY_DARK_05} !important`,
    border: `1px solid ${color.GRAY_DARK_05} !important`,
    '&& svg': {
      color: color.GRAY,
      '&:hover': { color: color.GRAY_DARK_05 },
      width: '17px !important',
      height: 19,
    },
    '&&:hover': {
      backgroundColor: `${color.SECONDARY_LIGHTEST_03} !important`,
    },
    '&&:active': {
      backgroundColor: `${color.SECONDARY_LIGHTEST_04} !important`,
    },
    '&&:disabled': {
      backgroundColor: `${color.SECONDARY_LIGHTEST_01} !important`,
    },
  },
  iconWrapper_secondary: {
    '&& path': { fill: color.GRAY_DARK_05 },
  },
  field: {
    height: '20px !important',
    backgroundColor: `${color.SECONDARY_LIGHTEST_02} !important`,
    border: `1px solid ${color.GRAY_DARK_05} !important`,
    '&& .MuiChip-label': {
      fontSize: '12px',
      color: color.GRAY_DARK_05,
    },
    '&& > .MuiSvgIcon-root': {
      color: color.GRAY,
      '&:hover': { color: color.GRAY_DARK_05 },
      width: '14px !important',
      height: 16,
    },
    '&:hover': { backgroundColor: `${color.SECONDARY_LIGHTEST_03} !important` },
    '&:active': {
      backgroundColor: `${color.SECONDARY_LIGHTEST_04} !important`,
    },
    '&:disabled': {
      backgroundColor: `${color.SECONDARY_LIGHTEST_01} !important`,
    },
  },
  iconWrapper_field: {
    '&& path': { fill: color.GRAY_DARK_05 },
  },
  filter: {
    height: '20px !important',
    backgroundColor: `${color.PRIMARY_LIGHTEST_02} !important`,
    border: `1px solid ${color.BLUE_DARK_04} !important`,
    '&& .MuiChip-label': {
      fontSize: '12px',
      color: color.BLUE_DARK_04,
    },
    '&& > .MuiSvgIcon-root': {
      color: color.BLUE,
      '&:hover': { color: color.BLUE_DARK_04 },
      width: '14px !important',
      height: 16,
    },
    '&:hover': { backgroundColor: `${color.PRIMARY_LIGHTEST_03} !important` },
    '&:active': {
      backgroundColor: `${color.PRIMARY_LIGHTEST_04} !important`,
    },
    '&:disabled': {
      backgroundColor: `${color.PRIMARY_LIGHTEST_01} !important`,
    },
  },
  iconWrapper_filter: {
    '&& path': { fill: color.BLUE_DARK_04 },
  },
  fieldError: {
    height: '20px !important',
    fontSize: '12px',
    backgroundColor: `${color.NEGATIVE_LIGHTEST_02} !important`,
    color: `${color.RED_DARK_05} !important`,
    border: `1px solid ${color.RED_DARK_05} !important`,
    '&& svg': {
      color: color.RED,
      '&:hover': { color: color.RED_DARK_05 },
      width: '14px !important',
      height: 16,
    },
    '&&:hover': { backgroundColor: `${color.NEGATIVE_LIGHTEST_03} !important` },
    '&&:active': {
      backgroundColor: `${color.NEGATIVE_LIGHTEST_04} !important`,
    },
    '&&:disabled': {
      backgroundColor: `${color.NEGATIVE_LIGHTEST_01} !important`,
    },
  },
  iconWrapper_fieldError: {
    '&& path': { fill: color.RED_DARK_05 },
  },
  fieldPositive: {
    height: '20px !important',
    fontSize: '12px',
    backgroundColor: `${color.POSITIVE_LIGHTEST_02} !important`,
    color: `${color.GREEN_DARK_05} !important`,
    border: `1px solid ${color.GREEN_DARK_05} !important`,
    '&& svg': {
      color: color.GREEN,
      '&:hover': { color: color.GREEN_DARK_05 },
      width: '14px !important',
      height: 16,
    },
    '&&:hover': { backgroundColor: `${color.POSITIVE_LIGHTEST_03} !important` },
    '&&:active': {
      backgroundColor: `${color.POSITIVE_LIGHTEST_04} !important`,
    },
    '&&:disabled': {
      backgroundColor: `${color.POSITIVE_LIGHTEST_01} !important`,
    },
  },
  iconWrapper_fieldPositive: {
    '&& path': { fill: color.GREEN_DARK_05 },
  },
  negative: {
    fontSize: '14px',
    backgroundColor: `${color.NEGATIVE_LIGHTEST_02} !important`,
    color: `${color.RED_DARK_05} !important`,
    border: `1px solid ${color.RED_DARK_05} !important`,
    '&& svg': {
      color: color.RED,
      '&:hover': { color: color.RED_DARK_05 },
      width: '17px !important',
      height: 19,
    },
    '&&:hover': { backgroundColor: `${color.NEGATIVE_LIGHTEST_03} !important` },
    '&&:active': {
      backgroundColor: `${color.NEGATIVE_LIGHTEST_04} !important`,
    },
    '&&:disabled': {
      backgroundColor: `${color.NEGATIVE_LIGHTEST_01} !important`,
    },
  },
  error: {
    fontSize: '14px',
    backgroundColor: `${color.NEGATIVE_LIGHTEST_02} !important`,
    border: `1px solid ${color.RED_DARK_05} !important`,
    '&& > .MuiSvgIcon-root': {
      color: color.RED,
      '&:hover': { color: color.RED_DARK_05 },
    },
    '&& .MuiChip-label': {
      color: color.RED_DARK_05,
    },
    '&&:hover': { backgroundColor: `${color.NEGATIVE_LIGHTEST_03} !important` },
    '&&:active': {
      backgroundColor: `${color.NEGATIVE_LIGHTEST_04} !important`,
    },
    '&&:disabled': {
      backgroundColor: `${color.NEGATIVE_LIGHTEST_01} !important`,
    },
  },
  iconWrapper_negative: {
    '&& path': { fill: color.RED_DARK_05 },
  },
  positive: {
    fontSize: '14px',
    backgroundColor: `${color.POSITIVE_LIGHTEST_02} !important`,
    color: `${color.GREEN_DARK_05} !important`,
    border: `1px solid ${color.GREEN_DARK_05} !important`,
    '&& svg': {
      color: color.GREEN,
      '&:hover': { color: color.GREEN_DARK_05 },
      width: '17px !important',
      height: 19,
    },
    '&&:hover': { backgroundColor: `${color.POSITIVE_LIGHTEST_03} !important` },
    '&&:active': {
      backgroundColor: `${color.POSITIVE_LIGHTEST_04} !important`,
    },
    '&&:disabled': {
      backgroundColor: `${color.POSITIVE_LIGHTEST_01} !important`,
    },
  },
  iconWrapper_positive: {
    '&& path': { fill: color.GREEN_DARK_05 },
  },
  notice: {
    fontSize: '14px',
    backgroundColor: `${color.NOTICE_LIGHTEST_02} !important`,
    color: `${color.ORANGE_DARK_05} !important`,
    border: `1px solid ${color.ORANGE_DARK_05} !important`,
    '&& svg': {
      color: color.ORANGE,
      '&:hover': { color: color.ORANGE_DARK_05 },
      width: '17px !important',
      height: 19,
    },
    '&&:hover': { backgroundColor: `${color.NOTICE_LIGHTEST_03} !important` },
    '&&:active': { backgroundColor: `${color.NOTICE_LIGHTEST_04} !important` },
    '&&:disabled': {
      backgroundColor: `${color.NOTICE_LIGHTEST_01} !important`,
    },
  },
  iconWrapper_notice: {
    '&& path': { fill: color.ORANGE_DARK_05 },
  },
}));

export const LynkChip: FC<ButtonProps> = ({
  id,
  text,
  component,
  onClick,
  onDelete,
  type = "field",
  disabled = false,
  icon,
  error,
}) => {
  const classes = useStyles();

  return (
    <Chip
      data-testid={id}
      icon={
        <>
          {icon && (
            <div
              className={`${classes.iconWrapper} ${
                classes[`iconWrapper_${type}`]
              }`}
            >
              {icon}
            </div>
          )}
        </>
      }
      size={type === "field" ? "small" : "medium"}
      className={`${classes.root} ${classes[type]} ${error && classes.error}`}
      label={text || component}
      onClick={onClick && (() => onClick())}
      onDelete={onDelete && (() => onDelete())}
      onMouseDown={(event: any) => {
        event.stopPropagation();
      }}
      disabled={disabled}
   />
  );
};
