import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  hasError: false,
  errorMessage: "",
};

const apiErrorSlice = createSlice({
  name: 'apiError',
  initialState,
  reducers: {
    setApiHasError: (state, action: PayloadAction<boolean>) => {
      state.hasError = action.payload;
    },
    setApiErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
  },
});

export const { setApiHasError, setApiErrorMessage } = apiErrorSlice.actions;

export default apiErrorSlice.reducer;