import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useApi } from '../../services/api/hooks';
import { color } from '../../consts/ColorConst';
import { KarteraFormField } from '../../components/kartera/formField';
import { KarteraButton } from '../../components/kartera/button';
import { KarteraProgress } from '../../components/kartera/progress';
import { LinkExpiredDialog } from "./linkExpiredDialog/LinkExpiredDialog";

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: color.GREY_DARK_4,
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: 400,
    borderRadius: 10,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadow.elevation16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '52px 32px',
  },
  resetPasswordHeader: {
    padding: '0 0 15px 0',
    fontSize: 26,
    fontWeight: 700,
  },
  pageDescription: {
    padding: '0 0 8px 0',
    fontSize: 14,
    lineHeight: 1.2,
  },
  criteriaContainer: {
    padding: '0 0 20px 10px',
    fontSize: 14,
    width: '100%',
  },
  criteriaItem: {
    listStyle: 'inside',
  },
  criteriaItemDone: {
    color: color.GREEN,
  },
  resetPasswordForm: {
    width: '100%',
    padding: '10px 0 15px 0',
  },
  loadingWrapper: {
    padding: '0 0 20px 0',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonWrapper: {
    padding: '4px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', 
    gap: 8,
  },
  submitButton: {
    padding: '4px 20px',
  },
  successText: {
    textAlign: 'center',
    fontSize: '14px',
  },
  errorText: {
    color: '#FF2559',
    fontSize: 14,
    textAlign: 'center',
  },
  errorMessage: {
    color: '#FF2559',
    fontSize: 12,
  },
}));

export const ChangePassword: React.FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const classes = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'changePassword' });
  const { postChangePassword, checkTokenExpiration } = useApi();

  const [errorMessages, setErrorMessages] = useState({ password: '', confirmPassword: '' });
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [requestFailed, setRequestFailed] = useState(false);
  const [requestError, setRequestError] = useState('');
  const [passwordCriterias, setPasswordCriterias] = useState({
    min8Char: false,
    min1Uppercase: false,
    min1Symbol: false,
    min1Number: false,
  })

  document.title = 'Kartera - Change your Password';

  function getEmailAndTokenFromParams(params: string) {
    try {
      const [param1, param2] = params.split('&');
      const email = param1.split('email=')[1];
      const token = param2.split('token=')[1];
      return { email, token };
    } catch {
      return { email: '', token: '' };
    }
  }

  async function checkToken(token: string) {
    try {
      const response = await checkTokenExpiration(token);
      if (response.expired === false) {
        setToken(token);
        setIsTokenExpired(false);
      } else {
        setIsTokenExpired(true);
      }    
    } catch (error) {
      setIsTokenExpired(true);
    }
  }

  useEffect(() => {
    const { email, token } = getEmailAndTokenFromParams(search);
    if (!email || !token) {
      navigate('/not-found');
      return;
    }
    setEmail(email);
    checkToken(token);
  }, []);

  useEffect(() => {
    setHasError(!!Object.values(errorMessages).find((msg) => msg !== ''));
  }, [errorMessages]);

  const criteria = [
    { label: t('character'), done: passwordCriterias.min8Char },
    { label: t('upperCase'), done: passwordCriterias.min1Uppercase }, 
    { label: t('symbol'), done: passwordCriterias.min1Symbol }, 
    { label: t('number'), done: passwordCriterias.min1Number }
  ];

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      try {
        event.preventDefault();
        setRequestFailed(false);
        setLoadingOpen(true);
        setRequestError('');

        const data = {
          email,
          token,
          new_password: password,
          confirm_new_password: confirmPassword,
        };
        await postChangePassword(data, (error) => {
          setRequestFailed(true);
          setRequestError(error.message);
          setLoadingOpen(false);
          return;
        }).then((response) => {
          if (response === '') {
            setLoadingOpen(false);
            navigate('/password-updated');
          }
        });

      } catch (error: any) {
        setRequestFailed(true);
        setRequestError(error);
        setLoadingOpen(false);
      }
    },
    [password, confirmPassword],
  );

  function handlePassword(e: any) {
      const pass = e.target.value;

      setPasswordCriterias({
        min8Char: pass.length >= 8,
        min1Number: /.*[\d].*/.test(pass),
        min1Symbol: /.*[!@#$%^&*=()_+{}\\[\]:;<>,.?~\\/\\-].*/.test(pass),
        min1Uppercase: /.*[A-Z].*/.test(pass),
      });

      setPassword(pass);
      if (requestFailed) {
        setRequestFailed(false);
      }
  }

  function passwordValidation() {
    let errorMessage = !passwordCriterias.min8Char ? t('errorMinChar') : '';
    errorMessage += !passwordCriterias.min1Number ? t('errorNumber') : '';
    errorMessage += !passwordCriterias.min1Symbol ? t('errorSymbol'): '';
    errorMessage += !passwordCriterias.min1Uppercase ? t('errorUppercase'): '';
    setErrorMessages((current) => ({ ...current, password: errorMessage }));
  }

  function confirmPasswordValidation() {
    const errorMessage = password !== confirmPassword ? t('errorMsgConfirmPassword') : '';
    setErrorMessages((current) => ({ ...current, confirmPassword: errorMessage }));
  }

  const buttonDisabled = hasError || !password || !confirmPassword;

  return (
    <Box data-testid='change-password-page-container' className={classes.root}>
      {isTokenExpired ? (
        <LinkExpiredDialog email={email} />
      ): (
        <form className={classes.container} onSubmit={handleSubmit}>
          <Typography className={classes.resetPasswordHeader}>{t('pageTitle')}</Typography>
          <Typography className={classes.pageDescription}>{t('description', { email })}</Typography>
          <ul className={classes.criteriaContainer}>
            {criteria.map((item, index) => (
              <li 
                key={index} 
                className={`${classes.criteriaItem} ${item.done && classes.criteriaItemDone}`}
              >
                {item.label}
                {item.done && (
                  <FontAwesomeIcon 
                    style={{ marginLeft: 6 }} 
                    icon={faCheck} 
                    color={color.GREEN}
                  />
                )}
              </li>
            ))}
          </ul>
          <Box className={classes.resetPasswordForm}>
            <KarteraFormField
              variant='password'
              placeholder={t('password')}
              value={password}
              errorMessage={errorMessages.password}
              onChange={handlePassword}
              onBlur={() => passwordValidation()}
              sx={{
                mb: errorMessages.password === '' ? '6px' : '16px',
                display: 'flex',
                alignItems: 'center',
              }}
              spacious
            />
            <KarteraFormField
              variant='password'
              placeholder={t('confirmPassword')}
              value={confirmPassword}
              errorMessage={errorMessages.confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                if (requestFailed) {
                  setRequestFailed(false);
                }
              }}
              onBlur={() => confirmPasswordValidation()}
              sx={{
                mb: errorMessages.confirmPassword === '' ? '6px' : '16px',
                display: 'flex',
                alignItems: 'center',
              }}
              spacious
            />
          </Box>
          {loadingOpen ? (
            <Box className={classes.loadingWrapper}>
              <Typography className={classes.errorMessage}>{requestError}</Typography>
              <KarteraProgress />
            </Box>
          ) : requestFailed ? (
            <Box className={classes.buttonWrapper}>
              <Typography className={classes.errorText}>{requestError}</Typography>
              <KarteraButton
                className={classes.submitButton}
                text={t('backButtonText')}
                variant={'link'}
                onClick={() => navigate('/login')}
                spacious
              />
            </Box>
          ) : (
            <KarteraButton
              text={t('ButtonText')}
              disabled={buttonDisabled}
              className={classes.submitButton}
              onClick={handleSubmit as any}
              spacious
            />
          )}
        </form>
      )}
    </Box>
  );
};
