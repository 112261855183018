import React, { FC } from "react";
import { TooltipProps } from "./types";
import { Tooltip } from "@mui/material";
import { color } from "../../../consts/ColorConst";

export const LynkTooltip: FC<TooltipProps> = ({ placement, title, children }) => {
  return (
    <Tooltip
      title={title}
      arrow
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: color.GRAY_DARK_06,
            borderRadius: "4px",
            padding: "6px 8px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            "& .MuiTooltip-arrow": {
              color: color.GRAY_DARK_06,
            },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};
