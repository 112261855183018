import { FC, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../oldComponents/layout';
import businessVerificationNotSubmittedImage from '../../assets/businessVerificationNotSubmittedImage.png';
import businessVerificationDaniedImage from '../../assets/businessVerificationDaniedImage.png';
import businessVerificationSubmittedImage from '../../assets/businessVerificationSubmittedImage.png';
import businessVerificationVerifiedImage from '../../assets/businessVerificationVerifiedImage.png';
import { useAuth } from "../../context/Auth";
import { Box, Typography } from '@mui/material';
import { color } from '../../consts/ColorConst';
import { KarteraButton } from '../../components/kartera/button';
import { PartnerStatus } from '../../consts/PartnerStatus';

const useStyles = makeStyles((theme: any) => ({
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '80px 0',
    },
    root: {
        width: 440,
        display: 'flex',
        padding: '19px 24px 16px',
        flexDirection: 'column',
        justifyContent: 'space-between',
        fontFamily: 'Roboto',
        height: "100%",
        borderRadius: 10,
        backgroundColor: color.WHITE_01,
        boxShadow: theme.shadow.elevation16,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '32px 0 24px',
    },
    titleContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    },
    title: {
        fontSize: 16,
        fontWeight: 700,
        color: color.GREY_DARK_4,
    },
    message: {
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: 400,
        color: color.GREY_DARK_4,
        marginBottom: '12px',
    },
    messageBold: {
        fontWeight: 600,
    },
    buttonsWrapper: {
        marginTop: 16,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    email: {
        paddingLeft: 4,
        fontSize: 14,
        fontWeight: 500,
        color: color.GREEN_DARK_1,
        textDecoration: 'none',
    }
}));

export const PendingVerification: FC = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'WaitForProcess' });
    const navigate = useNavigate();
    const { partner, updatePartner } = useAuth();
    const classes = useStyles();

    document.title = "Kartera - Pending Verification";
    
    const [ verificationStatus, setVerificationStatus ] = useState('');
    
    const errorStatus = verificationStatus && 
                [PartnerStatus.ERROR, PartnerStatus.SUSPENDED].includes(verificationStatus); 
    const isVerified = verificationStatus === PartnerStatus.VERIFIED; 

    let title, line1, line2, img = '';
    let bolderLine = 0;

    switch (verificationStatus) {
        case PartnerStatus.ERROR: 
            title = t('titleError');
            line1 = t('messageErrorLine1');
            line2 = t('messageErrorLine2');
            img = businessVerificationNotSubmittedImage;
            bolderLine = 0;
            break;
        case PartnerStatus.SUSPENDED:
            title = t('titleRejected');
            line1 = t('messageRejectedLine1');
            line2 = t('messageRejectedLine2');
            img = businessVerificationDaniedImage;
            bolderLine = 0;
            break;
        case PartnerStatus.VERIFIED:
            title = t('titleApproved');
            line1 = t('messageApproved1');
            line2 = t('messageApproved2');
            img = businessVerificationVerifiedImage;
            bolderLine = 2;
            break;
        default:
            title = t('title');
            line1 = t('message1');
            line2 = t('message2');
            img = businessVerificationSubmittedImage;
            bolderLine = 1;
    }

    function handleAction() {
        navigate('/');
    }

    async function handleVerificationStatus(loading: boolean) {
        const response = await updatePartner(loading, true);
        if (!response) {
            return setVerificationStatus('ERROR');
        }
        setVerificationStatus(response.verification_status);
    }
    
    useEffect(() => {
        handleVerificationStatus(true);
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => handleVerificationStatus(false), 30000);
        return () => clearInterval(intervalId);
    }, [partner]);

    return (
        <div data-testid="pending-verification-page-container">
            { verificationStatus && (
                <Layout>
                    <Box className={classes.container}>
                        <Box className={classes.root}>
                            <Box className={classes.titleContainer}>
                                <Typography className={classes.title}>
                                    {title}
                                </Typography>
                            </Box>
                            <Box className={classes.content}>
                                <img
                                    src={ img }
                                    alt="Processing your application" 
                                />
                            </Box>
                            <p className={`${classes.message} ${bolderLine === 1 && classes.messageBold}`}>
                                {line1}
                            </p>
                            <p className={`${classes.message} ${bolderLine === 2 && classes.messageBold}`}>
                                {line2}
                                {errorStatus && (
                                    <a className={classes.email} href="mailto:support@kartera.com" >
                                        support@kartera.com
                                    </a>
                                )}
                            </p>
                            <Box className={classes.buttonsWrapper}>
                                <KarteraButton
                                    text={isVerified ? t('launchDashboard') : t('close')}
                                    variant='primary'
                                    onClick={handleAction}
                                />
                            </Box>
                        </Box>          
                    </Box>
                </Layout>
            ) }
        </div>
    )
}