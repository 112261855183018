import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Box, Theme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { color } from '../../../consts/ColorConst';

type StyleProps = {
  fixCopyButton?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  codeBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: '0 0 10px 0',
    position: 'relative',
    wordBreak: 'break-all',
    padding: 14,
    gap: 12,
    borderRadius: 6,
    backgroundColor: color.GREY_LIGHT_5,
    border: `1px solid ${color.GREY_LIGHT_3}`,
    '& code': {
      flex: 1,
      fontFamily: "Roboto Mono",
      fontSize: 12,
      fontWeight: 600,
      color: color.GREY_DARK_3,
    },
    '&:hover': {
      '& $copyButton': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  copyButton: {
    display: ({fixCopyButton}) => fixCopyButton ? 'flex' : 'none',
    padding: '6px 10px',
    backgroundColor: color.GREY_LIGHT_4,
    fontSize: 14,
    fontWeight: 600,
    justifyContent: 'space-between',
    color: color.GREY_DARK_4,
    cursor: 'pointer',
    borderRadius: 6,
    transition: 'all .2s',
    '&:hover': {
      backgroundColor: color.GREY_LIGHT_3,
    },
  },
  copiedBox: {
    position: 'absolute',
    fontSize: 14,
    fontWeight: 600,
    color: color.GREY_DARK_1,
    top: 26,
    right: 5,
    animation: `$copyEffect 2s ease`
  },
  "@keyframes copyEffect": {
    "0%": {
      opacity: 0,
      top: 26,
    },
    "70%": {
      opacity: 1,
    },
    "100%": {
      top: -4,
      opacity: 0,
    }
  },
  copiedText: {
  },
}));

type Props = {
  code: string;
  fixCopyButton?: boolean;
  onCopyClick?: () => void;
  useParagraphTag?: boolean;
}

export function KarteraCodeSnippet({ 
  code, 
  fixCopyButton,
  useParagraphTag,
}: Props) {
  const classes = useStyles({ fixCopyButton });
  const { t } = useTranslation('translation', { keyPrefix: 'codeSnippet' });

  const [copiedModalOpen, setCopiedModalOpen] = useState(false);

  function onCopyCodeClick() {
    navigator.clipboard.writeText(code);
    setCopiedModalOpen(true);
    setTimeout(() => {
      setCopiedModalOpen(false);
    }, 2000);
  }

  function codeSnipperContent() {
    return (
      <>
        <code>{code}</code>
        <Box
          className={classes.copyButton}
          onClick={onCopyCodeClick}
        >
          <FontAwesomeIcon
            icon={faCopy}
            size='1x'
            style={{ color: color.GREY_DARK_4 }}
          />
          {!fixCopyButton && t('copy')}
        </Box>
        {copiedModalOpen && (
          <Box className={classes.copiedBox}>
            <p className={classes.copiedText}>copied</p>
          </Box>
        )}
      </>
    )
  }

  if (useParagraphTag) {
    return <p className={classes.codeBlock}>{codeSnipperContent()}</p>
  }

  return <pre className={classes.codeBlock}>{codeSnipperContent()}</pre>
}
