import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useApi } from "../../services/api/hooks";
import { dateTimeConverter } from "../../utils/dateConverter";
import { UserStatus } from "../../components/userStatus";
import { checkMockData, getMockUsersData } from "../../utils/generateMockData";
import { KarteraTable } from "../../components/kartera/table";
import { PageStateType } from "../../components/kartera/table/types";
import { underscoreToCammelCase } from "../../utils/helperFunctions";

const useStyles = makeStyles(() => ({
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "36px 40px",
    gap: 26,
  },
  title: {
    fontSize: 26,
    fontWeight: 700,
  },
}));

export function UserList() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { product_id } = useParams();
  const { getUsersList } = useApi();
  const { t } = useTranslation("translation", { keyPrefix: "userList" });

  
  const [selectedItems, setSelectedItems] = useState([]);
  const [updateDataTime, setUpdateDataTime] = useState(new Date());
  const [selectedActiveFilter, setSelectedActiveFilter] = useState("active=true");
  const [paginationAndSortingMode, setPaginationAndSortingMode] =
  useState<"server" | "client">("server");
  
  document.title = "Kartera - Users";
 
  const ACTIVE_FILTER = {
    items: [
      { text: t("active"), value: "active=true" },
      { text: t("noActive"), value: "active=false" },
    ], 
    defaultValue: "active=true",
    setSelectedItem: setSelectedActiveFilter,
  }

  function getParsedDate(params: GridValueGetterParams) {
    return dateTimeConverter(params.row.created_at);
  }

  function getName(params: GridValueGetterParams) {
    return `${params.row.first_name} ${params.row.last_name}`;
  }

  function renderStatus(params: GridRenderCellParams) {
    return <UserStatus status={params.value || 'active'} />;
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: t('colId'), width: 320 },
    { field: 'email', headerName: t('colEmail'), flex: 1 },
    { field: 'first_name', headerName: t('colName'), flex: 1, valueGetter: getName },
    {
      field: 'created_at',
      headerName: t('colCreatedAt'),
      minWidth: 200,
      valueGetter: getParsedDate,
    },
    {
      field: 'status',
      headerName: t('colStatus'),
      minWidth: 120,
      renderCell: renderStatus,
    },
  ];

  async function getUserData(pageState: PageStateType) {
    if (checkMockData()) {
      setPaginationAndSortingMode("client");
      return {
        data: getMockUsersData(),
        metadata: {
          total_elements: 500,
        },
      };
    }

    if (!product_id || product_id === " " || product_id === "null") return;
    setPaginationAndSortingMode("server");
    return await getUsersList(
      product_id, 
      selectedActiveFilter, 
      pageState.pageSize, 
      pageState.page, 
      `${underscoreToCammelCase(pageState.sortField)},${pageState.sortDirection}`,
    );
  }

  useEffect(() => {
    setUpdateDataTime(new Date());
  }, [selectedActiveFilter]);

  useEffect(() => {
    if (!product_id || product_id === " ") {
      navigate("/");
      return;
    }
  }, []);

  function handleUserDetail(params: GridRowParams) {
    navigate(`/product/${product_id}/user/${params.id}`);
  }

  return (
    <Box className={classes.contentWrapper} data-testid="user-list-page-container">
      <Typography className={classes.title}>{t("title")}</Typography>
      <KarteraTable
        rows={[]}
        title={t("title")}
        paginationAndSortingMode={paginationAndSortingMode}
        columns={columns}
        fetchData={getUserData}
        onRowClick={handleUserDetail}
        minHeight={500}
        searchPlaceholder={t("searchPlaceholder")}
        csvFileName={t("csvFileName")}
        quickFilters={[ACTIVE_FILTER]}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        filteringEnabled={false}
        refreshDate={updateDataTime}
      />
    </Box>
  );
}
