import { useEffect, useState, lazy, Suspense, useCallback } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { Box, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAddressCard,
  faBoxOpen,
  faChevronDown,
  faChartMixed,
  faCommentQuestion,
  faPlug,
  faFileChartColumn,
} from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from '../../components/sidebar';
import { ButtonMenu } from '../../components/common/buttonMenu';
import { getCurrentEnvironment, isSandbox } from '../../utils/environmentUtils';
import { generateMockData, clearMockData } from '../../utils/generateMockData';
import { color } from '../../consts/ColorConst';
import { AppRootState } from '../../reducers';
import { setBusinessVerification } from '../../reducers/businessVerificationReducers';
import {
  setShouldShowMockData,
  setShouldShowOnboardingDialog,
  setIsDemoAccount,
} from '../../reducers/demoAccountReducers';
import { useAuth } from '../../context/Auth';
import { DashboardAlert } from '../../components/kartera/dashboardAlert';
import { KarteraDialogue } from '../../components/kartera/dialogue';
import { KarteraSwitch } from '../../components/kartera/switch';
import { KarteraButton } from '../../components/kartera/button';
import { KarteraAppBar } from '../../components/kartera/appBar';
import { OnboardingInformationDialog } from '../../components/onboardingInformationDialog';
import { ConditionalComponent } from '../../components/contitionalComponent';
import SandboxOnboarding from '../../pages/sandbox/onboarding';
import Loader from '../../components/loader';
import { LayoutProps } from './types';
import { KarteraAlert } from '../../components/kartera/alert';
import { KarteraSnackBar } from '../../components/kartera/snackBar';
import { setApiMessage } from '../../reducers/apiMessageReducers';
import Rewards from '../../components/rewards/rewards';
import { MenuProps } from '../../components/kartera/menu/types';
import { useApi } from '../../services/api/hooks';
import { setActive, setCashbackValidityDays, setDiscountPercentage, setId, 
  setIsLoadingRewards, setPointSystemEarnRatio, setPointSystemIntroOffer, 
  setPointSystemIntroOfferParam, setPointSystemIntroOfferTnxLimit, 
  setPointSystemIntroOfferType, setPointSystemSpendRatio, 
  setRewardType, setShouldCallGetRewardApi } from '../../reducers/rewardsReducers';

const Onboarding = lazy(() => import('../../pages/onboarding'));

const useStyles = makeStyles<Theme>((theme) => ({
  selectButton: {
    fontSize: '14px',
    fontWeight: 500,
    height: '28px',
    display: 'flex',
    marginLeft: 10,
    justifyContent: 'space-between',
    borderRadius: 6,
    gap: 8,
  },
  buttonSandbox: {
    backgroundColor: color.ORANGE_02,
    color: color.ORANGE_DARK_04,
    '&:hover': {
      backgroundColor: color.NOTICE,
    },
  },
  buttonProduction: {
    backgroundColor: color.GREEN_LIGHT_3,
    color: color.GREEN_DARK_4,
    '&:hover': {
      backgroundColor: color.GREEN_LIGHT_1,
    },
  },
  innerBarWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  contentWrapper: {
    height: 'calc(100vh - 66px)',
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 69px)',
    },
  },
  innerRightContentWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 12,
  },
  message: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.2,
  },
  dialogContainer: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.2,
  },
  dialogMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  messageContainer: {
    padding: '36px 0',
    display: 'flex',
  },
  innerMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  messageTitle: {
    fontWeight: 700,
  },
  image: {
    padding: '0 36px',
  },
  demoContainer: {
    position: 'absolute',
    width: '100%',
    minWidth: '700px',
    height: '100%',
    backgroundColor: 'transparent',
    zIndex: '99',
  },
  mainContainer: {
    position: 'relative',
    minWidth: '700px',
  },
}));

const reduxConnector = connect((state: AppRootState) => ({
  shouldCallGetRewardApi: state.rewardsState.shouldCallGetRewardApi,
  hasError: state.apiErrorState.hasError,
  hasApiError: state.apiErrorState.hasError,
  isBusinessVerificationModalOpen: state.businessVerification.isOpen,
  isDemoAccount: state.demoAccountState.isDemoAccount,
  shouldShowMockData: state.demoAccountState.shouldShowMockData,
  shouldShowOnboardingDialog: state.demoAccountState.shouldShowOnboardingDialog,
  apiMessage: state.apiMessageState.message,
  errorMessage: state.apiErrorState.errorMessage,
  isRewardModalOpen: state.rewardsState.rewardModalOpen,
  selectedProductUser: state.productUserState.selectedProductUser,
}));

type Props = LayoutProps & ConnectedProps<typeof reduxConnector>;

const INTERVAL_DURATION = 10000; // 10 seconds
const TOTAL_TIME = 5 * 6 * INTERVAL_DURATION; // 5 minutes

function Layout({
  hasApiError,
  topCustomComponent,
  isBusinessVerificationModalOpen,
  isDemoAccount,
  shouldShowMockData,
  shouldShowOnboardingDialog,
  apiMessage,
  isRewardModalOpen,
  errorMessage,
  shouldCallGetRewardApi,
  selectedProductUser,
}: Props) {
  const navigate = useNavigate();
  const intercom = useIntercom();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { pathname } = useLocation();
  const { product_id } = useParams();
  const { partner, updatePartner, currentProduct, isLoadingPartner } = useAuth();
  const { getRewards } = useApi();
  const { t: tNavbar } = useTranslation('translation', { keyPrefix: 'navbar' });
  const { t: tSidebar } = useTranslation('translation', { keyPrefix: 'sidebar' });
  const { t: tDashboard } = useTranslation('translation', { keyPrefix: 'dashboard' });

  const [isOnboardingStarted, setIsOnboardingStarted] = useState(false);
  const [menuActiveItem, setMenuActiveItem] = useState<any>();

  const isPartnerNotReady = !partner?.isVerified || !currentProduct.ready;
  const ENVIRONMENTS = getCurrentEnvironment();
  const buttonMenuItems = [
    {
      text: tNavbar('optionSandbox'),
      value: 'sandbox',
      onClick: () => (window.location.href = 'https://app.sandbox.kartera.com'),
    },
    {
      text: tNavbar('optionProduction'),
      value: 'production',
      onClick: () => (window.location.href = 'https://app.kartera.com'),
    },
  ];

  const [selectedItem, setSelectedItem] = useState(() => {
    return isSandbox() ? buttonMenuItems[0] : buttonMenuItems[1];
  });

  let breadcrumbs = [{ text: menuActiveItem?.text }];
  if (pathname.includes('/product/product_') && pathname.includes('/user/productuser') && selectedProductUser) {
    breadcrumbs = [
      { text: tNavbar('userListLink'), href: `/product/${product_id}/users` } as {
        text: any;
        href: string;
      },
      { text: `${selectedProductUser.first_name} ${selectedProductUser.last_name}` },
    ];
  }

  intercom.update({ hideDefaultLauncher: false });

  function handleMockData() {
    if (shouldShowMockData) {
      dispatch(setShouldShowMockData(false));
      clearMockData();
    } else {
      generateMockData();
      dispatch(setShouldShowMockData(true));
    }
  }

  const onCloseSnackbar = useCallback(() => {
    dispatch(setApiMessage(''));
  }, [setApiMessage]);

  async function fetchRewards() {
    try {
      if (!product_id) return;
      dispatch(setIsLoadingRewards(true));
      const response = await getRewards(product_id);
      if (response.id) {
        dispatch(setShouldCallGetRewardApi(false));
        dispatch(setRewardType(response.reward_type));
        dispatch(setId(response.id));
        dispatch(setActive(response.active));
        dispatch(setCashbackValidityDays(response.cashback_validity_days));
        dispatch(setDiscountPercentage(response.discount_percentage));
        dispatch(setPointSystemEarnRatio(response.point_system_earn_ratio));
        dispatch(setPointSystemIntroOffer(response.point_system_intro_offer));
        dispatch(setPointSystemIntroOfferType(response.point_system_intro_offer_type));
        dispatch(setPointSystemIntroOfferParam(response.point_system_intro_offer_param));
        dispatch(setPointSystemIntroOfferTnxLimit(response.point_system_intro_offer_tnx_limit));
        dispatch(setPointSystemSpendRatio(response.point_system_spend_ratio));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setIsLoadingRewards(false));
    }
  }

  useEffect(() => {

    fetchRewards();

    dispatch(setShouldShowOnboardingDialog(!partner));
    dispatch(setShouldShowMockData(!partner));
    if (!partner) generateMockData();

    // If the partner is not verified, show the business verification dialog
    if (isLoadingPartner) return;
    let intervalId: NodeJS.Timeout | undefined = undefined;
    let timeoutId: NodeJS.Timeout | undefined = undefined;

    async function getUpdatedPartner() {
      try {
        await updatePartner();
        if (currentProduct.ready) {
          clearMockData();
          clearInterval(intervalId);
          return;
        }
      } catch (error) {
        clearInterval(intervalId);
      }
    }

    if (isPartnerNotReady) {
      getUpdatedPartner();
      //wait 2 seconds to show onboarding dialog and demo warning
      timeoutId = setTimeout(() => {
        dispatch(setShouldShowOnboardingDialog(true));
        dispatch(setIsDemoAccount(true));
      }, 2000);
      // This function will repeatedly call getUpdatedPartner()
      // every 10 seconds for 5 minutes, after it clears the interval, stopping the repeated calls.
      let elapsedTime = 0;
      intervalId = setInterval(() => {
        elapsedTime += INTERVAL_DURATION;
        if (elapsedTime < TOTAL_TIME) {
          getUpdatedPartner();
        } else {
          clearInterval(intervalId);
        }
      }, INTERVAL_DURATION);
    }

    return () => {
      clearTimeout(timeoutId);
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (pathname.includes('user')) {
      setMenuActiveItem({ text: tSidebar('menuUsers') });
      return;
    }

    if (pathname.includes('platforms')) {
      setMenuActiveItem({
        text: tSidebar('menuIntegrations'),
        subText: tSidebar('menuCommercePlatforms'),
      });
      return;
    }

    if (pathname.includes('widget')) {
      setMenuActiveItem({
        text: tSidebar('menuIntegrations'),
        subText: tSidebar('menuPaymentWidget'),
      });
      return;
    }

    if (pathname.includes('Party')) {
      setMenuActiveItem({
        text: tSidebar('menuIntegrations'),
        subText: tSidebar('menu3rdParty'),
      });
      return;
    }

    if (pathname.includes('transfers')) {
      setMenuActiveItem({
        text: tSidebar('menuFunds'),
        subText: tSidebar('transfers'),
      });
      return;
    }

    if (pathname.includes('transactions')) {
      setMenuActiveItem({ text: tSidebar('menuTransactions') });
      return;
    }

    if (pathname.includes('reports')) {
      setMenuActiveItem({ text: tSidebar('menuReports') });
      return;
    }

    setMenuActiveItem({ text: tSidebar('menuDashboard') });
  }, [pathname, setMenuActiveItem]);

  const menuArgs: MenuProps = {
    initialActiveItem: menuActiveItem,
    items: [
      {
        icon: <FontAwesomeIcon icon={faChartMixed} fontSize={16} width={'22px'} />,
        text: tSidebar('menuDashboard'),
        onClick: () => navigate(`/product/${currentProduct.id}`),
      },
      {
        icon: <FontAwesomeIcon icon={faAddressCard} fontSize={16} width={'22px'} />,
        text: tSidebar('menuUsers'),
        onClick: () => navigate(`/product/${currentProduct.id}/users`),
      },
      {
        icon: <FontAwesomeIcon icon={faPlug} fontSize={16} width={'22px'} />,
        text: tSidebar('menuIntegrations'),
        subItems: [
          {
            text: tSidebar('menuCommercePlatforms'),
            onClick: () => navigate(`/platforms`),
          },
          {
            text: tSidebar('menuPaymentWidget'),
            onClick: () => navigate(`/widget`),
          },
          {
            text: tSidebar('menu3rdParty'),
            onClick: () => navigate(`/3rdParty`),
          },
        ],
      },
      {
        icon: <FontAwesomeIcon icon={faFileChartColumn} fontSize={16} width={'22px'} />,
        text: tSidebar('menuReports'),
        onClick: () => navigate(`/product/${currentProduct.id}/reports`),
      },
    ],
  };

  menuArgs.items.push({
    icon: <FontAwesomeIcon icon={faCommentQuestion} fontSize={16} width={'22px'} />,
    text: tSidebar('support'),
    onClick: () => intercom.show(),
  });

  function handleBusinessVerificationDialog(open: boolean) {
    dispatch(setBusinessVerification(open));
  }

  function handleOnboarding() {
    dispatch(setBusinessVerification(false));
    setIsOnboardingStarted(true);
  }

  function handleLearnMore() {
    dispatch(setBusinessVerification(false));
  }

  useEffect(() => {
    if (shouldCallGetRewardApi) {
      fetchRewards();
    }
  }, [shouldCallGetRewardApi]);

  return (
    <Box component='main' sx={{ display: 'flex' }}>
      <Sidebar disabledMenu={!currentProduct?.ready} menuArgs={menuArgs} />
      <Box sx={{ width: '100%' }}>
        <KarteraAppBar
          breadcrumbs={breadcrumbs}
          component={
            <Box className={classes.innerBarWrapper}>
              <Box className={classes.innerRightContentWrapper}>
                {topCustomComponent && topCustomComponent}
                <ConditionalComponent
                  condition={!partner || !!ENVIRONMENTS?.includes('DEVELOPMENT')}
                >
                  <KarteraSwitch
                    checked={shouldShowMockData}
                    label={tNavbar('mockDataButton')}
                    onChange={handleMockData}
                  />
                </ConditionalComponent>
                <ConditionalComponent
                  condition={
                    !!(
                      ENVIRONMENTS &&
                      ['STAGING', 'DEVELOPMENT', 'SANDBOX', 'PRODUCTION'].includes(ENVIRONMENTS)
                    )
                  }
                >
                  <ButtonMenu
                    className={`
                        ${classes.selectButton} 
                        ${
                          selectedItem?.value === 'sandbox'
                            ? classes.buttonSandbox
                            : classes.buttonProduction
                        }`}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    iconLeft={
                      isSandbox() ? (
                        <FontAwesomeIcon
                          icon={faBoxOpen}
                          fontSize={14}
                          color={color.ORANGE_DARK_04}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          fontSize={14}
                          color={color.GREEN_DARK_4}
                        />
                      )
                    }
                    iconRight={<FontAwesomeIcon icon={faChevronDown} fontSize={12} />}
                    items={buttonMenuItems}
                  />
                </ConditionalComponent>
                <KarteraButton
                  variant='tertiary'
                  onClick={() => navigate('/api-docs')}
                  text={tNavbar('documentationButton')}
                />
              </Box>
            </Box>
          }
        />
        <Box className={classes.contentWrapper}>
          <ConditionalComponent condition={isDemoAccount || isPartnerNotReady}>
            <DashboardAlert
              partnerStatus={partner?.verificationStatus || ''}
              productReady={currentProduct.ready}
              setIsDialogVerifyNowOpen={handleOnboarding}
            />
          </ConditionalComponent>
          <div className={classes.mainContainer}>
            <ConditionalComponent condition={hasApiError}>
              <KarteraAlert
                severity='error'
                text={errorMessage !== '' ? errorMessage : tDashboard('errorTitle')}
              />
            </ConditionalComponent>
            {/* This is the main content of the page */}
            <Outlet />
            <ConditionalComponent condition={Boolean(apiMessage)}>
              <div
                style={{
                  position: 'fixed',
                  bottom: 20,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: 'fit-content',
                  zIndex: 9999,
                }}
              >
                <KarteraSnackBar
                  severity='simple'
                  text={apiMessage}
                  open={Boolean(apiMessage)}
                  timeToClose={4000}
                  onCloseClick={() => onCloseSnackbar()}
                />
              </div>
            </ConditionalComponent>
          </div>
        </Box>
        <ConditionalComponent condition={isOnboardingStarted}>
          {isSandbox() ? (
            <SandboxOnboarding closeOnboarding={() => setIsOnboardingStarted(false)} />
          ) : (
            <Suspense fallback={<Loader />}>
              <Onboarding closeOnboarding={() => setIsOnboardingStarted(false)} />
            </Suspense>
          )}
        </ConditionalComponent>
        <ConditionalComponent condition={shouldShowOnboardingDialog}>
          <OnboardingInformationDialog
            startOnboarding={() => setIsOnboardingStarted(true)}
            onClose={() => dispatch(setShouldShowOnboardingDialog(false))}
          />
        </ConditionalComponent>
        <ConditionalComponent condition={isBusinessVerificationModalOpen}>
          <KarteraDialogue
            size='small'
            activeButtonType='primary'
            actionButtonText={tDashboard('verifyNow')}
            cancelButtonText={tDashboard('later')}
            title={tDashboard('businessVerificationTitle')}
            showCloseButton={false}
            onCancelClick={() => handleBusinessVerificationDialog(false)}
            onActionClick={handleOnboarding}
            autoHeight
            othersButtonText={tDashboard('learnMore')}
            onOthersClick={handleLearnMore}
          >
            <div className={classes.dialogMessageContainer}>
              <p className={classes.message}>{tDashboard('businessVerificationMessage1')}</p>
              <p className={classes.message}>{tDashboard('businessVerificationMessage2')}</p>
            </div>
          </KarteraDialogue>
        </ConditionalComponent>
        <ConditionalComponent condition={isRewardModalOpen}>
          <Rewards />
        </ConditionalComponent>
      </Box>
    </Box>
  );
}

export default reduxConnector(Layout);
