import { useState, useRef, useEffect, useCallback } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { usePlaidLink } from 'react-plaid-link';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ConnectAccountModalProps } from './types';
import { KarteraDialogue } from '../kartera/dialogue';
import { color } from '../../consts/ColorConst';
import { useApi } from '../../services/api/hooks';
import { setAccountConnectClosed } from '../../reducers/accountCoonectReducer';
import { AppRootState } from '../../reducers';
import { useAuth } from '../../context/Auth';
import ConnectBankImage from '../../assets/connectBankImage.png';

const useStyles = makeStyles(() => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  descriptionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY_DARK_3,
    letterSpacing: 0.2,
    gap: 8,
  },
}));

const reduxConnector = connect(
  (state: AppRootState) => ({
    accountConnectClosed: state.accountConnectState.closed,
  }));

type Props = ConnectAccountModalProps & ConnectedProps<typeof reduxConnector>;

function ConnectAccountModal({
  modalOpen,
  setModalOpen,
  accountConnectClosed,
}: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'accountConnectModal' });
  const classes = useStyles();
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const { currentProduct } = useAuth();

  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const productId = currentProduct.id;

  const onSuccess = useCallback(async (public_token) => {
    setModalOpen(false);
    dispatch(setAccountConnectClosed(true));
    postConnectedAccount(productId, { plaid_public_token: public_token });
  }, []);

  const [config, setConfig] = useState({
    token: '',
    onSuccess,
  });

  const { getPlaidLinkToken, postConnectedAccount } = useApi();

  const { open, ready, exit } = usePlaidLink(config);

  const onConnectClick = async () => {
    const [tokenResponse]: any = await Promise.all([getPlaidLinkToken()]);
    setConfig({ ...config, token: tokenResponse?.plaid_link_token });
  };

  useEffect(() => {
    if (config.token !== '') {
      open();
    }
    exit();
  }, [config, open, exit]);

  const onCancelClick = useCallback(() => {
    setModalOpen(false);
    dispatch(setAccountConnectClosed(true));
  }, [setModalOpen, dispatch]);

  return (
    <Box ref={modalRef} sx={{ display: isImageLoaded ? 'block' : 'none' }}>
      <KarteraDialogue
        size={'large'}
        width='611px'
        activeButtonType={'primary'}
        actionButtonText={t('connectButton')}
        onActionClick={onConnectClick}
        cancelButtonText={t('later')}
        onCancelClick={onCancelClick}
        autoHeight
        othersButtonText={t('learnMorePliad')}
        onOthersClick={() => window.location.replace('https://plaid.com/docs/')}
        title={t('title')}
        sx={{
          '&.MuiPaper-root': { display: isImageLoaded ? 'block' : 'none' },
        }}
      >
        <Box className={classes.contentWrapper}>
          <img
            src={ConnectBankImage}
            alt='connect account'
            style={{ padding: '25px 0' }}
            onLoad={() => setIsImageLoaded(true)}
          />
          <Box className={classes.descriptionWrapper}>
            <Trans>{t('description1')}</Trans>
            {t('description2')}
            <p>
              <Trans>{t('description3')}</Trans>
            </p>
            {t('description4')}
          </Box>
        </Box>
      </KarteraDialogue>
    </Box>
  );
}

export default reduxConnector(ConnectAccountModal);
