import { FC } from 'react';
import { PinCodeInputProps } from './types';
import Box from '@mui/material/Box';
import PinInput from 'react-pin-input';
import { color } from '../../../consts/ColorConst';

let pin: any;

export const KarteraPinCodeInput: FC<PinCodeInputProps> = ({
  onComplete,
  length,
  disabled = false,
  error = false,
  requestedNew,
  setRequestedNew,
  id,
  onChange,
}) => {
  if (requestedNew === true) {
    pin.clear();
    pin.focus();
    setRequestedNew && setRequestedNew(false);
  }

  return (
    <Box 
      data-testid={id} 
      sx={{ 
        position: 'relative', 
        display: 'inline-flex', 
        width: '100%', 
        gap: 6 
      }}
    >
      {/* eslint-disable-next-line */}
      {/* @ts-ignore:next-line */}
      <PinInput
        focus
        length={length}
        ref={(p) => (pin = p)}
        initialValue=''
        type='numeric'
        inputMode='number'
        inputStyle={{
          borderColor: error ? color.RED : color.GRAY_01,
          backgroundColor: error ? color.RED_LIGHT_5 :color.GRAY_01,
          borderRadius: 7,
          boxSizing: 'border-box',
          width: 36,
          height: 39,
          fontSize: 18,
          fontWeight: 400,
          margin: '0 3px',
          color: error ? color.RED_DARK_3 : color.GREY_DARK_2,
        }}
        inputFocusStyle={{ borderColor: error ? color.RED : color.GRAY_01 }}
        onComplete={(value: any) => {
          onComplete && onComplete(value);
        }}
        autoSelect={true}
        regexCriteria={/^[0-9]*$/}
        style={{ gap: 6 }}
        disabled={disabled}
        onChange={(value)=>onChange && onChange(value)}
      />
    </Box>
  );
};
