import { Trans, useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { color } from '../../../consts/ColorConst';
import KarteraLogoBlack from '../../../assets/KarteraLogoBlack.svg';
import KarteraLogoGreen from '../../../assets/KarteraLogoGreen.svg';
import KarteraLogoWhite from '../../../assets/KarteraLogoWhite.svg';
import { truncateText } from '../../../utils/helperFunctions';

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 4,
    backgroundColor: color.WHITE_01,
    borderRadius: 6,
    border: `1px solid ${color.GREY_LIGHT_3}`,
    padding: 15,
  },
  tiny: {
    width: 50,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    border: `1px solid ${color.GREY_LIGHT_3}`,
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    color: color.GREY_DARK_4,
  },
  text: {
    fontSize: 12,
    fontWeight: 400,
    color: color.GREY_DARK_4,
    letterSpacing: 0.2,
    lineHeight: 1.2,
    marginTop: 4,
  },
  bottomText: {
    fontSize: 10,
    fontWeight: 400,
    color: color.GREY_DARK_1,
    letterSpacing: 0.2,
    textAlign: 'right',
    '& strong': {
      color: color.GREEN_DARK_1,
    }
  },
  topContent: {
    width: '100%',
    display: 'flex',
    height: 22,
    gap: 6,
  },
}));

type Props = {
  hidePoweredByKartera?: boolean;
  messageType?: 'DISCOUNT' | 'CASH_BACK' | 'POINTS' | 'NO_REWARD',
  badgeType?: 'NORMAL' | 'TINY',
  tinyBadgeVariant?: 'GREEN' | 'WHITE' | 'BLACK',
  customLogoSource?: string;
  title?: string;
}

export function KarteraPluginBadge({ 
  hidePoweredByKartera,
  badgeType = 'NORMAL',
  messageType = 'DISCOUNT',
  tinyBadgeVariant = 'WHITE',
  customLogoSource,
  title,
}: Props ) {
  const classes = useStyles();
  const { t } = useTranslation("translation", { keyPrefix: "platform" });

  if (badgeType === 'TINY') {
    return (
      <Box 
        className={classes.tiny}
        sx={{ 
          backgroundColor: tinyBadgeVariant === 'WHITE' 
              ? color.WHITE_01 : tinyBadgeVariant === 'GREEN' 
              ? color.GREEN : color.GREY_DARK_5 
        }}
      >
        <img 
          src={
            customLogoSource ? customLogoSource
              : tinyBadgeVariant === 'WHITE' ? KarteraLogoBlack 
              : tinyBadgeVariant === 'BLACK' ? KarteraLogoGreen 
              : KarteraLogoWhite 
          } 
          alt='Logo'
          height={16}
          width={16}
        />
      </Box>
    )
  }

  const rewardMessage = {
    'DISCOUNT': t('badgeDiscountText'),
    'CASH_BACK': t('badgeCashBackText'),
    'POINTS': t('badgePointsText'),
    'NO_REWARD': '',
  }

  return (
    <Box 
      className={classes.root}
      sx={{ 
        width: messageType === 'NO_REWARD' ? 'fit-content' : 300, 
        minWidth: messageType === 'NO_REWARD' ? 150 : 300 }}
    >
      <Box className={classes.topContent}>
        <Typography className={classes.title}>
          <img 
            src={customLogoSource ? customLogoSource : KarteraLogoBlack} 
            alt='Logo'
            height={22}
            width={22}
          />
        </Typography>
        <Typography className={classes.title}>
          {truncateText(title || t('badgeTitle'), 25)}
        </Typography>
      </Box>
      {messageType !== 'NO_REWARD' && (
        <Typography className={classes.text}>
          <Trans i18nkey='badgeText'>
            {rewardMessage[messageType]}
          </Trans>
        </Typography>
      )}
      {!hidePoweredByKartera && (
        <Typography className={classes.bottomText}>
          <Trans i18nkey='badgePoweredByKartera'>
            {t('badgePoweredByKartera')}
          </Trans>
        </Typography>
      )}
    </Box>
  );
}
