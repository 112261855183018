import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NotFoundImage from '../../assets/errorPageNotFound.png';
import UnderMaintenance from '../../assets/errorPageMaintenance.png';
import ConnectionLost from '../../assets/errorPageConnectionLost.png';
import AccessDenied from '../../assets/errorPageAccessDanied.png';
import UnknownError from '../../assets/errorPageWarning.png';
import { KarteraButton } from '../../components/kartera/button';
import { color } from '../../consts/ColorConst';

const useStyles = makeStyles(() => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    height: '100vh',
    flexDirection: 'column',
  },
  imageWrapper: {
    padding: '0 0 40px 0',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  errorText: {
    color: color.GREY_LIGHT_1,
    fontSize: 26,
    fontWeight: 700,
    padding: '0 8px 0 0',
  },
  title: { 
    fontSize: 26,
    fontWeight: 700,
    color: color.GREY_DARK_3,
  },
  description: {
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 400,
    padding: '8px 0',
    lineHeight: 1.2,
  },
  support: { fontSize: 16, padding: '0 4px 0 0' },
}));

type ErrorPageProps = {
  variant?: 'NOT_FOUND' | 'UNDER_MAINTENANCE' | 'ACCESS_DENIED' | 'CONNECTION_LOST' | 'UNKNOWN_ERROR';
};

type PageContent = {
  [key: string]: {
    imageSrc: string;
    errorText: string;
    title: string;
    description: string;
    support1: string;
    support2: string;
    contact: string;
  };
};

export function ErrorPage({ variant = 'UNKNOWN_ERROR' }: ErrorPageProps) {
  const classes = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'notFound' });

  const values: PageContent = {
    'NOT_FOUND': {
      imageSrc: NotFoundImage,
      errorText: t('errorTextNotFound'),
      title: t('titleNotFound'),
      description: t('descriptionNotFound'),
      support1: t('support1NotFound'),
      support2: t('support2NotFound'),
      contact: t('contactNotFound'),
    },
    'UNDER_MAINTENANCE': {
      imageSrc: UnderMaintenance,
      errorText: t('errorTextUnderMain'),
      title: t('titleUnderMain'),
      description: t('descriptionUnderMain'),
      support1: t('support1UnderMain'),
      support2: t('support2UnderMain'),
      contact: t('contactUnderMain'),
    },
    'ACCESS_DENIED': {
      imageSrc: AccessDenied,
      errorText: t('errorTextAccessDenied'),
      title: t('titleAccessDenied'),
      description: t('descriptionAccessDenied'),
      support1: t('support1AccessDenied'),
      support2: t('support2AccessDenied'),
      contact: t('contactAccessDenied'),
    },
    'CONNECTION_LOST': {
      imageSrc: ConnectionLost,
      errorText: t('errorTextConnectionLost'),
      title: t('titleConnectionLost'),
      description: t('descriptionConnectionLost'),
      support1: t('support1ConnectionLost'),
      support2: t('support2ConnectionLost'),
      contact: t('contactConnectionLost'),
    },
    'UNKNOWN_ERROR': {
      imageSrc: UnknownError,
      errorText: t('errorTextUnknownError'),
      title: t('titleUnknownError'),
      description: t('descriptionUnknownError'),
      support1: t('support1UnknownError'),
      support2: t('support2UnknownError'),
      contact: t('contactUnknownError'),
    },
  };

  document.title = `Kartera - ${values[variant].title}`;

  return (
    <Box>
      <Box data-testid='not-found-page-container' className={classes.root}>
        <Box className={classes.imageWrapper}>
          { variant !== 'CONNECTION_LOST' && (
            <img src={values[variant].imageSrc} alt='Image' sizes='80px' />
          )}
        </Box>
        <Box className={classes.rowContainer}>
          <Typography className={classes.errorText}>{values[variant].errorText}</Typography>
          <Typography className={classes.title}>{values[variant].title}</Typography>
        </Box>
        <Typography className={classes.description}>{values[variant].description}</Typography>
        <Box className={classes.rowContainer}>
          <Typography className={classes.support}>{values[variant].support1}</Typography>
          <KarteraButton
            text={values[variant].contact}
            variant='link'
            className={classes.support}
          ></KarteraButton>
          <Typography className={classes.support}>{values[variant].support2}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
