import { useParams } from "react-router-dom";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { SetupRewardsDialog } from "./setupRewardsDialog";
import TypeOfRewardDialog from "./typeOfRewardDialog";
import PointsDialog from "./pointsDialog";
import DiscountOrCashbackDialog from "./discountOrCashbackDialog";
import { RewardsRequestDTO, RewardsType } from "../../dtos/rewards/rewardsDTO";
import { useRewardsAPI } from "../../services/api/useRewardsAPI";
import { setApiMessage } from "../../reducers/apiMessageReducers";
import { AppRootState } from "../../reducers";
import { setRewardModalOpen, setRewardsModalStep, setShouldCallGetRewardApi } from "../../reducers/rewardsReducers";
import { useState } from "react";

const reduxConnector = connect((state: AppRootState) => ({
  rewardId: state.rewardsState.id,
  rewardType : state.rewardsState.reward_type,
  rewardModalStep: state.rewardsState.rewardsModalStep,
}));

type Props = ConnectedProps<typeof reduxConnector>;

function Rewards({ rewardType, rewardModalStep, rewardId }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation', { keyPrefix: 'rewards' });
  const { postRewards, updateRewards } = useRewardsAPI();
  const { product_id } = useParams();

  const [rewardTypeState, setRewardTypeState] = useState(rewardType as RewardsType);

  const rewardTypesCorrespondence = {
    INSTANT_DISCOUNT: t('instantDiscount'),
    CASHBACK: t('cashBackCredit'),
    POINT_SYSTEM: t('pointsSystem'),
    NO_REWARD: '',
  };

  function handleCloseModal() {
    dispatch(setRewardsModalStep('SETUP'));
    dispatch(setRewardModalOpen(false));
  }

  async function handleSetupRewards(data: RewardsRequestDTO) {
    try {
      if (!product_id) return;
      if (rewardId !== null && rewardId !== "no-reward") {
        await updateRewards(product_id, data);
        dispatch(
          setApiMessage(
            t('successUpdateMessage', {
              type: rewardTypesCorrespondence[rewardTypeState],
              percentage: data.discount_percentage,
            }),
          ),
        );
        return;
      }
      const response = await postRewards(product_id, data);
      if (response.id) {
        if (rewardTypeState === 'POINT_SYSTEM') {
          dispatch(
            setApiMessage(
              t('successPointSystemMessage', {
                amount: data.point_system_earn_ratio,
              }),
            ),
          );
        } else {
          dispatch(
            setApiMessage(
              t('successMessage', {
                type: rewardTypesCorrespondence[rewardTypeState],
                percentage: data.discount_percentage,
              }),
            ),
          );
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setShouldCallGetRewardApi(true));
      handleCloseModal();
    }
  }

  switch (rewardModalStep) {
    case 'SETUP':
      return <SetupRewardsDialog onDiscard={handleCloseModal} />;
    case 'SELECT_TYPE':
      return (
        <TypeOfRewardDialog 
          onCancel={handleCloseModal} 
          rewardType={rewardTypeState}
          setRewardType={setRewardTypeState}
        />
      );
    case 'SUBMIT':
      if (rewardTypeState === 'POINT_SYSTEM') {
        return (
          <PointsDialog
            onDiscard={handleCloseModal}
            createRewards={handleSetupRewards}
          />
        );
      } else if (rewardTypeState === 'INSTANT_DISCOUNT' || rewardTypeState === 'CASHBACK') {
        return (
          <DiscountOrCashbackDialog
            createRewards={handleSetupRewards}
            rewardType={rewardTypeState}
            onDiscard={handleCloseModal}
          />
        );
      } else {
        return null;
      }
  }
}

export default reduxConnector(Rewards);