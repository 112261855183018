import { ReactNode, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation, Trans } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useAuth } from '../../context/Auth';
import { useApi } from '../../services/api/hooks';
import {
  emailIncludesPlusSign,
  isEmailDomainAllowed,
  isEmailValid,
} from '../../utils/handleValuesHelper';
import { OtpLoginRequestDTO } from '../../dtos/auth/otpLoginRequestDTO';
import { OtpRequestDTO } from '../../dtos/auth/otpRequestDTO';
import { color } from '../../consts/ColorConst';
import KarteraLogo from '../../assets/KarteraLogoBlack.svg';
import { KarteraButton } from '../../components/kartera/button';
import { useNavigate } from 'react-router-dom';
import { KarteraOtp } from '../../components/kartera/otp';
import { KarteraTextField } from "../../components/kartera/textField";
import { AuthDataDTO } from "../../dtos/auth/authDataDTO";
import { SetPassword } from "./password";

const useStyles = makeStyles({
  container: {
    backgroundColor: color.GREY_DARK_4,
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formContainer: {
    width: '400px',
    display: 'flex',
    flexDirection: 'column',
    padding: '56px 32px 32px',
    gap: 20,
    backgroundColor: color.WHITE_01,
    borderRadius: 10,
    alignItems: 'center',
    '& > input': {
      fontSize: '14px',
    },
  },
  registerHeader: {
    fontSize: 26,
    fontWeight: 700,
  },
  descriptionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  descriptionText: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.2,
  },
  footerText: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.2,
    color: color.GREY,
  },
  inputWrapper: {
    width: '270px',
    padding: '12px 0 2px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 8,
  },
  nameWrapper: {
    display: 'flex',
    justifyContent: 'center',
    gap: 6,
  },
  errorMessage: {
    color: color.RED,
    fontSize: 11,
    padding: '0 0 10px 0',
  },
  registerButtonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  readyButtonWrapper: {
    padding: '12px 0',
  },
  registerButton: {
    padding: '0 36px',
    textAlign: 'center',
  },
  loginWrapper: {
    display: 'flex',
    marginTop: 24
  },
  loginText: {
    fontSize: 14,
    color: color.GREY_DARK_1,
  },
  loginButton: {
    cursor: 'pointer',
    color: color.GREEN_DARK_1,
    margin: '0 0 0 6px',
    fontSize: 14,
  },
  emailError: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    padding: 0,
    marginTop: -6,
    "& > button": {
      padding: 0,
      fontSize: 11,
    }
  }
});

type ErrorType = {
  firstName: string,
  lastName: string,
  email: string | ReactNode,
}

type StepsType = 'EMAIL' | 'PASSWORD' | 'OTP' | 'SUCCESS';

function Register() {
  const { t } = useTranslation('translation', { keyPrefix: 'register' });
  const classes = useStyles();
  const { signIn } = useAuth();
  const { postOtp, postAuthOtpLogin, postAuthRegister } = useApi();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [hasError, setHasError] = useState(false);

  const [errorMessages, setErrorMessages] = useState<ErrorType>({
    firstName: '',
    lastName: '',
    email: '',
  });

  const [currentStep, setCurrentStep] = useState<StepsType>('EMAIL');
  const [otpToken, setOtpToken] = useState('');
  const [otpTotalSeconds, setOtpTotalSeconds] = useState(300);
  const [otpLoadingValue, setOtpLoadingValue] = useState(40);
  const [otpFailed, setOtpFailed] = useState(false);

  const [loadingOpen, setLoadingOpen] = useState(false);
  const [requestError, setRequestError] = useState('');
  const [dataToSignin, setDataToSignin] = useState<AuthDataDTO>();

  const navigate = useNavigate();

  document.title = 'Kartera - Register';

  async function handleSendOtp(value: string) {
    try {
      setOtpFailed(false);
      const data: OtpLoginRequestDTO = {
        type: 'REGISTER',
        otp_token: otpToken,
        one_time_password: value,
      };
      const response = await postAuthOtpLogin(data);
      setDataToSignin(response);
      setOtpLoadingValue(100);
      if (!response) {
        setOtpLoadingValue(100);
        setOtpFailed(true);
        return;
      }
      setTimeout(() => setCurrentStep('SUCCESS'), 1000);
    } catch (error) {
      setOtpLoadingValue(100);
      setOtpFailed(true);
    }
  }

  async function handleRequestOtp() {
    try {
      setOtpFailed(false);
      const data: OtpRequestDTO = {
        type: 'REGISTER',
        email: email,
      };
      setOtpTotalSeconds(300);
      await postOtp(data);
    } catch (error) {
      setLoadingOpen(false);
    }
  }

  async function handleSignIn() {
    try {
      if (!dataToSignin) return;
      signIn({...dataToSignin, origin: 'REGISTER'});
    } catch (error) {
      setOtpLoadingValue(100);
      setOtpFailed(true);
    }
  }

  useEffect(() => {
    setHasError(!!Object.values(errorMessages).find((msg) => msg !== ''));
  }, [errorMessages]);

  useEffect(() => {
    if (loadingOpen) setLoadingOpen(false);
  }, [firstName, lastName, email]);

  function emailValidation() {
    if (email === '') return;
    let errorMessage = '';
    if (!isEmailValid(email)) errorMessage = t('errorMsgInvalidEmail');
    if (!isEmailDomainAllowed(email)) errorMessage = t('errorMsgWorkEmail');
    if (emailIncludesPlusSign(email)) errorMessage = t('errorMsgEmailPlusSign');
    setErrorMessages((prev) => ({ ...prev, email: errorMessage }));
  }

  function handleSubmitError(error: any) {
    if (error.message.includes('already exists')) {
      setErrorMessages(prev => {
        return ({
          ...prev, 
          email: <div className={classes.emailError}>
            {t('errorMsgEmailAlreadyExists')}
            <KarteraButton 
              variant="link"
              text={t('forgotPassword')}
              onClick={() => navigate('/reset-password')}
            />
          </div>
        })
      });
      setCurrentStep('EMAIL');
      return;
    }
    setRequestError(error.message)
  }

  async function handleSubmit(password: string, confirmPassword: string) {
    try {
      setLoadingOpen(true);
      setRequestError('');
      const data = {
        legal_first_name: firstName,
        legal_last_name: lastName,
        work_email: email,
        password: password,
        confirm_password: confirmPassword,
      };
      const response = await postAuthRegister(data, handleSubmitError);
      setOtpToken(response.otp_token);
      const dataOtpToken: OtpRequestDTO = {
        type: 'REGISTER',
        email: email,
      };
      await postOtp(dataOtpToken);
      setTimeout(() => setCurrentStep('OTP'), 1000);
    } catch (error: unknown) {
      setLoadingOpen(false);
    }
  }

  const buttonDisabled =
    hasError || !firstName || !lastName || !email;

  return (
    <div className={classes.container}>
      {currentStep === 'SUCCESS' ? (
        <form className={classes.formContainer}>
          <img src={KarteraLogo} alt='Kartera logo' width={70} />
          <Typography className={classes.registerHeader}>
            {t('readyForKartera')}
          </Typography>
          <div className={classes.descriptionWrapper}>
            <Typography className={classes.descriptionText}>{t('readyPanelText1')}</Typography>
            <Typography className={classes.descriptionText}>
              <Trans>
                {t('readyPanelText2')}
              </Trans>
            </Typography>
          </div>
          <Box className={classes.readyButtonWrapper}>
            <KarteraButton
              text={t('launchDashboard')}
              className={classes.registerButton}
              onClick={handleSignIn}
            />
          </Box>
          <Typography className={classes.footerText}>
            <Trans>
              {t('readyPanelText3')}
            </Trans>
          </Typography>
        </form>
      ) : currentStep === 'OTP' ? (
        <div>
          <KarteraOtp
            type='register'
            title={t('formTitle')}
            subtitle={t('formSubtitle')}
            descriptionTitle={t('otpDescriptionTitle')}
            description={t('otpDescription', { email })}
            progressValue={otpLoadingValue}
            totalSeconds={otpTotalSeconds}
            onPinCodeComplete={(value: any) => handleSendOtp(value)}
            requestClick={() => handleRequestOtp()}
            error={otpFailed}
          />
        </div>
      ) : currentStep === 'PASSWORD' ? (
        <div>
          <SetPassword
            email={email}
            onSubmit={handleSubmit}
          />
        </div>
      ) : (
        <form className={classes.formContainer}>
          <img src={KarteraLogo} alt='Kartera logo' width={70} />
          <Typography className={classes.registerHeader}>{t('registerHeader')}</Typography>
          <div className={classes.descriptionWrapper}>
            <Typography className={classes.descriptionText}>{t('formDescription')}</Typography>
            <Typography className={classes.descriptionText}>
              <Trans i18nkey='approvalDescription'>
                {t('approvalDescription')}
              </Trans>
            </Typography>
          </div>
          <div className={classes.inputWrapper}>
            <div className={classes.nameWrapper}>
              <KarteraTextField
                value={firstName}
                width={'50%'}
                placeholder={t('firstNamePlaceholder')}
                errorMessage={errorMessages.firstName}
                required
                onChange={(e) => setFirstName(e.target.value)}
                onCancel={() => setFirstName('')}
                spacious
              />
              <KarteraTextField
                value={lastName}
                width={'50%'}
                placeholder={t('lastNamePlaceholder')}
                errorMessage={errorMessages.lastName}
                required
                onChange={(e) => setLastName(e.target.value)}
                onCancel={() => setLastName('')}
                spacious
              />
            </div>
            <KarteraTextField
              id="email"
              value={email}
              fullWidth
              placeholder={t('emailPlaceholder')}
              errorMessage={errorMessages.email}
              required
              onChange={(e) => setEmail(e.target.value)}
              onCancel={() => setEmail('')}
              onBlur={emailValidation}
              spacious
            />
          </div>
          {requestError && <p className={classes.errorMessage}>{requestError}</p>}
          <Box className={classes.registerButtonWrapper}>
            <KarteraButton
              text={t('registerButton')}
              disabled={buttonDisabled}
              className={classes.registerButton}
              onClick={() => setCurrentStep('PASSWORD')}
              spacious
            />
          </Box>
          <Box className={classes.loginWrapper}>
            <Typography className={classes.loginText}>{t('loginText')}</Typography>
            <Typography className={classes.loginButton} onClick={() => navigate('/login')}>
              {t('loginButton')}
            </Typography>
          </Box>
        </form>
      )}
    </div>
  );
}


export default Register;