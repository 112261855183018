import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { color } from "../../consts/ColorConst";
import { useAuth } from "../../context/Auth";
import { KarteraHeadlineMenu } from '../../components/kartera/headlineMenu';
import ConnectAccountModal from '../../components/connectAccountModal';
import { AppRootState } from '../../reducers';
import { Refunds } from "../refunds";
import Transactions from "../transactions";
import { useApi } from "../../services/api/hooks";
import { Payments } from "../payments";
import Graphs from "./graphs";
import { IntervalType } from "../../dtos/reports/reportDTO";
import { setAccountConnectClosed } from "../../reducers/accountCoonectReducer";
import { setBusinessVerification } from "../../reducers/businessVerificationReducers";
import { ConditionalComponent } from "../../components/contitionalComponent";
import { setRewardModalOpen } from "../../reducers/rewardsReducers";
import { REPORT_INTERVAL } from "../../consts/UIConstants";

const useStyles = makeStyles((theme: any) => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.main,
    padding: '35px 40px',
  },
  contentContainer: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 0 20px 0',
  },
  welcomeTitle: {
    fontSize: 26,
    fontWeight: 700,
    padding: '0 20px 0 0',
  },
  boxContainer: {
    backgroundColor: theme.palette.common.white,
    height: 174,
    width: '100%',
    padding: 20,
    borderRadius: 18,
  },
  tableWrapper: {
    margin: '16px 0',
    width: '100%',
  },
  errorTitle: {
    color: theme.palette.error.main,
  },
}));

const reduxConnector = connect((state: AppRootState) => ({
  accountConnectClosed: state.accountConnectState.closed,
  shouldShowMockData: state.demoAccountState.shouldShowMockData,
}));

type Props = ConnectedProps<typeof reduxConnector>;

function Dashboard({ accountConnectClosed, shouldShowMockData }: Props) {
  const classes = useStyles();
  const { getRecurringPayments, getConnectedAccount, 
          syncOpenSearchIndex } = useApi();
  const dispatch = useDispatch();
  const { partner, isTestEnvironment } = useAuth();
  const { product_id } = useParams();
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard' });

  const [reportInterval, setReportInterval] = useState<IntervalType>('MONTH');
  const [connectAccountOpen, setConnectAccountOpen] = useState(true);
  const [hasPayments, setHasPayments] = useState(false);

  document.title = 'Kartera - Dashboard';

  async function getAsyncData() {
    try {
      if (product_id) {
        await syncOpenSearchIndex({ index: "" });
        const accountResponse: any = await getConnectedAccount(product_id);
        if (accountResponse && accountResponse.data.length > 0) {
          setConnectAccountOpen(false);
          dispatch(setAccountConnectClosed(true));
        }

        const payments = await getRecurringPayments(product_id);
        setHasPayments(payments?.data?.length > 0 || false);

      }
    } catch (error) {
      setHasPayments(false);
    }
  }

  function showBusinessVerificationDialog() {
    dispatch(setBusinessVerification(true));
  }

  function handleInterval(event: any) {
    if (partner) {
      return setReportInterval(event.target.value);
    }
    showBusinessVerificationDialog();
  }
  
  function handleOpenRewardSetup() {
    dispatch(setRewardModalOpen(true));
  }

  useEffect(() => {
    if (shouldShowMockData) return;    
    getAsyncData();
  }, [shouldShowMockData]);

  return (
    <Box data-testid='dashboard-page-container' className={classes.root}>
      <Box data-testid='dashboard-page-container-ready' className={classes.contentContainer}>
        <Box className={classes.titleContainer}>
          <Typography className={classes.welcomeTitle}>{t('glanceText')}</Typography>
          <KarteraHeadlineMenu
            items={REPORT_INTERVAL}
            width={'120px'}
            defaultValue={'MONTH'}
            onChange={handleInterval}
          />
        </Box>
        <ConditionalComponent condition={!isTestEnvironment}>
          <Graphs 
            useMockData={shouldShowMockData}
            interval={reportInterval}
            partner={partner}
            handleRewardsDialog={handleOpenRewardSetup} 
          />
        </ConditionalComponent>
        <Box className={classes.tableWrapper}>
          <Transactions 
            hasPayments={hasPayments} 
            isDemo={shouldShowMockData} 
            showBusinessVerificationDialog={showBusinessVerificationDialog}
          />
        </Box>
        <Box className={classes.tableWrapper}>
          <Refunds 
            isDemo={shouldShowMockData}
            showBusinessVerificationDialog={showBusinessVerificationDialog}
          />
        </Box>
        <ConditionalComponent condition={hasPayments}>
          <Box className={classes.tableWrapper}>
            <Payments />
          </Box>
        </ConditionalComponent>
      </Box>
      <ConditionalComponent condition={(!accountConnectClosed && !!partner && connectAccountOpen)}>
        <ConnectAccountModal
          modalOpen={connectAccountOpen}
          setModalOpen={setConnectAccountOpen}
        />
      </ConditionalComponent>
    </Box>
  );
}

export default reduxConnector(Dashboard);
