import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { color } from '../../../../consts/ColorConst';
import { useApi } from '../../../../services/api/hooks';
import { ProductUserSearchDTO } from '../../../../dtos/productUsers/productUserSearchDTO';
import { KarteraSelectField } from '../../../../components/kartera/selectField';
import { truncateText } from '../../../../utils/helperFunctions';
import { KarteraProgress } from '../../../../components/kartera/progress';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 16,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 500,
  },
  customerData: {
    width: 400,
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY_LIGHT_1,
    letterSpacing: 0.2,
    '& strong': {
      color: color.GREY_DARK_4,
      fontWeight: 400,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  loaderContainer:{
    display:"flex",
    justifyContent:"center",
    padding:"5px"
  },
  customerList: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 160,
    margin: '8px 0 20px',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: color.GREY_LIGHT_2,
      borderRight: `2px solid ${color.GREY_LIGHT_5}`,
    },
    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        borderRight: `2px solid ${color.GREY_LIGHT_4}`,
      },
    },
  },
  customerItem: {
    display: 'flex',
    gap: 4,
    padding: '6px 8px',
    '&:hover': {
      backgroundColor: color.GREY_LIGHT_5,
    },
    cursor: 'pointer',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

type Props = {
  setCustomer: (v: ProductUserSearchDTO) => void;
  nextStep: () => void;
};

export function SelectCustomer({ nextStep, setCustomer }: Props) {
  const classes = useStyles();
  const { getUsersSearch } = useApi();
  const { product_id } = useParams();
  const { t } = useTranslation('translation', { keyPrefix: 'refunds' });

  const [searchText, setSearchText] = useState('');
  const [usersLoading, setUsersLoading] = useState(false);
  const [customers, setCustomers] = useState<ProductUserSearchDTO[]>([]);

  async function getUsers() {
    if (product_id) {
      await getUsersSearch(product_id, searchText);
    }
  }

  async function getUsersList() {
    setUsersLoading(true);
    if (product_id) {
      const response = await getUsersSearch(product_id);
      setCustomers(response);
      setUsersLoading(false);
    } else {
      setUsersLoading(false);
    }
  }

  function handleCustomerSelect(customer: any) {
    setCustomer(customer);
    nextStep();
  }

  function handleDropdownSelect(event: any) {
    setSearchText(event.target.value);
    const selectedIndex = customers.findIndex((customer) => customer.id === event.target.value);
    setCustomer(customers[selectedIndex]);
    nextStep();
  }

  useEffect(() => {
    getUsersList();
  }, []);

  useEffect(() => {
    getUsers();
  }, [searchText]);


  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>{t('createRefund')}</Typography>
      <KarteraSelectField
        labelStyle='simple'
        sx={{ margin: '16px 0' }}
        value={searchText}
        onChange={(e: any) => handleDropdownSelect(e)}
        items={customers?.map((customer) => ({
          value: customer.id,
          text: `${customer.first_name} ${customer.last_name} (${customer.email})`,
        }))}
        placeholder={t('searchCustomerPlaceholder')}
        width={'312px'}
      />
      <Typography className={classes.subtitle}>{t('recentCustomers')}</Typography>
      {usersLoading ? (
        <Box className={classes.loaderContainer}>
          <KarteraProgress></KarteraProgress>
        </Box>
      ) : (
        <Box className={classes.customerList}>
          {customers.map((customer) => (
            <div
              key={customer.email}
              className={classes.customerItem}
              onClick={() => handleCustomerSelect(customer)}
            >
              <Typography className={classes.customerData}>
                <strong>{`${customer.first_name} ${customer.last_name}`}</strong>
                {` (${truncateText(
                  customer.email,
                  38 - (customer.first_name.length + customer.last_name.length),
                )})`}
              </Typography>
            </div>
          ))}
        </Box>
      )}
    </Box>
  );
}
