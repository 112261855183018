import { FC } from "react";
import { makeStyles } from "@mui/styles";
import { Box, MenuItem, Select } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { sentenceCaseGenerator } from "../../../utils/helperFunctions";
import { color } from "../../../consts/ColorConst";
import { SelectFieldProps } from "./types";

const useStyles = makeStyles((theme: any) => ({
  root: {
    fontFamily: 'Roboto',
    position: 'relative',
    '& .Mui-focused': {
      backgroundColor: color.WHITE_01,
      border: `1.5px solid ${color.GREEN}`,
      boxShadow: theme.shadow.elevation4Green,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .Mui-disabled': {
      backgroundColor: color.GREY_LIGHT_6,
      color: color.GREY_LIGHT_4,
      '&:hover': {
        backgroundColor: color.GREY_LIGHT_6,
        borderColor: color.GREY_LIGHT_6,
        boxShadow: 'none',
      },
      '& > svg': {
        color: color.GREY_LIGHT_4,
      },
    },
  },
  menuItemRoot: {
    padding: '0px 14px',
    fontSize: 14,
    [theme.breakpoints.down('md')]: {
      height: '40px',
    },
    '&$menuItemSelected, &$menuItemSelected:focus, &$menuItemSelected:hover': {
      backgroundColor: color.GREEN_LIGHT_4,
    },
  },
  menuItemSelected: {},
  selectStyle: {
    height: '28px',
    width: '100%',
    backgroundColor: color.GREY_LIGHT_5,
    color: color.GREY_DARK_4,
    border: `1.5px solid ${color.GREY_LIGHT_5}`,
    borderRadius: 6,
    [theme.breakpoints.down('md')]: {
      height: '34px',
    },
    '& .MuiInputBase-input': {
      padding: '0 0 0 10px',
      '&::placeholder': {
        color: color.GREY_DARK_4,
      },
    },
    '& .MuiSelect-select': {
      fontSize: '14px',
      fontWeight: 400,
      color: color.GREY_DARK_4,
    },
    '& > svg': {
      color: color.GREY_LIGHT_1,
    },
    '&:hover': {
      backgroundColor: color.GREY_LIGHT_5,
      borderColor: color.GREY_LIGHT_3,
      boxShadow: theme.shadow.elevation4Grey,
    },
  },
  placeholder: {
    color: color.GREY_LIGHT_1,
  },
  hasError: {
    backgroundColor: color.RED_LIGHT_5,
    borderColor: color.RED,
    '& .MuiInputBase-input': {
      color: color.RED_DARK_3,
      '&::placeholder': {
        color: color.GREY_LIGHT_1,
      },
    },
    '& > svg': {
      color: color.RED_DARK_2,
    },
  },
  errorMessage: {
    margin: '2px 0 0 0',
    fontFamily: 'inherit',
    fontSize: '11px',
    color: color.RED,
  },
  subNote: {
    margin: '2px 0 0 0',
    fontSize: '10px',
    letterSpacing: 0.3,
    color: color.GREY_DARK_1,
  },
  label: {
    display: 'block',
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.4,
    marginBottom: 4,
    color: color.GREY_DARK_1,
  },
  title: {
    marginBottom: 12,
    color: color.GREY_DARK_4,
  },
  disabled: {
    color: color.GREY_LIGHT_1,
  },
}));

export const KarteraSelectField: FC<SelectFieldProps> = ({
  placeholder,
  width = "100%",
  errorMessage,
  error,
  subNote,
  items,
  label,
  labelStyle = 'title',
  sx,
  disabled,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Box 
      className={classes.root} 
      sx={{ ...sx, width: width }}
    >
      {label && (
        <label 
          className={`
            ${classes.label} 
            ${labelStyle === 'title' && classes.title}
            ${disabled && classes.disabled}
          `} 
        >
          {label}
        </label>
      )}
      <Select
        displayEmpty
        IconComponent={KeyboardArrowDown}
        className={`
          ${classes.selectStyle} 
          ${(!!errorMessage || error) && classes.hasError}
        `}
        error={!!errorMessage}
        MenuProps={{ PaperProps: {
          style: {
            marginTop: 4,
            maxHeight: 300,
            width: width,
          },
        }}}
        renderValue={value => {
          if (!value && placeholder) {
            return <span className={classes.placeholder}>{placeholder}</span>
          }
          const selectedItem = items.find(item => item.value === value ); 
          return selectedItem?.text
        }}
        disabled={disabled}
        {...rest}
      >
        {items.map( item => (
          <MenuItem
            dense
            key={`select-item-${item.text}`}
            classes={{ 
              root: classes.menuItemRoot,
              selected: classes.menuItemSelected,
            }}
            value={item.value} 
            disabled={item.disabled}
          >
            {item.text}
          </MenuItem>
        ))}
      </Select>
      {errorMessage && (
        <p className={classes.errorMessage}>
          {sentenceCaseGenerator(errorMessage)}
        </p>
      )}
      {subNote && (
        <p className={classes.subNote}>
          {subNote}
        </p>
      )}
    </Box>
  );
};
