import { FC, useState } from "react";
import { Box, Collapse, Alert as MaterialAlert, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faTriangleExclamation,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { makeStyles } from "@mui/styles";
import { color } from '../../../consts/ColorConst';
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { AlertProps } from "./types";

const useStyles = makeStyles((theme: any) => ({
  container: {
    width: '100%',
    padding: 0,
    '&& .MuiPaper-root': {
      alignItems: 'center',
    },
    '&& .MuiAlert-action': {
      padding: 0,
    },
  },
  alertBox: {
    padding: '0 18px 0 16px',
    borderRadius: 6,
    fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: '16.4px',
    minHeight: 36,
    fontWeight: 400,
    '&& .MuiIconButton-root': {
      '&:hover': {
        backgroundColor: 'unset',
      },
      '&:active': {
        backgroundColor: 'unset',
      },
    },
    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
  },
  error: {
    backgroundColor: color.RED_LIGHT_5,
    color: color.RED_DARK_3,
    '&& .MuiAlert-icon': {
      color: color.RED_DARK_1,
    },
  },
  warning: {
    backgroundColor: color.ORANGE_LIGHT_5,
    color: color.ORANGE_DARK_3,
    '&& .MuiAlert-icon': {
      color: color.ORANGE_DARK_1,
    },
  },
  info: {
    backgroundColor: color.BLUE_LIGHT_03,
    color: color.BLUE_DARK_3,
    '&& .MuiAlert-icon': {
      color: color.BLUE_DARK_1,
    },
  },
  success: {
    backgroundColor: color.GREEN_LIGHT_5,
    color: color.GREEN_DARK_3,
    '&& .MuiAlert-icon': {
      color: color.GREEN_DARK_2,
    },
  },
}));

export const KarteraAlert: FC<AlertProps> = ({
  severity,
  text,
  supressCloseButton,
  sx,
  onClose,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const closeButtonColors = {
    success: color.GREEN_DARK_1,
    error: color.RED_DARK_1,
    warning: color.ORANGE_DARK_1,
    info: color.BLUE_DARK_1,
  };

  return (
    <Box className={classes.container} sx={sx}>
      <Collapse in={open}>
        <MaterialAlert
          className={`${classes.alertBox} ${classes[severity]}`}
          action={
            !supressCloseButton && (
              <IconButton
                aria-label='close'
                size='small'
                style={{ color: closeButtonColors[severity] }}
                onClick={() => {
                  setOpen(false);
                  onClose && onClose();
                }}
              >
                <FontAwesomeIcon icon={faXmark} size='sm' />
              </IconButton>
            )
          }
          severity={severity}
          iconMapping={{
            success: <FontAwesomeIcon icon={faCircleCheck} size='2xs' />,
            warning: <FontAwesomeIcon icon={faTriangleExclamation} size='2xs' />,
            error: <FontAwesomeIcon icon={faCircleExclamation} size='2xs' />,
            info: <FontAwesomeIcon icon={faCircleInfo} size='2xs' />,
          }}
        >
          {text}
        </MaterialAlert>
      </Collapse>
    </Box>
  );
};
