import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmarkHexagon } from '@fortawesome/pro-regular-svg-icons';
import { color } from '../../../consts/ColorConst';
import { AppRootState } from '../../../reducers';
import { KarteraButton } from '../../../components/kartera/button';
import { KarteraChip } from '../../../components/kartera/chip';
import { useRewardsAPI } from '../../../services/api/useRewardsAPI';
import { RewardsType } from '../../../dtos/rewards/rewardsDTO';
import { RewardsStepsType, setShouldCallGetRewardApi, setRewardModalOpen, 
         setRewardsModalStep } from '../../../reducers/rewardsReducers';
import { ConditionalComponent } from '../../../components/contitionalComponent';
import { KarteraDialogue } from '../../../components/kartera/dialogue';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '440px',
    padding: '20px 20px',
    whiteSpace: 'pre-line',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  descripMoreWrapper: {
    display: 'flex',
    padding: '12px 0 0 0',
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  removeButton: {
    color: color.RED,
    fontSize: 14,
    fontWeight: 500,
    cursor: 'pointer',
    '&:hover': {
      color: color.RED_DARK_1,
    },
  },
  heading: {
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY_DARK_1,
    letterSpacing: '0.2px',
    paddingTop: 8,
  },
  description: {
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY_DARK_3,
    letterSpacing: '0.2px',
    display: 'flex',
    alignItems: 'center',
    gap: 8
  },
  learnMore: {
    textDecoration: 'none',
    color: color.GREEN,
    fontSize: 14,
    fontWeight: 400,
  },
  learnMorePlaid: {
    textDecoration: 'none',
    margin: '15px 0 0 0',
    color: color.GREEN,
    fontSize: 14,
    fontWeight: 400,
    display: 'flex',
  },
  buttonWrapper: {
    padding: '12px 0 0 0',
  },
}));

const reduxConnector = connect((state: AppRootState) => ({
  isLoadingRewards: state.rewardsState.isLoadingRewards,
  rewardId: state.rewardsState.id,
  rewardType: state.rewardsState.reward_type,
  rewardActive: state.rewardsState.active,
  cashbackValidityDays: state.rewardsState.cashback_validity_days,
  discountPercentage: state.rewardsState.discount_percentage,
  pointSystemEarnRatio: state.rewardsState.point_system_earn_ratio,
  pointSystemIntroOfferParam: state.rewardsState.point_system_intro_offer_param,
  pointSystemIntroOfferTnxLimit: state.rewardsState.point_system_intro_offer_tnx_limit,
  pointSystemSpendRatio: state.rewardsState.point_system_spend_ratio,
}));

type RewardsDataDescriptionProps = {
  label: string;
  value: string;
  buttonText: string;
  onClick: () => void;
};

type Props = ConnectedProps<typeof reduxConnector>;

function RewardsProgram({ 
  isLoadingRewards,
  rewardId,
  rewardType,
  rewardActive,
  cashbackValidityDays,
  discountPercentage,
  pointSystemEarnRatio,
  pointSystemIntroOfferParam,
  pointSystemIntroOfferTnxLimit,
  pointSystemSpendRatio,
 }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'settings' });
  const dispatch = useDispatch();
  const { t: tRewards } = useTranslation('translation', { keyPrefix: 'rewards' });
  const { deleteRewards } = useRewardsAPI();
  const { product_id } = useParams();
  const classes = useStyles();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const rewardTypesCorrespondence = {
    'INSTANT_DISCOUNT': tRewards('instantDiscount'),
    'CASHBACK': tRewards('cashBackCredit'),
    'POINT_SYSTEM': tRewards('pointsSystem'),
    'NO_REWARD': '',
  }

  function RewardDataDescription({ 
    label, 
    value, 
    buttonText, 
    onClick 
  }: RewardsDataDescriptionProps) {
    return (
      <div>
        <p className={classes.heading}>{label}:</p>
        <p className={classes.description} style={{ fontWeight: 500 }}>
          {value}
          <KarteraButton
            variant='link'
            text={buttonText}
            onClick={onClick}
          />
        </p>    
      </div>
    )
  }

  function handleSetupRewards(step: RewardsStepsType) {
    dispatch(setRewardsModalStep(step));
    handleOpenRewardSetup();
  }

  function handleOpenRewardSetup() {
    dispatch(setRewardModalOpen(true));
  }

  async function handleRemoveReward() {
    try {
      if (product_id && rewardId) {
        await deleteRewards(product_id);
        dispatch(setShouldCallGetRewardApi(true));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShowDeleteModal(false);
    }
  }

  if (isLoadingRewards) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center'>
        <CircularProgress sx={{ color: color.GREEN }} size={38} />
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      {rewardId !== 'no-reward' && rewardId !== '' ? (
        <Box>
          <Typography className={classes.heading}>{t('status')}:</Typography>
          <Box className={classes.statusWrapper}>
            <KarteraChip
              text={rewardActive ? t('active') : t('inactive')}
              size={'small'}
              variant={rewardActive ? 'positive' : 'negative'}
              icon={
                <FontAwesomeIcon icon={rewardActive ? faCheck : faXmarkHexagon} width={'11px'} />
              }
            />
            <KarteraButton
              className={classes.removeButton}
              text={t('remove')}
              variant={'link'}
              onClick={() => setShowDeleteModal(true)}
            />
          </Box>
          <RewardDataDescription
            label={t('rewardsType')}
            value={rewardTypesCorrespondence[rewardType as RewardsType]}
            buttonText={t('change')}
            onClick={() => handleSetupRewards('SELECT_TYPE')}
          />
          {rewardType === 'POINT_SYSTEM' ? (
            <>
              <RewardDataDescription
                label={tRewards('pointsCollection')}
                value={tRewards('pointsCollectionLabel', {
                  points: pointSystemEarnRatio,
                })}
                buttonText={t('edit')}
                onClick={() => handleSetupRewards('SUBMIT')}
              />
              <RewardDataDescription
                label={tRewards('pointsRedemption')}
                value={tRewards('pointsRedemptionLabel', {
                  points: pointSystemSpendRatio,
                })}
                buttonText={t('edit')}
                onClick={() => handleSetupRewards('SUBMIT')}
              />
              <RewardDataDescription
                label={tRewards('introductoryOffer')}
                value={tRewards('introductoryOfferLabel', {
                  points: pointSystemIntroOfferParam,
                  value: pointSystemIntroOfferTnxLimit,
                })}
                buttonText={t('edit')}
                onClick={() => handleSetupRewards('SUBMIT')}
              />
            </>
          ) : (
            <>
              <RewardDataDescription
                label={t('percentage')}
                value={discountPercentage + '%'}
                buttonText={t('edit')}
                onClick={() => handleSetupRewards('SUBMIT')}
              />
              {rewardType === 'CASHBACK' && (
                <RewardDataDescription
                  label={t('cashBackBreakage')}
                  value={`${cashbackValidityDays} Days`}
                  buttonText={t('edit')}
                  onClick={() => handleSetupRewards('SUBMIT')}
                />
              )}
            </>
          )}
        </Box>
      ) : (
        <Box className={classes.content}>
          <Typography className={classes.description} style={{ fontWeight: 500 }}>
            {t('rewardsTitle')}
          </Typography>
          <Typography className={classes.description}>{t('rewardsTextP1')}</Typography>
          <Typography className={classes.description}>{t('rewardsTextP2')}</Typography>
          <Box className={classes.buttonWrapper}>
            <KarteraButton
              text={t('setupRewards')}
              variant={'secondary'}
              onClick={handleOpenRewardSetup}
            />
          </Box>
        </Box>
      )}
      <ConditionalComponent condition={showDeleteModal}>
        <KarteraDialogue
          id='deleteRewardModal'
          title={t('deleteRewardModalTitle', { rewardType: rewardTypesCorrespondence[rewardType as RewardsType] })}
          activeButtonType='negative'
          size='small'
          autoHeight
          actionButtonText={t('remove')}
          cancelButtonText={t('cancel')}
          onActionClick={handleRemoveReward}
          onCancelClick={() => setShowDeleteModal(false)}
          showCloseButton={false}
        >
          <Box className={classes.content}>
            <Typography className={classes.description}>
              {t('deleteRewardModalDescriptionP1')}
            </Typography>
            <Typography className={classes.description}>
              {t('deleteRewardModalDescriptionP2')}
            </Typography>
          </Box>
        </KarteraDialogue>
      </ConditionalComponent>
    </Box>
  );
}

export default reduxConnector(RewardsProgram);
