import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@mui/styles";
import { color } from '../../../consts/ColorConst';
import { RadioTileProps } from "./types";


const useStyles = makeStyles((theme: any) => ({
  button: {
    width: '100%',
    minHeight: 40,
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    borderRadius: 6,
    padding: "6px 14px",
    transition: "all ease .2s",
    backgroundColor: color.WHITE_01,
    border: `0.5px solid ${color.GREY_LIGHT_3}`,
    color: color.GREY,
    "&:hover:enabled": { 
      boxShadow: theme.shadow.elevation4Grey,  
    },
    "&:active:enabled": { 
      borderColor: color.GREY_DARK_2,
      boxShadow: theme.shadow.elevation4Grey, 
    },
    "&:disabled": { 
      backgroundColor: color.WHITE_01,
      borderColor: color.GREY_LIGHT_3,
      color: color.GREY_LIGHT_3,
    },
  },
  active: {
    backgroundColor: color.GREEN_LIGHT_5,
    color: color.GREEN_DARK_2,
    borderColor: color.GREEN,
    "&:hover:enabled": { 
      backgroundColor: color.GREEN_LIGHT_5,
      borderColor: color.GREEN,
      boxShadow: theme.shadow.elevation4Green,  
    },
    "&:active:enabled": { 
      backgroundColor: color.GREEN_LIGHT_5,
      borderColor: color.GREEN_DARK_2,
      boxShadow: theme.shadow.elevation4Green,  
    },
    "&:disabled": { 
      backgroundColor: color.GREEN_LIGHT_6,
      color: color.GREEN_LIGHT_3,
      borderColor: color.GREEN_LIGHT_3,
    },
  },
  content: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  checkWrapper: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

export function KarteraRadioTile({ 
  text,
  active,
  icon,
  disabled,
  onClick
 }: RadioTileProps) {
  const classes = useStyles();

  return (
    <button
      className={`
        ${classes.button} 
        ${active && classes.active}
      `}
      defaultChecked={true}
      disabled={disabled}
      onClick={onClick} 
    >
      <div className={classes.content}>
        {icon && (
          <div className={classes.iconWrapper}>
            {icon}
          </div>
        )}
        {text}
        {active && (
          <div className={classes.checkWrapper}>
            <FontAwesomeIcon icon={faCheck} />
          </div>
        )}
      </div>
    </button>
  );
}
