import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { color } from '../../../consts/ColorConst';
import { Typography } from '@mui/material';
import { KarteraButton } from '../../kartera/button';
import { KarteraSelectField } from '../../kartera/selectField';
import { KarteraTextField } from '../../kartera/textField';
import { KarteraSwitch } from '../../kartera/switch';
import { KarteraSegmentedButton } from '../../kartera/segmentedButton';
import { KarteraUploadButton } from '../../kartera/uploadButton';
import { KarteraRadioButton } from '../../kartera/radioButton';
import { PaymentWidgetBadge } from '../../kartera/paymentWidgetBadge/PaymentWidgetBadge';
import { UploadImageErrorDialogue } from '../../platforms/uploadImageErrorDialogue';
import KarteraLogo from '../../kartera/logo/KarteraLogo';

const useStyles = makeStyles((theme: any) => ({
  container: {
    width: 631,
    padding: '16px 16px 20px',
    background: color.WHITE_01,
    borderRadius: 6,
    boxShadow: theme.shadow.elevation4Grey,
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 20,
  },
  logoContainer: {
    display: 'flex',
    gap: 10,
    marginBottom: 6,
  },
  checkBoxContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
  },
  imageContainer: {
    width: 42,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${color.GREY_LIGHT_3}`,
    borderRadius: 4,
    padding: 6,
    gap: 10,
  },
  text: {
    fontSize: 14,
    fontWeight: 400,
    paddingRight: 20,
    color: color.GREY_DARK_4,
  },
  smallLabel: {
    marginTop: 2,
    fontSize: 11,
    fontWeight: 400,
    color: color.GREY_LIGHT_2,
    textAlign: 'right',
  },
  ugradeText: {
    fontSize: 12,
    fontWeight: 500,
    color: color.GREY_DARK_2,
    '& strong': {
      color: color.GREEN_DARK_1,
    },
  },
  message: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.2,
  },
  content: {
    display: 'flex',
    padding: '20px 0',
  },
  badgeContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    '& label': {
      fontSize: 14,
      fontWeight: 500,
      color: color.GREY_DARK_1,
    },
  },
  LIGHT: {
    backgroundColor: theme.palette.light.backgroundColor,
    '& label': {
      color: theme.palette.light.fontColor,
    },
  },
  DARK: {
    backgroundColor: theme.palette.dark.backgroundColor,
    '& label': {
      color: theme.palette.light.fontColor,
    },
  },
  toggleButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
    '& label': {
      fontSize: 10,
      fontWeight: 400,
      color: color.GREY_LIGHT_1,
    },
  },
  footer: {
    display: 'flex',
  },
  footerRightContent: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    gap: 12,
  },
  badgeWrapper: {
    width: 364,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '32px 32px 20px',
    marginRight: 15,
  },
  uploadImageWrapper: {
    display: 'flex',
    width: 80,
    flexDirection: 'column',
    gap: 4,
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY_DARK_1,
  },
  rigthContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  image: {
    margin: '0 20px',
  },
  button: {
    width: 'fit-content',
    padding: 0,
  },
}));

type ModeType = 'LIGHT' | 'DARK';
type ThemeType = 'KARTERA' | 'FIRE' | 'MINIMALIST' | 'NEUTRAL' | 'OCEAN' | 'SUNSET';
type LogoType = 'BLACK' | 'GREEN' | 'GRAY' | 'CUSTOM';
type ErrorType = 'SIZE' | 'FORMAT' | 'DIMENSION' | 'UNKNOWN' | undefined;

type Props = {
  onClose: () => void;
};

export function CustomizeWidgetDialog({ onClose }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'paymentWidget' });
  const classes = useStyles();

  const [title, setTitle] = useState('');
  const [theme, setTheme] = useState<ThemeType>('KARTERA');
  const [mode, setMode] = useState<ModeType>('LIGHT');
  const [logoType, setLogoType] = useState<LogoType>('BLACK');
  const [showPoweredByKartera, setShowPoweredByKartera] = useState(true);
  const [showSavings, setShowSavings] = useState(true);
  const [allowCashBack, setAllowCashBack] = useState(true);
  const [imageError, setImageError] = useState<ErrorType>();
  const [customLogo, setCustomLogo] = useState<string>();
  const [useCreditStatus, setUseCreditStatus] = useState<boolean>(false);

  const charLeft = 25 - title.length;
  const isDefault =
    theme === 'KARTERA' &&
    mode === 'LIGHT' &&
    title === '' &&
    showPoweredByKartera &&
    allowCashBack &&
    showSavings;

  function handleReset() {
    setTheme('KARTERA');
    setMode('LIGHT');
    setTitle('');
    setAllowCashBack(true);
    setShowPoweredByKartera(true);
    setImageError(undefined);
  }

  function handleTitle(text: string) {
    if (text.length <= 25) {
      setTitle(text);
    }
  }

  async function handleImageUpload(event: any) {
    try {
      setImageError(undefined);
      const file = event.target.files[0];
      if (!file) return;
      if (file.size > 2 * 1024 * 1024) {
        setImageError('SIZE');
        return;
      }
      if (!['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
        setImageError('FORMAT');
        return;
      }
      const image = new Image();
      image.onload = () => {
        if (image.width < 30 || image.height < 30) {
          setImageError('DIMENSION');
          return;
        }
        setCustomLogo(image.src);
      };
      image.onerror = () => {
        setImageError('UNKNOWN');
        return;
      };
      image.src = window.URL.createObjectURL(file);
    } catch (error: any) {
      setImageError('UNKNOWN');
    }
  }

  return (
    <>
      <div className={classes.container}>
        <Typography className={classes.title}>{t('customizePanelTitle')}</Typography>
        <p className={classes.text}>{t('customizePanelDescription')}</p>
        <div className={classes.content}>
          <div className={`${classes.badgeWrapper} ${classes[mode as keyof typeof classes]}`}>
            <div className={classes.badgeContainer}>
              <PaymentWidgetBadge
                logoType={logoType}
                customLogo={customLogo}
                title={title || t('defaultTitle')}
                text={showSavings ? t('defaultText') : undefined}
                buttonText={t('authorizePayment')}
                cashBackText={allowCashBack ? t('cashBackText') : undefined}
                mode={mode}
                theme={theme}
                hidePoweredByKartera={!showPoweredByKartera}
                useCreditStatus={useCreditStatus}
                setUseCreditStatus={setUseCreditStatus}
              />
            </div>

            <div className={classes.toggleButtonsContainer}>
              <label>{t('toggleMode')}</label>
              <KarteraSegmentedButton
                items={[
                  { text: t('light'), onClick: () => setMode('LIGHT') },
                  { text: t('dark'), onClick: () => setMode('DARK') },
                ]}
              />
            </div>
          </div>
          <div className={classes.rigthContainer}>
            <KarteraSelectField
              items={[
                { text: t('themeKartera'), value: 'KARTERA' },
                { text: t('themeFire'), value: 'FIRE' },
                { text: t('themeMinimalist'), value: 'MINIMALIST' },
                { text: t('themeNeutral'), value: 'NEUTRAL' },
                { text: t('themeOcean'), value: 'OCEAN' },
                { text: t('themeSunset'), value: 'SUNSET' },
              ]}
              onChange={(e: any) => setTheme(e.target.value)}
              value={theme}
              label={`${t('theme')}:`}
              labelStyle='simple'
              width='220px'
            />
            <div className={classes.uploadImageWrapper}>
              <label className={classes.label}>{t('image')}:</label>
              <div className={classes.logoContainer}>
                <div className={classes.imageContainer}>
                  <KarteraLogo size={30} color={color.GREY_DARK_4} />
                  <KarteraRadioButton
                    sx={{ marginLeft: '26px' }}
                    checked={logoType === 'BLACK'}
                    onClick={() => setLogoType('BLACK')}
                  />
                </div>
                <div className={classes.imageContainer}>
                  <KarteraLogo size={30} color={color.GREEN_LIGHT_1} />
                  <KarteraRadioButton
                    sx={{ marginLeft: '26px' }}
                    checked={logoType === 'GREEN'}
                    onClick={() => setLogoType('GREEN')}
                  />
                </div>
                <div className={classes.imageContainer}>
                  <KarteraLogo size={30} color={color.GRAY_DARK_06} />
                  <KarteraRadioButton
                    sx={{ marginLeft: '26px' }}
                    checked={logoType === 'GRAY'}
                    onClick={() => setLogoType('GRAY')}
                  />
                </div>
                {customLogo && (
                  <div className={classes.imageContainer}>
                    <img src={customLogo} alt='Custom logo' height={30} />
                    <KarteraRadioButton
                      sx={{ marginLeft: '26px' }}
                      checked={logoType === 'CUSTOM'}
                      onClick={() => setLogoType('CUSTOM')}
                    />
                  </div>
                )}
              </div>
              <KarteraUploadButton
                onChange={handleImageUpload}
                fileType='image/jpeg, image/png'
                type='primary'
                text={t('upload')}
              />
            </div>
            <div>
              <KarteraTextField
                label={`${t('title')}:`}
                labelStyle='simple'
                placeholder={t('defaultTitle')}
                fullWidth
                value={title}
                onChange={(e: any) => handleTitle(e.target.value)}
              />
              <div className={classes.smallLabel}>{`${charLeft} ${t('charLeft')}`}</div>
            </div>
            <div className={classes.checkBoxContainer}>
              <KarteraSwitch
                label={t('showSavings')}
                checked={showSavings}
                onClick={() => setShowSavings((prev) => !prev)}
                color='success'
              />
              <KarteraSwitch
                label={t('allowCashBack')}
                checked={allowCashBack}
                onClick={() => setAllowCashBack((prev) => !prev)}
                color='success'
              />
              <div className={classes.ugradeText}>
                <KarteraSwitch
                  label={t('poweredByKartera')}
                  checked={showPoweredByKartera}
                  color='success'
                  disabled
                />
                <Trans i18nKey={'upgradeYourAccount'}>{t('upgradeYourAccount')}</Trans>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <KarteraButton
            variant='link'
            text={t('reset')}
            onClick={handleReset}
            disabled={isDefault}
          />
          <div className={classes.footerRightContent}>
            <KarteraButton 
              text={t('cancel')} 
              variant='secondary'  
              onClick={onClose}
            />
            <KarteraButton text={t('save')} variant='primary' disabled={isDefault} />
          </div>
        </div>
      </div>
      {imageError && (
        <UploadImageErrorDialogue
          title={t(`${imageError}ErrorTitle`)}
          text={t(`${imageError}ErrorText`)}
          onCancel={() => setImageError(undefined)}
          onChooseImage={(image: any) => handleImageUpload(image)}
        />
      )}
    </>
  );
}
