import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { color } from '../../../../consts/ColorConst';
import { truncateText } from '../../../../utils/helperFunctions';
import { ProductUserSearchDTO } from '../../../../dtos/productUsers/productUserSearchDTO';
import { useApi } from '../../../../services/api/hooks';
import { dateConverter } from '../../../../utils/dateConverter';
import { PaymentDTO } from '../../../../dtos/payments/paymentDTO';
import { KarteraProgress } from '../../../../components/kartera/progress';

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: 'white',
    width: 360,
    padding: 22,
    borderRadius: 10,
    boxShadow: theme.shadows.elevation16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY_DARK_1,
    marginTop: 14,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 500,
    marginTop: 14,
  },
  customerData: {
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY_LIGHT_1,
    '& strong': {
      fontWeight: 500,
      color: color.GREY_DARK_4,
    },
  },
  transactionData: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY_DARK_4,
    '& strong': {
      fontWeight: 500,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 0 22px 0',
  },
  transactionList: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 146,
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: color.GREY_LIGHT_2,
      borderRight: `2px solid ${color.GREY_LIGHT_5}`,
    },
    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        borderRight: `2px solid ${color.GREY_LIGHT_4}`,
      },
    },
  },
  customerWrapper: {
    display: 'flex',
    padding: '0 0 8px',
  },
  transactionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 10px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: color.GREY_LIGHT_6,
    },
  },
  transactionLeftContent: {
    display: 'flex',
    gap: 8,
  },
  noTransaction: {
    paddingTop: 16,
    color: color.RED,
    fontSize: 14,
  },
  loaderContainer:{
    display:"flex",
    justifyContent:"center",
    padding:"5px"
  },
}));

type Props = {
  customer: ProductUserSearchDTO;
  setTransaction: (t: PaymentDTO) => void;
  nextStep: () => void;
};

export function SelectTransaction({ customer, setTransaction, nextStep }: Props) {
  const classes = useStyles();
  const { searchPayments } = useApi();
  const { product_id } = useParams();
  const { t } = useTranslation('translation', { keyPrefix: 'refunds' });

  const [payments, setPayments] = useState<PaymentDTO[]>([]);
  const [transactionsLoading, setTransactionsLoading] = useState(false);

  useEffect(() => {
    async function getPayments() {
      setTransactionsLoading(true);
      if (product_id) {
        const response = await searchPayments(product_id, {
          type: 'PAYMENT',
          user_id: customer.id,
          reverted: false,
        });
        setPayments(response.data);
        setTransactionsLoading(false);
      } else {
        setTransactionsLoading(false);
      }
    }
    getPayments();
  }, []);

  function handleSelectTransaction(t: PaymentDTO) {
    setTransaction(t);
    nextStep();
  }

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>{t('createRefund')}</Typography>
      <Typography className={classes.label}>{t('customer')}:</Typography>
      <Box className={classes.customerWrapper}>
        <Typography className={classes.customerData}>
          <strong>{`${customer.first_name} ${customer.last_name}`}</strong>
          {` (${customer.email})`}
        </Typography>
      </Box>
      <Divider />
      <Typography className={classes.subtitle}>{t('transactions')}</Typography>
      <Box className={classes.transactionList}>
        {payments.length === 0 ? (
          transactionsLoading ? (
            <div className={classes.loaderContainer}>
              <KarteraProgress></KarteraProgress>
            </div>
          ) : (
            <div className={classes.noTransaction}>{t('noTransactionAvailable')}</div>
          )
        ) : (
          payments.map((transaction) => (
            <div
              key={transaction.id}
              className={classes.transactionWrapper}
              onClick={() => handleSelectTransaction(transaction)}
            >
              <Box className={classes.transactionLeftContent}>
                <Typography className={classes.transactionData}>
                  <strong>{dateConverter(transaction.created_at)}</strong>
                </Typography>
                <Typography className={classes.transactionData}>
                  {truncateText(transaction.description, 15)}
                </Typography>
              </Box>
              <Typography className={classes.transactionData}>
                <strong>{transaction.amount}</strong> USD
              </Typography>
            </div>
          ))
        )}
      </Box>
    </Box>
  );
}
