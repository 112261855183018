import React, { FC } from "react";
import { LinearProgress, Box, Typography, Theme } from "@mui/material";
import { ProgressStepsProps } from "./types";
import { makeStyles } from "@mui/styles";
import { color as colors } from "../../../consts/ColorConst";

interface IProgressStepsStyle {
  progressColor: string;
}

const STEPS_CONTAINER_MARGIN = 42;

const useStyles = makeStyles<Theme, IProgressStepsStyle>((theme) => ({
  stepsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0 0 30px 0",
  },
  progressesContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: `${STEPS_CONTAINER_MARGIN}px`,
  },
  iconsContainer: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    top: "23px",
    fontSize: "8px",
  },
  stepTextIconWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "60px",
    fontSize: "8px",
    zIndex: 99,
    overflow: "hidden",
  },
  stepIconWrapper: {
    backgroundColor: colors.WHITE_01,
    display: "flex",
    width: "29px",
    height: "29px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    "& > svg": {
      fontSize: 16,
    },
  },
  disabledIconWrapper: {
    "& > svg > path": {
      fill: colors.GRAY_02,
    },
  },
  enabledIconWrapper: {
    "& > svg > path": {
      fill: ({ progressColor }: { progressColor: string }) => progressColor,
    },
  },
  progressWrapper: {
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "#fff",
      borderRadius: 10,
      boxSizing: "border-box",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: ({ progressColor }) => progressColor,
      borderRadius: 10,
      boxSizing: "border-box",
    },
  },
}));

export const LynkProgressSteps: FC<ProgressStepsProps> = ({
  totalSteps,
  currentStep,
  color,
  disabledColor = colors.GRAY_02,
  icons = [],
  stepWidth = 60,
  currentStepPercentage = 40,
  id,
}) => {
  const classes = useStyles({
    progressColor: color,
  });

  const valueConverter = (step: number, currentStep: number) => {
    if (currentStep > step) {
      return 100;
    } else if (currentStep < step) {
      return 0;
    } else if (currentStep === step) {
      return currentStepPercentage;
    }
  };

  const stepMargin = STEPS_CONTAINER_MARGIN - 18;
  const containerWidth =
    totalSteps * stepWidth +
    stepMargin * (totalSteps - 1) +
    STEPS_CONTAINER_MARGIN;
  const iconsMargin = stepWidth - (60 - STEPS_CONTAINER_MARGIN) - 18;

  return (
    <Box data-testid={id} sx={{ position: "relative", width: containerWidth }}>
      <Box className={classes.stepsContainer}>
        <Typography
          sx={{ fontSize: "14px", color: color }}
        >{`${currentStep}`}</Typography>
        <Typography sx={{ fontSize: "14px", color: disabledColor }}>
          &nbsp;/ {totalSteps}
        </Typography>
      </Box>
      <Box className={classes.iconsContainer}>
        {Array.from({ length: totalSteps }).map((_, index) => (
          <Box
            key={`iconStep-${index}`} 
            className={classes.stepTextIconWrapper}
            sx={{
              color: currentStep > index ? color : disabledColor,
              marginRight: `${iconsMargin}px`,
            }}
          >
            <Typography
              sx={{
                height: "15px",
                fontSize: "7px",
                fontWeight: 700,
                textTransform: "uppercase",
              }}
            >
              {index < icons.length ? icons[index]?.text : ""}
            </Typography>
            <Box
              className={`${classes.stepIconWrapper} ${
                currentStep > index
                  ? classes.enabledIconWrapper
                  : classes.disabledIconWrapper
              }`}
              sx={{
                border: `3.4px solid ${
                  currentStep > index ? color : disabledColor
                }`,
              }}
            >
              {index < icons.length && icons[index]?.icon}
            </Box>
          </Box>
        ))}
      </Box>
      <Box className={classes.progressesContainer}>
        {Array.from({ length: totalSteps }).map((_, index) => {
          return (
            <Box key={`progressStep-${index}`} className={classes.progressWrapper}>
              <LinearProgress
                variant="determinate"
                value={valueConverter(index + 1, currentStep)}
                sx={{
                  marginRight: `${stepMargin}px`,
                  width: stepWidth,
                }}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
