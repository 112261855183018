import { Trans, useTranslation } from 'react-i18next';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { color } from '../../../consts/ColorConst';
import { truncateText } from '../../../utils/helperFunctions';
import { KarteraButton } from '../button';
import { KarteraSwitch } from '../switch';
import KarteraLogo from '../logo/KarteraLogo';

type theme = 'KARTERA' | 'FIRE' | 'MINIMALIST' | 'NEUTRAL' | 'OCEAN' | 'SUNSET';

type StyleProps = {
  theme?: theme;
};

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 12,
    backgroundColor: color.WHITE_01,
    borderRadius: 6,
    border: `1px solid ${color.GREY_LIGHT_3}`,
    padding: 15,
  },
  rootDark: {
      backgroundColor: color.GRAY_DARK_05,
      borderColor: color.GRAY_DARK_05,
      '& p': {
          color: color.WHITE_01
      }
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    color: color.GREY_DARK_4,
  },
  text: {
    fontSize: 12,
    fontWeight: 400,
    color: color.GREY_DARK_4,
    letterSpacing: 0.2,
    lineHeight: 1.2,
  },
  cashBackContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    fontSize: 12,
    fontWeight: 400,
    color: color.GREY_DARK_4,
    letterSpacing: 0.2,
  },
  button: {
    width: '100% !important',
  },
  bottomText: {
    fontSize: 10,
    fontWeight: 400,
    color: color.GREY_DARK_1,
    letterSpacing: 0.2,
    textAlign: 'right',
    '& strong': {
      color: ({ theme }) =>  {
        if (theme === 'KARTERA') return color.GREEN_DARK_1;
        if (theme === 'OCEAN') return color.BLUE_LIGHT_01;
        if (theme === 'SUNSET') return color.ORANGE_LIGHT_2;
        if (theme === 'FIRE') return color.RED_LIGHT_2;
        else return color.GREY_LIGHT_5;
      }
    }
  },
  topContent: {
    width: '100%',
    display: 'flex',
    height: 22,
    gap: 6,
  },
}));

type Props = {
  mode?: 'DARK' | 'LIGHT';
  theme?: theme;
  title?: string;
  text?: string;
  buttonText: string;
  cashBackText?: string;
  tinyBadgeVariant?: 'GREEN' | 'WHITE' | 'BLACK';
  logoType?: 'BLACK' | 'GREEN' | 'GRAY' | 'CUSTOM';
  customLogo?: string;
  hidePoweredByKartera?: boolean;
  useCreditStatus: boolean;
  setUseCreditStatus: (useCreditStatus: boolean) => void;
};

export function PaymentWidgetBadge({
  title,
  text,
  buttonText,
  cashBackText,
  hidePoweredByKartera,
  mode = 'LIGHT',
  theme,
  logoType = 'BLACK',
  customLogo,
  useCreditStatus,
  setUseCreditStatus,
}: Props) {
  const classes = useStyles({ theme });
  const { t } = useTranslation('translation', { keyPrefix: 'platform' });

  const logoColor = {
    GREEN: mode === 'LIGHT' ? color.GREEN_LIGHT_1 : color.GREEN_LIGHT_3,
    BLACK: mode === 'LIGHT' ? color.GREY_DARK_4 : color.WHITE_01,
    GRAY: mode === 'LIGHT' ? color.GRAY_DARK_06 : color.GREY_LIGHT_4,
  };

  return (
    <Box className={`${classes.root} ${mode === 'DARK' && classes.rootDark}`}>
      <Box className={classes.topContent}>
        <Typography className={classes.title}>
          {logoType === 'CUSTOM' ? (
            <img src={customLogo} alt='Logo' height={22} width={22} />
          ) : (
            <KarteraLogo color={logoColor[logoType]} size={22} />
          )}
        </Typography>
        <Typography className={classes.title}>{truncateText(title, 25)}</Typography>
      </Box>
      {text && (
        <Typography className={classes.text}>
          <Trans i18nkey='badgeText'>{text}</Trans>
        </Typography>
      )}
      <KarteraButton
        text={buttonText}
        variant={theme?.toLowerCase() as any}
        mode={mode}
        className={classes.button}
        spacious
      />
      {cashBackText && (
        <div className={classes.cashBackContainer}>
          <KarteraSwitch
            checked={useCreditStatus}
            onClick={() => setUseCreditStatus(!useCreditStatus)}
            theme={theme}
          />
          <Trans>
            <p>{cashBackText}</p>
          </Trans>
        </div>
      )}
      {!hidePoweredByKartera && (
        <Typography className={classes.bottomText}>
          <Trans i18nkey='badgePoweredByKartera'>{t('badgePoweredByKartera')}</Trans>
        </Typography>
      )}
    </Box>
  );
}
