import { FC, useState } from "react";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentQuestion, faGear } from "@fortawesome/pro-regular-svg-icons";
import { useIntercom } from "react-use-intercom";
import KarteraLogo from "../../assets/KarteraLogoBlack.svg";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/Auth";
import { useLocation, useNavigate } from "react-router-dom";
import { color } from "../../consts/ColorConst";
import KarteraUserNav from "../../components/common/userNav";
import Settings from "../../pages/settings";
import { NavbarProps } from "./types";
import { KarteraButton } from "../../components/kartera/button";

const useStyles = makeStyles<Theme>(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    height: "66px",
    width: "100%",
    position: "sticky",
    top: 0,
    zIndex: 900,
    borderBottom: `1px solid ${color.GREY_LIGHT_4}`,
  },
  innerContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    margin: "0 40px 0 40px",
    position: "relative",
  },
  titleLogoWrapper: {
    display: "flex",
    alignItems: "center",
    "& p": {
      color: color.GREEN,
      padding: "0 0 0 14px",
    },
  },
  logoWrapper: {
    cursor: "pointer",
  },
  rightContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "auto",
  },
}));

export const Navbar: FC<NavbarProps> = ({
  headerTitle,
  publicRoute = false,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "navbar" });
  const { isAuthenticated, partner, logout } = useAuth();
  const navigate = useNavigate();
  const classes = useStyles();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const { pathname } = useLocation();
  const { show } = useIntercom();

  const onLogoClick = () => {
    isAuthenticated && navigate("/");
  };

  const menuProps = [
    {
      text: t("menuSettings"),
      icon: <FontAwesomeIcon icon={faGear} />,
      onClick: () => setIsSettingsOpen(true),
      sx: { paddingTop: "10px !important" },
    },
    {
      text: t("menuHelp"),
      icon: <FontAwesomeIcon icon={faCommentQuestion} />,
      onClick: () => show(),
      divider: true,
      sx: { paddingBottom: "10px !important" },
    },
    {
      text: t("menuLogout"),
      onClick: () => logout(),
      sx: { paddingTop: "10px !important" },
    },
  ];

  return (
    <header className={classes.container}>
      <div className={classes.innerContent}>
        <div className={classes.titleLogoWrapper}>
          <div className={classes.logoWrapper} onClick={() => onLogoClick()}>
            <img src={KarteraLogo} alt="Kartera logo" sizes="80px" />
          </div>
          <p>{headerTitle}</p>
        </div>

        {isAuthenticated && partner?.isVerified ? (
          <KarteraUserNav
            alt="Avatar"
            variant="circular"
            menuProps={menuProps}
            showPanel
          />
        ) : (
          !pathname.includes("/register") &&
          publicRoute === false && (
            <KarteraButton text={t("menuLogout")} onClick={() => logout()} />
          )
        )}
        {isSettingsOpen && (
          <Settings
            isOpen={isSettingsOpen}
            handleClose={() => setIsSettingsOpen(false)}
          />
        )}
      </div>
    </header>
  );
};
