import { useCookies } from "react-cookie";
import { useAxios } from "./useAxios";
import { BillingDTO } from "../../dtos/billing/billingDTO";
import { BillingRequestDTO } from "../../dtos/billing/billingRequestDTO";

export function useStaffAdminBillingAPI() {
  const [cookies] = useCookies(["lynk.access-token", "lynk.partner-id"]);
  const authToken = {
    headers: { Authorization: `Bearer ${cookies["lynk.access-token"]}` },
  };

  const { getApi, postApi } = useAxios();
  
  async function getAdminPartnerBillingPlan(partnerId: string): Promise<BillingDTO> {
    return await getApi(
      `/staff/admin/partners/${partnerId}/billing/plan`,
      authToken,
      false
    );
  }

  async function postAdminPartnerBillingPlan(
    partnerId: string, 
    data: BillingRequestDTO
  ): Promise<BillingDTO> {
    return await postApi(`/staff/admin/partners/${partnerId}/billing/plan`, data, authToken);
  }

  return {
    getAdminPartnerBillingPlan,
    postAdminPartnerBillingPlan,
  };
}
