import { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { CategoryScale, ScriptableContext } from "chart.js";
import { Bar, Line } from 'react-chartjs-2';
import Chart from "chart.js/auto";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faArrowDownRight, faArrowRight, 
         faArrowUpRight, faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { color } from '../../../consts/ColorConst';
import { KarteraChip } from '../chip';
import { KarteraChartCardSkeleton } from './chartCardSkeleton';
import { KarteraTooltip } from '../tooltip';
import { numberFormatter } from '../../../utils/handleValuesHelper';
import { handleRangeLabel, truncateText } from '../../../utils/helperFunctions';
import WarningImg from '../../../assets/warningImage.png';
import { KarteraButton } from '../button';
import { Props } from './types';

Chart.register(CategoryScale);

const useStyles = makeStyles((theme: any) => ({
  root: {
    position: 'relative',
    width: '270px',
    height: '266px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: color.WHITE_01,
    boxShadow: theme.shadow.elevation4Grey,
    borderRadius: 6,
    padding: '12px 14px',
  },
  topContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  topLeftContent: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    color: color.GREY_DARK_4,
  },
  range: {
    fontSize: 12,
    fontWeight: 400,
    color: color.GREY_LIGHT_2,
    marginTop: -2,
  },
  errorWrapper: {
    flex: 1,
    padding: '34px 8px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,
  },
  errorMessage: {
    fontSize: 12,
    fontWeight: 400,
    color: color.GREY_DARK_2,
    textAlign: 'center',
  },
  totalWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  total: {
    fontSize: 36,
    fontWeight: 600,
    color: color.GREY_DARK_2,
  },
  monetary: {
    fontSize: 20,
    fontWeight: 500,
    color: color.GREY_LIGHT_2,
    paddingTop: 10,
  },
  symbol: {
    fontSize: 24,
    fontWeight: 500,
    color: color.GREY_LIGHT_2,
    paddingTop: 6,
  },
  chartWrapper: {
    flex: 1,
    width: '100%',
    display: 'flex',
    paddingTop: 6,
  },
  buttonWtrapper: {
    filter: "blur(6px)",
    "-webkit-filter": "blur(6px)",
  },
  rewardButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'max-content',
    zIndex: 1,
  },
}));

type trendProps = {
  variant: 'primary' | 'secondary' | 'negative',
  icon: IconDefinition,
}

type DataItem = {
  time_span: string;
  amount: string;
}

export function KarteraChartCard({ 
  type = 'bar', 
  getData,
  interval, 
  title, 
  tooltip,
  useMockData,
  mockData,
  showButton,
  buttonClick,
  isLoading,
  totalFormat = 'MONEY', 
}: Props ) {
  const classes = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'graphs' });
  const isMountedRef = useRef(false);

  const [ trendData, setTrendData ] = useState<trendProps>();
  const [ _isLoading, setIsLoading] = useState(true);
  const [ error, setError] = useState(false);
  const [ reloaded, setReloaded] = useState(false);
  const [ data, setData ] = useState<DataItem[]>([]);
  
  const labels: string[] = [];
  const values: string[] = [];

  function handleDateLabel(time_span: string): string {
    const date = new Date(time_span.split('T')[0]);
    switch (interval) {
      case 'DAY': 
        return date.toLocaleString('default', { weekday: 'long', timeZone: 'UTC' });
      case 'WEEK': 
        return date.toLocaleString('default', { weekday: 'short', timeZone: 'UTC' }).substring(0, 1);
      case 'MONTH': 
        return date.toLocaleString('default', { day: 'numeric', timeZone: 'UTC' });
      case 'YEAR': 
        return date.toLocaleString('default', { month: 'short', timeZone: 'UTC' });
      default:
        return date.getFullYear() +'-'+ date.toLocaleString('default', { month: 'short', timeZone: 'UTC' });
    }
  
  }

  let total = 0;
  data?.forEach( dt => {
    labels.push(handleDateLabel(dt.time_span));
    values.push(dt.amount);
    total += Number(dt.amount);
  })

  const totalValue = numberFormatter(total);
  const heigth = 140;
  const width = 240;
  const options = {
    responsive: true,
    scales: {
      x: {
        grid: { display: false },
        ticks: {
          maxRotation: 0,
          minRotation: 0
        }
      },
      y: {
        display: false,
        grid: { display: false }
      }
    },
    plugins: {
      title: {
        display: false,
      },
      subtitle: {
        display: false,
      },
      legend: {
        display: false
      }
    },
    lineTension: 0.5
  }

  async function handelReload() {
    await handleData();
    setReloaded(true);
  }

  function handleEmail() {
    window.open('mailto:support@kartera.com');
  }

  async function handleData() {
    try {
      if (isLoading) return;

      setIsLoading(true);
      setError(false);
      if (useMockData) {
        setData(mockData);
        return;
      }
      const result = await getData();
      if (isMountedRef.current) {
        setData(result || []);
      }
    } catch (error) {
      if (isMountedRef.current) setError(true);
    } finally {
      if (isMountedRef.current && !isLoading) setIsLoading(false);
    }
  }

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    handleData();
  }, [interval, useMockData, mockData, isLoading]);

  useEffect(() => {
    const val = calculateTrend(values);
    if (val >= 0.1) {
      return setTrendData({
        variant: 'primary',
        icon: faArrowUpRight,
      })
    }
    if (val <= -0.1) {
      return setTrendData({
        variant: 'negative',
        icon: faArrowDownRight,
      })
    }

    return setTrendData({
      variant: 'secondary',
      icon: faArrowRight,
    })
  }, [data]);

  if (_isLoading) {
    return <KarteraChartCardSkeleton title={isLoading ? '' : title} />
  }

  function trendLineCalculation(values: string[]) {
    if (!values) return;
    const trends: number[] = [];
    values.forEach( (v, i) => {
      if (values[i + 2]) {
        trends.push((Number(values[i + 1]) + Number(values[i + 2]) + Number(v)) / 3);
      } else if (values[i + 1]) {
        trends.push((Number(values[i + 1]) + Number(v)) / 2);
      } else {
        trends.push(Number(v));
      }
    });

    return trends;
  }

  function calculateTrend(values: string[]) {
    const len = values.length;
    const x = Array.from({ length: len }, (_, i) => i);
    const y = values;
    
    let sumX = 0;
    let sumY = 0;
    let sumXY = 0;
    let sumXX = 0;
    for (let i = 0; i < len; i++) {
      sumX += x[i];
      sumY += Number(y[i]);
      sumXY += x[i] * Number(y[i]);
      sumXX += x[i] * x[i];
    }
    
    return (len * sumXY - sumX * sumY) / (len * sumXX - sumX * sumX);
  }

  const trendLine = {
    type: 'line',
    cubicInterpolationMode: 'monotone',
    fill: false,
    borderColor: '#FF00E5',
    borderDash: [10,5],
    data: trendLineCalculation(values),
    tension: 1,
    pointRadius: 0,
  }

  return (
    <Box className={`${classes.root}`}>
      <Box className={classes.topContent}>
        <Box className={classes.topLeftContent}>
          <Typography className={classes.title}>
            {truncateText(title, 20)}
          </Typography>
          {trendData && data.length > 0 && (
            <KarteraChip
              size='small'
              icon={(
                <FontAwesomeIcon 
                  style={{ padding: '0 2px' }}
                  icon={trendData.icon} 
                  fontSize='12px'
                />
              )}
              variant={trendData.variant}
            />
          )}
        </Box>
        {!error && tooltip && (
          <KarteraTooltip title={tooltip} placement='right' >
            <FontAwesomeIcon 
              size='sm'
              icon={faQuestionCircle} 
              color={color.GREY_DARK_1}
            />
          </KarteraTooltip>
        )}
      </Box>
      {!error && (
        <Typography className={classes.range}>
          {handleRangeLabel(interval)}
        </Typography>
      )}
      { showButton && (
        <KarteraButton
          className={classes.rewardButton}
          text='Setup Rewards'
          onClick={buttonClick}
        />
      )}
      {error ? (
        <Box className={classes.errorWrapper}>
           <img src={WarningImg} alt='warning icon' />
          <Typography className={classes.errorMessage}>
            {t('errorMessage')}
          </Typography>
          <KarteraButton
            text={reloaded ? t('contactSupport') : t('reload')}
            onClick={reloaded ? handleEmail : handelReload}
          />
        </Box>
      ) : (
        <Box className={showButton ? classes.buttonWtrapper : ''}>
          <Box className={classes.totalWrapper}>
            <Typography className={classes.monetary}>
              { totalFormat === 'MONEY' 
                  ? '$' : totalFormat === 'PERCENT' 
                  ? '%' : ''
              }
            </Typography>
            <Typography className={classes.total}>
              {totalValue.split(" ")[0]}
            </Typography>
            <Typography className={classes.symbol}>
              {totalValue.split(" ")[1]}
            </Typography>
          </Box>
          <Box className={classes.chartWrapper}>
            {type === 'bar' && (
              <Bar
                data={{
                  labels: labels,
                  datasets: [{
                    data: values,
                    backgroundColor: [ color.GREEN ],
                    borderRadius: 10,
                    order: 2,
                    barThickness: labels.length > 3 ? 120 / labels.length : 80 / labels.length,
                  }],
                }}
                height={heigth}
                width={width}
                options={options}
              />
            )}
            {type == 'line' && (
              <Line
                data={{
                  labels: labels,
                  datasets: [{
                    cubicInterpolationMode: 'monotone',
                    fill: 'start',
                    borderColor: color.GREEN_DARK_1,
                    backgroundColor: (context: ScriptableContext<"line">) => {
                      const ctx = context.chart.ctx;
                      const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                      gradient.addColorStop(0, color.GREEN_LIGHT_1);
                      gradient.addColorStop(0.5, 'rgba(246, 253, 249, 80)');
                      return gradient;
                    },
                    data: values,
                    tension: 1,
                    pointRadius: 0,
                    order: 2
                  } ],
                }}
                height={heigth}
                width={width}
                options={options}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}
