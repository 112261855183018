import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Trans, useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { KarteraDialogue } from "../../../components/kartera/dialogue";
import { useApi } from "../../../services/api/hooks";
import { color } from "../../../consts/ColorConst";

const useStyles = makeStyles(() => ({
  contentWrapper: {
    display: 'flex', 
    flexDirection: 'column',
    padding: '12px 0 4px',
    gap: 12,
    '& strong': {
      color: color.GREEN_DARK_1,
      fontWeight: 500,
    }
  },
}));

type Props = {
    paymentId: string;
    onClose: () => void;
    setRefeshTime: () => void;
}

export function DeletePaymentType({ paymentId, onClose, setRefeshTime }: Props) {
    const { t } = useTranslation("translation", { keyPrefix: "payments" });
    const classes = useStyles();
    const { product_id } = useParams();
    const {deleteRecurringPayments} = useApi();
  

    async function handleSubmit() {   
      if (!product_id) return onClose();
      await deleteRecurringPayments(product_id, paymentId);
      setRefeshTime();
      onClose();
    }

    return (
      <KarteraDialogue 
        title={'Delete "Payment type Name"'}
        titleIcon={(
          <FontAwesomeIcon 
            icon={faTrash} 
            color={color.RED_DARK_1}
            width='22px' 
            size="xl"
          />
        )}
        size='small'
        autoHeight
        actionButtonText={t('delete')}
        activeButtonType="negative"
        onActionClick={handleSubmit}
        cancelButtonText={t('cancel')}
        onCancelClick={onClose}
        showCloseButton={false}
        supressOutsideClick
      >
        <div className={classes.contentWrapper}>
          <p>{t('deleteDialog1')}</p>
          <p>
            <Trans i18nkey='deleteDialog2'>
              {t('deleteDialog2')}
            </Trans>
          </p>
        </div>
      </KarteraDialogue>
    );
}
