import React, { FC } from "react";
import { BackdropProps } from "./types";
import Backdrop from "@mui/material/Backdrop";

export const KarteraBackdrop: FC<BackdropProps> = ({
  onClose,
  open = true,
  id,
  children,
  transparent = false,
}) => {
  return (
    <div>
      <Backdrop
        data-testid={id}
        sx={{ zIndex: 999, backgroundColor: transparent ? "transparent" : "rgba(31, 34, 38, 0.45)" }}
        open={open}
        onClick={onClose && onClose}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </Backdrop>
    </div>
  );
};
