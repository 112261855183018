import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { color } from '../../../consts/ColorConst';
import { Props } from "./types";
import { KarteraButton } from "../button";
import { useTranslation } from "react-i18next";
import { PartnerStatus } from "../../../consts/PartnerStatus";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    minWidth: '700px',
    padding: '6px 20px',
    minHeight: '44px',
    display: 'flex',
    alignItems: "center",
    gap: 26,
  },
  content: {
    flex: 1,
    textAlign: 'center',
    color: color.GREY_DARK_4
  },
  title: {
    fontSize: 13,
    fontWeight: 500,
  },
  message: {
    fontSize: 13,
    fontWeight: 400,
  }
}));

export function DashboardAlert({ partnerStatus, productReady, setIsDialogVerifyNowOpen }: Props) {
  const classes = useStyles();
  const { t } = useTranslation("translation", { keyPrefix: "dashboard" });

  const errorStatus = partnerStatus && 
            [PartnerStatus.ERROR, PartnerStatus.SUSPENDED].includes(partnerStatus); 

  return (
    <Box 
      className={classes.container} 
      sx={{ 
        backgroundColor: errorStatus 
              ? color.RED_LIGHT_1 : color.ORANGE_LIGHT_1
      }}
    >
      <Box className={classes.content}>
        <p className={classes.title}>{t('demoAlertTitle')}</p>
        <p className={classes.message}>
          {partnerStatus === 'retry' || !productReady 
            ? t('demoAlertMessagePendingVerification') 
            : errorStatus 
                ? t('demoAlertMessageRejected') : t('demoAlertMessage')}
        </p>
      </Box>
      {errorStatus && (
        <KarteraButton
          text={t('contactSupport')}
          variant="secondary"
        />
      )}
      {!partnerStatus && (
        <KarteraButton
          text={t('verifyNow')}
          onClick={() => setIsDialogVerifyNowOpen(true)}
        />
      )}
    </Box>
  );
}
