import { useCookies } from "react-cookie";
import { ProductDTO } from "../../dtos/products/productDTO";
import { ProductRequestDTO } from "../../dtos/products/productRequestDTO";
import { ProductUpdateRequestDTO } from "../../dtos/products/productUpdateRequestDTO";
import { useAxios } from "./useAxios";

type ApiResponse<T> = {
  data: T[];
}

export function useProductsAPI() {
  const [cookies] = useCookies(["lynk.access-token", "lynk.partner-id"]);
  const authToken = {
    headers: { Authorization: `Bearer ${cookies["lynk.access-token"]}` },
  };

  const { getApi, postApi, patchApi } = useAxios();


  async function getProducts(sort = "createdAt,DESC"): Promise<ApiResponse<ProductDTO>> {
    return getApi(`/products?sort=${sort}`, authToken, false, true);
  }

  async function getProductById(id: string, showLoading?: boolean): Promise<ProductDTO> {
    return await getApi(`/products/${id}`, authToken, showLoading);
  }

  async function postProducts(data: ProductRequestDTO): Promise<ProductDTO> {
    return postApi("/products", data, authToken);
  }

  async function updateProduct(productId: string, data: ProductUpdateRequestDTO): Promise<ProductDTO> {
    return patchApi(`/products/${productId}`, data, authToken);
  }

  return {
    updateProduct,
    getProducts,
    getProductById,
    postProducts,
  };
}
