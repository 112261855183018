import { FC } from "react";
import { makeStyles } from "@mui/styles";
import { Slider } from "@mui/material";
import { color } from "../../../consts/ColorConst";
import { SliderProps } from "./types";

const useStyles = makeStyles(() => ({
  root: {
    color: color.GREEN,
    height: 3,
    padding: "16px 0",
  },
  track: {
      height: "3px",
      borderRadius: 2,
      padding: "0 2px",
  },
  rail: {
      height: "5px",
      padding: "0 2px",
      borderRadius: 4,
  },
  markLabel: {
    top: 28,
    fontSize: 12,
    color: color.GREY,
  },
  thumb: {
      height: 16,
      width: 16,
      backgroundColor: color.GREEN,
      color: "#fff",
      "&:hover": {
        backgroundColor: color.GREEN_DARK_1,
      },
      '&:hover, &.Mui-focusVisible': {
        boxShadow: `0px 0px 0px 8px transparent`,
      },
      "&:active": {
        backgroundColor: color.GREEN_DARK_2,
      },
  },
  disabled: {
    "& .MuiSlider-rail": {
      color: color.GREEN_LIGHT_3,
    },
    "& .MuiSlider-track": {
      color: color.GREEN_LIGHT_3,
    },
    "& .MuiSlider-thumb": {
      backgroundColor: color.GREEN_LIGHT_3,
    },
  },
}));

export const KarteraSlider: FC<SliderProps> = ({
  marks,
  formatLabel,
  formatValue,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Slider
      classes={{ 
        root: classes.root, 
        thumb: classes.thumb, 
        track: classes.track,
        rail: classes.rail,
        disabled: classes.disabled,
        markLabel: classes.markLabel,
      }}
      valueLabelFormat={formatLabel}
      getAriaValueText={formatValue}
      marks={marks ? marks : true}
      {...rest}
    />
  );
};
