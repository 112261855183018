import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { color } from './consts/ColorConst';

const newTheme = createTheme({
  palette: {
    light: {
      backgroundColor: color.GREY_LIGHT_6,
      fontColor: color.GRAY_DARK_04,
    },
    dark: {
      backgroundColor: color.GRAY_DARK_04,
      fontColor: color.WHITE_01,
    },
    kartera: {
      manBackground: color.GRAY_LIGHT_01,
      background: color.WHITE_01,
      title: color.GRAY_DARK_04,
      text: color.GRAY_DARK_04,
      secondaryText: color.GRAY_LIGHT_04,
      buttonColor: color.GREEN_LIGHT_1,
      buttonText: color.WHITE_01,
    },
    Fire: {
      manBackground: color.GRAY_LIGHT_01,
      background: color.WHITE_01,
      title: color.GRAY_DARK_04,
      text: color.GRAY_DARK_04,
      secondaryText: color.GRAY_LIGHT_04,
      buttonColor: color.GREEN_LIGHT_1,
      buttonText: color.WHITE_01,
    },
    Minimalist: {
      manBackground: color.GRAY_LIGHT_01,
      background: color.WHITE_01,
      title: color.GRAY_DARK_04,
      text: color.GRAY_DARK_04,
      secondaryText: color.GRAY_LIGHT_04,
      buttonColor: color.GREEN_LIGHT_1,
      buttonText: color.WHITE_01,
    },
    Neutral: {
      manBackground: color.GRAY_LIGHT_01,
      background: color.WHITE_01,
      title: color.GRAY_DARK_04,
      text: color.GRAY_DARK_04,
      secondaryText: color.GRAY_LIGHT_04,
      buttonColor: color.GREEN_LIGHT_1,
      buttonText: color.WHITE_01,
    },
    Ocean: {
      manBackground: color.GRAY_LIGHT_01,
      background: color.WHITE_01,
      title: color.GRAY_DARK_04,
      text: color.GRAY_DARK_04,
      secondaryText: color.GRAY_LIGHT_04,
      buttonColor: color.GREEN_LIGHT_1,
      buttonText: color.WHITE_01,
    },
    Sunset: {
      manBackground: color.GRAY_LIGHT_01,
      background: color.WHITE_01,
      title: color.GRAY_DARK_04,
      text: color.GRAY_DARK_04,
      secondaryText: color.GRAY_LIGHT_04,
      buttonColor: color.GREEN_LIGHT_1,
      buttonText: color.WHITE_01,
    },
    primary: {
      main: '#0B72FF',
      contrastText: '#fff',
    },
    secondary: {
      main: '#4D7CFE',
    },
    error: {
      main: '#FF2559',
    },
    action: {
      disabledBackground: '#C4C4C4',
      disabled: '#fff',
      hoverOpacity: 0.14,
    },
    common: {
      black: '#202020',
      white: '#FFFFFF',
      grey: '#A7A7A7',
      blue: '#0B72FF',
    },
    text: {
      main: color.GRAY_DARK_04,
      secondary: color.GREY_LIGHT_2,
    },
    background: {
      main: color.GREY_LIGHT_6,
      paper: color.WHITE_01,
    },
  },
  shape: {
    borderRadius: 10,
  },
  shadow: {
    elevation4Grey: '0px 0px 4px rgba(125, 132, 143, 0.4)', 
    elevation4Red: '0px 0px 4px rgba(241, 8, 13, 0.4)', 
    elevation4Green: '0px 0px 4px rgba(26, 192, 107, 0.4)', 
    elevation4Orange: '0px 0px 4px rgba(248, 149, 0, 0.4)', 
    elevation4Blue: '0px 0px 4px rgba(0, 75, 179, 0.4)',
    elevation8: '0px 0px 4px rgba(141, 149, 161, 0.4), 0px 3px 8px rgba(141, 149, 161, 0.5)', 
    elevation16: '0px 0px 12px rgba(94, 103, 114, 0.12), 0px 8px 12px rgba(94, 103, 114, 0.5), 0px 12px 60px rgba(94, 103, 114, 0.5)', 
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});
const themeLight = responsiveFontSizes(newTheme);

export default themeLight;
