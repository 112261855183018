import React, { FC, useState } from "react";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsis,
  faFilter,
  faGear,
  faPlus,
  faSearch,
  faCopy, 
  faTrashAlt
} from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { LynkSearchField } from "../../common/searchField";
import { TableToolbarProps } from "./types";
import { LynkBadge } from "../../common/badge/lynkBadge";
import { color } from "../../../consts/ColorConst";
import { KarteraButton } from "../../kartera/button";

const useStyles = makeStyles(() => ({
  root: {
    boxSizing: "border-box",
    width: "100%",
    height: "44px",
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 12px",
    backgroundColor: "white",
  },
  leftContentWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 16,
    fontWeight: 500,
  },
  rightButtonWrapper: {
    width: "auto",
    height: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: 8,
  },
  iconButton: {
    color: "#333",
  },
}));

export const LynkTableToolbar: FC<TableToolbarProps> = ({
  counterTitle,
  searchPlaceholder,
  searchValue,
  setSearchValue,
  handleAddData,
  handleDeleteData,
  handleCopyData,
  handleSettings,
  handleFilterData,
  handleMoreOptions,
  handleExportData,
  totalElements,
  totalSelectedElements,
  quickFilter,
  filterCounter,
  filteringEnabled = true,
  component,
}) => {
  const classes = useStyles();

  const { t } = useTranslation("translation", { keyPrefix: "table" });
  const [searchOpen, setSearchOpen] = useState(false);

  function handleSearchCancel() {
    setSearchOpen(false);
    setSearchValue("");
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.leftContentWrapper}>
        {`${counterTitle} (${String(totalElements)})`}
      </Box>
      <Box className={classes.rightButtonWrapper}>
        {/* {searchOpen ? (
          <Box>
            <LynkSearchField
              placeholder={searchPlaceholder}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onCancel={handleSearchCancel}
            />
          </Box>
        ) : (
          <KarteraButton
            variant="tertiary"
            icon={<FontAwesomeIcon icon={faSearch} />}
            onClick={() => setSearchOpen(true)}
          />
        )} */}
        {quickFilter}
        {component}
        {handleAddData && (
          <KarteraButton
            variant="primary"
            icon={<FontAwesomeIcon icon={faPlus} color={color.GREEN_DARK_1}/>}
            text={t("buttonAdd")}
            onClick={handleAddData}
          />
        )}
        {handleCopyData && (
          <KarteraButton
            variant="tertiary"
            icon={<FontAwesomeIcon icon={faCopy} color={color.GREEN_DARK_1}/>}
            onClick={handleCopyData}
            disabled
          />
        )}
        {handleDeleteData && (
          <KarteraButton
            variant="tertiary"
            icon={<FontAwesomeIcon icon={faTrashAlt} color={color.GREEN_DARK_1}/>}
            text={`${
              totalSelectedElements && totalSelectedElements > 0
                ? "(" + totalSelectedElements + ")"
                : ""
            }`}
            onClick={handleDeleteData}
            disabled
          />
        )}
        {handleExportData && (
          <KarteraButton
            variant="tertiary"
            icon={<FontAwesomeIcon icon={faDownload} color={color.GREEN_DARK_1}/>}
            onClick={handleExportData}
          />
        )}
        {handleFilterData && filteringEnabled && (
          <LynkBadge
            type="blue"
            text={filterCounter ? String(filterCounter) : null}
          >
            <KarteraButton
              variant="tertiary"
              icon={<FontAwesomeIcon icon={faFilter} color={color.GREEN_DARK_1}/>}
              onClick={handleFilterData}
            />
          </LynkBadge>
        )}
        {handleSettings && (
          <KarteraButton
            variant="tertiary"
            icon={<FontAwesomeIcon icon={faGear} color={color.GREEN_DARK_1}/>}
            onClick={handleSettings}
          />
        )}
        {handleMoreOptions && (
          <KarteraButton
            variant="tertiary"
            icon={<FontAwesomeIcon icon={faEllipsis} color={color.GREEN_DARK_1}/>}
            onClick={handleMoreOptions}
          />
        )}
      </Box>
    </Box>
  );
};
