import React, { FC, useState } from 'react';
import { Button, Menu, MenuItem, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ButtonMenuProps, MenuItemType } from './types';
import { truncateText } from '../../../utils/helperFunctions';
import { color } from '../../../consts/ColorConst';

const useStyles = makeStyles(() => ({
    itemContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: 'flex-start',
        padding: "6px 12px",
    },
    itemWrapper: {
        display: "flex",
        alignItems: 'center',
        gap: 6,
    },
    mainItemText: {
        fontSize: 14,
        fontWeight: 400,
        color: color.GRAY_DARK_04,
    },
    secondaryItemText: {
        fontSize: 11,
        color: color.SECONDARY_DARK_02,
    },
    menu: {
        minWidth: "126px"
    }
  }));

export const ButtonMenu: FC<ButtonMenuProps> = ({
    iconRight,
    iconLeft,
    text,
    items,
    className,
    disabled,
    truncateTextSize,
    loading,
    selectedItem,
    setSelectedItem,
    additionalItem,
}) => {
    const classes = useStyles();
    const [ anchorEl, setAnchorEl ] = useState(null);

    function handleClick(event: any) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function handleItemClick(item: MenuItemType) {
        setSelectedItem(item);
        item.onClick && item.onClick();
        setAnchorEl(null);
    }

    const buttonText = truncateTextSize
                        ? truncateText(selectedItem?.text || text, truncateTextSize || 12)
                        : selectedItem?.text || text

    return  (
        <>
            <Button 
                className={className}
                onClick={handleClick} 
                disabled={disabled}
            >
                {iconLeft && iconLeft}
                {loading 
                    ? <CircularProgress size={12} />
                    : <span>{buttonText}</span>
                }
                {iconRight && iconRight}
            </Button>
            <Menu
                classes={{ paper: classes.menu }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {items.map( item => (
                    <MenuItem 
                        key={item.text} 
                        className={classes.itemContainer}
                        onClick={() => handleItemClick(item)}
                        component={item.redirectUrl ? "a" : "li"}
                        href={item.redirectUrl}
                    >
                        <div className={classes.itemWrapper}>
                            {item.icon}
                            <div>
                                <Typography className={classes.mainItemText}>
                                    {item.text}
                                </Typography>
                                {item.secondaryText && (
                                    <Typography className={classes.secondaryItemText}>
                                        {item.secondaryText}
                                    </Typography>
                                )}
                            </div>
                        </div>
                    </MenuItem>
                ))}
                {additionalItem}
            </Menu>
        </>
    );
} 