import { FC, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Input } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { color } from "../../../consts/ColorConst";
import { TextSearchProps } from "./types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme: any) => ({
  inputStyle: {
    height: "28px",
    padding: "6px 10px",
    backgroundColor: color.GREY_LIGHT_5,
    color: color.GREY_DARK_4,
    border: `1.5px solid ${color.GREY_LIGHT_5}`,
    borderRadius: 6,
    [theme.breakpoints.down('md')]: {
      height: "34px",
    },
    "& .MuiInputBase-input": {
      fontSize: "14px",
      fontWeight: 400,
      "&::placeholder": {
        color: color.GREY_DARK_4,
      },
    },
    "& .MuiInputBase-root": {
      border: `1.5px solid ${color.GREY_LIGHT_5}`,
      width: "fit-content",
    },
    "&:hover": { 
      backgroundColor: color.GREY_LIGHT_5,
      borderColor: color.GREY_LIGHT_3,
      boxShadow: theme.shadow.elevation4Grey,
    },
  },
  root: {
    position: "relative",
    "& .Mui-focused": {
      backgroundColor: color.WHITE_01,
      border: `1.5px solid ${color.GREEN}`,
      boxShadow: theme.shadow.elevation4Green,
    },
    "& .Mui-disabled": {
      "&:hover": {
        "& .MuiInputBase-input": {
          height: "16px",
        },
        backgroundColor: color.GREY_LIGHT_5,
        borderColor: color.GREY_LIGHT_5,
        boxShadow: 'none',
      },
    },
  },
  icon: {
    height: "11px",
    color: color.GREY_LIGHT_2,
    "&:hover": {
      color: color.GREY,
    },
  },
  listBox: {
    width: '100%',
    marginTop: 2,
    padding: 4,
    zIndex: 99,
    position: 'absolute',
    listStyle: 'none',
    borderRadius: 6,
    backgroundColor: color.WHITE_01,
    overflow: 'auto',
    maxHeight: 200,
    border: `1px solid ${color.GREY_LIGHT_3}`,
    '& li:hover': {
      backgroundColor: color.GREEN_LIGHT_5,
      cursor: 'pointer',
    },
    '& li:active': {
      backgroundColor: color.GREEN_LIGHT_2,
    },
  },
  item: {
    padding: '4px 8px',
  }
}));

export const KarteraSearchField: FC<TextSearchProps> = ({
  width = "180px",
  onCancel,
  disabled,
  onBlur,
  fullWidth,
  items = [],
  showListBox = false,
  ...rest
}) => {
  const classes = useStyles();
  const [onFocus, setOnFocus] = useState(false);
  const [onCancelButtomFocus, setOnCancelButtonFocus] = useState(false);
  const [filteredItems, setFilteredItems] = useState(items);
  const [text, setText] = useState('');

  function handleBlur(e: any) {
    setOnFocus(false);
    if (onBlur) onBlur(e);
  }

  function handleFilter() {
    const filteredData = filteredItems.filter( item => {
      item.searchableData.some( data => 
        data.toLowerCase().includes(text.toLowerCase())
      );
    });

    setFilteredItems(filteredData)
  }

  return (
    <div className={classes.root} style={{ width: fullWidth ? '100%' : width }}>
      <Input
        fullWidth
        className={classes.inputStyle}
        disableUnderline
        disabled={disabled}
        onBlur={handleBlur}
        value={text}
        onChange={(e) => setText(e.target.value)}
        onFocus={() => setOnFocus(true)}
        startAdornment={
            <InputAdornment position="start">
              <FontAwesomeIcon 
                icon={faSearch} 
                color={disabled ? color.GRAY_DARK_08 : color.GRAY}
                width={14} 
              />
            </InputAdornment>
        }
        endAdornment={
          (onFocus || onCancelButtomFocus) && (
            <InputAdornment position="end">
              <IconButton
                aria-label="close button"
                edge="end"
                onMouseOver={() => setOnCancelButtonFocus(true)}
                onClick={onCancel}
                onMouseLeave={() => setOnCancelButtonFocus(false)}
                tabIndex={-1}
                disabled={disabled}
                disableRipple
              >
                <FontAwesomeIcon icon={faXmarkCircle} className={classes.icon} />
              </IconButton>
            </InputAdornment>
          )
        }
        {...rest}
      />
      {(showListBox && filteredItems.length > 0) && (
        <Box className={classes.listBox}>
          {filteredItems.map( item => (
            <li className={classes.item} key={item.searchableData[0]}>
              {item.data}
            </li>
          ))}
        </Box>
      )}
      <Box>

      </Box>
    </div>
  );
};
