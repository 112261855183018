import { AuthDataDTO } from "../../dtos/auth/authDataDTO";
import { ChangePasswordRequestDTO } from '../../dtos/auth/changePasswordRequestDTO';
import { LoginRequestDTO } from '../../dtos/auth/loginRequestDTO';
import { OtpLoginRequestDTO } from '../../dtos/auth/otpLoginRequestDTO';
import { OtpRequestDTO } from '../../dtos/auth/otpRequestDTO';
import { OtpTokenDTO } from '../../dtos/auth/otpTokenDTO';
import { RegisterRequestDTO } from '../../dtos/auth/registerRequestDTO';
import { ResetPasswordRequestDTO } from '../../dtos/auth/resetPasswordRequestDTO';
import { useAxios } from './useAxios';
import { useCookies } from 'react-cookie';
import { AuthTotpConnectDTO } from '../../dtos/auth/authTotp';
import { AuthTotpStatusDTO } from '../../dtos/auth/authTotpStatus';
import { AuthTotpActivateDTO } from '../../dtos/auth/authTotpActivate';
import { TotpLoginRequestDTO } from '../../dtos/auth/totpLoginRequestDTO';

export function useAuthAPI() {
  const { postApi, patchApi, getApi } = useAxios();

  const [cookies] = useCookies(['lynk.access-token', 'lynk.partner-id']);
  const authToken = {
    headers: { Authorization: `Bearer ${cookies['lynk.access-token']}` },
  };

  async function postAuthLogin(
    data: LoginRequestDTO,
    callBackError?: (error: any) => void,
  ): Promise<OtpTokenDTO> {
    return postApi('/auth/login', data, {}, callBackError, false);
  }

  async function postResetPassword(
    data: ResetPasswordRequestDTO,
    callBackError?: (error: any) => void,
  ) {
    return postApi('/auth/reset_password', data, {}, callBackError, false);
  }

  async function postChangePassword(
    data: ChangePasswordRequestDTO,
    callBackError?: (error: any) => void,
  ) {
    return postApi('/auth/change_password', data, {}, callBackError, false);
  }

  async function postAuthRegister(
    data: RegisterRequestDTO,
    callBackError?: (error: any) => void,
  ): Promise<OtpTokenDTO> {
    return postApi('/auth/register', data, {}, callBackError, false);
  }

  async function postOtp(data: OtpRequestDTO) {
    return postApi('/otp', data, {}, undefined, false);
  }

  async function postAuthOtpLogin(data: OtpLoginRequestDTO): Promise<AuthDataDTO> {
    return postApi('/auth/otp_login', data, {}, undefined, false);
  }

  async function postAuthTotpLogin(data: TotpLoginRequestDTO): Promise<AuthDataDTO> {
    return postApi('/auth/totp_login', data, {}, undefined, false);
  }

  async function postAuthTotpConnect(): Promise<AuthTotpConnectDTO> {
    return postApi('/totp/connect', {}, authToken);
  }

  async function getAuthTotpConnect(): Promise<AuthTotpStatusDTO> {
    return getApi('/totp/status', authToken);
  }

  async function checkTokenExpiration(tokenId: string): Promise<{ expired: boolean }> {
    return getApi(`/auth/reset_password_tokens/${tokenId}/status`);
  }

  async function postAuthTotpActivate(
    code: string,
    callBackError?: (error: any) => void,
  ): Promise<AuthTotpActivateDTO> {
    return postApi('/totp/activate', { totp_code: code }, authToken, callBackError);
  }

  return {
    postAuthLogin,
    postAuthRegister,
    checkTokenExpiration,
    postResetPassword,
    postChangePassword,
    postOtp,
    postAuthOtpLogin,
    postAuthTotpConnect,
    getAuthTotpConnect,
    postAuthTotpActivate,
    postAuthTotpLogin
  };
}
