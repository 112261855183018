import { FC, useEffect } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { AppRootState } from "../../reducers";
import { color } from "../../consts/ColorConst";
import { setIsLoading } from "../../reducers/loaderReducers";

const reduxConnector = connect(
  (state: AppRootState) => ({ isLoading: state.loaderState.isLoading }),
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;

const Loader: FC<PropsFromRedux> = ({ isLoading }) => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    return () => {
      dispatch(setIsLoading(false));
    }
  }, []);
  
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: 1000 }}
      open={isLoading}
    >
      <CircularProgress
        size={68}
        sx={{ color: color.GREEN }}
      />
    </Backdrop>
  );
}

export default reduxConnector(Loader);
