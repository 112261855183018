import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { color } from '../../../consts/ColorConst';
import { Divider, Typography } from '@mui/material';
import { KarteraButton } from '../../kartera/button';
import { KarteraSelectField } from '../../kartera/selectField';
import { KarteraTextField } from '../../kartera/textField';
import { KarteraSwitch } from '../../kartera/switch';
import { KarteraSegmentedButton } from '../../kartera/segmentedButton';
import { KarteraPluginBadge } from '../../kartera/pluginBadge';
import { KarteraUploadButton } from '../../kartera/uploadButton';
import { UploadImageErrorDialogue } from '../uploadImageErrorDialogue';

const useStyles = makeStyles((theme: any) => ({
    container: {
      width: 631,
      padding: '16px 16px 20px',
      background: color.WHITE_01,
      borderRadius: 6,
      boxShadow: theme.shadow.elevation4Grey,
    },
    title: {
      fontSize: 16,
      fontWeight: 500,
      marginBottom: 24,
    },
    subtitle: {
      fontSize: 14,
      fontWeight: 500,
      color: color.GREY_DARK_4,
    },
    text: {
        fontSize: 14,
        fontWeight: 400,
        paddingRight: 10,
        color: color.GREY_DARK_4,
    },
    smallLabel: {
        marginTop: 2,
        fontSize: 11,
        fontWeight: 400,
        color: color.GREY_LIGHT_2,
        textAlign: 'right',
    },
    ugradeText: {
        fontSize: 12,
        fontWeight: 500,
        color: color.GREY_DARK_2,
        '& strong': {
            color: color.GREEN_DARK_1
        }
    },
    message: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0.2
    },
    content: {
        display: 'flex', 
        padding: '20px 0',
    },
    tinyButtonControlWrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '18px 0 0',
        gap: 12,
    },
    normalButtonControlWrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 0 18px',
        gap: 12,
    },
    badgeContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8, 
        '& label': {
            fontSize: 14,
            fontWeight: 500,
            color: color.GREY_DARK_1
        }
    },
    toggleButtonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 8, 
        '& label': {
            fontSize: 10,
            fontWeight: 400,
            color: color.GREY_LIGHT_1
        }
    },
    footer: {
        display: 'flex', 
    },
    footerRightContent: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
        gap: 12
    },
    messageContainer: {
      display: 'flex', 
      padding: '8px 0 0'
    },
    badgeWrapper: {
        width: 364,
        height: 330,
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: color.GREY_LIGHT_6,
        padding: '32px 32px 20px',
        marginRight: 15,
    },
    uploadImageWrapper: {
        display: 'flex', 
        width: 80,
        flexDirection: 'column',
        gap: 4,
    },
    label: {
        fontSize: 14,
        fontWeight: 400,
        color: color.GREY_DARK_1,
    },
    rigthContainer: {
      display: 'flex', 
      flexDirection: 'column',
    },
    messageTitle: {
      fontWeight: 700
    },
    image: {
      margin: '0 20px',
    },
    button: {
      width: 'fit-content',
      padding: 0,
    }
  }));

type ColorType = 'GREEN' | 'WHITE' | 'BLACK';
type MessageType = 'CASH_BACK' | 'DISCOUNT' | 'POINTS' | 'NO_REWARD';
type ErrorType = 'SIZE' | 'FORMAT' | 'DIMENSION' | 'UNKNOWN' | undefined;

type Props = {
    onClose: () => void;
}

export function ManagePlugin({ onClose }: Props) {
    const { t } = useTranslation('translation', { keyPrefix: 'platforms' });
    const classes = useStyles();

    const [color, setColor] = useState<ColorType>('WHITE');
    const [messageType, setMessageType] = useState<MessageType>('CASH_BACK');
    const [title, setTitle] = useState('');
    const [charLeft, setCharLeft] = useState(25);
    const [showPoweredByKartera, setShowPoweredByKartera] = useState(true);
    const [imageError, setImageError] = useState<ErrorType>();
    const [customLogo, setCustomLogo] = useState<string>();

    const accountType = 'STARTER'; //should get this data from api in the future

    const isDefault = color === 'WHITE' && messageType === 'CASH_BACK' &&
                    title === '' && showPoweredByKartera && !customLogo;  

    function handleReset() {
        setColor('WHITE');
        setMessageType('CASH_BACK');
        setTitle('');
        setCharLeft(25);
        setShowPoweredByKartera(true);
        setImageError(undefined);
        setCustomLogo(undefined);
    }

    function handleTitle(text: string) {
        if (text.length <= 25) {
            setTitle(text);
            setCharLeft(25 - text.length);
        }
    }

    async function handleImageUpload(event: any) {
        try {
            setImageError(undefined);
            const file = event.target.files[0];
   
            if (!file) return;
            if (file.size > 2 * 1024 * 1024) {
                setImageError('SIZE');
                return;
            }
            if (!['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
                setImageError('FORMAT');
                return;
            }
            const image = new Image();
            image.onload = () => {
                if (image.width < 22 || image.height < 22) {
                    console.log('Inside the onLoad --> ', image, image.width, image.height, image.width < 22, image.height < 22);
                    setImageError('DIMENSION');
                    return;
                }
                setCustomLogo(image.src);
            };
            image.onerror = () => {
                setImageError('UNKNOWN');
                return;
            };
            image.src = window.URL.createObjectURL(file);
       
        } catch (error: any) {
            setImageError('UNKNOWN');
        }
    }

    return (
        <>
            <div className={classes.container}>
                <Typography className={classes.title}>
                    {t('customizeTitle')}
                </Typography>
                <p className={classes.text}>
                    {t('customizeText')}
                </p>
                <div className={classes.content}>
                    <div className={classes.badgeWrapper}>
                        <div className={classes.badgeContainer}>
                            <label>{t('normalButton')}</label>
                            <KarteraPluginBadge
                                badgeType='NORMAL'
                                messageType={messageType}
                                title={title}
                                hidePoweredByKartera={!showPoweredByKartera}
                                customLogoSource={customLogo}
                            />    
                        </div>
                        <div className={classes.badgeContainer}>
                            <label>{t('tinyButton')}</label>
                            <KarteraPluginBadge 
                                badgeType='TINY'
                                tinyBadgeVariant={color}
                                customLogoSource={customLogo}
                            />                                            
                        </div>
                        <div className={classes.toggleButtonsContainer}>
                            <label>{t('toggleMessageTypes')}</label>
                            <KarteraSegmentedButton
                                items={[
                                    {text: t('cashBack'), onClick: () => setMessageType('CASH_BACK')},
                                    {text: t('discount'), onClick: () => setMessageType('DISCOUNT')},
                                    {text: t('points'), onClick: () => setMessageType('POINTS')},
                                    {text: t('noRewards'), onClick: () => setMessageType('NO_REWARD')},
                                ]}
                            />                
                        </div>

                    </div>
                    <div className={classes.rigthContainer}>
                        <div className={classes.normalButtonControlWrapper}>
                            <label className={classes.subtitle}>
                                {t('normalButton')}
                            </label>
                            <div className={classes.uploadImageWrapper}>
                                <label className={classes.label}>{t('image')}:</label>
                                <KarteraUploadButton
                                    onChange={handleImageUpload}
                                    fileType="image/jpeg, image/png"
                                    type='primary'
                                    text={t('upload')}
                                />
                            </div>
                            <div>
                                <KarteraTextField 
                                    label={`${t('title')}:`}
                                    labelStyle='simple'
                                    placeholder={t('payByBank')}
                                    fullWidth
                                    value={title}
                                    onChange={(e: any) => handleTitle(e.target.value)}
                                />
                                <div className={classes.smallLabel}>
                                    {`${charLeft} ${t('charLeft')}`}
                                </div>
                            </div>
                            <div className={classes.ugradeText}>
                                <KarteraSwitch
                                    label={t('poweredByKartera')}
                                    checked={showPoweredByKartera}
                                    onClick={() => setShowPoweredByKartera(prev => !prev)}
                                    color="success"
                                    disabled={accountType === 'STARTER'}
                                />
                                <Trans i18nKey={'upgradeYourAccount'}>
                                    {t('upgradeYourAccount')}
                                </Trans>
                            </div>
                        </div>
                        <Divider/>
                        <div className={classes.tinyButtonControlWrapper}>
                            <label className={classes.subtitle}>
                                {t('tinyButton')}
                            </label>
                            <KarteraSelectField
                                items={[
                                    {text: t('white'), value: 'WHITE'},
                                    {text: t('black'), value: 'BLACK'},
                                    {text: t('green'), value: 'GREEN'},
                                ]}
                                onChange={(e: any) => setColor(e.target.value)}
                                value={color}
                                label={`${t('style')}:`}
                                labelStyle='simple'
                                width='220px'
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.footer}>
                    <KarteraButton
                        variant='link'
                        text='Reset to Default'
                        onClick={handleReset}
                        disabled={isDefault}
                    />
                    <div className={classes.footerRightContent}>
                        <KarteraButton
                            text='Cancel'
                            variant='secondary'
                            onClick={onClose}
                        />
                        <KarteraButton
                            text='Save'
                            variant='primary'
                            disabled={isDefault}
                        />
                    </div>
                </div>
            </div>
            {imageError && (
                <UploadImageErrorDialogue 
                    title={t(`${imageError}ErrorTitle`)}
                    text={t(`${imageError}ErrorText`)}
                    onCancel={() => setImageError(undefined)}
                    onChooseImage={handleImageUpload}
                />
            )}   
        </>
    );
}