import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, FormGroup, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { color } from '../../../consts/ColorConst';
import { useApi } from '../../../services/api/hooks';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { AppRootState } from '../../../reducers';
import { KarteraButton } from '../../../components/kartera/button';
import { KarteraAvatar } from '../../../components/kartera/avatar';
import { KarteraFormField } from '../../../components/kartera/formField';
import { KarteraUploadButton } from '../../../components/kartera/uploadButton';
import { KarteraAlert } from '../../../components/kartera/alert';
import { useAuth } from '../../../context/Auth';
import { useCookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faPen,
  faCircleQuestion,
  faShieldKeyhole,
} from '@fortawesome/pro-regular-svg-icons';
import { ChangePasswordModal } from '../changePasswordModal';
import { KarteraTooltip } from '../../../components/kartera/tooltip';
import { TwoFAEnableModal } from '../twoFAEnableModal';
import { TwoFADisableModal } from '../twoFADisableModal';
import { setPartnerUser, setPartnerUserAvatar } from '../../../reducers/partnerUserReducers';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '440px',
    padding: '20px 12px',
  },
  editButtonWrapper: {
    position: 'absolute',
    display: 'flex',
    left: '82%',
    width: '120px',
    justifyContent: 'flex-end',
  },
  saveButton: {
    marginRight: '10px',
  },
  messageWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0',
    gap: 6,
  },
  messageText: {
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.2px',
    color: color.GRAY_DARK_04,
  },
  messageEmail: {
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.2px',
    color: color.GREEN,
  },
  avatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: '126px',
    width: '400px',
    height: '64px',
    margin: '12px 0',
    gap: 10,
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px 0 30px',
    gap: 12,
  },
  textRow: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  buttonTwoFA: {
    padding: '0 8px',
  },
  textTitle: {
    width: 120,
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY_DARK_1,
  },
  textDesc: { 
    fontSize: 14, 
    fontWeight: 400, 
    color: color.GREY_DARK_4, 
  },
  disabledWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  changePassWrapper: {
    padding: '0 0 0 124px',
  },
  OTPHintWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
  },
  hintText: {
    fontSize: 11,
    fontWeight: 200,
    color: color.GREY_LIGHT_6, 
  },
  learnMore: {
    textDecoration: 'none',
    fontWeight: 600,
    marginLeft: 4,
    color: color.GREY_LIGHT_6,
  },
}));

const reduxConnector = connect((state: AppRootState) => ({
  partnerUserId: state.partnerUserState.id,
  partnerUserFirstName: state.partnerUserState.first_name,
  partnerUserLastName: state.partnerUserState.last_name,
  partnerUserRole: state.partnerUserState.role,
  partnerUserEmail: state.partnerUserState.email,
  partnerUserAvatarImage: state.partnerUserState.avatar_image,
}));

type Props = ConnectedProps<typeof reduxConnector>;

type ErrorType = 'SIZE' | 'TYPE' | 'UNKNOWN' | undefined;

function Account({
  partnerUserId,
  partnerUserFirstName,
  partnerUserLastName,
  partnerUserRole,
  partnerUserEmail,
  partnerUserAvatarImage,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { partner } = useAuth();
  const {
    postPartnerUserAvatar,
    removePartnerUserAvatar,
    updatePartnerUserById,
    getAuthTotpConnect,
    getPartnerUserById,
  } = useApi();
  const { t } = useTranslation('translation', { keyPrefix: 'settings' });

  const [cookies] = useCookies(['lynk.partner-id', 'lynk.api-version']);
  const hasPartner = Boolean(cookies['lynk.partner-id']);

  const [imageError, setImageError] = useState<ErrorType>();
  const [editMode, setEditMode] = useState(false);
  const [passModalOpen, setPassModalOpen] = useState(false);
  const [twoFAModalOpen, setTwoFAModalOpen] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  const [twoFADisableModalOpen, setTwoFADisableModalOpen] = useState(false);

  const isStaffAdmin = pathname.includes('staff-admin');

  const getdata = useCallback(async () => {
    const response = await getAuthTotpConnect();
    setIs2FAEnabled(response.enabled);
  }, []);

  useEffect(() => {
    setFirstName(partnerUserFirstName);
    setLastName(partnerUserLastName);
    getdata();
  }, [twoFAModalOpen]);

  async function uploadAvatarImage(event: any) {
    try {
      const file = event.target.files[0];
      if (!file) return;
      if (file.size > (2 * 1024 * 1024)) {
          setImageError('SIZE');
          return;
      }
      if (!['image/jpeg','image/png','image/jpg'].includes(file.type)) {
          setImageError('TYPE');
          return;
      }

      setImageError(undefined);
      const formData = new FormData();
      formData.append("image", file);
      
      if (partnerUserId) {
        await postPartnerUserAvatar(partnerUserId, formData);
        const { avatar_image } = await getPartnerUserById(partnerUserId);
        dispatch(setPartnerUserAvatar(avatar_image));
      }
    } catch (error: any) {
      setImageError('UNKNOWN');
    }
  }

  async function onDeleteClick() {
    if (partnerUserId) {
      const response = await removePartnerUserAvatar(partnerUserId);
      if (response.id) {
        dispatch(setPartnerUserAvatar(''));
      }
    }
  }

  const role =
    partnerUserRole === 'OWNER'
      ? t('roleOwner')
      : partnerUserRole === 'ADMIN'
      ? t('roleAdmin')
      : partnerUserRole === 'INVITED'
      ? t('roleReadOnly')
      : partnerUserRole === 'READ_ONLY'
      ? t('roleReadOnly')
      : '';

  const onCancelClick = () => {
    setEditMode(false);
    setFirstName(partnerUserFirstName);
    setLastName(partnerUserLastName);
  };

  const onSaveClick = async () => {
    if (!partner?.id) return;
    const partnerUser = await updatePartnerUserById(partnerUserId, {
      legal_first_name: firstName,
      legal_last_name: lastName,
    });
    if (partnerUser?.id) {
      dispatch(setPartnerUser({
          id: partnerUser.id,
          first_name: partnerUser.legal_first_name,
          last_name: partnerUser.legal_last_name,
          name: `${partnerUser.legal_first_name} ${partnerUser.legal_last_name}`,
          role: partnerUser.role,
          email: partnerUser.work_email,
          avatar_image: partnerUser.avatar_image,
      }));
      setEditMode(false);
    }
  };

  return (
    <Box className={classes.container}>
      {imageError && (
        <KarteraAlert
          severity='error'
          supressCloseButton
          text={
            <>
              <p style={{ fontWeight: 500 }}>{t(`imageError${imageError}Title`)}</p>
              <p>{t(`imageError${imageError}Text`)}</p>
            </>
          }
        />
      )}
      {editMode ? (
        <Box className={classes.editButtonWrapper}>
          <KarteraButton
            variant='primary'
            text={t('buttonSave')}
            onClick={() => onSaveClick()}
            className={classes.saveButton}
          />
          <KarteraButton
            variant='secondary'
            text={t('buttonCancel')}
            onClick={() => onCancelClick()}
          />
        </Box>
      ) : (
        <Box className={classes.editButtonWrapper}>
          <KarteraButton
            variant='secondary'
            text={t('edit')}
            onClick={() => setEditMode(true)}
            icon={<FontAwesomeIcon icon={faPen} width={'12px'} />}
            disabled={partnerUserId === '' ? true : false}
          />
        </Box>
      )}
      {editMode ? (
        <Box className={classes.avatarWrapper}>
          <KarteraAvatar
            size={{ width: 64, height: 64 }}
            variant='circular'
            alt='User avatar'
            color='secondary'
            src={partnerUserAvatarImage}
          />
          <KarteraUploadButton
            onChange={uploadAvatarImage}
            fileType='image/jpeg, image/png'
            type={partnerUserAvatarImage ? 'secondary' : 'primary'}
            text={partnerUserAvatarImage ? t('buttonChangePicture') : t('buttonUpload')}
            disabled={!hasPartner}
          />
          {partnerUserAvatarImage && (
            <FontAwesomeIcon
              icon={faTrash}
              width={'12px'}
              style={{ cursor: 'pointer', color: color.RED_DARK_1 }}
              onClick={onDeleteClick}
            />
          )}
        </Box>
      ) : (
        <Box className={classes.avatarWrapper}>
          <KarteraAvatar
            size={{ width: 64, height: 64 }}
            variant='circular'
            alt='User avatar'
            color='secondary'
            src={partnerUserAvatarImage}
          />
        </Box>
      )}
      {editMode ? (
        <FormGroup className={classes.formWrapper}>
          <KarteraFormField
            width='400px'
            labelWidth='180px'
            direction='horizontal'
            label={t('firstName')}
            value={firstName}
            onChange={(event) => {
              setFirstName(event.target.value);
            }}
            fullWidth
          />
          <KarteraFormField
            width='400px'
            labelWidth='180px'
            direction='horizontal'
            label={t('lastName')}
            value={lastName}
            onChange={(event) => {
              setLastName(event.target.value);
            }}
            fullWidth
          />
          <KarteraFormField
            width='400px'
            labelWidth='180px'
            direction='horizontal'
            label={t('email')}
            value={partnerUserEmail}
            fullWidth
            disabled
          />
          {!isStaffAdmin && (
            <KarteraFormField
              width='400px'
              labelWidth='180px'
              direction='horizontal'
              label={t('role')}
              value={role}
              fullWidth
              disabled
            />
          )}
        </FormGroup>
      ) : (
        <Box className={classes.formWrapper}>
          <Box className={classes.textRow}>
            <Typography className={classes.textTitle}>{t('firstName')}</Typography>
            <Typography className={classes.textDesc}> {partnerUserFirstName}</Typography>
          </Box>
          <Box className={classes.textRow}>
            <Typography className={classes.textTitle}>{t('lastName')}</Typography>
            <Typography className={classes.textDesc}> {partnerUserLastName}</Typography>
          </Box>
          <Box className={classes.textRow}>
            <Typography className={classes.textTitle}>{t('email')}</Typography>
            <Typography className={classes.textDesc}> {partnerUserEmail}</Typography>
          </Box>
          <Box className={classes.textRow}>
            <Typography className={classes.textTitle}>{t('role')}</Typography>
            <Typography className={classes.textDesc}> {role}</Typography>
          </Box>
          <Box className={classes.textRow}>
            <Typography className={classes.textTitle}>{t('apiVersion')}</Typography>
            <Typography className={classes.textDesc}> {cookies['lynk.api-version']}</Typography>
          </Box>
          <Box className={classes.textRow}>
            <Typography className={classes.textTitle}>{t('twoFAMethodLabel')}</Typography>
            {is2FAEnabled ? (
              <Box className={classes.disabledWrapper}>
                <FontAwesomeIcon
                  icon={faShieldKeyhole}
                  fontSize={14}
                  color={color.GREY_DARK_4}
                />
                <Typography className={classes.textDesc}>
                  {t('authAppText')}
                </Typography>
                <KarteraButton
                  text={t('buttonDisable')}
                  onClick={() => setTwoFADisableModalOpen(true)}
                  className={classes.buttonTwoFA}
                  variant={'tertiary'}
                />
              </Box>
            ) : (
              <KarteraButton
                text={t('buttonTwoFA')}
                onClick={() => setTwoFAModalOpen(true)}
                className={classes.buttonTwoFA}
                disabled={partnerUserId === '' ? true : false}
              />
            )}
            <KarteraTooltip
              placement='right'
              title={(
                <>
                  {is2FAEnabled ? t('tooltipTextEnabled') : (
                  <Box className={classes.OTPHintWrapper}>
                    <Typography className={classes.hintText}>
                      {t('tooltipText1')}
                    </Typography>
                    <Typography className={classes.hintText}>
                      {t('tooltipText2')}
                      <a 
                        className={classes.learnMore} 
                        href={'https://www.kartera.com/blog'}
                        target='_blank'
                        rel="noreferrer"
                      >
                        {t('learnMore')}
                      </a>           
                    </Typography>
                  </Box>  
                  )}
                </>
              )}
              width={'230px'}
            >
              <FontAwesomeIcon
                icon={faCircleQuestion}
                fontSize={14}
                color={color.GREY_DARK_1}
              />
            </KarteraTooltip>
          </Box>
          <Box className={classes.changePassWrapper}>
            <KarteraButton
              variant='secondary'
              text={t('buttonChangePass')}
              onClick={() => setPassModalOpen(true)}
              disabled={partnerUserId === '' ? true : false}
            />
          </Box>
        </Box>
      )}
      {passModalOpen && (
        <ChangePasswordModal onModalOpen={setPassModalOpen} partnerUserId={partnerUserId} />
      )}
      {twoFAModalOpen && <TwoFAEnableModal onModalOpen={setTwoFAModalOpen} />}
      {twoFADisableModalOpen && <TwoFADisableModal onModalOpen={setTwoFADisableModalOpen} />}
      {!isStaffAdmin && editMode && (
        <Box className={classes.messageWrapper}>
          <Typography className={classes.messageText}>{t('changeEmailMessage')}</Typography>
          <Typography className={classes.messageEmail}>support@kartera.com.</Typography>
        </Box>
      )}
    </Box>
  );
}

export default reduxConnector(Account);
