import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { color } from '../../../consts/ColorConst';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KarteraButton } from '../../../components/kartera/button';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles((theme: any) => ({
  root: { 
    width: '100%', 
    height: "440px",
    padding: "10px 4px 0px 0",
  },
  container: {
    width: "100%",
    height: "100%",
    padding: "10px 16px 18px",
    "&::-webkit-scrollbar": {
      width: 7,
    },
    "&::-webkit-scrollbar-track": {
      background: "#fff",
    },
    "&::-webkit-scrollbar-thumb": {
      background: color.GRAY_02,
      borderRadius: 4,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: color.GRAY, 
    }
  },
  gridWrapper: {
    padding: 0,
    gap: 0,
  },
  text: {
    fontSize: '14px',
    fontWeight: 400,
    color: color.GREY_DARK_2,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  innerContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  headerAbsoluteContainer: {
    position: 'absolute',
    width: 180,
    left: '424px',
    top: '72px',
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  panelWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  modalApiContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 8,
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: 500,
    color: color.GREY_DARK_3,
  },
}));

export function IpWhitelist() {
  const classes = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'whitelist' })

  return (
    <Box className={classes.root}>
      <Box className={classes.container} data-testid='whitelist-page-container'>
        <Box className={classes.innerContent}>
          <Typography className={classes.text}>
            {t('messageP1')}
          </Typography>
          <Typography className={classes.text}>
            {t('messageP2')}
          </Typography>
          <Box className={classes.headerContainer}>
            <KarteraButton
              text={t('addIp')}
              icon={<FontAwesomeIcon icon={faPlus} width={20} />}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
