import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { color } from "../consts/ColorConst";
import { truncateText } from "../utils/helperFunctions";
import commafy from "../utils/commafy";
import { LynkTooltip } from "../components/common/tooltip";
import mccCodes from "../consts/mccCodes.json";

const useStyles = makeStyles(() => ({
  merchantDescriptionWrapper: {
    display: "flex",
    alignItems: "center", 
    gap: 4
  },
  transactionDescription: {
    width: "100%", 
    overflow: "hidden", 
    textOverflow: "ellipsis"
  },
  tooltip: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    padding: 2,
  }
}));


export const useTransactions = () => {
  const classes = useStyles();

  function getFormattedValue(params: GridValueGetterParams) {
    return `${commafy(params.value)}$`;
  }

  function getMerchantMid(params: GridValueGetterParams) {
    return params.row.merchant.mid;
  }

  function getMerchantName(params: GridValueGetterParams) {
    return params.row.merchant.name;
  }

  function renderMercantCode(params: GridRenderCellParams) {
    if (params.row.merchant.mcc) {
      return (
        <Box className={classes.merchantDescriptionWrapper}>
          {/* eslint-disable-next-line */}
          {/* @ts-ignore-next-line */}
          {truncateText(mccCodes[params.row.merchant.mcc]?.irs_description, 25) || ''}
          <LynkTooltip
            placement='right'
            title={(
              <div className={classes.tooltip}>
                {/* eslint-disable-next-line */}
                {/* @ts-ignore-next-line */}
                <span>{mccCodes[params.row.merchant.mcc]?.edited_description}</span>
                <span>{`Category Code: ${params.row.merchant.mcc}`}</span>
              </div>
            )}
          >
            <FontAwesomeIcon icon={faInfoCircle} fontSize={14} color={color.GRAY} />
          </LynkTooltip>
        </Box>
      );
    } else return null;
  }

  function renderTransactionDescription(params: GridRenderCellParams) {
    return (
      <LynkTooltip
        placement="top"
        title={params.row.description}
      >
        <Box className={classes.transactionDescription}>
          {params.row.description}
        </Box>
      </LynkTooltip>
    );
  }

  function getMerchantCountry(params: GridValueGetterParams) {
    return params.row.merchant.address.country;
  }

  function getMerchantState(params: GridValueGetterParams) {
    return params.row.merchant.address.state;
  }

  function getMerchantCity(params: GridValueGetterParams) {
    return params.row.merchant.address.city;
  }

  function getMerchantPostalCode(params: GridValueGetterParams) {
    return params.row.merchant.address.postal_code;
  }

  return {
    renderTransactionDescription,
    getMerchantMid,
    getMerchantName,
    renderMercantCode,
    getFormattedValue,
    getMerchantCity,
    getMerchantState,
    getMerchantPostalCode,
    getMerchantCountry,
  };
};
