import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  hasMessage: false,
  message: "",
};

const apiMessageSlice = createSlice({
  name: 'apiMessage',
  initialState,
  reducers: {
    setApiHasMessage: (state, action: PayloadAction<boolean>) => {
      state.hasMessage = action.payload;
    },
    setApiMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
  },
});

export const { setApiHasMessage, setApiMessage } = apiMessageSlice.actions;

export default apiMessageSlice.reducer;