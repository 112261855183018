import { FC } from "react";
import { makeStyles } from "@mui/styles";
import { Chip } from "@mui/material";
import { color } from "../../../consts/ColorConst";
import { ButtonProps } from "./types";

const useStyles = makeStyles((theme: any) => ({
  root:{
    height: "28px !important",
    border: '1px solid transparent',
    [theme.breakpoints.down('md')]: {
      height: "34px !important",
    },
  },
  small:{
    height: "20px !important",
  },
  label: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
  },
  labelSmall: {
    fontSize: "11px",
    fontWeight: 500,
  },
  deleteIcon: {
    fontSize: '16px !important'
  },
  deleteIconSmall: {
    fontSize: '14px !important'
  },
  iconWrapper: {
    display: "flex",
    width: 12,
    height: 12,
    paddingLeft: 7,
    alignItems: "center",
  },
  primaryIconWrapper: {
    "&& path": { fill: color.GREEN_DARK_2 },
  },
  secondaryIconWrapper: {
    "&& path": { fill: color.GREY_DARK_2 },
  },
  negativeIconWrapper: {
    "&& path": { fill: color.RED_DARK_2 },
  },
  positiveIconWrapper: {
    "&& path": { fill: color.GREEN_DARK_2 },
  },
  noticeIconWrapper: {
    "&& path": { fill: color.ORANGE_DARK_2 },
  },
  primary: {
    backgroundColor: color.GREEN_LIGHT_7,
    color: color.GREEN_DARK_2,
    borderColor: color.GREEN_LIGHT_1,
    "& path": { 
      fill: color.GREEN_LIGHT_1,
      "&:hover": { fill: color.GREEN_DARK_1 }
    },
    "&:hover": { 
      backgroundColor: color.GREEN_LIGHT_6, 
      boxShadow: theme.shadow.elevation4Green,  
    },
    "&:active": { 
      backgroundColor: color.GREEN_LIGHT_5,
      boxShadow: theme.shadow.elevation4Green,  
    },
    "&:disabled": { 
      backgroundColor: color.GREEN_LIGHT_7,
    },
  },
  secondary: {
    backgroundColor: color.GREY_LIGHT_5,
    color: color.GREY_DARK_1,
    borderColor: color.GREY,
    "& path": { 
      fill: color.GREY_LIGHT_1,
      "&:hover": { fill: color.GREY_DARK_2 }
    },
    "&:hover": { 
      backgroundColor: color.GREY_LIGHT_4,
      boxShadow: theme.shadow.elevation4Grey,  
    },
    "&:active": { 
      backgroundColor: color.GREY_LIGHT_3,
      boxShadow: theme.shadow.elevation4Grey, 
    },
    "&:disabled": { backgroundColor: color.GREY_LIGHT_5 },
  },
  negative: {
    backgroundColor: color.RED_LIGHT_5,
    color: color.RED_DARK_2,
    borderColor: color.RED,
    "& path": { 
      fill: color.RED,
      "&:hover": { fill: color.RED_DARK_2 }
    },
    "&:hover": { 
      backgroundColor: color.RED_LIGHT_4,
      boxShadow: theme.shadow.elevation4Red, 
    },
    "&:active": { 
      backgroundColor: color.RED_LIGHT_3,
      boxShadow: theme.shadow.elevation4Red,
    },
    "&:disabled": { backgroundColor: color.RED_LIGHT_5 },
  },
  positive: {
    backgroundColor: color.GREEN_LIGHT_6,
    color: color.GREEN_DARK_2,
    borderColor: color.GREEN,
    "& path": { 
      fill: color.GREEN,
      "&:hover": { fill: color.GREEN_DARK_2 }
    },
    "&:hover": { 
      backgroundColor: color.GREEN_LIGHT_5,
      boxShadow: theme.shadow.elevation4Green,
    },
    "&:active": { 
      backgroundColor: color.GREEN_LIGHT_4,
      boxShadow: theme.shadow.elevation4Green, 
    },
    "&:disabled": { backgroundColor: color.GREEN_LIGHT_5 },
  },
  notice: {
    backgroundColor: color.ORANGE_LIGHT_5,
    color: color.ORANGE_DARK_2,
    borderColor: color.ORANGE,
    "& path": { 
      fill: color.ORANGE,
      "&:hover": { fill: color.ORANGE_DARK_2 }
    },
    "&:hover": { 
      backgroundColor: color.ORANGE_LIGHT_4,
      boxShadow: theme.shadow.elevation4Orange, 
    },
    "&:active": { 
      backgroundColor: color.ORANGE_LIGHT_3,
      boxShadow: theme.shadow.elevation4Orange,
    },
    "&:disabled": { backgroundColor: color.ORANGE_LIGHT_5 },
  },
}));

export const KarteraChip: FC<ButtonProps> = ({
  id,
  text,
  component,
  icon,
  size,
  onClick,
  onDelete,
  variant = "primary",
  disabled = false,
}) => {
  const classes = useStyles();

  return (
    <Chip
      id={id}
      className={`${classes[variant]}`}
      classes={{ 
        root: classes.root,
        sizeSmall: classes.small,
        label: classes.label, 
        labelSmall: classes.labelSmall,
        deleteIcon: classes.deleteIcon,
        deleteIconSmall: classes.deleteIconSmall,
      }}
      size={size}
      label={text || component}
      onClick={onClick}
      disabled={disabled}
      onDelete={onDelete}
      onMouseDown={(event: any) => {
        event.stopPropagation();
      }}
      icon={
        <>
          {icon && (
            <div
              className={`
                ${classes.iconWrapper} 
                ${classes[`${variant}IconWrapper`]}
              `}
            >
              {icon}
            </div>
          )}
        </>
      }
   />
  );
};
