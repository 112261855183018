import { FC } from "react";
import IconButton from "@mui/material/IconButton";
import { IconButtonProps } from "./types";

export const LynkIconButton: FC<IconButtonProps> = ({
  children,
  onClick,
  id,
  ...rest
}) => {
  return (
    <IconButton
      data-testid={id}
      color="default"
      size="large"
      onClick={ onClick && onClick }
      {...rest}
    >
      { children }
    </IconButton>
  );
};
