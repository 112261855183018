import React, { FC } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Theme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { color } from "../../../consts/ColorConst";
import { FormFieldProps } from "./types";
import { KarteraTextField } from "../textField";

const useStyles = makeStyles<Theme>((theme) => ({
  verticalDirection: {
    diplay: "flex",
    flexDirection: "column",
  },
  horizontalDirection: {
    diplay: "flex",
    flexDirection: "row",
  },
  labelStyle: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 12,
    color: color.GREY_DARK_4,
    textTransform: "capitalize",
  },
  horizontalLabel: {
    justifyContent: "flex-end",
    marginRight: "8px",
  },
  verticalLabel: {
    justifyContent: "flex-start",
    marginRight: "0",
  },
  labelDisabled: {
    color: color.GRAY_05,
  },
  required: {
    fontSize: "16px",
    fontWeight: 700,
    paddingLeft: "2px",
    color: color.RED_DARK_1,
  },
}));

export const KarteraFormField: FC<FormFieldProps> = ({
  id,
  label,
  value,
  type = "rest",
  variant = "text",
  direction = "vertical",
  fullWidth,
  subNote,
  placeholder,
  errorMessage,
  width = "100%",
  labelWidth = "fit-content",
  required,
  onChange,
  onBlur,
  onCancel,
  disabled,
  checkDangerousContent,
  setCheckError,
  ref,
  sx,
  spacious,
}) => {
  const classes = useStyles();

  return (
    <FormControl
      className={classes[direction + 'Direction']}
      variant='outlined'
      sx={{ ...sx, width: width }}
    >
      {label && (
        <Typography
          className={`
            ${classes.labelStyle} 
            ${classes[direction + 'Label']}
            ${disabled && classes.labelDisabled}
          `}
          sx={{ width: labelWidth }}
        >
          {label}
          {required && <span className={classes.required}>*</span>}
        </Typography>
      )}
      <KarteraTextField
        id={id}
        ref={ref}
        value={value}
        placeholder={placeholder}
        errorMessage={errorMessage}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        onCancel={onCancel}
        type={variant}
        subNote={subNote}
        fullWidth={fullWidth}
        spacious={spacious}
        checkDangerousContent={checkDangerousContent}
        setCheckError={setCheckError}
      />
    </FormControl>
  );
};
