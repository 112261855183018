import { FC, useEffect } from 'react';
import { SnackBarProps } from './types';
import { Box, Typography, Theme, Grow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { color } from '../../../consts/ColorConst';
import { KarteraButton } from '../button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    backgroundColor: color.GREY_DARK_3,
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 6,
    fontSize: 14,
    padding: '12px 16px',
    fontFamily: 'Roboto',
    [theme.breakpoints.down('md')]: {
      gap: 16,
    },
  },
  actionsBox: {
    display: 'flex',
    alignItems: 'center',
  },
  actionButtonWrapper: {
    paddingRight: 14,
  },
  actionButton: {
    width: 63,
  },
}));

export const KarteraSnackBar: FC<SnackBarProps> = ({
  severity,
  text,
  actionText,
  onActionClick,
  onCloseClick,
  width = '100%',
  timeToClose,
  open,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (timeToClose) {
      setTimeout(() => {
        onCloseClick && onCloseClick();
      }, timeToClose);
    }
  }, [timeToClose]);

  return (
    <Grow in={open} timeout={500}>
      <Box className={classes.root} sx={{ width: width }}>
        <Typography>{text}</Typography>
        {severity === 'action' && (
          <Box className={classes.actionsBox}>
            <Box className={classes.actionButtonWrapper}>
              <KarteraButton
                text={actionText}
                onClick={onActionClick && onActionClick}
                variant='secondary'
                className={classes.actionButton}
              />
            </Box>
          </Box>
        )}
        <FontAwesomeIcon
          icon={faClose}
          fontSize={12}
          style={{ cursor: 'pointer', marginLeft: 16 }}
          color={color.WHITE_01}
          onClick={onCloseClick}
          cursor={'pointer'}
        />
      </Box>
    </Grow>
  );
};
