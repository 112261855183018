import { FC } from "react";
import { RadioGroupProps } from "./types";
import { RadioGroup } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { KarteraRadioButton } from "../radioButton";
import { color } from "../../../consts/ColorConst";
import { sentenceCaseGenerator } from "../../../utils/helperFunctions";

const useStyles = makeStyles(() => ({
  container:{
    display: "flex",
    flexDirection: 'column',
  },
  radioGroup: {
    marginLeft: 12,
  },
  errorMessage: {
    fontSize: "11px",
    fontWeight: 400,
    color: color.RED,
    margin: 0,
  },
  label: {
    display: 'block',
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.4,
    marginBottom: 18,
    color: color.GREY_DARK_4,
  },
}));

export const KarteraRadioGroup: FC<RadioGroupProps> = ({
  items,
  itemsGap,
  error,
  errorMessage,
  disabled,
  sx,
  title,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container} style={sx}>
      {title && (
        <label 
          className={classes.label} 
          style={{ 
            color: disabled ? color.GREY_LIGHT_2 : color.GREY_DARK_4
          }}
        >
          {title}
        </label>
      )}
      <RadioGroup className={classes.radioGroup} sx={{ gap: itemsGap }} {...rest}>
        {items.map(item => (
            <KarteraRadioButton
              key={item.value}
              label={item.text}
              value={item.value}
              disabled={item.disabled || disabled}
              subnote={item.subnote}
              error={error}
            />
        ))}
      </RadioGroup>
      {errorMessage && (
        <p className={classes.errorMessage}>
          {sentenceCaseGenerator(errorMessage)}
        </p>
      )}
    </div>
  );
};
