import { createContext, useContext, useEffect, useState } from "react";
import storage from "redux-persist/lib/storage";
import { useCookies } from "react-cookie";
import { useApi } from "../services/api/hooks";
import { PartnerUserDTO } from "../dtos/partnerUsers/partnerUserDTO";
import { AuthContextType, AuthProviderProps, CurrentProductProps, 
         PartnerType, SignInProps } from "./types";
import { PartnerStatus } from "../consts/PartnerStatus";

export const AuthContext = createContext({} as AuthContextType);

type CookiesType = "lynk.access-token" | "lynk.signin-origin" | "lynk.partner-id" | 
"lynk.partner-name" | "lynk.partner-user-id" | "lynk.user-id" | "lynk.user-name" | 
"lynk.user-email" | "lynk.staff-user-id" | "lynk.api-version";

export const AuthContextProvider = ({ children }: AuthProviderProps) => {
  const { getPartnerById, getProducts, getPartnerUserById, getApiVersion, getPartnerUsersList } =
    useApi();
  const [cookies, setCookie, removeCookie] = useCookies([
    'lynk.access-token',
    'lynk.partner-id',
    'lynk.partner-user-id',
    'lynk.partner-name',
    'lynk.user-id',
    'lynk.user-name',
    'lynk.user-email',
    'lynk.staff-user-id',
    'lynk.signin-origin',
    'lynk.api-version',
  ]);

  const [partner, setPartner] = useState<PartnerType>();
  const [isLoadingPartner, setIsLoadingPartner] = useState(false);
  const [partnerUser, setPartnerUser] = useState<PartnerUserDTO>();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isContextUpdated, setIsContextUpdated] = useState(false);
  const [isTestEnvironment] = useState(false);
  const [currentProduct, setCurrentProduct] = useState<CurrentProductProps>({
    id: '',
    name: '',
    plan: '',
    status: '',
    accountId: '',
    ready: false,
    cardAuthEnabled: true,
  });

  async function registerPartner(newPartnerId: string, newPartnerName: string) {
    try {
      if (newPartnerId) {
        setCookie('lynk.partner-id', newPartnerId, { path: '/' });
        setCookie('lynk.partner-name', newPartnerName, { path: '/' });
        setPartner({ id: newPartnerId, isVerified: false });
      }
      await updatePartner();
    } catch (error) {
      console.error('Error registering partner:', error);
      // Consider adding more error handling here
    }
  }

  async function signIn({
    access_token,
    expires_at,
    partner_id,
    partner_name,
    partner_user_id,
    user_id,
    first_name,
    last_name,
    user_email,
    staff_user_id,
    is_internal_verified,
    origin,
  }: SignInProps) {
    try {
      const options = { path: '/', expires: new Date(expires_at) };
      storage.removeItem('persist:root');

      const response = await getApiVersion();
      setCookie('lynk.api-version', response.version, options);

      if (origin) setCookie('lynk.signin-origin', origin, options);
      if (user_id) setCookie('lynk.user-id', user_id, options);
      if (user_email) setCookie('lynk.user-email', user_email, options);
      if (first_name && last_name)
        setCookie('lynk.user-name', `${first_name} ${last_name}`, options);
      if (partner_name) setCookie('lynk.partner-name', partner_name, options);
      if (partner_user_id) setCookie('lynk.partner-user-id', partner_user_id, options);
      if (partner_id) {
        setCookie('lynk.partner-id', partner_id, options);
        setPartner({ id: partner_id, isVerified: is_internal_verified || false });
      }
      if (staff_user_id) setCookie('lynk.staff-user-id', staff_user_id, options);
      //access token should be the last one to be set in order to avoid triggering the useEffect
      if (access_token) setCookie('lynk.access-token', access_token, options);
    } catch (error) {
      console.error('Error during sign in:', error);
      // Handle or throw error as needed
    }
  }

  async function updatePartner(showLoading = false, forceUpdate = false) {
    if (isLoadingPartner && forceUpdate === false) return;
    try {
      setIsLoadingPartner(true);
      const partnerId = cookies['lynk.partner-id'];
      const accessToken = cookies['lynk.access-token'];
      const partnerUserId = cookies['lynk.partner-user-id'];
      if (!accessToken || !partnerId) return;
      const partnerResp = await getPartnerById(partnerId, showLoading, true);
      if (partnerResp?.id) {
        setPartner({
          id: partnerResp.id,
          isVerified: partnerResp.verification_status === PartnerStatus.VERIFIED,
          verificationStatus: partnerResp.verification_status,
        });
      }

      if (partnerUserId && partnerResp.verification_status === PartnerStatus.VERIFIED) {
        const partnerUser = await getPartnerUserById(partnerUserId);
        setPartnerUser(partnerUser);
      } else {
        const partnerUsers = await getPartnerUsersList();
        const filteredItem = partnerUsers.data.filter((item) => {
          return item.role === 'OWNER';
        });
        const partnerUser = await getPartnerUserById(filteredItem[0].id);
        setPartnerUser(partnerUser);
      }

      await updateProduct();

      return partnerResp;
    } catch (error) {
      console.error('Error updating partner:', error);
      setPartner({
        id: '',
        isVerified: false,
        verificationStatus: 'ERROR',
      });
      //TODO: we need to deal with error here (but not logout the user)
    } finally {
      setIsContextUpdated(true);
      setIsLoadingPartner(false);
    }
  }

  function setProductReady(productReady: boolean) {
    setCurrentProduct(prev => ({...prev, ready: productReady}));
  }

  async function updateProduct() {
    try {
      const response = await getProducts();
      if (response?.data) {
        const [currentProduct] = response.data;
        setCurrentProduct({
          id: currentProduct.id,
          name: currentProduct.name,
          plan: currentProduct.plan,
          status: currentProduct.status,
          accountId: currentProduct.account_id,
          ready: currentProduct.status === 'BANK_ACCOUNT_READY',
          cardAuthEnabled: currentProduct.card_auth_request_enabled,
        });
      }
      return currentProduct;
    } catch (error) {
      console.error("Error updating product:", error);
      //TODO: we need to deal with error here (but not logout the user)
    }
  }

  function getUserData() {
    if (!cookies["lynk.user-id"]) return undefined;
  
    return {
      id: cookies["lynk.user-id"],
      name: cookies["lynk.user-name"],
      email: cookies["lynk.user-email"],
    };
  }

  function logout() {
    try {
      const cookiesToRemove: CookiesType[] = [
        "lynk.access-token",
        "lynk.partner-id",
        "lynk.partner-user-id",
        "lynk.partner-name",
        "lynk.user-id",
        "lynk.user-name",
        "lynk.user-email",
        "lynk.staff-user-id",
        "lynk.signin-origin",
        "lynk.api-version",
      ];

      cookiesToRemove.forEach((cookie) => {
        removeCookie(cookie, { path: "/" });
      });
  
      storage.removeItem("persist:root");
      localStorage.clear();
      setIsAuthenticated(false);
      //reload window to clear all the state
      window.location.reload();
    } catch (error) {
      console.error("Error during logout:", error);
      // Add more error handling here
    }
  }

  useEffect(() => {
    async function updateAuthenticationAndPartner() {
      if (!cookies["lynk.access-token"]) {
        setIsAuthenticated(false);
      } else {
        setIsAuthenticated(true);
        await updatePartner();
      }
    }
  
    updateAuthenticationAndPartner();
  }, [cookies["lynk.access-token"]]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isContextUpdated,
        isLoadingPartner,
        isTestEnvironment,
        partner,
        partnerUser,
        currentProduct,
        setPartner,
        signIn,
        logout,
        registerPartner,
        updatePartner,
        getUserData,
        setProductReady,
        updateProduct,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context == null) {
    throw new Error("useAuth must be used within a AuthContextProvider");
  }
  return context as AuthContextType;
};
