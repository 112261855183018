import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  closed: false,
};

const accountConnectSlice = createSlice({
  name: 'accountConnect',
  initialState,
  reducers: {
    setAccountConnectClosed: (state, action: PayloadAction<boolean>) => {
      state.closed = action.payload;
    },
  },
});

export const { setAccountConnectClosed } = accountConnectSlice.actions;

export default accountConnectSlice.reducer;