import { Box, Skeleton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { color } from '../../../consts/ColorConst';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '270px',
    height: '266px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: color.WHITE_01,
    boxShadow: theme.shadow.elevation4Grey,
    borderRadius: 6,
    padding: '10px 16px 18px',
  },
  topContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    color: color.GREY_DARK_2,
  },
  range: {
    fontSize: 12,
    fontWeight: 400,
    color: color.GREY_LIGHT_2,
    marginBottom: 16,
  },
  chartWrapper: {
    flex: 1,
    width: '100%',
    height: '140px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  barWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 8,
  },
}));

type Props = {
  title: string,
}

export function KarteraChartCardSkeleton({ title }: Props ) {
  const classes = useStyles();

  return (
    <Box className={`${classes.root}`}>
      <Box className={classes.topContent}>
        <Typography className={classes.title}>
          {title}
        </Typography>
      </Box>
      <Typography className={classes.range}>
        <Skeleton variant="rounded" width={80} height={14} />
      </Typography>
      <Skeleton variant="rounded" width={120} height={42}/>

      <Box className={classes.chartWrapper}>
        {[30, 40, 50, 60, 70, 80, 90].map( h => (
          <Box key={h} className={classes.barWrapper}>
            <Skeleton variant="rounded" width={18} height={h} />
            <Skeleton variant="rounded" width={12} height={14} />
          </Box>
        ))}
      </Box>
    </Box>
  );
}
