import { useAccountsAPI } from "./useAccountsAPI";
import { useACHPaymentsAPI } from "./useACHPaymentsAPI";
import { usePaymentsAPI } from "./usePaymentsAPI";
import { useAuthAPI } from './useAuthAPI';
import { useCardsAPI } from './useCardsAPI';
import { useDocAPI } from "./useDocAPI";
import { usePartnersAPI } from "./usePartnersAPI";
import { usePartnerUsersAPI } from "./usePartnerUsersAPI";
import { useProductApiKeyAPI } from "./useProductApiKeyAPI";
import { useProductsAPI } from "./useProductsAPI";
import { useProductUsersAPI } from "./useProductUsersAPI";
import { useStaffAdminAPI } from "./useStaffAdminAPI";
import { useStaffAdminBillingAPI } from "./useStaffAdminBillingAPI";
import { useTransactionsAPI } from "./useTransactionsAPI";
import { useWebhooksAPI } from "./useWebhooksAPI";
import { useLinkedAccountAPI } from "./useLinkedAccountAPI";
import { useReportsAPI } from "./useReportsAPI";
import { useRewardsAPI } from "./useRewardsAPI";
import { useVersionAPI } from "./useVersionAPI";
import { useOpenSearchAPI } from "./useOpenSearchAPI";

export function useApi() {
    const accounts = useAccountsAPI();
    const achPayments = useACHPaymentsAPI();
    const payments = usePaymentsAPI();
    const auth = useAuthAPI();
    const cards = useCardsAPI();
    const partners = usePartnersAPI();
    const partnerUsers = usePartnerUsersAPI();
    const productApiKey = useProductApiKeyAPI();
    const products = useProductsAPI();
    const productUsers = useProductUsersAPI();
    const staffAdmin = useStaffAdminAPI();
    const transactions = useTransactionsAPI();
    const webhooks = useWebhooksAPI();
    const adminBilling = useStaffAdminBillingAPI();
    const docApi = useDocAPI();
    const linkedAccount = useLinkedAccountAPI();
    const reports = useReportsAPI();
    const rewards = useRewardsAPI();
    const version = useVersionAPI();
    const openSearch = useOpenSearchAPI();

    return {
      ...accounts,
      ...achPayments,
      ...auth,
      ...cards,
      ...partners,
      ...partnerUsers,
      ...productApiKey,
      ...products,
      ...productUsers,
      ...staffAdmin,
      ...transactions,
      ...webhooks,
      ...adminBilling,
      ...docApi,
      ...payments,
      ...linkedAccount,
      ...reports,
      ...rewards,
      ...version,
      ...openSearch,
    };
}