import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type OnboardingErrorType = {
  [key: string]: string;
};

const initialState = {
  currentStep: 1,
  errors: {} as OnboardingErrorType,
  ownCompany: '',
  brokerageOrExchange: '',
  ownPublicCompany: '',
  businessName: '',
  EIN: '',
  incorporationDate: '',
  incorporationCountry: 'US',
  incorporationState: '',
  legalStructure: '',
  businessCategory: '',
  otherBusinessCategory: '',
  description: '',
  phoneCodeBusiness: 'US',
  phoneNumberBusiness: '',
  url: '',
  countryBusiness: 'USA',
  unitNumberBusiness: '',
  addressBusiness: '',
  cityBusiness: '',
  stateBusiness: '',
  zipCodeBusiness: '',
  relationshipToCompany: '',
  name: '',
  lastname: '',
  citizenshipCode: '',
  dateOfBirth: '',
  gender: '',
  passportCode: 'US',
  passportNumber: '',
  nationalId: '',
  country: '',
  address: '',
  unitNumber: '',
  city: '',
  state: '',
  zipCode: '',
  phoneCode: 'US',
  phoneNumber: '',
  termsLynkStatementRead: false,
  termsAcceptAgreementAndPolicy: false,
  termsProvidedCorrectInfo: false,
  discardOnboarding: false,
  idempotencyId: '',
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    setOwnCompany: (state, action: PayloadAction<string>) => {
      state.ownCompany = action.payload;
    },
    setBrokerageExchange: (state, action: PayloadAction<string>) => {
      state.brokerageOrExchange = action.payload;
    },
    setOwnPublicCompany: (state, action: PayloadAction<string>) => {
      state.ownPublicCompany = action.payload;
    },
    setBusinessName: (state, action: PayloadAction<string>) => {
      state.businessName = action.payload;
    },
    setEIN: (state, action: PayloadAction<string>) => {
      state.EIN = action.payload;
    },
    setIncorporationDate: (state, action: PayloadAction<string>) => {
      state.incorporationDate = action.payload;
    },
    setIncorporationCountry: (state, action: PayloadAction<string>) => {
      state.incorporationCountry = action.payload;
    },
    setIncorporationState: (state, action: PayloadAction<string>) => {
      state.incorporationState = action.payload;
    },
    setLegalStructure: (state, action: PayloadAction<string>) => {
      state.legalStructure = action.payload;
    },
    setBusinessCategory: (state, action: PayloadAction<string>) => {
      state.businessCategory = action.payload;
    },
    setOtherBusinessCategory: (state, action: PayloadAction<string>) => {
      state.otherBusinessCategory = action.payload;
    },
    setDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    setPhoneCodeBusiness: (state, action: PayloadAction<string>) => {
      state.phoneCodeBusiness = action.payload;
    },
    setPhoneNumberBusiness: (state, action: PayloadAction<string>) => {
      state.phoneNumberBusiness = action.payload;
    },
    setUrl: (state, action: PayloadAction<string>) => {
      state.url = action.payload;
    },
    setCountryBusiness: (state, action: PayloadAction<string>) => {
      state.countryBusiness = action.payload;
    },
    setUnitNumberBusiness: (state, action: PayloadAction<string>) => {
      state.unitNumberBusiness = action.payload;
    },
    setAddressBusiness: (state, action: PayloadAction<string>) => {
      state.addressBusiness = action.payload;
    },
    setCityBusiness: (state, action: PayloadAction<string>) => {
      state.cityBusiness = action.payload;
    },
    setStateBusiness: (state, action: PayloadAction<string>) => {
      state.stateBusiness = action.payload;
    },
    setZipCodeBusiness: (state, action: PayloadAction<string>) => {
      state.zipCodeBusiness = action.payload;
    },
    setRelationshipToCompany: (state, action: PayloadAction<string>) => {
      state.relationshipToCompany = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setLastname: (state, action: PayloadAction<string>) => {
      state.lastname = action.payload;
    },
    setCitizenshipCode: (state, action: PayloadAction<string>) => {
      state.citizenshipCode = action.payload;
    },
    setDateOfBirth: (state, action: PayloadAction<string>) => {
      state.dateOfBirth = action.payload;
    },
    setGender: (state, action: PayloadAction<string>) => {
      state.gender = action.payload;
    },
    setPassportCode: (state, action: PayloadAction<string>) => {
      state.passportCode = action.payload;
    },
    setPassportNumber: (state, action: PayloadAction<string>) => {
      state.passportNumber = action.payload;
    },
    setNationalId: (state, action: PayloadAction<string>) => {
      state.nationalId = action.payload;
    },
    setCountry: (state, action: PayloadAction<string>) => {
      state.country = action.payload;
    },
    setAddress: (state, action: PayloadAction<string>) => {
      state.address = action.payload;
    },
    setUnitNumber: (state, action: PayloadAction<string>) => {
      state.unitNumber = action.payload;
    },
    setCity: (state, action: PayloadAction<string>) => {
      state.city = action.payload;
    },
    setState: (state, action: PayloadAction<string>) => {
      state.state = action.payload;
    },
    setZipCode: (state, action: PayloadAction<string>) => {
      state.zipCode = action.payload;
    },
    setPhoneCode: (state, action: PayloadAction<string>) => {
      state.phoneCode = action.payload;
    },
    setPhoneNumber: (state, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload;
    },
    setTermsLynkStatementRead: (state, action: PayloadAction<boolean>) => {
      state.termsLynkStatementRead = action.payload;
    },
    setTermsAcceptAgreementAndPolicy: (state, action: PayloadAction<boolean>) => {
      state.termsAcceptAgreementAndPolicy = action.payload;
    },
    setTermsProvidedCorrectInfo: (state, action: PayloadAction<boolean>) => {
      state.termsProvidedCorrectInfo = action.payload;
    },
    setDiscardOnboarding: (state, action: PayloadAction<boolean>) => {
      state.discardOnboarding = action.payload;
    },
    setIdempotencyId: (state, action: PayloadAction<string>) => {
      state.idempotencyId = action.payload;
    },
    setOnboardingError: (state, action: PayloadAction<OnboardingErrorType>) => {
      state.errors = { ...state.errors, ...action.payload };
    },
    setClearOnboardingState: () => initialState,  
  },
});

export const {
  setCurrentStep,
  setOwnCompany,
  setBrokerageExchange,
  setOwnPublicCompany,
  setBusinessName,
  setEIN,
  setIncorporationDate,
  setIncorporationCountry,
  setIncorporationState,
  setLegalStructure,
  setBusinessCategory,
  setOtherBusinessCategory,
  setDescription,
  setPhoneCodeBusiness,
  setPhoneNumberBusiness,
  setUrl,
  setCountryBusiness,
  setUnitNumberBusiness,
  setAddressBusiness,
  setCityBusiness,
  setStateBusiness,
  setZipCodeBusiness,
  setRelationshipToCompany,
  setName,
  setLastname,
  setCitizenshipCode,
  setDateOfBirth,
  setGender,
  setPassportCode,
  setPassportNumber,
  setNationalId,
  setCountry,
  setAddress,
  setUnitNumber,
  setCity,
  setState,
  setZipCode,
  setPhoneCode,
  setPhoneNumber,
  setTermsLynkStatementRead,
  setTermsAcceptAgreementAndPolicy,
  setTermsProvidedCorrectInfo,
  setDiscardOnboarding,
  setIdempotencyId,
  setOnboardingError,
  setClearOnboardingState,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;