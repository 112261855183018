import React, { FC } from "react";
import { BadgeProps } from "./types";
import { makeStyles } from "@mui/styles";
import { color } from "../../../consts/ColorConst";
import { Badge } from "@mui/material";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiBadge-badge": {
      height: "18px",
      minWidth: "18px",
      fontSize: "11px",
      fontFamily: "Roboto",
      margin: 5,
    },
  },
  default: {
    "& .MuiBadge-badge": {
      backgroundColor: color.RED_03,
    },
  },
  blue: {
    "& .MuiBadge-badge": {
      backgroundColor: color.BLUE_LIGHT_01,
    },
  },
  grey: {
    "& .MuiBadge-badge": {
      backgroundColor: color.GRAY,
    },
  },
  ghost: {
    "& .MuiBadge-badge": {
      color: color.GRAY_DARK_05,
      backgroundColor: "transparent",
    },
  },
  dot: {
    "& .MuiBadge-badge": {
      height: "10px",
      minWidth: "10px",
      borderRadius: "50%",
      backgroundColor: color.GREEN_03,
    },
  },
}));

export const LynkBadge: FC<BadgeProps> = ({
  icon,
  text,
  children,
  type = "default",
  max = 99,
}) => {
  const classes = useStyles();

  return (
    <Badge
      color="info"
      max={max}
      className={`${classes.root} ${classes[type]}`}
      badgeContent={icon ? icon : text}
      variant={type === "dot" ? "dot" : "standard"}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {children}
    </Badge>
  );
};
